export default class Monster {
  constructor(options) {
    const {name, variant, location, description, imageUrl, data} = options
    this.name = name
    this.variant = variant
    this.location = location
    this.description = description
    this.imageURL = imageUrl
    for (const [key, value] of Object.entries(data)) {
      this[key] = value
    }
  }

  set label(value) {}

  get label() {
    return (`${this.variant} ${this.name}` + (this.location ? ` (${this.location})` : '')).trim()
  }

}
