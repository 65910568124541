export class Item {

  /**
   * A physical inventory item, which has geometry and therefore can also be displayed on a map
   * @param options typical geometric, physical and logical properties of a dungeon item
   */

  constructor(options) {
    const {
      id,
      name,
      description,
      origin,
      extent,
      geometry,
      pattern,
      type,
      mass,
      mass_units,
      value,
      value_units,
      location = '',
      container_id,
      contents,
      skill_id,
      bonus,
      common_use,
      quantity = 0,
      capacity = 0
    } = options

    this.id = id
    this.name = name
    this.description = description
    this.origin = origin
    this.extent = extent
    this.geometry = geometry
    this.pattern = pattern
    this.type = type || 'rect'
    this.mass = mass
    this.mass_units = mass_units
    this.value = value
    this.value_units = value_units
    this.location = location
    this.container_id = container_id
    this.contents = contents
    this.skill_id = skill_id
    this.bonus = bonus
    this.common_use = common_use
    this.quantity = quantity
    this.capacity = capacity
  }

  locationLabel() {
    return this.location.charAt(0).toUpperCase() + this.location.slice(1)
  }
}
