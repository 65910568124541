/*
 * Various utilities for unit conversions & bonus calculations
 */

export function parseDiceRolls(expression) {
  const terms = expression.split(' ')
  return terms
    .map(term => term.match(/(\d*)d(\d+)(x(\d*))*/))
    .filter(dieExpr => !!dieExpr)
    .map(dieExpr => {
      const count = dieExpr[1] ? parseInt(dieExpr[1]) : 1
      const faces = dieExpr[2]
      const explode = dieExpr[3]
      return {count, faces, explode}
    })
    .flat()
}

export function sanitizeString(string) {
  let newString = ""
  let inTag = false
  for (let i = 0; i < string.length; i++) {
    let ch = string.charAt(i)
    if (ch === '<') {
      inTag = true
    }
    if (!inTag) {
      newString += ch
    }
    if (ch === '>') {
      inTag = false
    }
  }
  return newString
}

export function formatWeight(item) {
  return item.mass ? item.mass + ' ' + (item.mass_units || 'kg') : null
}

export function formatValue(item) {
  return item.value ? (item.description + ': ' + item.value + ' ' + (item.value_units)) : null
}

export function formatInt(intValue) {
  if (isNaN(intValue) || intValue === null) {
    return null
  }
  return intValue > 0 ? `+${intValue}` : intValue
}

const conversion_units = {
  // weight units
  'kg': 1.0,
  'g': 1000.0,
  'lb': 2.54,
  'lbs': 2.54,
  'oz': 35.27,
  // distance units
  'km': 0.001,
  'm': 1.0,
  'cm': 100.0,
  'mm': 1000.0,
  'ft': 3.280839895,
  'yd': 1.093613298,
  'in': 39.37007874,
  'mi': 0.000621371,
}

/**
 * Convert units to kilograms or meters
 *
 * @param value value to convert
 * @param units units for value
 * @returns {number} value converted to kg or m
 */
export function convert_to_kgm(value, units) {
  return value / (conversion_units[units] || 1.0)
}

export function totalMass(items) {
  return items.reduce((acc, item) => acc + convert_to_kgm(item.mass || 0, item.mass_units), 0)
}


export function parseAttack(content) {
  const attacks = content.split('/').map(data => parseAttackData(data))
  return {content, attacks}
}

function parseAttackData(data) {
  let attack = parseNonWeaponAttack(data)
  if (attack.bonus === undefined) {
    attack = parseWeaponAttack(data)
  }
  if (attack.bonus === undefined) {
    attack = parseSpecialAttack(data)
  }
  if (!attack.bonus) {
    const weapon = data.match(/(\d+)?(Melee|Missile|Spell|Poison|Special)/i)
    if (weapon) {
      attack.type = weapon[2]
      if (weapon[1]) {
        attack.bonus = +weapon[1]
      }
    }
  }
  if (data.includes('«')) {
    attack.same_round = true
  } else if (data.includes('√')) {
    attack.next_round = true
  }
  if (data.includes('*')) {
    attack.special = true
  }
  const both = data.match(/(both|Both)(\d*)/)
  if (both) {
    attack.both = true
    if (both[2]) {
      attack.probability = +both[2]
    }
  }
  if (Object.keys(attack).length === 0) {
    console.error('unable to parse row', data)
  }
  return attack
}

function parseSpecialAttack(data) {
  /*
   * Some attacks, such as dragon breath, are treated as elemental spell attacks
   * and use the following code without an attack size prefix:

   * FBolt = Fire Bolt
   * IBolt = Ice Bolt
   * LBolt = Lightning Bolt
   * SBolt = Shock Bolt
   * WBolt = Water Bolt
   * GCone = Poison Gas Cone (varying effects)
   * CBall = Cold Ball
   * FBall = Fire Ball
   * CCone = Cold Cone
   * FCone = Fire Cone
   *
   * Fire Breath (‘FBr’), Shock Breath (‘SBr’), and Lightning Breath (‘LBr’) indicate
   * a “Bolt” attack of the given type, or a cone attack with half of the given OB.
   * Ice Breath (‘IBr’) and Water Breath (‘WBr’) may usually only be used as bolts, unless specified otherwise.
   * Gas Breath (‘GBr’) and Cold Breath (‘CBr’) may usually only be used as cones.
   *
   * Plasma Breath (PBr) use Assault Plasma chart with Plasma Criticals
   * Dark Breath (DBr) use Lightning Bolt chart with Cold Criticals
   */
  const special = data.match(/(\d*)([FILSWGCPD])?(Bolt|Ball|Cone|Br)(\(.*\))?(\[.*\])?.*$/)
  let attack = {}
  if (special) {
    attack.bonus = +special[1]
    attack.element = special[2]
    attack.shape = special[3]
    if (special[4]) {
      attack = {...attack, ...parseCriticalEffects(special[4])}
    }
    if (special[5]) {
      attack = {...attack, ...parseAdditionalCrits(special[5])}
    }
  }
  return attack
}

export const speedModifiers = [
  {rate: 'Inching', code: 'IN', db: -25, evade: 0, charge: 0, initiative: -16},
  {rate: 'Creeping', code: 'CR', db: -20, evade: 0, charge: 0, initiative: -12},
  {rate: 'Very Slow', code: 'VS', db: -10, evade: 0, charge: 0, initiative: -8},
  {rate: 'Slow', code: 'SL', db: 0, evade: 0, charge: 0, initiative: -4},
  {rate: 'Medium', code: 'MD', db: 10, evade: 5, charge: -5, initiative: 0},
  {rate: 'Moderately Fast', code: 'MF', db: 20, evade: -10, charge: 0, initiative: +4},
  {rate: 'Fast', code: 'FA', db: 30, evade: 15, charge: -15, initiative: +8},
  {rate: 'Very Fast', code: 'VF', db: 40, evade: 20, charge: -20, initiative: +12},
  {rate: 'Blindingly Fast', code: 'BF', db: 50, evade: 25, charge: -20, initiative: +16}
]

/*
 * Some attacks are treated as weapon attacks using the following code without an attack size prefix:
 *
 * We = General weapon used based upon situation
 *
 * ba = battle axe
 * bs = broadsword
 * bo = bola
 * cl = club
 * cb = composite bow
 * da = dagger
 * fa = falchion
 * ha = hand axe
 * ja = javelin
 * lb = long bow
 * xl = light cross bow
 * ma = mace
 * la = mounted lance
 * pa = pole arm
 * qs = quarter staff
 * ro = rock (Large Crush)
 * sb = short bow
 * sc = scimitar
 * sl = sling
 * sp = spear
 * ss = short sword
 * ts = two handed sword
 * sh = shuriken
 * wh = war hammer
 * wm = war mattock
 * wp = whip
 * hb = halberd
 * xh = heavy cross bow
 */
function parseWeaponAttack(data) {
  const weapon = data.match(/^(\d*)(We|ba|bs|bo|cl|cb|da|fa|ha|ja|lb|xl|ma|la|pa|qs|ro|sb|sc|sl|sp|ss|ts|sh|wh|wm|wp|hb|xh)(\d*)(\(.*\))?(\[.*\])?.*$/)
  let attack = {}
  if (weapon) {
    attack.bonus = +weapon[1]
    attack.weapon = weapon[2]
    if (weapon[3]) {
      attack.probability = +weapon[3]
    }
    if (weapon[4]) {
      attack = {...attack, ...parseCriticalEffects(weapon[4])}
    }
    if (weapon[5]) {
      attack = {...attack, ...parseAdditionalCrits(weapon[5])}
    }
  }
  return attack
}

/* For most non-weaponry attacks, the first letter indicates the size of the attack:
  *   S = Small M = Medium L = Large H = Huge T = Tiny
  *
  * Ba = Bash/Ram/Butt/Knock Down/Slug
  * Bi = Bite
  * Cl = Claw/Talon
  * Gr = Grapple/Grasp/Envelop/Swallow
  * Ho = Horn/Tusk
  * Msw = Martial Arts Sweeps & Throws
  * Mst = Martial Arts Striking
  * Cr = Crush/Fall
  * Pi = Pincer/Beak
  * St = Stinger
  * Ti = Tiny
  * Ts = Trample/Stomp
  * Br = Brawling
  */
function parseNonWeaponAttack(data) {
  let attack = {}
  // 'Br' is technically the code for brawling, but it interferes with detecting breath attacks, and no monsters brawl
  const weapon = data.match(/^(\d*)([TSMLH])(Ba|Bi|Cl|Gr|Ho|Msw|Mst|Cr|Pi|St|Ti|Ts)(\d*)(\(.*\))?(\[.*])?.*$/)

  if (weapon) {
    // 1) Offensive Bonus -- the first number is the OB for the attack
    attack.bonus = +weapon[1]
    // 2) Attack Type -- the letter codes following the ob indicate the attack type
    attack.size = weapon[2]
    attack.non_weapon = weapon[3]
    if (weapon[4]) {
      attack.probability = +weapon[4]
    }
    if (weapon[5]) {
      attack = {...attack, ...parseCriticalEffects(weapon[5])}
    }
    if (weapon[6]) {
      attack = {...attack, ...parseAdditionalCrits(weapon[6])}
    }
  }
  return attack
}

function parseAdditionalCrits(data) {
  let attack = {}
  const paren = data.substring(1, data.length - 1)
  attack.additional_critical = paren
  return attack
}

// (#)  -- if this # of creatures attack as a group this attack may be used
// (#x) -- the number of times this attack can be used per round
// (#D) -- damage multiplier
// (Critical) -- if this attack inflicts a critical, then use this instead

function parseCriticalEffects(data) {
  /* The critical codes are:
    S = Slash
    P = Puncture
    K = Krush
    U = Unbalance
    G = Grappling
    T = Tiny Animal
    H = Heat
    C = Cold
    B = Brawling
    ST = Martial Arts Strikes
    SW = Martial Arts Sweeps & Throws
    LP = Large Creature (Physical)
    SLP = Super Large Creature (Physical)
    LS = Large Creature (Spells)
    SLS = Super Large Creature (Spells)
    E = Electricity
    I = Impact
  */

  let attack = {}

  const paren = data.substring(1, data.length - 1)
  const critical = paren.match(/(S|P|K|U|G|T|H|C|B|ST|SW|LP|SLP|LS|SLS|E|I)/)

  if (paren.includes('D')) {
    // always an integer
    attack.damage_multiply = parseInt(paren)
  } else if (paren.includes('x')) {
    // could be an integer or something like 1-6x
    attack.per_round = paren.slice(0, paren.length - 1)
  } else if (critical) {
    attack.critical = paren
  } else if (paren.match(/\d+/)) {
    attack.group_size = parseInt(paren)
  } else {
    console.error('Invalid parentheses', paren)
  }
  return attack
}
