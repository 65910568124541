import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Container, Form, Modal, Row, Stack} from 'react-bootstrap'
import axios from 'axios'
import {authHeaders, fetchRole} from '../actions/combatActions'
import Select from 'react-select'
import '../styles/combatant.css'

export default function AddInventory(props) {
  const {done, role, item} = props
  const dispatch = useDispatch()

  const [description, setDescription] = useState(item.description || '')
  const [location, setLocation] = useState(item.location || '')
  const [quantity, setQuantity] = useState(item.quantity || 0)
  const [capacity, setCapacity] = useState(item.capacity || 0)
  const [mass, setMass] = useState(item.mass || 0)
  const [massUnits, setMassUnits] = useState(item.mass_units || 'kg')
  const [value, setValue] = useState(item.value || 0)
  const [valueUnits, setValueUnits] = useState(item.value_units || '')
  const [skill, setSkill] = useState(item.skill_id)
  const [bonus, setBonus] = useState(item.bonus || 0)

  const system = useSelector(state => state.combatReducers.system)

  const massUnitChoices = ['kg', 'g', 'lbs', 'oz'].map(ea => {
    return {label:ea, value:ea}
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()
    const values = {
      role_id: role.id, id: item.id, description, location,
      mass, mass_units: massUnits, quantity, capacity,
      skill_id:skill, bonus, value, value_units: valueUnits
    }
    formData.append('values', JSON.stringify(values))
    const method = item.id ? axios.put : axios.post
    method(`/app/roles/items`, formData, authHeaders())
    .then(_ => {
      dispatch(fetchRole(role.id))
    })
    .catch(console.error)
    .finally(done)
  }

  const handleDelete = () => {
    dispatch(() => {
      axios.delete(`/app/roles/items/${item.id}`, authHeaders())
      .then(_ => dispatch(fetchRole(role.id)))
      .catch(err => console.error(err))
    })
    done()
  }

  return (
    <Container>
      {item && inventoryForm(item)}
    </Container>
  )

  function selectSkill(selection) {
    console.log(selection)
    setSkill(selection.value.id)
  }

  function inventoryForm(item) {
    const skillChoices = role.skills.map(ea => {return {label:ea.getLabel(), value:ea}})
      .sort((a, b) => a.label.localeCompare(b.label))
    const skillChoice = skillChoices.find(ea => ea.value.id === skill)

    const massUnitChoice = massUnitChoices.find(ea => ea.value === massUnits)

    const logInfo = () => {
      console.log(system?.skills)
    }

    return (<>
      <Form onSubmit={handleSubmit} onReset={() => done()}>
        <Form.Group className='mb-2'>
          <Form.Label>Description:</Form.Label>
          <Form.Control value={description} as='textarea' style={{height: 100,resize:'none',overflowY:'auto'}}
                        onChange={(e) => setDescription(e.target.value)}/>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Row style={{maxWidth:650}}>
            <Col md={4}>
              <Form.Label>Location:</Form.Label>
              <Form.Control value={location}
                            onChange={(e) => setLocation(e.target.value)}/>
            </Col>
            <Col md={4}>
              <Form.Label>Weight:</Form.Label>
              <Stack direction='horizontal'>
                <Form.Control as='input' value={mass} type='number' min={0} style={{width:70,marginRight:4}}
                              onChange={(e) => setMass(e.target.value)}/>
                <Select value={massUnitChoice}
                        onChange={(sel) =>  setMassUnits(sel.value)}
                        options={massUnitChoices}/>
              </Stack>
            </Col>
            <Col md={4}>
              <Form.Label>Value:</Form.Label>
              <Stack direction='horizontal' gap={3}>
                <Form.Control as='input' value={value} type='number' min={0} style={{textAlign:'right'}}
                              onChange={(e) => setValue(e.target.value)}/>
                <Form.Control value={valueUnits} placeholder='¢'
                              onChange={(e) => setValueUnits(e.target.value)}/>
              </Stack>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Row>
            <Col md={2}>
              <Form.Label>Charges:</Form.Label>
              <Form.Control as='input' value={quantity || 0} type='number' step='1'
                            onChange={(e) => setQuantity(e.target.value)}/>
            </Col>
            <Col md={2}>
              <Form.Label>Capacity:</Form.Label>
              <Form.Control as='input' value={capacity || 0} type='number' step='1'
                            onChange={(e) => setCapacity(e.target.value)}/>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Row>
            <Col md={6}>
              <Form.Label>Skill & Ability Bonus:</Form.Label>
              <Select className='add-template-select'
                      value={[skillChoice]}
                      onChange={selectSkill}
                      options={skillChoices}/>
            </Col>
            <Col md={2}>
              <Form.Label>Bonus:</Form.Label>
              <Form.Control as='input' value={bonus || 0} type='number' step='5'
                            onChange={(e) => setBonus(e.target.value)}/>
            </Col>
          </Row>
        </Form.Group>
        <Row>&nbsp;</Row>
        <Modal.Footer className='stat-modal-footer'>
          {item.id ? <Button variant='danger' type='reset' onClick={handleDelete}>Delete</Button> : <div/>}
          <Button variant='outline-success' onClick={logInfo}>Info</Button>
          <div/>
          <Button variant='secondary' type='reset'>Cancel</Button>
          <Button variant='primary' type='submit'>Save</Button>
        </Modal.Footer>
      </Form>
    </>)
  }

}
