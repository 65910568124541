import {Shape} from './Shape'

import solid_door_ns from '../images/solid-door-ns.svg'
import double_door_ns from '../images/double-door-ns.svg'
import open_door_ns from '../images/open-door-ns.svg'
import stair_down_w from '../images/stair-down-w.svg'

export class Door extends Shape {
  // Door types
  static OPEN_DOOR = 0
  static SOLID_DOOR = 1
  static DOUBLE_DOOR = 2
  static STAIR_CASE = 3

  static images = [open_door_ns, solid_door_ns, double_door_ns, stair_down_w].map(src => {
    const img = new Image()
    img.src = src
    return img
  })

  // Door directions
  static NORTH = 0
  static EAST = 1
  static SOUTH = 2
  static WEST = 3

  constructor(options) {
    super({...options, type:'door'})

    const {door_type = Door.SOLID_DOOR, direction = Door.NORTH, from_id, to_id} = options

    if (!this.origin || (this.origin[0] < 0 || this.origin[1] < 0)) {
      console.error('creating Door at', this.origin)
    }

    this.door_type = door_type
    this.direction = direction
    this.from_id = from_id
    this.to_id = to_id
  }

  // Draw door
  draw(context, extent, [w, h]) {
    const image = Door.images[this.door_type]
    context.save()
    context.rotate(Math.PI / 2 * this.direction)
    try {
      context.drawImage(image, 0, 0)
    }
    catch (err) {
      console.log(typeof image)
      console.error(err)
    }
    context.restore()
  }

}
