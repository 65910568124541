import React, {useContext, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/combat.css'
import {useDispatch, useSelector} from 'react-redux'
import {authHeaders, encounterContext, setLastRoll,} from '../actions/combatActions'
import dice_icon from '../images/d100.svg'
import {PlusCircleFill} from 'react-bootstrap-icons'
import {GameSessionContext} from './GameSession'

export default function EncounterDetails({encounter, combatants}) {
  const [roll, setRoll] = useState(0)
  const roles = useSelector(state => state.combatReducers.combatants)
  const auth_info = useSelector(state => state.combatReducers.auth_info)
  const session = useContext(GameSessionContext)
  const [formula, setFormula]= useState(null)
  const dispatch = useDispatch()

  const combatIndex = encounter?.selectedAttack
  const round = encounter?.combatRound
  const attacker_id = combatants[combatIndex] && combatants[combatIndex].id
  const attacker = roles.find(ea => ea.id === attacker_id)
  const target_id = attacker && attacker.target && attacker.target.id
  const defender = roles.find(ea => ea.id === target_id)
  const gameSession = session.gameSession(encounter?.game_id)
  const gm = gameSession?.adventure?.owner_id === auth_info.id

  const {
    attack,
    deleteCombatLog,
    beginCombat,
    endCombat,
    nextRound,
    nextAttacker
  } = encounterContext(session, encounter)

  useEffect(() => {
    dispatch(setLastRoll(roll))
  }, [dispatch, roll])

  const roll_d100 = () => {
    fetch('/app/sessions/roll?formula=d100x96', authHeaders())
      .then(resp => {
        resp.json().then(roll => {
          const expanded = (+roll.result === roll.total) ? null : roll.result
          setFormula(expanded)
          setRoll(roll.total)
        })
      })
  }

  const handleAttack = (ev) => {
    ev.preventDefault()
    dispatch(attack(attacker, defender))
  }

  const renderPlayerDisplay = () => {
    if (round <= 0) {
      return (<>
        <div className='cg-battle-round'>
          <div className='cg-encounter-name'>
            <label>No Combat</label>
          </div>
        </div>
      </>)
    }
    return (<>
      <div className='cg-battle-round'>
        <div className='cg-encounter-name'>
          <label>Round&nbsp;</label>
          <span className='cg-round-number'>{round}</span>
        </div>
      </div>
    </>)
  }

  const renderCombatButtons = () => {
    if (round <= 0) {
      return (
        <div className='cg-enc-row'>
          {encounter?.combatLog.length > 0 &&
            <Button className='cg-encounter-button' variant='dark' size='sm' onClick={deleteCombatLog}>
              Clear Log
            </Button>}
          <Button className='cg-encounter-button' variant='dark' size='sm'
            onClick={_ => dispatch(beginCombat(combatants))}>Begin Combat
          </Button>
        </div>)
    }

    return (<>
      <div className='cg-battle-round'>
        <div className='cg-encounter-name'>
          <label>Round&nbsp;</label>
          <span className='cg-round-number'>{round}</span>
          <Button className='cg-increment-button' variant='dark' size='sm' alt='Next Round'
                  onClick={_ => dispatch(nextRound(combatants))}>
            <span className='cg-round-button'>
              <PlusCircleFill/>
            </span>
          </Button>
        </div>
      </div>

      <div className='cg-enc-row'>
        <Button className='cg-encounter-button' variant='dark' size='sm'
                disabled={combatIndex >= encounter.tokens.length - 1}
                onClick={nextAttacker}>Next Attacker</Button>
        <Button className='cg-encounter-button' variant='dark' size='sm'
                onClick={endCombat}>End Combat</Button>
      </div>

      <div className='cg-enc-row'>
        <div className='enc-input'>
          <Button style={{display:'contents'}}>
            <div style={{display:'flex',flexDirection:'column'}}>
              <img src={dice_icon} width={44} height={44} onClick={roll_d100} alt='Roll d100'/>
              {formula && <div style={{color:'darkred',fontSize:12,textAlign:'center'}}>{formula}</div>}
            </div>
          </Button>
          <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'center'}}>
              <input type='number' value={roll} style={{textAlign:'right',padding:4,margin:4,width:72}}
                     onChange={e => setRoll(+e.target.value)}/>
            <Button variant='dark' size='sm' style={{height:'2rem',alignSelf:'center'}}
                    disabled={!(attacker && defender)}
                    onClick={(e) => handleAttack(e)}>Attack</Button>
          </div>
        </div>
      </div>
    </>)
  }

  return (<>
    {gm ? renderCombatButtons() : renderPlayerDisplay()}
  </>)
}
