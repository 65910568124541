import React, {useEffect, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import axios from 'axios'
import '../styles/profile.css'
import {authHeaders, setUserFromToken} from '../actions/combatActions'
import {useDispatch, useSelector} from 'react-redux'
import {AUTH_TOKEN} from '../util/string_constants'

export default function ProfileUsername() {
  const [username, setUsername] = useState()
  const [new_username, setNewUsername] = useState('')
  const [password, setPassword] = useState('')
  const [changeUsername, setChangeUsername] = useState(false)
  const [username_message, setUsernameMessage] = useState()

  const dispatch = useDispatch()
  const auth_info = useSelector(state => state.combatReducers.auth_info)

  useEffect(() => {
    if (auth_info) {
      setUsername(auth_info.name)
    }
  }, [auth_info])

  const handleUsername = (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('new_username', new_username)
    formData.append('password', password)
    axios.post('/app/profile/username', formData, authHeaders())
    .then((response) => {
      // XXX don't render this in red
      setUsernameMessage('Your user name has been changed')
      setNewUsername('')
      setPassword('')
      localStorage.setItem(AUTH_TOKEN, response.data)
      dispatch(setUserFromToken(response.data))
    })
    .catch(err => {
      const response = err.response
      const status = response && response.status
      const data = response && response.data
      const message = data && data.message

      if (status === 403) {
        // XXX need a separate field for password error message
        setUsernameMessage(message)
      }
      else if (status === 409) {
        setUsernameMessage(message)
      }
      else {
        setUsernameMessage('An error occurred', err)
        console.error(err)
      }
    })
  }

  const toggleChange = () => {
    setChangeUsername(!changeUsername)
    setNewUsername('')
    setPassword('')
    setUsernameMessage('')
  }

  return (
    <Form onSubmit={handleUsername}>
      <Form.Group controlId="formBasicUsername">
        <div className='up-change-attrib'>
          {changeUsername ? <small className='form-text'>Your current user name is <b>{username}</b></small>
              : <Form.Label>{username}</Form.Label>}
          <a href='#' className='form-text up-change-link'
             onClick={toggleChange}>{changeUsername ? 'Hide' : 'Change'}</a>
        </div>
        {changeUsername && <>
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <Form.Control type="username" placeholder="New user name" value={new_username}
                          style={{width:'40%'}}
                          onChange={(e) => setNewUsername(e.target.value)}/>
            <Form.Control type="password" placeholder="Current password" value={password}
                          style={{width:'40%'}}
                          onChange={(e) => setPassword(e.target.value)}/>
            <Button variant="primary" type="submit">Update</Button>
          </div>
          <Form.Text>
            {username_message && <>
              <span style={{color:username_message.color || 'red'}}>{username_message}</span>
              <Link to='/recover'> Forgot your password?</Link>
            </>}
          </Form.Text>
        </>}
      </Form.Group>
    </Form>
  )
}