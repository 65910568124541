export class Adventure {
  constructor(options) {
    const { id, owner_id, name, description, imageURL, system, gm, role, start_dt, finish_dt, players = [], data } = options
    this.id = id
    this.owner_id = owner_id
    this.name = name
    this.description = description
    this.imageURL = imageURL
    this.system = system
    this.gm = gm
    this.role = role
    this.players = players
    this.year = data?.year
    this.setting = data?.setting

    // zero is 1970-01-01
    this.start_dt = start_dt && new Date(start_dt)
    this.finish_dt = finish_dt && new Date(finish_dt)
  }
}
