import React, {useContext, useEffect} from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/app.css'
import 'react-tippy/dist/tippy.css'
import {fetchAdventures} from '../actions/combatActions'
import {useDispatch, useSelector} from 'react-redux'
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap'
import {AUTH_TOKEN} from '../util/string_constants'
import {Link} from 'react-router-dom'
import ConnectionStatus from './ConnectionStatus'
import logo from '../images/logo.svg'
import {GameSessionContext} from './GameSession'

export default function NavContainer() {
  const dispatch = useDispatch()

  const auth_info = useSelector(state => state.combatReducers.auth_info)
  const session = useContext(GameSessionContext)

  useEffect(() => {
    if (auth_info) {
      dispatch(fetchAdventures())
    }
  }, [dispatch, auth_info])

  const handleLogout = () => {
    const game_ids = session.connections.map(ea => ea.adventure.id)
    session.sendMessage({type: 'leave', game_ids})
    localStorage.removeItem(AUTH_TOKEN)
    window.location.href = '/login'
  }

  return (<>
    <Navbar collapseOnSelect bg='dark' variant='dark'>
      <Container fluid>
        <Navbar.Brand as={Link} to='/home'>
          <img src={logo} width='30' height='30' className='d-inline-block align-top' alt='Spymaster logo'/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse>
          <Nav className='me-auto'>
            {auth_info && <GameMenu/>}
          </Nav>

          {!auth_info && <Nav>
            <Nav.Link as={Link} to='/login'>Sign in</Nav.Link>
          </Nav>}
          {!auth_info && <Nav>
            <Nav.Link as={Link} to='/signup'>Sign up</Nav.Link>
          </Nav>}
          <Nav>
            {auth_info && <>
              <ConnectionStatus/>
              <NavDropdown id='nav-player-choices' title={auth_info.name}>
                <NavDropdown.Item as={Link} to='/profile'>Profile</NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </>}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>)

  function GameMenu() {
    return (
      <NavDropdown id='nav-adventure-choices' title='Games'>
        <NavDropdown.Item as={Link} to='/new-game'>New Game</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/games'>My Games</NavDropdown.Item>
      </NavDropdown>
    )
  }

}
