import React, {useEffect, useState} from 'react'
import {maxZIndex, useLocalStorage} from '../util/hooks'
import Draggable from 'react-draggable'
import {Resizable} from 're-resizable'
import {useSelector} from 'react-redux'

export default function MovableComponent(props) {
  const {children, id, cancel, maxWidth, maxHeight, minWidth, minHeight, name, defaultPosition, defaultSize, onResize} = props
  const [position, setPosition] = useLocalStorage(name + '-pos', defaultPosition)
  const [size, setSize] = useLocalStorage(name + '-size', defaultSize)
  const [zIndex, setZIndex] = useState(0)
  const disableBackgroundDragEvents = useSelector(state => state.combatReducers.disableBackgroundDragEvents)

  // check window position on mount
  useEffect(() => {
    const root = document.getElementById('root')
    const rootRect = root.getBoundingClientRect()
    if (position.x > rootRect.width || position.y > rootRect.height) {
      setPosition(defaultPosition)
    }
  }, [])

  function updateWindowPosition(event, data) {
    const {x, y} = data
    setPosition({x, y})
  }

  function updateZIndex() {
    setZIndex(maxZIndex() + 1)
  }

  function handleResizeStop(event, direction, ref, delta) {
    setSize({width:size.width + delta.width, height:size.height + delta.height})
  }

  function handleResize(event, direction, ref) {
    const w = parseInt(ref.style.width)
    const h = parseInt(ref.style.height)
    // use touch events for tablet users
    const clientX = event.clientX || (event.touches && event.touches[0]?.clientX)
    const clientY = event.clientY || (event.touches && event.touches[0]?.clientY)
    const x = (w > minWidth && w < maxWidth) ? clientX : position.x
    const y = (h > minHeight && h < maxHeight) ? clientY : position.y

    switch (direction) {
      case "topLeft":
        setPosition({x, y})
        break
      case "topRight":
      case "top":
        setPosition({...position, y})
        break
      case "bottomLeft":
      case "left":
        setPosition({...position, x})
        break
      default:
    }
    onResize && onResize(event, direction, ref)
  }

  return (<>
    <Draggable position={position} onMouseDown={updateZIndex} disabled={disableBackgroundDragEvents}
               onStop={updateWindowPosition} cancel={cancel}>
      <Resizable className='ap-resizable' id={id} handleWrapperClass='drag-ignore' style={{zIndex}}
                 maxWidth={maxWidth} maxHeight={maxHeight} minWidth={minWidth} minHeight={minHeight}
                 onResizeStop={handleResizeStop}
                 onResize={handleResize}
                 defaultSize={size}>
        <div>
          {children}
        </div>
      </Resizable>
    </Draggable>
  </>)

}
