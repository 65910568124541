import React, {useEffect, useState} from 'react'
import {Button, Col, Container, Form, Navbar, Row} from 'react-bootstrap'
import logo from '../images/logo.svg'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {useParams} from 'react-router-dom'

export default function ResetPassword() {
  const [user, setUser] = useState({})
  const [password, setPassword] = useState('')
  const [error_message, setErrorMessage] = useState('')
  const [password_message, setPasswordMessage] = useState('')

  const {token} = useParams()

  useEffect(() => {
    try {
      setUser(token && JSON.parse(atob(token.split('.')[1])))
    }
    catch(err) {
      console.error('bad token:', token, err)
      setErrorMessage('An error has occurred')
      window.location.href = '/'
    }
  }, [token])

  const handleSubmit = (e) => {
    e.preventDefault()
    const headers = {Authorization: `Bearer ${token}`}
    const formData = new FormData();
    formData.append('password', password)
    axios.post('/app/reset', formData, {headers})
    .then(() => {
      window.location.href = '/reset/success'
    })
    .catch(err => {
      setErrorMessage('An error has occurred')
      console.error(err)
    })
  }

  const handlePassword = (e) => {
    const pw = e.target.value
    if (pw.length < 6) {
      setPasswordMessage('Password too short')
    }
    setPassword(pw)
  }

  return (<>
    <Navbar bg='dark' variant='dark'>
      <Navbar.Brand href='/'>
        <img src={logo} width={30} height={30} className='d-inline-block align-top'
             alt='Spymaster logo'/>
      </Navbar.Brand>
    </Navbar>
    <Container fluid='sm' style={{paddingTop:'80px'}}>
      <Row>
        <Col sm={{span:8, offset:2}}>
          <h3>Reset Password</h3>
          <p className='text-muted'>
            Email: <b>{user && user.email}</b>
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{span:6, offset:3}}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" value={password}
                            onChange={handlePassword}/>
              <Form.Text>
                <span style={{color:'red'}}>{error_message}</span>
              </Form.Text>
              <Form.Text>
                <span style={{color:'darkgreen'}}>{password_message}</span>
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
              Continue
            </Button>
          </Form>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col sm={{span:8, offset:2}}>
          <Link to='/login'>Back to Sign in</Link>
        </Col>
      </Row>
    </Container>
  </>)
}