import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Modal} from 'react-bootstrap'
import {authHeaders, fetchSystem, saveCombatant} from '../actions/combatActions'
import Select from 'react-select'
import axios from 'axios'

import {Combatant} from '../models/Combatant'
import {Item} from '../models/Item'

import remove_icon from '../images/minus-button-red.svg'
import checked_icon from '../images/checked.svg'
import unchecked_icon from '../images/unchecked.svg'
import star_icon from '../images/star.svg'
import add_icon from '../images/add.svg'

import AddSkill from './AddSkill'
import AddInventory from './AddInventory'
import EditCombatant from './EditCombatant'
import EditCategories from './EditCategories'
import EditSkill from './EditSkill'
import AddTrainingPackage from './AddTrainingPackage'
import {formatInt, formatValue, formatWeight, totalMass} from '../util/rpg_utils'

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import CharacterStats from '../Rolemaster/components/CharacterStats'
import {ArrowUpCircle, Heart, HeartFill, SortDown, SortUp} from 'react-bootstrap-icons'
import EditCategory from './EditCategory'
import {useLocalStorage} from '../util/hooks'

const mode = {
  edit_role: {title: 'Combat Stats', className: 'cs-combatant-grid'},
  stats: {title: 'Basic Stats', className: 'cs-stats-grid'},
  weapon_costs: {title: 'Weapon Costs', className: 'cs-cost-grid'},
  psychic_costs: {title: 'Psychic Costs', className: 'cs-cost-grid'},
  add_inventory: {title: 'Inventory Item', className: 'cs-combatant-grid'},
  add_skills: {title: 'Skill Assignment', className: 'cs-add-grid'},
  edit_skill: {title: 'Edit Skill', className: 'cs-add-grid'},
  edit_category: {title: 'Edit Category', className: 'cs-add-grid'},
  add_tp: {title: 'Training Package', className: 'cs-add-grid'},
}

export default function CharacterSheet(props) {
  const {isEditing, isPrinting, done, role, page} = props
  const [modal, setModal] = useState(null)
  const dispatch = useDispatch()

  const [skillAssignments, setSkillAssignments] = useState([])
  const [levels, setLevels] = useState([])
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedSkill, setSelectedSkill] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedInventory, setSelectedInventory] = useState(null)
  const [dpUsed, setDpUsed] = useState(0)
  const [skillCosts, setSkillCosts] = useState(0)
  const [categoryCosts, setCategoryCosts] = useState(0)
  const [bonuses, setBonuses] = useState([])
  const [shield_bonus, setShieldBonus] = useState([])
  const [race, setRace] = useState(null)

  const weapons = useSelector(state => state.combatReducers.weapons)
  const system = useSelector(state => state.combatReducers.system)

  // Skill page sorting
  const [sortColumn, setSortColumn] = useLocalStorage('cs-skill-sort-column', 'skill')
  const [sortDirection, setSortDirection] = useLocalStorage('cs-skill-sort-direction', 'none')
  const [filterFavorites, setFilterFavorites] = useLocalStorage('cs-skill-favorite-filter', false)

  const aqp = -system?.armor_quickness_penalty(role?.at)

  // sort levels array from highest to lowest
  useEffect(() => {
    if (isEditing) {
      let role_levels = [{label: 'Adolescent', value: -1}]
      if (role.level >= 0) {
        role_levels.push({label: 'Apprentice', value: 0})
      }
      if (role.level > 0) {
        role_levels = role_levels.concat([...Array(role.level).keys()].map(lvl => {
          return {label: `Level ${lvl + 1}`, value: lvl + 1}
        }))
      }
      role_levels.sort((a, b) => b.value - a.value)
      setLevels(role_levels)
    } else {
      setSelectedLevel(null)
    }
  }, [role, isEditing])

  useEffect(() => {
    if (role && !system) {
      dispatch(fetchSystem(role.system))
    }
  }, [system, role])

  useEffect(() => {
    if (role) {
      setSkillAssignments(role.skills.sort((a, b) => a.category.label.localeCompare(b.category.label)))
    }
  }, [role])

  useEffect(() => {
    if (system && selectedLevel?.value >= 0) {
      const level = selectedLevel.value
      const skill_costs = skillAssignments
        .filter(ea => ea.num_ranks(role, level) > 0)
        .map(skill => skill.rank_cost(role, level))
        .reduce((acc, val) => acc + val, 0)

      const category_costs = role.skill_categories
        .filter(ea => ea.applicable_stats)  // filter out unmapped categories
        .filter(ea => ea.num_ranks(role, level) > 0)
        .map(cat => cat.useBonus(system, role, level).rank_cost())
        .reduce((acc, val) => acc + val, 0)

      setSkillCosts(skill_costs)
      setCategoryCosts(category_costs)
      setDpUsed(skill_costs + category_costs)
    }
  }, [selectedLevel, skillAssignments, system, role])

  useEffect(() => {
    if (role && system?.statBonuses) {
      setBonuses(system.statBonuses(role))
      setShieldBonus(system.shieldBonuses(role))
      setRace(system.races.find(ea => ea.label === role.race))
    }
  }, [system, role])

  const updateSkillRanks = (skill, num_ranks) => {
    // skill argument is a skill assignment (not an instance of Skill)
    // index into [Skill] array
    const skill_index = skillAssignments.indexOf(skill)
    const new_skills = role.skills.slice()
    skill.setNumRanks(num_ranks, role, selectedLevel && selectedLevel.value)
    new_skills[skill_index] = skill

    const new_role = new Combatant(role)
    new_role.skills = new_skills
    dispatch(saveCombatant(new_role))
  }

  const updateCategoryRanks = (category, num_ranks) => {
    const level = selectedLevel.value
    const cat_index = role.skill_categories.findIndex(cat => cat.id === category.id)
    const new_category = (cat_index < 0) ?
      {...category, ranks: '', levels: ''} : role.skill_categories[cat_index]
    const new_cats = role.skill_categories.slice()

    if (cat_index < 0) {
      new_cats.push(new_category)
    } else {
      new_cats[cat_index] = new_category
    }

    const ranks = new_category.ranks
    const levels = new_category.levels

    if (levels.includes(level)) {
      const rank_index = levels.indexOf(level)
      ranks[rank_index] = num_ranks
    } else {
      ranks.push(num_ranks)
      levels.push(level)
    }
    new_category.ranks = ranks
    new_category.levels = levels

    const new_role = new Combatant(role)
    new_role.skill_categories = new_cats
    dispatch(saveCombatant(new_role))
  }

  if (!role) {
    return null
  }

  return (
    <div>
      {modal && selectedModal()}
      {isEditing && selectEditOptions()}
      {system && selectedPage()}
    </div>
  )

  function selectedPage() {
    switch (page) {
      case 1:
        return statsPage()
      case 2:
        return skillCategoriesPage()
      case 3:
        return skillsPage()
      case 4:
        return inventoryPage()
      default:
        return null
    }
  }

  function selectedModal() {
    return (modal &&
      <Modal dialogClassName={modal.className} show={!!modal} onHide={() => setModal(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle(modal, role)}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={modal.style}>
          {renderModal(modal, role, () => setModal(null))}
        </Modal.Body>
      </Modal>
    )
  }

  function modalTitle(modal, role) {
    switch (modal) {
      case mode.edit_role:
        return `Edit ${role.name}`
      case mode.stats:
        return `Stats for ${role.name}`
      case mode.psychic_costs:
        return `Psychic costs for ${role.name}`
      case mode.weapon_costs:
        return `Weapon costs for ${role.name}`
      case mode.add_inventory:
        return `Inventory for ${role.name}`
      case mode.add_skills:
        return `Add skill for ${role.name}`
      case mode.edit_skill:
        return `${selectedSkill.label} (${selectedSkill.category.label})`
      case mode.edit_category:
        return `${selectedCategory.label}`
      case mode.add_tp:
        return `Add training package for ${role.name}`
      default:
        return 'Edit'
    }
  }

  function renderModal(modal, role, done) {
    switch (modal) {
      case mode.edit_role:
        return <EditCombatant role={role} done={done}/>
      case mode.stats:
        return <CharacterStats role={role} done={done}/>
      case mode.psychic_costs:
        return <EditCategories role={role} category='psychic_costs' done={done}/>
      case mode.weapon_costs:
        return <EditCategories role={role} category='weapon_costs' done={done}/>
      case mode.add_inventory:
        return <AddInventory role={role} item={selectedInventory} done={done}/>
      case mode.add_skills:
        return <AddSkill role={role} done={done}/>
      case mode.edit_skill:
        return <EditSkill role={role} skill={selectedSkill} done={done}/>
      case mode.edit_category:
        return <EditCategory role={role} system={system} category={selectedCategory} done={done}/>
      case mode.add_tp:
        return <AddTrainingPackage role={role} done={done}/>
      default:
        return null
    }
  }

  function handleLevelUp() {
    dispatch(saveCombatant(new Combatant({...role, level:role.level + 1})))
  }

  function selectEditOptions() {
    return (
      <div className='cs-level-input'>
        <label>Level: </label>
        <Select className='cs-level-select'
                value={[selectedLevel]}
                onChange={(selection) => setSelectedLevel(selection)}
                options={levels}/>
        {!role.skills.length && selectedLevel && selectedLevel.value < 0 ?
          <Button variant='primary' type='submit' onClick={addAdolescentSkills}>
            Add Adolescent Skills
          </Button> :
          <Button variant='primary' type='submit' onClick={addTrainingPackages}>
            Add Training Packages
          </Button>}
        {selectedLevel?.value >= 0 && <>
          <div className='cs-dp-summary'>
            <label>DP:</label>
            <div>{dpUsed} of {role.dev_points()}</div>
            <div>(categories: {categoryCosts} skills: {skillCosts})</div>
          </div>
          <Button size='sm' variant='outline-success' onClick={handleLevelUp}>
            Level Up&nbsp;<ArrowUpCircle/>
          </Button>
        </>
        }
      </div>
    )
  }

  function addAdolescentSkills() {
    const role_system = system.useRole(new Combatant(role))

    race?.adolescent_ranks.forEach(spec => {
      if (spec.skill) {
        role_system.assignSkill({...spec, level: -1})
      } else if (spec.category) {
        role_system.setCategoryRanks({...spec, level: -1})
      } else if (spec.skills) {
        for (let id of spec.ids) {
          system.skills.filter(ea => ea.cat_id === id)
            .forEach(skill => {
              role_system.assignSkill({...skill, level: -1, ranks: 0})
            })
        }
      } else if (spec.categories) {
        for (let id of spec.ids) {
          role_system.setCategoryRanks({id: id, level: -1, ranks: 0})
        }
      }
    })
    const updated = new Combatant({...role_system.role, level: 0})
    dispatch(saveCombatant(updated))
  }

  function addTrainingPackages() {
    setModal(mode.add_tp)
  }

  function skillsPage() {
    const removeSkill = (skill) => {
      const new_role = new Combatant(role)
      const new_assignments = role.skills.slice()
      const index = skillAssignments.indexOf(skill)
      new_assignments.splice(index, 1)
      new_role.skills = new_assignments
      dispatch(saveCombatant(new_role))
    }

    const handleSkillClick = (skill) => {
      if (isEditing) {
        setSelectedSkill(skill)
        setModal(mode.edit_skill)
      }
    }

    const toggleFavorite = (skill) => {
      skill.common_use = !skill.common_use

      const formData = new FormData()
      formData.append('favorite', JSON.stringify({role_id: role.id, skill, type: 'skill'}))
      axios.put('/app/roles/favorites', formData, authHeaders())
        .then(() => {
          dispatch(saveCombatant(role))
          done()
        })
    }

    const max_ranks = (skill) => {
      let max = 0
      if (selectedLevel) {
        max = skill.max_ranks(role)
        // adolescent skill groups can have more than the normal rank limits
        // otherwise hobby skills are limited to the max_ranks
        if (selectedLevel.value < 0) {
          race?.adolescent_ranks.filter(ea => ea.skill_ids)
            .forEach(group => {
              if (skill.id >= group.skill_ids.from && skill.id <= group.skill_ids.to) {
                max = group.ranks
              }
            })
        }
      }
      return max
    }

    function sortFunction() {
      if (sortColumn === 'skill') {
        if (sortDirection === 'descending') {
          return (a, b) => b.getLabel().localeCompare(a.getLabel())
        }
        else if (sortDirection === 'ascending') {
          return (a, b) => a.getLabel().localeCompare(b.getLabel())
        }
      }
      else if (sortColumn === 'bonus') {
        if (sortDirection === 'ascending') {
          return (a, b) => a.useBonus(system, role).skill_bonus() - b.useBonus(system, role).skill_bonus()
        }
        else if (sortDirection === 'descending') {
          return (a, b) => b.useBonus(system, role).skill_bonus() - a.useBonus(system, role).skill_bonus()
        }
      }
      else if (sortColumn === 'ranks') {
        if (sortDirection === 'ascending') {
          return (a, b) => a.num_ranks(role) - b.num_ranks(role)
        }
        else if (sortDirection === 'descending') {
          return (a, b) => b.num_ranks(role) - a.num_ranks(role)
        }
      }
      // 'none' is default order
      return null
    }

    function sortedSkills() {
      const fun = sortFunction()
      if (!fun) {
        return role.skills
      }
      const sorted = role.skills.slice()
      sorted.sort((a, b) => {
        return fun(a, b)
      })
      return sorted
    }

    function filteredSkills() {
      return sortedSkills()
        .filter(ea => !filterFavorites || ea.common_use)
    }

    /**
     * Click on a column header to sort by that column.
     * Click a second time to reverse the direction.
     * Click a third time to revert to none.
     */
    function triStateToggle(column) {
      if (sortColumn === column) {
        if (sortDirection === 'ascending') {
          setSortDirection('descending')
        }
        else if (sortDirection === 'descending') {
          setSortDirection('none')
        }
        else {
          setSortDirection('ascending')
        }
      }
      else {
        setSortColumn(column)
        setSortDirection('ascending')
      }
    }

    function handleSkillHeaderClick() {
      triStateToggle('skill')
    }

    function handleRanksHeaderClick() {
      triStateToggle('ranks')
    }

    function handleBonusHeaderClick() {
      triStateToggle('bonus')
    }

    function buttonAdornment(column) {
      if (sortColumn === column) {
        if (sortDirection === 'ascending') {
          return <SortDown/>
        }
        else if (sortDirection ==='descending') {
          return <SortUp/>
        }
      }
      return null
    }

    function toggleFavoriteFilter() {
      setFilterFavorites(!filterFavorites)
    }

    function favoriteColumnToggle() {
      const favoriteIndicator = filterFavorites ? <HeartFill/> : <Heart/>
      return (<>
        <Tippy arrow={true} content='Filter favorites'>
          <button className='list-header-button' onClick={toggleFavoriteFilter}>
            <span style={{color:'darkred'}}>{favoriteIndicator}</span>
          </button>
        </Tippy>
      </>)
    }

    let last_category = null

    return (
      <div className='character-sheet'>
        <div className='cs-header'>
          <div className='cs-header-left'>
            <div className='cs-header-title'>
              <div className='cs-title-text'>Skills</div>
              {isEditing && <input className='cs-title-button'
                                   type='image' src={add_icon} width={24} height={24}
                                   alt='Add skills'
                                   onClick={() => setModal(mode.add_skills)}/>}
            </div>
          </div>
          <div className='cs-header-name-box box'>
            <div>
              <label>Character: </label>
              <div>{role.name}</div>
            </div>
          </div>
        </div>
        <div className='cs-body'>
          <table>
            <colgroup>
              {!isEditing && !isPrinting && <col style={{width: 25}}/>}
              <col style={{width: 230}}/>
              {isEditing && <col style={{width: 55}}/>}
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
            </colgroup>
            <thead>
            <tr>
              {!isEditing && !isPrinting && <th>
                {favoriteColumnToggle()}
              </th>}
              <th style={{textAlign: 'left', paddingTop: 10}}>
                <Tippy arrow={true} content='Sort by skill name'>
                  <button className='list-header-button' onClick={handleSkillHeaderClick}>Skill</button>
                </Tippy>
                {buttonAdornment('skill')}
              </th>
              {isEditing && <th>Dev.<br/>Cost</th>}
              <th style={{textAlign: 'left', paddingTop: 10}}>
                  <div style={{display:'flex'}}>
                    <Tippy arrow={true} content='Sort by ranks developed'>
                        <button className='list-header-button' onClick={handleRanksHeaderClick}>Ranks</button>
                    </Tippy>
                    {buttonAdornment('ranks')}
                  </div>
              </th>
              <th>Cost</th>
              <th>Bonus</th>
              <th>Category</th>
              <th>Item</th>
              <th>Special</th>
              <th>Special</th>
              <th>
                <div style={{display:'flex'}}>
                  <Tippy arrow={true} content='Sort by total bonus'>
                    <button className='list-header-button' onClick={handleBonusHeaderClick}>Total</button>
                  </Tippy>
                  {buttonAdornment('bonus')}
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            {filteredSkills().map((skill, key) => {
              const label = skill.getLabel()
              const level = selectedLevel && selectedLevel.value
              const num_ranks = skill.num_ranks(role, level)
              const {
                rank_bonus,
                cat_bonus,
                item_bonus,
                skill_bonus
              } = skill.useBonus(system, role, level)
              let render_category = (last_category !== skill.category || key === 0)
              if (render_category) {
                last_category = skill.category
              }
              return (
                <tr key={key} style={{borderTop: render_category && '0.5px black solid'}}>
                  {!isEditing && !isPrinting && <td>
                    <img src={skill.common_use ? checked_icon : unchecked_icon}
                         alt={skill.common_use ? 'Commonly used' : 'Not commonly used'}
                         style={{hover: 'pointer'}}
                         onClick={() => toggleFavorite(skill)}/>
                  </td>}
                  <td style={{textAlign: 'left'}}><>
                    {render_category && <><span className='cc-list-group-label'>{skill.category.label}</span><br/></>}
                    {isEditing && <img src={remove_icon} alt='remove skill' onClick={() => removeSkill(skill)}/>}
                    <span style={{cursor: isEditing ? 'pointer' : 'arrow'}}
                          onClick={() => handleSkillClick(skill)}>{label}</span>
                  </>
                  </td>
                  {isEditing && <td className='ul'>
                    <div>{skill.category.useBonus(system, role).dev_cost()}</div>
                  </td>}
                  <td className='ul'>
                    {isEditing ?
                      <input type='number' value={num_ranks}
                             className='cs-set-ranks'
                             min={0} max={max_ranks(skill)}
                             onChange={(e) => updateSkillRanks(skill, +e.target.value)}/> :
                      <div>{num_ranks}</div>}
                  </td>
                  <td className='ul'>
                    <div>{skill.rank_cost(role, level)}</div>
                  </td>
                  <td className='ul'>
                    <Tippy arrow={true} content={<>{skill.rank_bonus_description(role, level)}</>}>
                      <div>{formatInt(rank_bonus())}</div>
                    </Tippy>
                  </td>
                  <td className='ul'>
                    <Tippy content={<div>
                      {skill.category.num_ranks(role, level)} Ranks for <b>{skill.category.label}</b><br/>
                      See Skill Category page for details
                    </div>}>
                      <div>{formatInt(cat_bonus())}</div>
                    </Tippy>
                  </td>
                  <td className='ul'>
                    <div>{formatInt(item_bonus())}</div>
                  </td>
                  <td className='ul'>
                    <div>{formatInt(skill.special_bonus1)}</div>
                  </td>
                  <td className='ul'>
                    <div>{formatInt(skill.special_bonus2)}</div>
                  </td>
                  <td className='ul'>
                    <div>{formatInt(skill_bonus())}</div>
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  function inventoryPage() {
    const handleItemClick = (item) => {
      if (item) {
        setSelectedInventory(item)
        setModal(mode.add_inventory)
      }
    }

    const valuables = role.items
      .filter(ea => ea.type === 'money')
      .sort((a, b) => b.value - a.value)
    const valuables8 = valuables.slice(0, 8)
    const valuables16 = valuables.slice(8, 16)

    return (
      <div className='character-sheet'>
        <div className='cs-header'>
          <div className='cs-header-left'>
            <div className='cs-header-title'>
              <div className='cs-title-text'>Money & Equipment</div>
              {!isPrinting && <input className='cs-title-button'
                                     type='image' src={add_icon} width={24} height={24}
                                     alt='Add inventory'
                                     onClick={() => {
                                       setSelectedInventory(new Item({}))
                                       setModal(mode.add_inventory)
                                     }}/>}
            </div>
          </div>
          <div className='cs-header-name-box box'>
            <div>
              <label>Character: </label>
              <div>{role.name}</div>
            </div>
          </div>
        </div>
        <div className='cs-body'>
          <div className='cs-inventory-column'>
            <div className='rbox'>
              <div style={{textAlign: 'center'}}><b>Equipment and Gear</b></div>
              {equipmentAndGear({rows: 54, role, click: handleItemClick})}
            </div>
          </div>
          <div className='cs-inventory-column'>
            <div className='rbox'>
              <div style={{textAlign: 'center'}}><b>Money and Other Wealth</b></div>
              <div className='cs-pair'>
                {moneyAndWealth({rows: 8, items: valuables8, click: handleItemClick})}
                {moneyAndWealth({rows: 8, items: valuables16, click: handleItemClick})}
              </div>
            </div>
            <div className='rbox'>
              <div style={{textAlign: 'center'}}><b>Special Items</b></div>
              {specialItems({rows: 30, role, click: handleItemClick})}
            </div>
          </div>
        </div>
      </div>
    )
  }

  function specialItems(props) {
    const {rows, role, click} = props
    const keys = [...Array(rows).keys()]
    const items = role.items.filter(ea => ea.bonus)
    return (
      <div>
        <table>
          <colgroup>
            <col style={{width: '80%'}}/>
            <col style={{width: '20%'}}/>
          </colgroup>
          <thead>
          <tr>
            <th className='inventory-th' style={{textAlign: 'left'}}>Bonus Items</th>
            <th className='inventory-th'>Bonus</th>
          </tr>
          </thead>
          <tbody>
          {keys.map((key, i) => {
            const item = items[i]
            return <tr key={key}>
              <td className='ul'>
                <div onClick={() => click(item)}
                     style={{textAlign: 'left', cursor: item ? 'pointer' : 'arrow'}}>
                  {item && item.description}
                </div>
              </td>
              <td className='ul'>
                <div>{item && formatInt(item.bonus)}</div>
              </td>
            </tr>
          })
          }
          </tbody>
        </table>
      </div>
    )
  }

  function moneyAndWealth(props) {
    const {rows, items, click} = props
    const keys = [...Array(rows).keys()] // list of integers

    return (
      <div>
        <table>
          <colgroup>
            <col style={{width: '90%'}}/>
          </colgroup>
          <tbody>
          {keys.map((key, i) => {
            const item = items[i]
            return <tr key={key}>
              <td className='ul'>
                <div style={{textAlign: 'left', cursor: item ? 'pointer' : 'arrow'}}
                     onClick={() => click(item)}>
                  {item && formatValue(item)}
                </div>
              </td>
            </tr>
          })}
          </tbody>
        </table>
      </div>
    )
  }

  function equipmentAndGear(props) {
    const {rows, role, click} = props
    const keys = [...Array(rows).keys()]

    const toggleFavorite = (item) => {
      item.common_use = !item.common_use

      dispatch(() => {
        const formData = new FormData()
        formData.append('favorite', JSON.stringify({role_id: role.id, item: item, type: 'item'}))
        axios.put('/app/roles/favorites', formData, authHeaders())
          .then(() => {
            dispatch(saveCombatant(role))
            done()
          })
          .catch(err => console.error(err))
      })
    }

    const toggleIcon = (item) => {
      if (!item)
        return null

      return <img src={item.common_use ? checked_icon : unchecked_icon}
                  alt={item.common_use ? 'Commonly used' : 'Not commonly used'}
                  style={{hover: 'pointer', verticalAlign: 'unset'}}
                  onClick={() => toggleFavorite(item)}/>
    }

    return (
      <div>
        <table>
          <colgroup>
            {!isPrinting && <col style={{width: '25px'}}/>}
            <col style={{width: '67%'}}/>
            <col style={{width: '33%'}}/>
            <col style={{width: '33%'}}/>
          </colgroup>
          <thead>
          <tr>
            {!isPrinting && <th className='inventory-th'><img src={star_icon} alt='Commonly used'/></th>}
            <th className='inventory-th' style={{textAlign: 'left'}}>Item (Description)</th>
            <th className='inventory-th'>Location</th>
            <th className='inventory-th'>Weight</th>
          </tr>
          </thead>
          <tbody>
          {keys.map((key, i) => {
            const item = role.items[i]
            return <tr key={key}>
              {!isPrinting && <td>{toggleIcon(item)}</td>}
              <td className='ul'>
                <div style={{textAlign: 'left', cursor: item ? 'pointer' : 'arrow'}}
                     onClick={() => click(item)}>
                  {item && item.description}
                </div>
              </td>
              <td className='ul'>
                <div>{item && item.location}</div>
              </td>
              <td className='ul'>
                <div>{item && formatWeight(item)}</div>
              </td>
            </tr>
          })
          }
          <tr>
            <td>&nbsp;</td>
            <td colSpan='2'>
              <div style={{textAlign: 'right', fontSize: 'smaller'}}>Total Dead Weight:</div>
            </td>
            <td className='ul' style={{textAlign: 'right'}}>
              <div>{Math.round(totalMass(role.items))} kg</div>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan='2'>
              <div style={{textAlign: 'right', fontSize: 'smaller', lineHeight: 1}}>Encumbrance Penalty*:<br/>
                <span style={{fontSize: 'x-small'}}>* -8 per 10% of player's weight</span>
              </div>
            </td>
            <td className='ul' style={{textAlign: 'right'}}>
              <div>{formatInt(role.encumbrance_penalty)}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    )
  }

  function skillCategoriesPage() {
    const category_groups = [[1, 2, 3], [4, 5], [6, 7, 8], [9, 10, 11], [12, 13, 14], [15, 16, 17],
      [18, 19, 20], [21, 22, 23], [24], [25, 26], [27, 28, 29, 30, 31, 32, 33, 34, 35], [36, 37, 38, 39, 40],
      [41, 42, 43], [44, 45, 46], [47, 48, 49, 50, 51], [52], [53, 54, 55, 56, 57, 58, 59, 60]]

    let group_index = -1
    const max_ranks = (cat) => {
      let max = 0
      if (selectedLevel) {
        max = cat.max_ranks()
        // adolescent category groups can have more than the normal rank limits
        // otherwise hobby categories are limited to the max_ranks
        if (selectedLevel.value < 0) {
          race?.adolescent_ranks.filter(ea => ea.cat_ids)
            .forEach(group => {
              if (cat.id >= group.cat_ids.from && cat.id <= group.cat_ids.to) {
                max = group.ranks
              }
            })
        }
      }
      return max
    }

    const handleCategoryClick = (category) => {
      if (isEditing) {
        setSelectedCategory(category)
        setModal(mode.edit_category)
      }
    }

    return (
      <div className='character-sheet'>
        <div className='cs-header'>
          <div className='cs-header-left'>
            <div className='cs-header-title'>
              <div className='cs-title-text'>Skill Categories</div>
            </div>
          </div>
          <div className='cs-header-name-box box'>
            <div><label>Character: </label>
              <div>{role.name}</div>
            </div>
          </div>
        </div>
        <div className='cs-body'>
          <table>
            <colgroup>
              <col style={{width: 190}}/>
              <col style={{width: 90}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
              <col style={{width: 55}}/>
            </colgroup>
            <thead>
            <tr className='cs-page-header'>
              <th style={{textAlign: 'left'}}>Skill Category</th>
              <th>Applicable<br/>Stats</th>
              <th>Dev.<br/>Cost</th>
              <th># of<br/>Ranks</th>
              <th>Rank<br/>Cost</th>
              <th>Rank<br/>Bonus</th>
              <th>Stat<br/>Bonus</th>
              <th>Prof.<br/>Bonus</th>
              <th>Special</th>
              <th>Special</th>
              <th>Total<br/>Bonus</th>
            </tr>
            </thead>
            <tbody>
            {role.skill_categories
              .map((cat, key) => {
                const level = selectedLevel && selectedLevel.value
                const num_ranks = cat.num_ranks(role, level)
                const new_section = key === 0 || !category_groups[group_index].includes(cat.id)
                if (new_section) {
                  group_index++
                }

                const {
                  category_label,
                  rank_cost,
                  dev_cost,
                  rank_bonus,
                  stat_bonus,
                  prof_bonus,
                  cat_bonus,
                } = cat.useBonus(system, role, level)

                return (
                  <tr key={key} style={{borderTop: new_section && '0.5px solid black'}}>

                    <td style={{textAlign: 'left'}}>
                      {isEditing ? <span style={{cursor: isEditing ? 'pointer' : 'arrow'}}
                                         onClick={() => handleCategoryClick(cat)}>{category_label()}</span> :
                        category_label()}
                    </td>

                    <td>{cat.applicable_stats}</td>
                    <td className='ul'>
                      <div>{dev_cost()}</div>
                    </td>
                    <td className='ul'>
                      {isEditing ?
                        (cat.rank_bonus_type === 'Standard' ?
                          <input type='number' value={num_ranks}
                                 className='cs-set-ranks'
                                 min={0} max={max_ranks(cat)}
                                 onChange={(e) => updateCategoryRanks(cat, +e.target.value)}/> :
                          null) :
                        <div>{num_ranks}</div>}
                    </td>
                    <td className='ul'>
                      <div>{rank_cost()}</div>
                    </td>
                    <td className='ul'>
                      <Tippy arrow={true} content={<>{cat.rank_bonus_description(role, level)}</>}>
                        <div>{formatInt(rank_bonus())}</div>
                      </Tippy>
                    </td>
                    <td className='ul'>
                      <div>{formatInt(stat_bonus())}</div>
                    </td>
                    <td className='ul'>
                      <div>{formatInt(prof_bonus())}</div>
                    </td>
                    <td className='ul'>
                      <div>{formatInt(cat.special_bonus1)}</div>
                    </td>
                    <td className='ul'>
                      <div>{formatInt(cat.special_bonus2)}</div>
                    </td>
                    <td className='ul'>
                      <div>{formatInt(cat_bonus())}</div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }


  function illustration(role) {
    const image_data = role.image_data.find(ea => ea.image_type === 'illustration')
    return (<>
      {image_data && <img width='240px'
                          src={`/images/${image_data.path}`}
                          alt={`Artistic illustration of ${role.name}`}/>}
    </>)
  }

  function commonSkills(props) {
    const {rows, left_border, common, role} = props
    const keys = [...Array(rows).keys()] // list of integers
    const style = left_border ? {borderLeft: '0.5px #4F4F4F solid'} : {}

    return (
      <div style={style}>
        <table>
          <colgroup>
            <col style={{width: '60%'}}/>
            <col style={{width: '20%'}}/>
            <col style={{width: '20%'}}/>
          </colgroup>
          <thead>
          <tr>
            <th style={{textAlign: 'left'}}>Skill</th>
            <th>Ranks</th>
            <th>Bonus</th>
          </tr>
          </thead>
          <tbody>
          {keys.map((key, i) => {
            const assign = common[i]
            return <tr key={key}>
              <td style={{textAlign: 'left'}} className='ul'>
                <div>{assign && assign.getLabel()}</div>
              </td>
              <td className='ul'>
                <div>{assign && assign.num_ranks(role)}</div>
              </td>
              <td className='ul'>
                <div>{assign && formatInt(system.skill_bonus(role, assign))}</div>
              </td>
            </tr>
          })}
          </tbody>
        </table>
      </div>
    )
  }

  function commonAttacks(props) {
    const {rows, role, common, weapons} = props
    const keys = [...Array(Math.max(rows, common.length)).keys()] // list of integers

    return (
      <div>
        <table>
          <colgroup>
            <col style={{width: '30%'}}/>
            <col style={{width: '15%'}}/>
            <col style={{width: '15%'}}/>
            <col style={{width: '15%'}}/>
            <col style={{width: '25%'}}/>
          </colgroup>
          <thead>
          <tr>
            <th style={{textAlign: 'left'}}>Attack/Weapon</th>
            <th>Ranks</th>
            <th>Bonus</th>
            <th>Fumble</th>
            <th style={{textAlign: 'left'}}>Range Modifications</th>
          </tr>
          </thead>
          <tbody>
          {keys.map((key, i) => {
            const assign = common[i]
            const attack = assign && role.attacks.find(attack => attack.skill_id === assign.category.id)
            const weapon = attack && weapons[attack.weapon_id]
            return <tr key={key}>
              <td className='ul'>
                <div>{assign && assign.getShortLabel()}</div>
              </td>
              <td className='ul'>
                <div>{assign && assign.num_ranks(role)}</div>
              </td>
              <td className='ul'>
                <div>{assign && formatInt(system.skill_bonus(role, assign))}</div>
              </td>
              <td className='ul'>
                <div>{weapon && weapon.fumble}</div>
              </td>
              <td className='ul' style={{margin: 0}}>
                <div>{range_modifications(weapon)}</div>
              </td>
            </tr>
          })}
          </tbody>
        </table>
      </div>
    )
  }

  function range_modifications(weapon) {
    if (!weapon) {
      return null
    }
    const {range_unit, pb, sr, mr, lr, pb_mod, sr_mod, mr_mod, lr_mod} = weapon
    const unit = range_unit === 'meters' ? 'm' : range_unit
    return <>
      <table className='cs-range-modifications'>
        <tbody>
        <tr>
          <td>{pb}{unit}</td>
          <td>{sr}{unit}</td>
          <td>{mr}{unit}</td>
          <td>{lr}{unit}</td>
        </tr>
        <tr>
          <td>{formatInt(pb_mod)}</td>
          <td>{formatInt(sr_mod)}</td>
          <td>{formatInt(mr_mod)}</td>
          <td>{formatInt(lr_mod)}</td>
        </tr>
        </tbody>
      </table>
    </>
  }

  function commonEquipment(props) {
    const {rows, common} = props
    const keys = [...Array(Math.max(rows, common.length)).keys()] // list of integers

    return (
      <div>
        <table>
          <colgroup>
            <col style={{width: 200}}/>
            <col style={{width: 50}}/>
            <col style={{width: 50}}/>
          </colgroup>
          <thead>
          <tr>
            <th style={{textAlign: 'left'}}>Item</th>
            <th>Location</th>
            <th>Weight</th>
          </tr>
          </thead>
          <tbody>
          {keys.map((key, i) => {
            const item = common[i]
            return <tr key={key}>
              <td style={{textAlign: 'left'}} className='ul'>
                <div>{item && item.description}</div>
              </td>
              <td className='ul'>
                <div>{item && item.location}</div>
              </td>
              <td className='ul'>
                <div>{item && formatWeight(item)}</div>
              </td>
            </tr>
          })}
          </tbody>
        </table>
      </div>
    )
  }

  function levelDescription(role) {
    if (role.level < 0) {
      return <div className='role-data'><a href={`/roles/${role.id}`}>Adolescent</a></div>
    }
    else if (role.level === 0) {
      return <div className='role-data'><a href={`/roles/${role.id}`}>Apprentice</a></div>
    }
    else {
      return <div className='role-data'>{role.level}</div>
    }
  }

  function statsPage() {
    const favSkills = skillAssignments.filter(ea => ea.common_use && !ea.isAttack)
      .sort((a, b) => a.label.localeCompare(b.label))
    const favAttacks = skillAssignments.filter(ea => ea.common_use && ea.isAttack)
      .sort((a, b) => system.skill_bonus(role, b) - system.skill_bonus(role, a))
    const favItems = role.items.filter(ea => ea.common_use)
    const common15 = favSkills.slice(0, 15)
    const common30 = favSkills.slice(15, 30)

    return (
      <div className='character-sheet'>
        <div className='cs-header'>
          <div className='cs-header-left'>
            <div className='cs-header-title' style={{height: '28px'}}>
              <div className='cs-title-text'>Character Sheet</div>
            </div>
            <div className='cs-header-left-detail'>
              <div className='cs-header-xp-box box'>Experience Points</div>
              <div className='cs-header-level-box box'>
                <label>Level: </label>
                {levelDescription(role)}
              </div>
            </div>
          </div>
          <div className='cs-header-name-box box'>
            <div><label>Character Name: </label>
              <div>{role.name}</div>
            </div>
            <div><label>Player: </label>
              <div>{role.type === 'PC' ? role.player : 'NPC'}</div>
            </div>
            <div><label>Campaign (GM): </label>
              <div>{role.campaign}</div>
            </div>
          </div>
        </div>
        <div className='cs-body'>
          <div className='cs-left-stats-column'>
            <div className='box'>
              <div className='cs-stats-title'>Cultural Background</div>
              <div><label>Race: </label>
                <div>{role.race}</div>
              </div>
              <div><label>Height: </label>
                <div>{role.height} {role.height_units}</div>
                <label>Weight: </label>
                <div>{role.weight} {role.weight_units}</div>
              </div>
              <div><label>Culture: </label>
                <div>{role.background}</div>
              </div>
              <div><label>Profession: </label>
                <div>{role.profession}</div>
              </div>
              <div><label>Training Packages: </label>
              </div>
              {role.tp_assignments.map((tp, i) => {
                return <div key={i} style={{textIndent: '1em'}}>
                  <div>{tp.name}</div>
                </div>
              })}
            </div>
            <div className='box'>
              <div className='cs-stats-title'>Encumbrance and Movement</div>
              <div><label>Armor Type: </label>
                <div>{role.at}</div>
              </div>
              <div><label>Weight Penalty<sup>1</sup>:</label>
                <div>{system?.weight_penalty(role)}</div>
              </div>
              <div><label>Base Movement Rate: </label>
                <div>{system?.base_movement_rate(role) + ' m / round'}</div>
              </div>
              <div><label>Moving Maneuver Penalty: </label>
                <div>{system?.moving_maneuver_penalty(role)}</div>
              </div>
              <div><label>Missile Penalty: </label>
                <div>{system?.ranged_attack_penalty(role.at)}</div>
              </div>
            </div>
            <div className='box'>
              <div className='cs-stats-title'>Defensive Bonus</div>
              <div><label>Quickness Bonus (3 x Qu): </label>
                <div>{formatInt(bonuses.qu * 3)}</div>
              </div>
              <div><label>Armor Quickness Penalty: </label>
                <div>{formatInt(aqp)}</div>
              </div>
              <div><label>Shield: </label>
                <div>{role.shield}</div>
              </div>
              <table>
                <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Melee</th>
                  <th>Energy</th>
                  <th>Firearm</th>
                  <th>Thrown</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td style={{fontSize: 'smaller', fontWeight: 600}}>Shield</td>
                  <td className='rr_ul'>
                    <div>{formatInt(shield_bonus.melee)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(shield_bonus.energy)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(shield_bonus.firearm)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(shield_bonus.thrown)}</div>
                  </td>
                </tr>
                <tr>
                  <td style={{fontSize: 'smaller', fontWeight: 600}}>Quickness</td>
                  <td className='rr_ul'>
                    <div>{formatInt(bonuses.qu * 3)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(bonuses.qu * 3)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(bonuses.qu * 3)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(bonuses.qu * 3)}</div>
                  </td>
                </tr>
                <tr>
                  <td style={{fontSize: 'smaller', fontWeight: 600}}>AQP</td>
                  <td className='rr_ul'>
                    <div>{formatInt(aqp)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(aqp)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(aqp)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(aqp)}</div>
                  </td>
                </tr>
                <tr>
                  <td style={{fontSize: 'smaller', fontWeight: 600}}>Total</td>
                  <td className='rr_ul'>
                    <div>{formatInt(shield_bonus.melee + bonuses.qu * 3 + aqp)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(shield_bonus.energy + bonuses.qu * 3 + aqp)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(shield_bonus.firearm + bonuses.qu * 3 + aqp)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(shield_bonus.thrown + bonuses.qu * 3 + aqp)}</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className='box'>
              <div className='cs-stats-title'>Resistance Rolls</div>
              <table>
                <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th style={{width: '20%'}}>Race Bonus</th>
                  <th style={{width: '40%'}} colSpan='2'>Stat<br/>Bonus</th>
                  <th style={{width: '20%'}}>Total Bonus</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td style={{fontSize: 'smaller', fontWeight: 600}}>Psychic</td>
                  <td className='rr_ul'>
                    <div>{formatInt(race?.psychic)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(bonuses.sd * 3)}</div>
                  </td>
                  <td style={{width: '52pt', fontSize: 'smaller'}}>(3 x SD)</td>
                  <td className='rr_ul'>
                    <div>{formatInt(race?.psychic + bonuses.sd * 3)}</div>
                  </td>
                </tr>
                <tr>
                  <td style={{fontSize: 'smaller', fontWeight: 600}}>Poison</td>
                  <td className='rr_ul'>
                    <div>{formatInt(race?.poison)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(bonuses.co * 3)}</div>
                  </td>
                  <td style={{width: '52pt', fontSize: 'smaller'}}>(3 x Co)</td>
                  <td className='rr_ul'>
                    <div>{formatInt(race?.poison + bonuses.co * 3)}</div>
                  </td>
                </tr>
                <tr>
                  <td style={{fontSize: 'smaller', fontWeight: 600}}>Disease</td>
                  <td className='rr_ul'>
                    <div>{formatInt(race?.disease)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(bonuses.co * 3)}</div>
                  </td>
                  <td style={{width: '52pt', fontSize: 'smaller'}}>(3 x Co)</td>
                  <td className='rr_ul'>
                    <div>{formatInt(race?.disease + bonuses.co * 3)}</div>
                  </td>
                </tr>
                <tr>
                  <td style={{fontSize: 'smaller', fontWeight: 600}}>Fear</td>
                  <td className='rr_ul'>
                    <div>{formatInt(race?.fear)}</div>
                  </td>
                  <td className='rr_ul'>
                    <div>{formatInt(bonuses.sd * 3)}</div>
                  </td>
                  <td style={{width: '52pt', fontSize: 'smaller'}}>(3 x SD)</td>
                  <td className='rr_ul'>
                    <div>{formatInt(race?.fear + bonuses.sd * 3)}</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className='box'>
              <div className='cs-stats-title'>Race/Stat Fixed Info</div>
              <div><label>Body Progression: </label>
                <div>{race && race.bd_prog.join(' • ')}</div>
              </div>
              {race?.mp_prog && <div><label>MP Progression: </label>
                <div>{race.mp_prog.join(' • ')}</div>
              </div>}
            </div>
            <div className='box'>
              {system.weight_penalty(role) !== 0 && <div style={{fontSize: 'x-small'}}>
                <label><sup>1</sup>Weight Penalty = </label>
                <div>(Enc + AQP) + 3 * ST = ({system.encumbrance_penalty(role)} + {-system.armor_quickness_penalty(role.at)}) + 3
                  x {bonuses.st}</div>
              </div>}
            </div>
            <div className='box'>
              {illustration(role)}
            </div>
          </div>
          <div className='cs-right-stats-column'>
            <div className='rbox'>
              <div className='cs-stats-title'>Basic Statistics</div>
              <table>
                <colgroup>
                  <col style={{width: 81}}/>
                  <col style={{width: 42}}/>
                  <col style={{width: 42}}/>
                  <col style={{width: 42}}/>
                  <col style={{width: 42}}/>
                  <col style={{width: 42}}/>
                  <col style={{width: 42}}/>
                </colgroup>
                <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Temp</th>
                  <th>Pot</th>
                  <th>Basic Bonus</th>
                  <th>Racial Bonus</th>
                  <th>Special Bonus</th>
                  <th>Stat<br/>Bonus</th>
                </tr>
                </thead>
                <tbody>
                {system.stats.map((stat, i) => {
                  return (
                    <tr key={stat.abbr} style={{borderTop: !(i % 5) && '0.5px solid black'}}>
                      <td style={{textAlign: 'left'}}>{stat.name}</td>
                      <td className='ul'>
                        <div>{role.stats[stat.abbr]}</div>
                      </td>
                      <td className='ul'>
                        <div>{role.stats[stat.pot_abbr]}</div>
                      </td>
                      <td className='ul'>
                        <div>{formatInt(system.basic_bonus(role, stat))}</div>
                      </td>
                      <td className='ul'>
                        <div>{formatInt(system.race_bonus(role, stat))}</div>
                      </td>
                      <td className='ul'>
                        <div>{formatInt(system.special_bonus(role, stat))}</div>
                      </td>
                      <td className='ul'>
                        <div>{formatInt(system.total_stat_bonus(role, stat))}</div>
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
            <div className='rbox'>
              <div className='cs-stats-title'>Commonly Used Skills</div>
              <div className='cs-pair'>
                {commonSkills({rows: 15, role, common: common15})}
                {commonSkills({rows: 15, left_border: true, role, common: common30})}
              </div>
            </div>
            <div className='rbox'>
              <div className='cs-stats-title'>Commonly Used Attacks</div>
              <div>
                {commonAttacks({rows: 6, role, common: favAttacks, weapons})}
              </div>
            </div>
            <div className='rbox'>
              <div className='cs-stats-title'>Commonly Used Equipment</div>
              <div>
                {commonEquipment({rows: 6, role, common: favItems})}
              </div>
            </div>
            <div className='rbox'
                 style={{display: 'flex', flexDirection: 'row', padding: 0}}>
              <div className='box' style={{width: '33%'}}>
                <div style={{display: 'flex', flexDirection: 'column', height: '1in'}}>
                  <label>Hits (Maximum): </label>
                  <div className='cs-hit-points'>{system?.maximum_hits(role)}</div>
                </div>
              </div>
              <div className='box' style={{width: '34%'}}>
                <div>Miscellaneous Information:</div>
              </div>
              <div className='box' style={{width: '33%'}}>
                <div style={{display: 'flex', flexDirection: 'column', height: '1in'}}>
                  <label>Mind Points (Max): </label>
                  <div className='cs-hit-points'>{system?.maximum_mps(role)}</div>
                </div>
              </div>
            </div>
            <div className='rbox' style={{padding:6}}>
              <div><label>Notes: </label>
                <div className='ul'>
                  {/* Crude formatting until we get contentEditable working */}
                  {(role.notes || '').split('\n\n').map((line, i) => <p key={i}>{line}</p>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
