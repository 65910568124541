import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import configureStore from './store'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import ErrorBoundary from './components/ErrorBoundary'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import GameSession from './components/GameSession'

TimeAgo.addDefaultLocale(en)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={configureStore()}>
    <ErrorBoundary>
      <GameSession>
        <App />
      </GameSession>
    </ErrorBoundary>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()