import {Breadcrumb, Button, Container, Form, Row} from 'react-bootstrap'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {createAdventure} from '../actions/combatActions'
import {useNavigate} from 'react-router'

export default function NewGame({user}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [campaign, setCampaign] = useState('')

  function handleSubmit(event) {
    dispatch(createAdventure({name:campaign, owner_id:user.id}))
    event.preventDefault()
    navigate('/games')
  }

  return (<>
    <Container>
      <Row>&nbsp;</Row>
      <Row>
        <NavigationTrail/>
      </Row>
      <Row>
        <h2>Create your Adventure</h2>

        <p>Spymaster is a world building environment that lets you quickly build interactive stories that you can
          invite your friends to join.</p>

        <p>To create your adventure, first give it a name. You can then add a memorable image,
          define the cast of characters, and the settings in which they find themselves; together
          you can make the plot unfold.</p>

        <p>Don't worry about getting everything right at first. You can always come back and change what you want.</p>

        <Form method='post' onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="rpg-system">
            <Form.Label>Name of Adventure</Form.Label>
            <Form.Control type="text" value={campaign} placeholder="Campaign" onChange={(ev) => setCampaign(ev.target.value)} />
          </Form.Group>
          <Button type='submit'>Create your Adventure!</Button>
        </Form>
      </Row>
    </Container>
  </>)
}

function NavigationTrail() {
  const navigate = useNavigate()
  return (<>
    <Breadcrumb>
      <Breadcrumb.Item onClick={() => navigate('/games')}>My Games</Breadcrumb.Item>
    </Breadcrumb>
    </>)
  }
