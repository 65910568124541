import {Combatant} from '../models/Combatant'
import Skill from '../models/Skill'
import {TrainingPackage} from '../models/TrainingPackage'
import {Player} from '../models/Player'
import {Scene} from '../models/Scene'
import {Adventure} from '../models/Adventure'
import {RPGSystem} from '../models/RPGSystem'
import {speedModifiers} from '../util/rpg_utils'

const initialState = {
  auth_info: null,
  alertMessage: '',
  combatants: [],
  waitingForInput: false,
  system: null,
  weapons: [],
  criticals: [],
  tps: [],
  teams: [
    {label: 'red', value: 'red'},
    {label: 'blue', value: 'blue'},
    {label: 'orange', value: 'orange'},
    {label: 'green', value: 'green'},
    {label: 'gray', value: 'gray'},
  ],
  encounters: [],
  resolution: null,
  lastRoll: 0,
  adventures: [],
  adventure: null,
  scenes: [],
  scene: null,
  shapes: [],
  selectedItems: [],
  players: [],
  editorTab: 0,
  navbarEnabled: true,
  disableBackgroundDragEvents: false,
  uploadProgress: 0,
  uploadController: null,
  pointers: [],
}

const updateMembers = (team, combatant) => {
  return team.map(member => member.id === combatant.id ? combatant : member)
}

const waitingForInput = (state, value) => {
  return { ...state, waitingForInput: value }
}

const updateCombatants = (state, combatants) => {
  combatants.forEach(combatant => {
    state.combatants = updateMembers(state.combatants, combatant)
  })
  return state
}

function quicknessBonus(role, system) {
  const {attack_quickness} = role
  if (role.type === 'Monster' && attack_quickness) {
    return speedModifiers.find(ea => ea.code === attack_quickness).initiative
  }
  else {
    const qu = system.getStat('qu')
    return system.total_stat_bonus(role, qu)
  }
}

const updateInitiative = (state, {role, roll}) => {
  const {system} = state
  const qu_bonus = quicknessBonus(role, system)
  // surprised: -4, declared movement: -1 per 10%, > 50% hits: -4
  const damage_mod = (role.hits / role.maximum_hits) < 0.5 ? -4 : 0
  const surprised_mod = role.surprised ? -4 : 0
  const movement_mod = 0
  // const movement_mod = role.actions.reduce((acc, val) => {
  //   return acc + val.type === 'move' ? (val.percentage / 10) * -1 : 0
  // }, 0)
  const combatant = new Combatant(role)
  combatant.initiative = {
    total: roll + qu_bonus + damage_mod + surprised_mod + movement_mod,
    qu: qu_bonus,
    roll: roll,
    surprised_mod,
    movement_mod,
    damage_mod
  }
  state.combatants = updateMembers(state.combatants, combatant)
  return state
}

const updateSkills = (state, skills) => {
  state.skills = skills.map(skill => new Skill(skill))
  return state.skills
}

const updateTrainingPackages = (state, tps) => {
  state.tps = tps.map(tp => new TrainingPackage(tp))
  return state.tps
}

/*
 * Toggle selection -- if the item is already in the selection, then remove it,
 * otherwise allow multiple items to be selected
 */
const updateSelection = (state, item) => {
  let items = state.selectedItems.slice()
  const index = items.findIndex(ea => ea.id === item.id)
  if (index < 0) {
    items.push(item)
  }
  else {
    items[index] = item
  }
  return {...state, selectedItems:items }
}

const setSelection = (state, item) => {
  if (item instanceof Scene) {
    return {...state, selectedItems:[item], scene:item }
  }
  else {
    return {...state, selectedItems:[item] }
  }
}

const deleteScene = (state, scene_id) => {
  const scenes = state.scenes.slice()
  const index = scenes.findIndex(ea => ea.id === scene_id)
  if (index >= 0) {
    scenes.splice(index, 1)
  }
  return {...state, scenes:scenes }
}

const deleteShape = (state, shape_id) => {
  const shapes = state.shapes.slice()
  const index = shapes.findIndex(item => item.id === shape_id)
  const deleted = shapes[index]
  shapes.splice(index, 1)

  // update hierarchy rows
  const scenes = state.scenes.slice()
  const scene_index = scenes.findIndex(ea => ea.id === deleted.scene_id)
  const scene = scenes[scene_index]
  const item_index = scene.items.findIndex(ea => ea.id === deleted.id)
  if (item_index >= 0) {
    scene.items.splice(item_index, 1)
  }
  scenes[scene_index] = new Scene(scene)

  return { ...state, selectedItems:[scene], shapes, scenes, scene }
}

const updateSelections = (state, item) => {
  let selections = state.selectedItems.slice()
  const index = selections.findIndex(ea => ea.id === item.id)
  if (index >= 0) {
    selections[index] = item
  }
  return selections
}

const updateScene = (state, scene) => {
  const scenes = state.scenes.slice()
  const index = scenes.findIndex(ea => ea.id === scene.id)
  scenes[index] = scene
  const selectedItems = updateSelections(state, scene)
  return {...state, selectedItems, scenes, scene }
}

const createShape = (state, shape) => {
  return createShapes(state, [shape])
}

const createShapes = (state, items) => {
  // update hierarchy rows
  const scenes = state.scenes.slice()
  const scene_id = items.map(ea => ea.scene_id)[0]
  const scene_index = scenes.findIndex(ea => ea.id === scene_id)
  const scene = new Scene(scenes[scene_index])
  scenes[scene_index] = scene
  const shapes = state.shapes.slice()

  items.forEach(shape => {
    scene.items.push(shape)
    shapes.push(shape)
  })

  return {...state, selectedItems:items, shapes, scenes, scene }
}

const updateShape = (state, item) => {
  let shapes = state.shapes.slice()
  const index = shapes.findIndex(ea => ea.id === item.id)
  shapes[index] = item
  const selectedItems = updateSelections(state, item)
  return {...state, selectedItems, shapes }
}

const updateClones = (state, payload) => {
  const [clones, ids] = payload
  let shapes = state.shapes.slice()
  let selectedItems = state.selectedItems.slice()
  clones.forEach((item, i) => {
    const index = shapes.findIndex(ea => ea.id === item.id)
    shapes[index] = item
    const selection_index = selectedItems.findIndex(ea => ea.id === item.id)
    if (selection_index >= 0) {
      selectedItems[selection_index] = item
    }
    item.id = ids[i]
  })
  return {...state, selectedItems, shapes }
}

const updateShapes = (state, items) => {
  let shapes = state.shapes.slice()
  let selectedItems = state.selectedItems.slice()
  items.forEach(item => {
    const index = shapes.findIndex(ea => ea.id === item.id)
    shapes[index] = item
    const selection_index = selectedItems.findIndex(ea => ea.id === item.id)
    if (selection_index >= 0) {
      selectedItems[selection_index] = item
    }
  })
  return {...state, selectedItems, shapes }
}

const updateAdventure = (state, payload) => {
  const adventures = state.adventures.slice()
  const index = adventures.findIndex(ea => ea.id === payload.id)
  const adventure = new Adventure(payload)
  if (index < 0) {
    return { ...state, adventure:payload, adventures:adventures.concat(adventure) }
  }
  adventures[index] = adventure
  return { ...state, adventure, adventures }
}

const deleteAdventure = (state, id) => {
  const adventures = state.adventures.slice()
  const index = adventures.findIndex(ea => ea.id === id)
  if (index < 0) {
    console.error('deleteAdventure: not removed', id)
  }
  adventures.splice(index,1)
  return { ...state, adventures }
}

const updatePlayer = (state, payload) => {
  const {player, ...rest} = payload

  const players = state.players.slice()
  const index = players.findIndex(ea => ea.id === player.id)
  players[index] = new Player({...player, ...rest})
  const selectedItems = updateSelections(state, player)

  return { ...state, selectedItems, players:players }
}

const resolveCombat = (state, resolution) => {
  const { attacker, defender } = resolution
  state.combatants = updateMembers(state.combatants, new Combatant(attacker))
  state.combatants = updateMembers(state.combatants, new Combatant(defender))
  state.resolution = resolution
  return state
}

function updatePointers(state, data) {
  // {
  //   "type": "pointer",
  //   "user": { "user_id": 4, "name": "Steve" },
  //   "from": "Steve",
  //   "from_id": 4,
  //   "pos": { "x": 4093.3164556962024, "y": 421.77215189873425 }
  //   "enabled": true
  // }

  const {user, pos, enabled} = data
  const pointers = state.pointers.slice()
  const index = pointers.findIndex(ea => ea.user_id === user.user_id)
  if (index < 0) {
    pointers.push({...user, pos, enabled})
  }
  else {
    pointers[index] = {...user, pos, enabled}
  }
  return {...state, pointers}
}

export default function reduceAction(state = initialState, action) {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'UPDATE_POINTERS':
      return updatePointers(newState, action.payload)
    case 'DISPLAY_ALERT':
      return { ...state, alertMessage: action.payload }
    case 'SAVE_USER':
      const user = action.payload
      return { ...state, user }
    case 'UPDATE_AUTH_INFO':
      const auth_info = action.payload
      return { ...state, auth_info }
    case 'UPDATE_COMBAT_STORE':
      const {key, value} = action.payload
      newState[key] = value
      return newState
    case 'ADD_COMBATANT':
      return { ...state, combatants: newState.combatants.concat([action.payload.combatant]) }
    case 'DELETE_COMBATANT':
      return { ...state, combatants: newState.combatants.filter(role => role.id !== action.payload.id) }
    case 'SAVE_COMBATANT':
      const { combatant } = action.payload
      const combatants = newState.combatants.slice()
      const index = combatants.findIndex(ea => ea.id === combatant.id)
      if (index < 0) {
        combatants.push(combatant)
      }
      else {
        combatants[index] = combatant
      }
      return { ...state, combatants }
    case 'READ_SYSTEM':
      const system = RPGSystem.fromData(action.payload)
      return { ...state, system }
    case 'READ_WEAPONS':
      return { ...state, weapons: action.payload }
    case 'READ_CRITICALS':
      return { ...state, criticals: action.payload }
    case 'READ_ROLES':
      return { ...state, combatants: action.payload }
    case 'READ_ENCOUNTERS':
      return { ...state, encounters: action.payload }
    case 'READ_SKILLS':
      return { ...state, skills: updateSkills(newState, action.payload) }
    case 'READ_TRAINING_PACKAGES':
      return { ...state, tps: updateTrainingPackages(newState, action.payload) }
    case 'LAST_ROLL':
      return { ...state, lastRoll: action.payload }
    case 'ATTACK_RESOLVED':
      return resolveCombat(newState, action.payload)
    case 'WAITING_FOR_INPUT':
      return waitingForInput(newState, action.payload)
    case 'UPDATE_COMBATANTS':
      return updateCombatants(newState, action.payload)
    case 'UPDATE_INITIATIVE':
      return updateInitiative(newState, action.payload)
    case 'SET_EDITOR_TAB':
      return { ...state, editorTab:action.payload }
    case 'CREATE_SCENE':
      return { ...state, scene: action.payload, scenes: state.scenes.concat(action.payload) }
    case 'DELETE_SCENE':
      return deleteScene(state, action.payload)
    case 'ADD_SELECTION':
      return updateSelection(state, action.payload)
    case 'REMOVE_SELECTION':
      return updateSelection(state, action.payload)
    case 'SELECT_ITEM':
      return setSelection(state, action.payload)
    case 'UPDATE_SCENE':
      return updateScene(state, action.payload)
    case 'FETCH_SHAPES':
      return { ...state, shapes:action.payload }
    case 'UPDATE_SHAPES':
      return updateShapes(state, action.payload)
    case 'UPDATE_CLONES':
      return updateClones(state, action.payload)
    case 'CREATE_SHAPES':
      return createShapes(state, action.payload)
    case 'CREATE_SHAPE':
      return createShape(state, action.payload)
    case 'DELETE_SHAPE':
      return deleteShape(state, action.payload)
    case 'UPDATE_SHAPE':
      return updateShape(state, action.payload)
    case 'CLONE_SHAPE':
      return createShape(state, action.payload)
    case 'UPDATE_PLAYER':
      return updatePlayer(state, action.payload)
    case 'CREATE_ADVENTURE':
      return updateAdventure(state, action.payload)
    case 'UPDATE_ADVENTURE':
      return updateAdventure(state, action.payload)
    case 'DELETE_ADVENTURE':
      return deleteAdventure(state, action.payload)
    case 'FETCH_SCENES':
      return { ...state, scenes:action.payload }
    case 'FETCH_SCENE':
      return { ...state, scene:action.payload }
    case 'FETCH_PLAYERS':
      return { ...state, players:action.payload }
    case 'FETCH_ADVENTURES':
      return {...state, adventures:action.payload }
    case 'NAVBAR_ENABLED':
      return {...state, navbarEnabled:action.payload }
    case 'DISABLE_BACKGROUND_DRAG_EVENTS':
      return {...state, disableBackgroundDragEvents:action.payload }
    default:
      return state
  }
}
