import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'

export default function RecoverSuccess() {

  return (<>
    <Container fluid='sm' style={{paddingTop:'80px',textAlign:'center'}}>
      <Row>
        <Col sm={{span:5, offset:3}}>
          <h3>Thank you for your patience</h3>
          <p className='text-muted'>
            We've sent password reset instructions to your email address.<br/>
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{span:5, offset:3}}>
          <Link as='button' to='/login'>Back to Sign in</Link>
        </Col>
      </Row>
    </Container>
  </>)
}