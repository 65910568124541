import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import '../styles/profile.css'
import ProfileUsername from './ProfileUsername'
import ProfilePassword from './ProfilePassword'

export default function Profile() {
  return (<>
    <Container fluid='sm' style={{paddingTop:'80px'}}>
      <Row>
        <Col sm={{span:8, offset:2}}>
          <h3>Spymaster Profile</h3>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col sm={{span:8, offset:2}}>
          <ProfileUsername/>
          {/*<ProfileEmail/>*/}
          <ProfilePassword/>
          <hr/>
        </Col>
      </Row>
    </Container>
  </>)
}