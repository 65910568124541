import React, {useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import axios from 'axios'
import '../styles/profile.css'
import {authHeaders} from '../actions/combatActions'

export default function ProfilePassword() {
  const [password, setPassword] = useState('')
  const [new_password, setNewPassword] = useState('')
  const [changePassword, setChangePassword] = useState(false)
  const [password_message, setPasswordMessage] = useState()

  const handlePassword = (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('new_password', new_password)
    formData.append('password', password)
    axios.post('/app/profile/password', formData, authHeaders())
    .then((response) => {
      setPasswordMessage(response.data && response.data.message)
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        setPasswordMessage(err.response.data && err.response.data.message)
      }
      else {
        setPasswordMessage('An error occurred')
        console.error(err)
      }
    })
  }

  const toggleChange = () => {
    setChangePassword(!changePassword)
    setNewPassword('')
    setPassword('')
    setPasswordMessage('')
  }

  return (
    <Form onSubmit={handlePassword}>
      <Form.Group controlId="formBasicPassword">
        <div className='up-change-attrib'>
          <Form.Label>Password</Form.Label>
          <a href='#' className='form-text up-change-link'
             onClick={toggleChange}>{changePassword ? 'Hide' : 'Change'}</a>
        </div>
        {changePassword && <>
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <Form.Control type="password" placeholder="New Password" value={new_password}
                          style={{width:'40%'}}
                          onChange={(e) => setNewPassword(e.target.value)}/>
            <Form.Control type="password" placeholder="Current Password" value={password}
                          style={{width:'40%'}}
                          onChange={(e) => setPassword(e.target.value)}/>
            <Button variant="primary" type="submit">Update</Button>
          </div>
          <Form.Text>
            <span style={{color:'red'}}>{password_message}</span>
          </Form.Text>
        </>}
      </Form.Group>
    </Form>
  )
}