export default class Profession {
  constructor(options) {
    const {label, data} = options
    this.label = label
    this.prime_stats = data.prime_stats
    this.category_bonuses = data.category_bonuses
    this.skills = data.skills
  }

  category_bonus(category) {
    const lookup = this.category_bonuses.find(ea => ea.category === category)
    return lookup?.bonus ?? 0
  }
}
