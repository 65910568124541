import React, {useEffect, useState} from 'react'
import {Button, Col, Container, Form, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {useNavigate} from 'react-router'

export default function Signup() {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [username_message, setUsernameMessage] = useState('')
  const [email_message, setEmailMessage] = useState('')
  const [submit_message, setSubmitMessage] = useState('')
  const [password_message, setPasswordMessage] = useState('')

  const navigate = useNavigate()

  const handleSignup = (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('username', username)
    formData.append('email', email)
    formData.append('password', password)
    axios.post('/app/signup', formData)
    .then(() => {
      navigate('/signup/success')
    })
    .catch(err => {
      const status = err.response && err.response.status
      const message = err.response.data && err.response.data.message
      if (status === 409) {
        console.error(message)
        if (message.includes('User name')) {
          setUsernameMessage(message)
        }
        else if (message.includes('Email')) {
          setEmailMessage(message)
        }
      }
      else {
        setSubmitMessage(err && err.message)
      }
    })
  }

  useEffect(() => {
    if (username || password || email) {
      setUsernameMessage('')
      setPasswordMessage('')
      setEmailMessage('')
      setSubmitMessage('')
    }
  }, [username, email, password])

  return (<>
    <Container className='app-signin'>
      <Row>
        <Col sm={{span:8, offset:2}}>
          <h3>Spymaster Sign up</h3>
          <p>
            Spymaster is not yet available for public access, but if you're interested, please sign up below.
          </p>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col sm={{span:6, offset:3}} className='app-signin-form'>
          <Form onSubmit={handleSignup}>

            <Form.Group controlId="formBasicUsername">
              <Form.Label>Name</Form.Label>
              <Form.Control type="username" placeholder="Enter user name" value={username}
                onChange={(e) => setUsername(e.target.value)}/>
              <Form.Text>
                <span style={{color:'red'}}>{username_message}</span><br/>
                {username_message && <Link to='/recover'>Forgot your password?</Link>}
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" value={email}
                onChange={(e) => setEmail(e.target.value)}/>
              <Form.Text>
                <span style={{color:'red'}}>{email_message}</span><br/>
                {email_message && <Link to='/recover'>Forgot your password?</Link>}
                <p className="text-muted">
                  We do not share your email &nbsp;
                  <Link to='/privacy' className='small'>Privacy Policy</Link>
                </p>
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" value={password}
                onChange={(e) => setPassword(e.target.value)}/>
              <Form.Text>
                <span style={{color:'red'}}>{password_message}</span><br/>
              </Form.Text>
            </Form.Group>
            <Form.Text>
              <span style={{color:'red'}}>{submit_message}</span><br/>
            </Form.Text>

            <Button variant="primary" type="submit" disabled={!username || !password || !email}>
              Sign up
            </Button>

          </Form>
        </Col>
        <Col sm={{span:8, offset:2}}>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col sm={{span:8, offset:2}}>
          <p>Already have an account? <Link to='/login'>Click here to sign in</Link></p>
        </Col>
      </Row>
    </Container>
  </>)
}