import {useDispatch} from 'react-redux'
import React, {useContext, useState} from 'react'
import {Adventure} from '../models/Adventure'
import {updateAdventure} from '../actions/combatActions'
import {Button, Card} from 'react-bootstrap'
import placeholder from '../images/gradient.png'
import FileUpload from './FileUpload'
import {Tooltip} from 'react-tippy'
import {Link} from 'react-router-dom'
import {GameSessionContext} from './GameSession'
import ReactTimeAgo from 'react-time-ago'
import {useNavigate} from 'react-router'

export default function AdventureSession({user, adventure, session}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [imageHover, setImageHover] = useState(false)

  function updateAdventureImage(response, adventure) {
    const options = {...adventure, imageURL: response.data.path}
    const updated = new Adventure(options)
    dispatch(updateAdventure(updated))
  }

  function handleNavigate() {
    navigate((adventure.owner_id === user.id) ? `/games/${adventure.id}` : `/play/${adventure.id}`)
  }

  const gm = adventure && adventure.owner_id === user.id
  return (<>
    <Card className='md-6'>
      <Card.Img className='game-nav'
                onClick={handleNavigate}
                onMouseOver={() => setImageHover(true)}
                onMouseLeave={() => setImageHover(false)}
                variant="top" src={adventure.imageURL || placeholder} />
      {gm && imageHover &&
        <Card.ImgOverlay className='fu-overlay'
                         onMouseOver={() => setImageHover(true)}>
          <FileUpload game_id={adventure.id}
                      onCompletion={(res) => updateAdventureImage(res, adventure)}/>
        </Card.ImgOverlay>}
      <Card.Body className='game-nav'>
        <Card.Title className='game-card' onClick={handleNavigate}>{adventure.name}</Card.Title>
        <PlayersSubtitle adventure={adventure} session={session}/>
        <Card.Text>
          {gm && <SessionButton game={session}/>}
          {session && !gm && <SessionLink adventure={adventure} />}
          {adventure.description}
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <CardFooter game={session}/>
      </Card.Footer>
    </Card>
  </>)
}

function PlayersSubtitle({adventure, session}) {

  const playerLabel = (player) => {
    if (session && session.users.find(user => user.user_id === player.player_id)) {
      return <Tooltip title={`${player.name} is connected`}>
        <span className='ad-connected-user'>{player.name}</span>
      </Tooltip>
    }
    else {
      return <span>{player.name}</span>
    }
  }

  const gmLabel = (adventure) => {
    if (session && session.users.find(user => user.user_id === adventure.owner_id)) {
      return <Tooltip title={`${adventure.gm} is connected`}>
        <span className='ad-connected-user'>{adventure.gm}</span>
      </Tooltip>
    }
    else {
      return <span>{adventure.gm}</span>
    }
  }

  if (adventure.players && adventure.players.length) {
    const playerList = adventure.players
      .filter(player => player.player_id !== adventure.owner_id)
      .map(player => playerLabel(player))
      .map((el, id) => <span key={id}> {el}</span>)

    return (<>
      <Card.Subtitle className='mb-2 text-muted'>GM: {gmLabel(adventure)}</Card.Subtitle>
      <Card.Subtitle className='mb-2 text-muted'>Players: {playerList}</Card.Subtitle>
    </>)
  } else {
    return <Link to={`/game/${adventure.id}/invite`}>invite some players!</Link>
  }
}

function CardFooter({game}) {
  const session = useContext(GameSessionContext)

  if (!game) {
    return (<>
      <small className="text-muted">Not connected &nbsp;</small>
      <Button size='sm' variant='warning' onClick={() => session.reconnect()}>Reconnect</Button>
    </>)
  }

  const adventure = game.adventure
  const now = new Date().getTime()
  let startMessage

  if (!adventure) {
    return null
  }

  if (adventure.start_dt < now) {
    startMessage = <span>Game started <ReactTimeAgo date={adventure.start_dt}/></span>
  }
  else {
    startMessage = <span>Game will start <ReactTimeAgo date={adventure.start_dt}/></span>
  }

  if (adventure.start_dt && !adventure.finish_dt) {
    return <small className="text-muted">{startMessage}</small>
  }
  else if (adventure.finish_dt) {
    return <small className="text-muted">Last Played <ReactTimeAgo date={adventure.finish_dt}/></small>
  }
  else {
    return <small className="text-muted">Not yet played</small>
  }
}

function SessionButton({game}) {
  const navigate = useNavigate()
  const session = useContext(GameSessionContext)

  if (!game) {
    return null
  }

  const adventure = game.adventure

  function handleStartSession() {
    session.sendMessage({type:'create', game_id:adventure.id})
    navigate(`/play/${adventure.id}/`)
  }

  const handleJoin = () => {
    navigate(`/play/${adventure.id}/`)
  }

  if (adventure.start_dt && !adventure.finish_dt) {
    return <Button size='sm' className='ad-start-button' variant='warning'
                   onClick={handleJoin}>Join Game</Button>
  }
  else {
    return <Button size='sm' className='ad-start-button' variant='warning'
                   onClick={handleStartSession}>Start Game</Button>
  }
}

function SessionLink({adventure}) {
  const session = useContext(GameSessionContext)
  const navigate = useNavigate()

  const handleJoin = () => {
    navigate(`/play/${adventure.id}/`)
  }

  if (!adventure) {
    return null
  }

  const gameSession = session.gameSession(adventure.id)
  const game = gameSession.adventure
  if (game.start_dt && !game.finish_dt) {
    return (<>
      <Link to={`/play/${adventure.id}/`} onClick={handleJoin} className='ad-join-link'>Join Game</Link>
    </>)
  }
  return null
}

