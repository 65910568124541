import React, {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import '../styles/combat.css'
import {Stack} from 'react-bootstrap'
import {Tooltip} from 'react-tippy'
import {Send} from 'react-bootstrap-icons'
import {GameSessionContext} from './GameSession'
import axios from 'axios'
import {authHeaders} from '../actions/combatActions'

export default function CriticalDetails({roll, chart, category, game}) {
  const [critical, setCritical] = useState([])
  const dispatch = useDispatch()
  const session = useContext(GameSessionContext)

  function sendCriticalDetails(content) {
    session.sendMessage({type: 'text', content, game_id: game.id})
  }

  function urlForChart(chart, severity, roll) {
    if (chart.type === 'critical') {
      // chart is numeric
      return `/app/weapons/critical?severity=${category}&chart=${chart.value}&roll=${roll}`
    } else if (chart.type === 'fumble') {
      // chart is a string
      return `/app/weapons/fumble?type=${category}&chart=${chart.code}&roll=${roll}`
    } else {
      return null
    }
  }

  useEffect( () => {
    if (chart && roll > 0) {
      const url = urlForChart(chart, category, roll)
      if (url) {
        axios.get(url, authHeaders())
          .then(lookup => {
            setCritical(lookup.data)
          })
      }
      else {
        setCritical([])
      }
    }
  }, [roll, chart, dispatch, category])

  return (<>
    <Stack direction='horizontal' className='p-3'>
      {renderCritical(critical)}
      {critical.critical && <Tooltip title='Send to chat'>
        <div className='cc-menu-button'
             onClick={() => sendCriticalDetails(critical.critical)}
             style={{marginLeft: 'auto', color: 'blue'}}>
          <Send/>
        </div>
      </Tooltip>}
    </Stack>
  </>)
}

function renderCritical(critical) {
  // Key:
  // ßπ =must parry ß rounds;
  // ß∏=no parry for ß rounds;
  // ß∑=stunned for ß rounds;
  // ß∫ =bleed ß hits per round;
  // (-ß)=foe has -ß penalty;
  // (+ß)=attacker gets +ß next round.

  const damages = critical.damage ? critical.damage.split(' ') : []
  const message = critical.critical || critical.description
  const damage = damages.map(code => {
    const found_hits = code.match(/(\d+)H/)
    const found_parry = code.match(/(\d*)π/)
    const found_stun_no_parry = code.match(/(\d*)∑∏/)
    const found_no_parry = code.match(/(\d*)∏/)
    const found_stunned = code.match(/(\d*)∑/)
    const found_bleed = code.match(/(\d*)∫/)
    const found_penalty = code.match(/\((-\d+)\)/)
    const found_bonus = code.match(/\((\+\d+)\)/)

    if (found_hits) {
      return `+${found_hits[1]}H damage`
    }
    if (found_parry) {
      return `must parry ${found_parry[1] || 1} rounds`
    }
    if (found_stun_no_parry) {
      return `stunned and unable to parry for ${found_stun_no_parry[1] || 1} rounds`
    }
    if (found_no_parry) {
      return `cannot parry for ${found_no_parry[1] || 1} rounds`
    }
    if (found_stunned) {
      return `stunned for ${found_stunned[1] || 1} rounds`
    }
    if (found_bleed) {
      return `bleeding ${found_bleed[1] || 1} hits per round`
    }
    if (found_penalty) {
      return `foe has ${found_penalty[1]} penalty`
    }
    if (found_bonus) {
      return `attacker has ${found_bonus[1]} bonus`
    }
    return null
  })
  return <div>
    <span>{message}</span>
    <span>&nbsp;</span>
    <span>{damage.filter(ea => ea).join(', ')}</span>
  </div>
}
