import {Alert, Button, Col, Container, Row} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/adventure.css'
import {useDispatch, useSelector} from 'react-redux'
import {displayAlert} from '../actions/combatActions'
import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {GameSessionContext} from './GameSession'
import AdventureSession from './AdventureSession'

export default function MyGames({adventures, user}) {
  const sessions = useContext(GameSessionContext)
  const dispatch = useDispatch()
  const alertMessage = useSelector(state => state.combatReducers.alertMessage)

  return (<div className='adventure-list'>
    {alertMessage && <Alert variant='danger' onClose={() => dispatch(displayAlert(null))} dismissible>
      {alertMessage}
    </Alert>}
    <Container>
      <Row>&nbsp;</Row>
      <Row>
        <h2>My Games
        <Button as={Link} to={'/new-game'} size='sm' className='ad-new-game'>New Game</Button>
        </h2>
      </Row>
      <Row xs={1} md={3} className="g-4">
        {adventures.map((adventure, i) => {
          const session = sessions.gameSession(adventure.id)
          return <Col key={i}>
            <AdventureSession user={user} adventure={adventure} session={session}/>
          </Col>
        })
        }
      </Row>
    </Container>
  </div>)
}

