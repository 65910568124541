// Skill Categories

export default class Category {
  constructor(options) {
    const {
      id,
      label,
      applicable_stats,
      rank_bonus_type,
      cost_1,
      cost_2,
      cost_3,
      ranks = [],
      levels = [],
      special_bonus1 = null,
      special_bonus2 = null
    } = options
    this.id = id
    this.label = label
    this.applicable_stats = applicable_stats
    this.rank_bonus_type = rank_bonus_type
    this.cost_1 = cost_1
    this.cost_2 = cost_2
    this.cost_3 = cost_3
    this.ranks = ranks
    this.levels = levels
    this.special_bonus1 = special_bonus1
    this.special_bonus2 = special_bonus2
  }

  num_ranks(role, level = null) {
    if (this.rank_bonus_type === 'Standard') {
      if (level !== null) {
        const index = this.levels.indexOf(level)
        return (index < 0) ? 0 : this.ranks[index]
      }
      return this.ranks.reduce((acc, val) => acc + val, 0)
    }
    return 'n/a'
  }

  setNumRanks(value, role, level) {
    const ranks = this.ranks
    const levels = this.levels
    if (levels.includes(level)) {
      const index = levels.indexOf(level)
      ranks[index] = value
    } else {
      ranks.push(value)
      levels.push(level)
    }
    this.ranks = ranks
    this.levels = levels
  }

  max_ranks() {
    const costs = [this.cost_1, this.cost_2, this.cost_3]
    return costs.filter(ea => Number.isInteger(ea)).length
  }

  new_ranks() {
    switch (this.rank_bonus_type) {
      case 'Special':
        return '†'
      case 'Combined':
        return '*'
      case 'Limited':
        return '‡'
      default:
        return '❏❏❏'
    }
  }

  rank_bonus_description(role, level) {
    if (this.rank_bonus_type === 'Standard') {
      const progressions = [-15, 2, 1, 0.5, 0]
      return <div>
        <b>Category Rank Bonuses</b><br/>
        No ranks: {progressions[0]}<br/>
        Ranks 1-10: +{progressions[1]}<br/>
        Ranks 11-20: +{progressions[2]}<br/>
        Ranks 21-30: +{progressions[3]}<br/>
        Above Rank 30: +{progressions[4]}
      </div>
    } else {
      return <div>
        <b>{this.rank_bonus_type} rank progression</b><br/>
        <p>The combined, limited, and special category rank bonuses are always zero.</p>
      </div>
    }
  }

  category_label() {
    let text = this.label

    const patterns = [
      [/Scientific\/Analytic •/, 'Science •'],
      [/Psychic Powers •/, 'Psi •'],
      [/Technical\/Trade •/, 'Tech •'],
      [/Martial Arts •/, 'MA •'],
    ]
    patterns.forEach(pattern => {
      if (text.match(pattern[0])) {
        text = text.replace(pattern[0], pattern[1])
      }
    })
    return text
  }

  dev_cost() {
    return this.cost_1
      + ((this.cost_2 && ('/' + this.cost_2)) || '')
      + ((this.cost_3 && ('/' + this.cost_3)) || '')
  }

  rank_cost(role, level = null) {
    const costs = [this.cost_1, this.cost_2, this.cost_3]
    const max_ranks = costs.filter(ea => Number.isInteger(ea)).length
    const ranks = this.num_ranks(role, level)
    let sum = 0
    for (let i = 0; i < Math.min(max_ranks, ranks); i++) {
      sum += +costs[i]
    }
    return sum
  }

  rank_bonus(role, level = null) {
    if (this.rank_bonus_type === 'Standard') {
      const progressions = [-15, 2, 1, 0.5, 0]
      const num_ranks = this.num_ranks(role, level)
      let sum = num_ranks === 0 ? progressions[0] : 0, progression = 0
      for (let rank = 1; rank <= num_ranks; rank++) {
        if (rank <= 10) {
          progression = progressions[1]
        } else if (rank <= 20) {
          progression = progressions[2]
        } else if (rank <= 30) {
          progression = progressions[3]
        } else {
          progression = progressions[4]
        }
        sum += progression
      }
      return sum
    }
    return 0
  }

  useBonus(system, role, level = null) {

    const profession = system.professions.find(ea => ea.label === role.profession)

    const stat_bonus = () => {
      return this.applicable_stats.split('/').map(ea => ea.toLowerCase())
        .filter(ea => ea.length > 0)
        .reduce((sum, abbr) => {
          const stat = system.getStat(abbr)
          return sum + system.total_stat_bonus(role, stat)
        }, 0)
    }

    const prof_bonus = () => {
      const prof_bonus = profession?.category_bonuses.find(ea => ea.id === this.id)
      if (prof_bonus) {
        return prof_bonus.bonus
      }
      return null
    }

    const cat_bonus = () => {
      return +this.rank_bonus(role, level) + +stat_bonus() + +prof_bonus() + +this.special_bonus1 + +this.special_bonus2
    }

    return {
      category_label: () => this.category_label(),
      rank_cost: () => this.rank_cost(role, level),
      dev_cost: () => this.dev_cost(),
      rank_bonus: () => this.rank_bonus(role, level),
      stat_bonus,
      prof_bonus,
      cat_bonus,
    }
  }
}
