import React, {Fragment, useState} from 'react'
import {Col, ListGroup} from 'react-bootstrap'
import axios from 'axios'
import {authHeaders} from '../actions/combatActions'
import {Combatant} from '../models/Combatant'
import AdolescentSkillAssignment from './AdolescentSkillAssignment'

export default function AdolescentSkillsPanel({role, system, race}) {

  const [skills, setSkills] = useState([])
  const [skill_categories, setSkillCategories] = useState([])

  const r = system?.races.find(ea => ea.label === race)

  function saveSkills() {
    const formData = new FormData()
    const _role = new Combatant({...role, skills, skill_categories})
    formData.append('role', JSON.stringify(_role))
    axios.put('/app/roles/skills', formData, authHeaders())
      .catch(console.error)
  }

  return (<>
    <h6>Adolescent Skill Ranks</h6>
    <Col md={1}/>
    <ListGroup style={{maxHeight:291,overflowY:'auto'}}>
      {r.adolescent_ranks.map((rank, key) => {
        // use previous item as a filter for wildcard selections (weapons, vocational, general)
        const previous_item = (rank.skills && key > 0) ? r.adolescent_ranks[key - 1] : null
        return (<Fragment key={key}>
          <ListGroup.Item>
            <AdolescentSkillAssignment role={role} rank={rank} race={r} system={system} category_selection={previous_item}/>
          </ListGroup.Item>
        </Fragment>)
      })}
    </ListGroup>
  </>)
}
