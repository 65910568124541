import React, {useContext, useEffect, useState} from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/encounter.css'
import {deleteEncounter, updateEncounter} from '../actions/combatActions'
import {useDispatch, useSelector} from 'react-redux'
import remove_icon from '../images/minus-button-red.svg'
import {Button, Card, Col, Form, InputGroup, Stack, Tab, Tabs} from 'react-bootstrap'
import {Encounter, Grid, Token} from '../models/Encounter'
import placeholder from '../images/gradient.png'
import FileUpload from './FileUpload'
import Select from 'react-select'
import {GameSessionContext} from './GameSession'

export default function EditEncounter({done, encounter}) {
  const [name, setName] = useState(encounter.name || '')
  const [imageURL, setImageURL] = useState(encounter.imageURL)
  const [smallImageURL, setSmallImageURL] = useState(encounter.smallImageURL)
  const [showGrid, setShowGrid] = useState(encounter.grid.enabled || false)
  const [pixels, setPixels] = useState(encounter.grid.pixels || 140)
  const [distance, setDistance] = useState(encounter.grid.distance || 5)
  const [units, setUnits] = useState(encounter.grid.units || 'ft')
  const [worldWidth, setWorldWidth] = useState(encounter.worldWidth)
  const [worldHeight, setWorldHeight] = useState(encounter.worldHeight)
  const [tokens, setTokens] = useState(encounter.tokens)
  const [selected, setSelected] = useState({})
  const [combatantChoices, setCombatantChoices] = useState([])
  const dispatch = useDispatch()
  const uploadController = useSelector(state => state.combatReducers.uploadController)
  const combatants = useSelector(state => state.combatReducers.combatants)
  const session = useContext(GameSessionContext)

  useEffect(() => {
    const choices = combatants
      .filter(role => !tokens.find(ec => ec.role_id === role.id))
      .map(ea => {
        return {label: ea.name, value: ea.id}
      })
      .sort((a, b) => a.label.localeCompare(b.label))
    setCombatantChoices(choices)
    setSelected(choices[0])
  }, [encounter, tokens, combatants])

  const handleReset = () => done()

  const handleSubmit = (e) => {
    e.preventDefault()
    const grid = new Grid({...encounter.grid, enabled: showGrid, distance, pixels, units})
    const updatedEncounter = new Encounter({
      ...encounter,
      grid,
      worldWidth,
      worldHeight,
      name,
      tokens,
      imageURL,
      smallImageURL
    })
    dispatch(updateEncounter(updatedEncounter))
    session.connections.forEach(connection => {
      const {adventure} = connection
      if (encounter.id === adventure?.encounter?.id) {
        // update connected players
        session.sendMessage({type: 'encounter', encounter: updatedEncounter})
      }
    })
    done()
  }

  const handleDelete = (encounter) => {
    session.sendMessage({type:'encounter', action:'stop', encounter})
    dispatch(deleteEncounter(encounter))
    done()
  }

  const addCombatant = () => {
    const new_tokens = tokens.slice()
    const role = combatants.find(ea => ea.id === selected.value)
    new_tokens.push(Token.createToken(role, encounter))
    setTokens(new_tokens)
  }

  const removeToken = (token) => {
    const new_tokens = tokens.filter(ea => ea.role_id !== token.role_id)
    setTokens(new_tokens)
  }

  const page1 = () => {
    return (<>
      <Stack gap={2}>
        <InputGroup>
          <InputGroup.Text>Name</InputGroup.Text>
          <Form.Control type="text" className='enc-name' autoFocus={!name}
                        onChange={(event) => setName(event.target.value)} value={name}/>
        </InputGroup>
        <Stack direction='horizontal' className='justify-content-between'>
          <Select className='enc-select' value={selected} onChange={setSelected} options={combatantChoices}/>
          <Button variant='success' className='cc-add-button' size='sm'
                  onClick={addCombatant}>Add</Button>
        </Stack>

        {tokens.map((token, i) => {
          const role = combatants.find(ea => ea.id === token.role_id)
          return <div key={i}>
            <div className="enc-list-item">
              <img src={remove_icon}
                   alt='remove attack'
                   onClick={() => removeToken(token)}/>
              <div>{role?.name}</div>
            </div>
          </div>
        })}
      </Stack>
    </>)
  }

  function updateEncounterImage(response) {
    setImageURL(response.data.path)
    setSmallImageURL(response.data.thumb)
    setWorldWidth(response.data.width)
    setWorldHeight(response.data.height)
  }

  function gridPixelsChanged(event) {
    setPixels(+event.target.value)
  }

  function gridDistanceChanged(event) {
    setDistance(+event.target.value)
  }

  function gridUnitsChanged(event) {
    setUnits(event.target.value)
  }

  const page2 = () => {
    return (<>
      <Card className='mx-2'>
        <Card.Img className='game-nav' variant="top" src={smallImageURL || placeholder}/>
        <Card.ImgOverlay className='fu-overlay'>
          <FileUpload game_id={encounter.game_id} onCompletion={updateEncounterImage}/>
        </Card.ImgOverlay>
        <Card.Body>
          <Stack direction='horizontal' gap={3}>
            <InputGroup>
              <InputGroup.Text>Width</InputGroup.Text>
              <Form.Control type="number" readOnly value={worldWidth}/>
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Height</InputGroup.Text>
              <Form.Control type="number" readOnly value={worldHeight}/>
            </InputGroup>
          </Stack>
        </Card.Body>
      </Card>
    </>)
  }

  const page3 = () => {
    return (<>
      <Card className='mx-2'>
        <Card.Body>
          <Stack gap={3}>
            <div className="form-check form-switch enc-grid-check align-self-start">
              <input className="form-check-input" type="checkbox" id="grid-switch"
                     checked={showGrid} onChange={() => setShowGrid(!showGrid)}/>
              <label className="form-check-label" htmlFor="grid-switch">Show Grid</label>
            </div>
            {showGrid && <>
              <Stack gap={3}>
                <Stack direction='horizontal'>
                  <Col className='col-8'>
                    <Form.Label>Grid Size</Form.Label>
                  </Col>
                  <Col>
                    <InputGroup>
                      <Form.Control type="number" placeholder="Grid Pixels"
                                    value={pixels} onChange={gridPixelsChanged}/>
                      <InputGroup.Text>Pixels</InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Stack>
                <Stack direction='horizontal'>
                  <Col className='col-3'>
                    <Form.Label>Grid Scale</Form.Label>
                  </Col>
                  <Col>
                    <Stack direction='horizontal' gap={3}>
                      <InputGroup>
                        <InputGroup.Text>Distance</InputGroup.Text>
                        <Form.Control type="number" placeholder="Distance"
                                      value={distance} onChange={gridDistanceChanged}/>
                      </InputGroup>
                      <InputGroup style={{width: 'auto'}}>
                        <InputGroup.Text>Units</InputGroup.Text>
                        <Form.Control type="text" placeholder="Units"
                                      value={units} onChange={gridUnitsChanged}/>
                      </InputGroup>
                    </Stack>
                  </Col>
                </Stack>
              </Stack>
            </>}
          </Stack>
        </Card.Body>
      </Card>
    </>)
  }

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset}>
      <Tabs defaultActiveKey="page1" className="mb-3">
        <Tab eventKey="page1" title="Combatants">
          {page1()}
        </Tab>
        <Tab eventKey="page2" title="Image">
          {page2()}
        </Tab>
        <Tab eventKey="page3" title="Grid">
          {page3()}
        </Tab>
      </Tabs>
      <div className='enc-modal-footer'>
        {(encounter.id && <input onClick={() => handleDelete(encounter)}
                                 className="btn btn-danger" type='reset' value='Delete'/>) || <div>&nbsp;</div>}
        &nbsp;
        <input className="btn btn-default" type='reset' value='Cancel'/>
        <input className="btn btn-primary" type='submit' value='Save' disabled={!name || uploadController}/>
      </div>
    </Form>
  )
}
