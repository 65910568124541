import {forwardRef} from 'react'

const Battery = forwardRef(({percent}, ref) => {
  const level = isNaN(percent) ? 0 : Math.floor(10 * percent)
  return (<span ref={ref}>
    <svg xmlns="http://www.w3.org/2000/svg" className="health-percent" fill="currentColor"
         width="16" height="16" viewBox="0 0 16 16">
        <path d={`M2 6h${level}v4H2V6z`}/>
        <path
          d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z"/>
    </svg>
  </span>)
})

export default Battery
