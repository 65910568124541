import React, {useEffect, useState} from 'react'
import {BrowserRouter, Route, useParams} from "react-router-dom"
import NavContainer from './components/NavContainer'
import PrintCharacterSheet from './components/PrintCharacterSheet'
import Signup from './components/Signup'
import SignIn from './components/SignIn'
import {fetchRole, setUserFromToken} from './actions/combatActions'
import {useDispatch, useSelector} from 'react-redux'
import Recover from './components/Recover'
import RecoverSuccess from './components/RecoverSuccess'
import SignupSuccess from './components/SignupSuccess'
import ResetPassword from './components/ResetPassword'
import ResetSuccess from './components/ResetSuccess'
import {Routes} from 'react-router'
import {AUTH_TOKEN} from './util/string_constants'
import NewGame from './components/NewGame'
import MyGames from './components/MyGames'
import CharacterView from './components/CharacterView'
import AdventureDetail from './components/AdventureDetail'
import Profile from './components/Profile'
import SceneDetails from './components/SceneDetails'
import AdventurePlayer from './components/AdventurePlayer'
import Privacy from './content/Privacy'
import WelcomePage from './components/WelcomePage'
import NotFound from './components/NotFound'
import PlayerInvite from './components/PlayerInvite'

export default function App() {
  const dispatch = useDispatch()
  const auth_info = useSelector(state => state.combatReducers.auth_info)
  const adventures = useSelector(state => state.combatReducers.adventures)
  const navbarEnabled = useSelector(state => state.combatReducers.navbarEnabled)

  // execute once when app loads to ensure we have the latest auth token
  useEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN)
    dispatch(setUserFromToken(token))
  }, [dispatch])

  return (
    <>
      <BrowserRouter>
        {navbarEnabled && <NavContainer/>}
        <Routes>
          <Route path="/home" element={
            auth_info ?  <MyGames adventures={adventures} user={auth_info}/> : <WelcomePage/>
          }/>
          <Route path="/" element={
            auth_info ?  <MyGames adventures={adventures} user={auth_info}/> : <WelcomePage/>
          }/>

          <Route path="/login" element={<SignIn/>}/>
          <Route path="/logout" render={(props) => <SignIn {...props} logout/>}/>
          <Route path="/print/:id" element={<PrintCharacterSheet/>}/>
          <Route path="/privacy" element={<Privacy/>}/>
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/recover/success" element={<RecoverSuccess/>}/>
          <Route path="/recover" element={<Recover/>}/>
          <Route path="/reset/:token" element={<ResetPassword/>}/>
          <Route path="/reset/success" element={<ResetSuccess/>}/>
          <Route path="/signup/success" element={<SignupSuccess/>}/>
          <Route path="/signup" element={<Signup/>}/>

          <Route path="/games" element={<MyGames adventures={adventures} user={auth_info}/>}/>
          <Route path="/games/:game_id" element={<AdventureDetail adventures={adventures} user={auth_info}/>}/>
          <Route path="/roles/:role_id" element={<LookupRole user={auth_info}/>}/>
          <Route path="/games/:game_id/scenes/:scene_id" element={<SceneDetails/>}/>
          <Route path="/play/:game_id" element={<AdventurePlayer adventures={adventures} user={auth_info}/>}/>
          <Route path="/game/:game_id/invite" element={<PlayerInvite/>}/>

          <Route path="/new-game" element={<NewGame user={auth_info}/>}/>

          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>
    </>
 )
}

function LookupRole() {
  const dispatch = useDispatch()
  const {role_id} = useParams()
  const combatants = useSelector(state => state.combatReducers.combatants)
  const [role, setRole] = useState(null)

  useEffect(() => {
    if (combatants.length > 0) {
      const new_role = combatants.find(ea => ea.id === +role_id)
      setRole(new_role)
    }
    else {
      dispatch(fetchRole(role_id))
    }
  }, [dispatch, role_id, combatants])

  return role && <CharacterView role={role}/>
}
