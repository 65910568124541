import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import '../styles/combat.css'
import {Button, Dropdown, DropdownButton, ListGroup} from 'react-bootstrap'
import {saveCombatant, updateInitiative} from '../actions/combatActions'
import {Combatant} from '../models/Combatant'
import dead_icon from '../images/dead.png'
import paralyzed_icon from '../images/paralyzed.png'
import stunned_icon from '../images/stunned.svg'
import blood_icon from '../images/blood-drop.svg'
import must_parry_icon from '../images/must-parry.png'
import no_parry_icon from '../images/no-parry.png'
import attack_icon from '../images/crossed-swords.svg'
import target_icon from '../images/scope.svg'
import {PersonCircle} from 'react-bootstrap-icons'
import Battery from './svg/Battery'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

export function EncounterCombatant({role, team, combatants, encounter, selected, index, editCombatant}) {

  const dispatch = useDispatch()
  const [opponents, setOpponents] = useState([])
  const weapons = useSelector(state => state.combatReducers.weapons)
  const system = useSelector(state => state.combatReducers.system)

  useEffect(() => {
    // const opposingTeam = combatants.filter(ea => ea.team !== team)
    // sometimes you need to attack your own team
    setOpponents(combatants.map(member => {
      return {label: member.name, value: member}
    }))
    if (role.target) {
      const target = combatants.find(ea => ea.id === role.target.id)
      if (target) {
        role.target = target
      }
    }
  }, [combatants])

  const setValue = (attribute, value) => {
    const new_role = new Combatant(role)
    new_role[attribute] = value
    dispatch(saveCombatant(new_role))
  }

  const renderIcon = (icon, alt) => {
    return (
      <span>
        <img src={icon} alt={alt} width={16} height={16}/> {alt}
      </span>
    )
  }

  const renderStatus = () => {
    const labels = []

    if (role.dead) {
      labels.push(renderIcon(dead_icon, `${role.name} is dead`))
    } else {
      if (role.paralyzed > 0) {
        labels.push(renderIcon(paralyzed_icon, `Paralyzed`))
      }
      if (role.stunned > 0) {
        labels.push(renderIcon(stunned_icon, `Stunned for ${role.stunned} rounds`))
      }
      if (role.bleeding > 0) {
        labels.push(renderIcon(blood_icon, `Bleeding at ${role.bleeding} hits per round`))
      }
      if (role.must_parry > 0) {
        labels.push((role.no_parry === 0) && renderIcon(must_parry_icon, `Must parry for ${role.must_parry} rounds`))
      }
      if (role.no_parry > 0) {
        labels.push(renderIcon(no_parry_icon, `Unable to parry for ${role.no_parry} rounds`))
      }
    }

    return labels.map((label, index) => <div key={index}>{label}</div>)
  }

  const obSummary = (role) => {
    const weapon = role.attack && role.attack.weapon_id && weapons[role.attack.weapon_id]
    const modifiers = Object.keys(role.ob_modifiers)
      .map(key => `${key}: (${role.ob_modifiers[key]})`)

    const labels = [`${weapon ? weapon.label : 'Unarmed'} (Bonus: ${role.attack?.bonus || 0})`]
    if (role.ob_modifiers && Object.keys(role.ob_modifiers).length > 0) labels.concat(modifiers)
    if (role.target && role.target.stunned) labels.push(`Opponent is stunned (+20)`)
    if (role.ob_temp) labels.push(`Next round: (${role.ob_temp})`)

    return labels.map((label, index) => <div key={index}>{label}</div>)
  }

  const weaponSummary = (role) => {
    const labels = []
    const weapon = role.attack && role.attack.weapon_id && weapons[role.attack.weapon_id]
    if (!weapon) {
      return 'No Weapon Selected'
    }
    const item = role.attack.item_id && role.items.find(item => item.id === role.attack.item_id)
    labels.push(`Weapon Type: ${weapon.type}`)
    labels.push(item ? `Item: ${item.description}` : 'No item for this attack')
    item && item.capacity && labels.push(<div>
      Shots remaining: {item.quantity} / {item.capacity}
    </div>)
    return labels.map((label, index) => <div key={index}>{label}</div>)
  }

  const healthSummary = (role) => {
    const percent = system ? Math.max(role.hits, 0) / system.maximum_hits(role) : 0
    return (
      <Tippy placement='right' arrow={true} theme='light-border'
             content={<>
              <div>Hits: {role.hits} ({system?.maximum_hits(role)}) {(percent * 100).toFixed(0)}%</div>
              <div>{renderStatus()}</div>
              <hr/>
              <h6>Bonuses & Penalties</h6>
              <div>{obSummary(role)}</div>
              <div>{weaponSummary(role)}</div>
            </>
            }>
        <Battery percent={percent}/>
      </Tippy>
    )
  }

  /*
   * Ammo bar -- quantity / capacity
   * ===============================
   * ```
   * Energy / Ballistic / Thrown & Grenade / Bows
   *    Quantity = attack.weapon.item.quantity
   *    Capacity = attack.weapon.item.capacity
   * Psychic
   *    Quantity = role.stats.mind_points
   *    Capacity = role.stats.max_mind_points (derived from skills)
   * Spells
   *    Quantity = role.stats.power_points
   *    Capacity = role.stats.max_power_points (derived from skills)
   * ```
   */
  const ammoSummary = (weapon) => {
    let {quantity, capacity} = 0

    const attack_type = weapon?.type
    const item = role.items.find(item => item.id === role.attack?.item_id)

    if (item) {
      quantity = item && item.quantity
      capacity = item && item.capacity
    } else if (attack_type === 'Magic') {
      quantity = system.maximum_pps(role)
      capacity = role.stats.max_power_points
    } else if (attack_type === 'Directed') {
      quantity = role.stats.mind_points
      capacity = system.maximum_mps(role)
    } else {
      return null
    }

    const percent = Math.max(quantity, 0) / capacity
    return (capacity && <Battery percent={percent}/>)
  }

  function takeInitiative(event) {
    const values = combatants.map(ea => ea.initiative.total)
    // if player already has initiative, move them to the bottom of the list
    role.initiative.total = (index > 0) ? Math.max(...values) + 1 : Math.min(...values) - 1
    dispatch(saveCombatant(role))
  }

  function toggleSurprised(event) {
    role.surprised = event.target.checked
    // change combatant initiative total without modifying dice roll
    dispatch(updateInitiative(role, role.initiative.roll))
  }

  function updateInitiativeRoll(event) {
    // change combatant initiative total without regenerating dice roll
    dispatch(updateInitiative(role, +event.target.value))
  }

  function targetDistance(target) {
    const attacker = encounter.tokens.find(ea => ea.role_id === role.id)
    const defender = encounter.tokens.find(ea => ea.role_id === target.id)
    if (!defender || !attacker) return null

    const {pixels, distance, units} = encounter.grid
    const {x:origin_x, y:origin_y} = attacker
    const {x:dest_x, y:dest_y} = defender
    const screen_units = Math.sqrt((origin_x - dest_x) * (origin_x - dest_x) + (origin_y - dest_y) * (origin_y - dest_y))
    return (screen_units / pixels * distance).toFixed(1) + ' ' + units
  }

  const weapon = role.attack && weapons[role.attack.weapon_id]
  const selectedClass = selected ? 'enc-selected ' : ''
  const {total, qu, roll, surprised_mod, movement_mod, damage_mod} = role.initiative || {}

  return (<>
    <ListGroup.Item className={selectedClass + 'cg-encounter-role'}>
      {encounter?.combatRound > 0 &&
        <Tippy interactive={true} arrow={true}
               placement='right' trigger='click' theme='light-border'
               content={
                 <div className='cg-tooltip'>
                   <h6>Initiative Summary</h6>
                   <div>Quickness: {qu}</div>
                   <div className='cg-tooltip-input'>
                     <label>Roll</label>
                     <input id='initiative-roll' type='number' value={roll || 0} onChange={updateInitiativeRoll}/>
                   </div>
                   {surprised_mod !== 0 && <div>Surprised: {surprised_mod}</div>}
                   {movement_mod !== 0 && <div>Movement: {movement_mod}</div>}
                   {damage_mod !== 0 && <div>Damage: {damage_mod}</div>}
                   <div className='cg-tooltip-input'>
                     <input type='checkbox' checked={index === 0} onChange={takeInitiative}/>
                     <label>Has initiative</label>
                   </div>
                   <div className='cg-tooltip-input'>
                     <input type='checkbox' checked={role.surprised === true} onChange={toggleSurprised}/>
                     <label>Surprised</label>
                   </div>
                 </div>
               }>
          <div className='cg-initiative-button'>
            {total}
          </div>
        </Tippy>
      }
      <div style={{display: 'flex', flexDirection: 'column', paddingRight: 10}}>
        <Button className={`cg-${team} cg-encounter-combatant`}
                onClick={() => editCombatant(role)}>
          <div className='cg-avatar' style={{rotate: 90}}>
            <PersonCircle/>
          </div>
        </Button>
        <span className='cg-small-text cg-avatar'
              style={{alignSelf: 'flex-start', marginTop: 6, paddingRight: 4}}>
          {healthSummary(role)}
        </span>
      </div>
      <div>
        <span className='cg-small-text'>{role.name}</span>
        <div>
          <DropdownButton id={'weapon-select-'+role.id}
            onSelect={(eventKey) => {
              const attack = (eventKey === 'none') ? null : role.attacks[+eventKey]
              setValue('attack', attack)
            }}
            className='cg-inline-button' size='sm'
            title={<img src={attack_icon} alt='Select Attack' width={16} height={16}/>}>
            <Dropdown.Item eventKey='none'>Unarmed</Dropdown.Item>
            <Dropdown.Divider/>
            {role.attacks.map((ea, i) => {
              return <Dropdown.Item eventKey={i} key={i} as="button">
                {ea.label}
              </Dropdown.Item>
            })}
          </DropdownButton>
          <span style={{fontSize: 'small'}}>{weapon ? weapon.label : 'Unarmed'}</span>
        </div>
        <div>
          <DropdownButton id={'target-select-'+role.id}
            onSelect={(eventKey, event) => {
              if (eventKey === 'none') {
                setValue('target', null)
              } else {
                setValue('target', opponents[+eventKey].value)
              }
            }}
            className='cg-inline-button' size='sm'
            title={<img src={target_icon} alt='Select Opponent' width={16} height={16}/>}>
            <Dropdown.Item eventKey='none'>No target</Dropdown.Item>
            {opponents.length > 0 && <Dropdown.Divider/>}
            {opponents.map((ea, i) => {
              return <Dropdown.Item eventKey={i} key={i} as="button">
                {ea.label} ({targetDistance(ea.value)})
              </Dropdown.Item>
            })}
          </DropdownButton>
          <span style={{fontSize: 'small'}}>{
            role.target ? `${role.target.name} (${targetDistance(role.target)})` : 'No target'}
          </span>
        </div>
      </div>
    </ListGroup.Item>
  </>)
}
