import React, {useContext, useEffect, useState} from 'react'
import {GameSessionContext} from './GameSession'
import {Button, Card, CloseButton, ListGroup, Stack} from 'react-bootstrap'
import {Tooltip} from 'react-tippy'
import {Pencil, PlayCircle, PlusCircle, StopCircle} from 'react-bootstrap-icons'
import Draggable from 'react-draggable'
import '../styles/container.css'
import {displayAlert, setCombatReducer} from '../actions/combatActions'
import {useDispatch, useSelector} from 'react-redux'
import {maxZIndex, useLocalStorage} from '../util/hooks'
import ModalEncounter from './ModalEncounter'
import {Encounter} from '../models/Encounter'

export default function EncounterList({encounters, combatants, game, show, onHide}) {
  const [encounter, setEncounter] = useState(null)
  const [showEncounter, setShowEncounter] = useState(false)
  const [createEncounter, setCreateEncounter] = useState(false)
  const session = useContext(GameSessionContext)
  const dispatch = useDispatch()
  const alertMessage = useSelector(state => state.combatReducers.alertMessage)
  const uploadController = useSelector(state => state.combatReducers.uploadController)
  const [position, setPosition] = useLocalStorage('encounter-list-pos', {x: 134, y: 24})
  const [zIndex, setZIndex] = useState(0)

  function editEncounter(enc) {
    setEncounter(enc)
    setShowEncounter(true)
  }

  function presentEncounter(encounter) {
    const action = encounter.active ? 'stop' : 'start'
    session.sendMessage({type: 'encounter', action, encounter})
  }

  function dismissModal() {
    setCreateEncounter(false)
    setShowEncounter(false)
    dispatch(displayAlert(null))
    // cancel any file uploads in progress
    if (uploadController) {
      uploadController.abort()
      dispatch(setCombatReducer('uploadProgress', 0))
      dispatch(setCombatReducer('uploadController', null))
    }
  }

  function updateWindowPosition(event, data) {
    const {x, y} = data
    setPosition({x, y})
  }

  function updateZIndex() {
    setZIndex(maxZIndex() + 1)
  }

  useEffect(() => {
    if (show) {
      updateZIndex()
    }
  }, [show])

  function handleCreateEncounter() {
    setEncounter(new Encounter({game_id:game.id}))
    setCreateEncounter(true)
  }

  if (!show) {
    return null
  }

  return (<>
    <ModalEncounter show={createEncounter || showEncounter}
                    dismissModal={dismissModal}
                    alertMessage={alertMessage}
                    encounter={encounter}
                    combatants={combatants}/>
    <Draggable defaultPosition={position} onMouseDown={updateZIndex}
               onStop={updateWindowPosition} cancel='input,.cc-menu-button,.list-group-item,drag-ignore'>
      <Card className='ap-encounter-list' style={{zIndex}}>
        <Card.Header className='cc-header'>
          <h6 style={{margin: '2px 4px 0 0'}}>Encounter</h6>
          <div className='cc-menu-button' onClick={handleCreateEncounter}>
            <PlusCircle/>
          </div>
          <CloseButton className='drag-ignore' onClick={onHide}/>
        </Card.Header>
        <Card.Body>
          <ListGroup variant='light'>
            {encounters.map((enc, i) => {
              const title = enc.active ? 'Stop Presentation' : 'Present to Players'
              return (
                <ListGroup.Item key={i} eventKey={enc.name}>
                  <Stack direction='horizontal'>
                    {enc.name}
                    <Tooltip className='cc-float-right' title='Edit'>
                      <Button variant='light' size='sm' onClick={() => editEncounter(enc)}>
                        <Pencil/>
                      </Button>
                    </Tooltip>
                    <Tooltip className='cc-present-button' title={title}>
                      <Button variant='light' size='sm' onClick={() => presentEncounter(enc)}>
                        {enc.active ? <StopCircle/> : <PlayCircle/>}
                      </Button>
                    </Tooltip>
                  </Stack>
                </ListGroup.Item>)
            })}
          </ListGroup>
        </Card.Body>
      </Card>
    </Draggable>
  </>)
}
