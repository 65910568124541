import React, {useRef, useState} from 'react'
import {Button, Card, CloseButton, Dropdown, Form, InputGroup, ListGroup, ListGroupItem, Stack} from 'react-bootstrap'
import {PersonCircle, Search} from 'react-bootstrap-icons'
import {Combatant} from '../models/Combatant'
import {displayAlert, setCombatReducer} from '../actions/combatActions'
import {useDispatch, useSelector} from 'react-redux'
import EditCombatantModal from './EditCombatantModal'
import MovableComponent from './MovableComponent'
import {useLocalStorage} from '../util/hooks'

export default function CharacterList({roles, game, user, show, onHide, onDragCombatant}) {
  const [createCharacter, setCreateCharacter] = useState(false)
  const [role, setRole] = useState(null)
  const [roleFilter, setRoleFilter] = useState('')
  const [showRole, setShowRole] = useState(false)
  const [listHeight, setListHeight] = useLocalStorage('character-list-height', 400)
  const root = useRef()

  const dispatch = useDispatch()
  const uploadController = useSelector(state => state.combatReducers.uploadController)
  const system = useSelector(state => state.combatReducers.system)

  function dismissModal() {
    dispatch(displayAlert(null))
    setCreateCharacter(false)
    setShowRole(false)
    // cancel any file uploads in progress
    if (uploadController) {
      uploadController.abort()
      dispatch(setCombatReducer('uploadProgress', 0))
      dispatch(setCombatReducer('uploadController', null))
    }
  }

  function editRole(role) {
    setRole(role)
    setShowRole(true)
  }

  function filterRoles(event) {
    setRoleFilter(event.target.value)
  }

  function updateSize(ref) {
    const cardBody = ref.querySelector('.card-body')
    const cardHeader = ref.querySelector('.card-header')
    const {height} = ref.getBoundingClientRect()

    if (!cardBody) {
      return
    }

    // determine the size of the content area of the component (excluding header)
    const headerRect = cardHeader.getBoundingClientRect()
    const listHeight = Math.max(32, height - 38)

    cardBody.style.height = `${listHeight}px`
    setListHeight(listHeight)
  }

  function onResize(event, direction, ref) {
    updateSize(ref)
  }

  if (!show) {
    return null
  }

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
  // "escape" special regex characters in user input
  const re = new RegExp(roleFilter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')

  const teams = Array.from(new Set(roles.map(ea => ea.team)))

  function createNewCharacter(eventKey) {
    const type = eventKey
    const teams = {
      'NPC': 'green',
      'Monster': 'red',
      'PC': 'blue',
    }
    const new_character = new Combatant({
      active: true,
      type,
      user_id: user.id,
      system: game.system,
      team: teams[eventKey]
    })
    system.generateStats(new_character).then(_ => {
      setRole(new_character)
      setCreateCharacter(true)
    })
  }

  return (<>
    <EditCombatantModal show={createCharacter || showRole} role={role} dismissModal={dismissModal}/>
    <MovableComponent maxWidth={window.innerWidth} maxHeight={window.innerHeight} minWidth={320} minHeight={160}
                      name='character-list'
                      cancel='input,.drag-ignore,.dropdown-toggle,.dropdown-item' onResize={onResize} id='character-list'
                      defaultPosition={{x: 420, y: 24}} defaultSize={{width: 800, height: 600}}>
      <Card ref={root}>
        <Card.Header className='cc-header'>
          <h6 style={{margin: '2px 4px 0 0'}}>Characters</h6>
          <CloseButton className='drag-ignore' onClick={onHide}/>
        </Card.Header>
        <Card.Body style={{maxHeight:listHeight}}>
          <Stack gap={3}>
            <InputGroup>
              <InputGroup.Text><Search/></InputGroup.Text>
              <Form.Control aria-label="Filter by name" value={roleFilter} className='drag-ignore'
                            onChange={filterRoles} placeholder="Filter by name"/>
              <Dropdown onSelect={createNewCharacter}>
                <Dropdown.Toggle as={Button} variant='secondary' size='sm'
                                 id='monster-type-menu'>Add
                </Dropdown.Toggle>
                <Dropdown.Menu className='cc-menu-button'>
                  <Dropdown.Item eventKey='Monster'>Monster</Dropdown.Item>
                  <Dropdown.Item eventKey='Character'>Character</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
            <div style={{overflowY:'auto',maxHeight:listHeight-78}} className='character-list-box'>
              {teams.map(team => {
                  const members = roles.filter(ea => ea.team === team && ea.name.match(re))
                  if (!members.length) {
                    return null
                  }
                  return (
                    <div key={team}>
                      <span className='cc-list-group-label'>{team}</span>
                      <ListGroup className='drag-ignore'>
                        {members
                          .map(role => {
                            return (
                              <ListGroupItem key={role.id} eventKey={role.name} action
                                             onClick={() => editRole(role)} active={false}>
                                <Stack direction='horizontal'>
                                  <div draggable={true} onDragStart={() => onDragCombatant(role)}>
                                    <PersonCircle color={role.team || 'white'}
                                                  style={{marginRight: 10, marginBottom: 2}}/>
                                    {role.name}
                                  </div>
                                </Stack>
                              </ListGroupItem>)
                          })}
                      </ListGroup>
                    </div>
                  )
                }
              )}
            </div>
          </Stack>
        </Card.Body>
      </Card>
    </MovableComponent>
  </>)
}
