import React, {useState} from 'react'
import {Button, Col, Form, Row} from 'react-bootstrap'
import Select from 'react-select'
import {authHeaders, fetchRole} from '../actions/combatActions'
import {Combatant} from '../models/Combatant'
import {useDispatch} from 'react-redux'
import axios from 'axios'

export default function EditCategories(props) {
  const {role, category, done} = props
  const category_ids = role[category]
  const labels = category_ids.map((ea, i) => 'Category ' + (i + 1))

  const specific_categories = category_ids
    .map(id => role.skill_categories.find(cat => cat.id === id))
    .filter(c => c)
    .map(category => {
      const label = category.label.substring(category.label.indexOf('• ') + 2)
      return {label: label, value: category}
    })

  const dev_costs = specific_categories.map(ea => ea.value.dev_cost())
  // sort dev costs from lowest to highest
  // we want 2/5 to come before 2/7 and 2/2/2 to come before 3/3/3
  // 4/4/4 and 6/6/6 should come before 8/8
  // 100 should come before 75, etc.
  dev_costs.sort((a, b) => {
    const quantify = (x) => x.split('/').map(ea => +ea)
    const qa = quantify(a)
    const qb = quantify(b)
    let val = qa[0] - qb[0]
    if (!val && qa[1] && qb[1]) {
      val = qa[1] - qb[1]
    }
    return val
  })

  const dispatch = useDispatch()
  const [selections, setSelections] = useState(specific_categories)

  const handleSubmit = (ev) => {
    ev.preventDefault()
    const new_role = new Combatant(role)
    new_role[category] = selections.map(ea => ea.value.id)

    dispatch(() => {
      const formData = new FormData()
      formData.append('allocation', JSON.stringify({role:new_role, category}))
      axios.put('/app/roles/skill_allocation/', formData, authHeaders())
      .then(() => {
        dispatch(fetchRole(role.id))
        done()
      })
      .catch(err => console.error(err))
    })
    done()
  }

  const handleReset = () => {
    done()
  }

  const handleSelection = (cat, current_index) => {
    const current = selections[current_index]
    const new_index = selections.findIndex(entry => entry.value === cat.value)
    const new_selections = selections.slice()
    new_selections[current_index] = {label: cat.label, value: cat.value}
    new_selections[new_index] = {label:current.label, value:current.value}
    setSelections(new_selections)
  }

  return (
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        {labels.map((label, index) => {
          const cat = selections[index]
          return (
            <Row key={index} className='align-items-center'>
              <Col sm>
                <Form.Label>{label}</Form.Label>
              </Col>
              <Col sm={{span:5}}>
                <Select width='200px'
                        value={[{label:cat.label, value: cat}]}
                        onChange={(cat) => handleSelection(cat, index)}
                        options={specific_categories}/>
              </Col>
              <Col sm className='cc-cost-column'>
                {dev_costs[index]}
              </Col>
            </Row>
          )
        })}

        <Row>&nbsp;</Row>
        <div className='cs-modal-footer'>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <Button variant='secondary' type='reset'>Close</Button>
          <Button variant='primary' type='submit'>Save</Button>
        </div>
      </Form>
  )
}
