import {Button, Card, CloseButton, Dropdown} from 'react-bootstrap'
import icosahedron from '../images/icosahedron.svg'
import {GameSessionContext} from './GameSession'
import React, {useContext, useEffect, useRef, useState} from 'react'
import ReactTimeAgo from 'react-time-ago'
import axios from 'axios'
import {authHeaders} from '../actions/combatActions'
import {useSelector} from 'react-redux'
import {Tooltip} from 'react-tippy'
import {ThreeDots, Trash3} from 'react-bootstrap-icons'
import d4 from '../images/d4.svg'
import d6 from '../images/d6.svg'
import d8 from '../images/d8.svg'
import d10 from '../images/d10.svg'
import d12 from '../images/d12.svg'
import d20 from '../images/d20.svg'
import d100 from '../images/d100.svg'
import dice_sound from '../media/dice.mp3'
import {parseDiceRolls} from '../util/rpg_utils'
import MovableComponent from './MovableComponent'
import {useLocalStorage} from '../util/hooks'

const imageLookup = {
  4: d4,
  6: d6,
  8: d8,
  10: d10,
  12: d12,
  20: d20,
  100: d100,
}

export default function GameChat({show, adventure, onHide}) {
  const session = useContext(GameSessionContext)
  const auth_info = useSelector(state => state.combatReducers.auth_info)

  const [messages, setMessages] = useState([])
  const [history, setHistory] = useState([])
  const [historyIndex, setHistoryIndex] = useState(0)
  const [listHeight, setListHeight] = useLocalStorage('cc-list-height', 400)
  const audio = new Audio(dice_sound)

  const endRef = useRef()
  const inputRef = useRef()
  const root = useRef()


  // if the page is reloaded in the middle of a chat session, we need to recover our messages and rejoin the game
  useEffect(() => {
    if (adventure) {
      axios.get(`/app/sessions/messages/${adventure.id}/`, authHeaders())
        .then(res => setMessages(res.data))
        .catch(console.error)
    }
  }, [adventure])

  useEffect(() => {
    const roll_index = session.messages.findIndex(ea => ea.type === 'text' && ea.content && ea.content.verb === 'roll')
    if (roll_index >= 0) {
      const message = session.messages[roll_index]
      const terms = parseDiceRolls(message.content.source.content)
      // don't play audio for non-random rolls
      if (terms.length > 0) {
        audio.play().then()
      }
    }
    setMessages(messages.concat(session.messages))
  }, [session])

  useEffect(() => {
    setHistoryIndex(history.length - 1)
  }, [history])

  function handleDieRoll() {
    const content = '/roll d100x96'
    session.sendMessage({game_id:adventure.id, type:'text', content})
    updateHistory(content)
  }

  function updateHistory(content) {
    if (!content) {
      return
    }
    let new_history = history.slice()
    const index = new_history.indexOf(content)
    if (index >= 0) {
      new_history.splice(index, 1)
    }
    new_history.push(content)
    setHistory(new_history)
  }

  function updateHistoryIndex(event, index) {
    setHistoryIndex(index)
    if (history[historyIndex]) {
      const len = history[historyIndex].length
      event.target.value = history[historyIndex]
      event.target.setSelectionRange(len, len)
      event.preventDefault()
    }
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      // if you don't specify recipients the message will be sent to all users logged into the session
      session.sendMessage({game_id:adventure.id, type:'text', content:event.target.value })
      updateHistory(event.target.value)
      inputRef.current.value = ''
    }
    else if (event.key === 'ArrowUp') {
      const new_index = Math.max(0, historyIndex - 1)
      updateHistoryIndex(event, new_index)
    }
    else if (event.key === 'ArrowDown') {
      const new_index = Math.min(history.length - 1, historyIndex + 1)
      updateHistoryIndex(event, new_index)
    }
    else if (event.key === 'Delete' || event.key === 'Backspace') {
      event.stopPropagation()
    }
  }

  function clearChatLog() {
    axios.delete(`/app/sessions/messages/${adventure.id}`, authHeaders())
      .then(_ => setMessages([]))
      .catch(console.error)
  }

  useEffect(() => {
    const childRect = endRef.current?.getBoundingClientRect()
    const root = document.getElementById('root')
    const body = root.querySelector('.cc-body')
    const header = root.querySelector('.cc-header')
    const headerRect = header?.getBoundingClientRect()

    body?.scrollBy({top: childRect.top + headerRect.height, behavior:'smooth'})
  }, [messages])

  useEffect(() => {
    const childRect = endRef.current?.getBoundingClientRect()
    const root = document.getElementById('root')
    const body = root.querySelector('.cc-body')

    body?.scrollTo({top: childRect.top, behavior:'instant'})
  }, [show]);

  function updateSize(ref) {
    const cardBody = ref.querySelector('.cc-body')
    const cardHeader = ref.querySelector('.cc-header')
    const cardFooter = ref.querySelector('.cc-footer')
    const {height} = ref.getBoundingClientRect()

    if (!cardBody || !cardFooter) {
      return
    }

    // determine the size of the content area of the component (excluding header & footer)
    const headerRect = cardHeader.getBoundingClientRect()
    const footerRect = cardFooter.getBoundingClientRect()
    const bodyHeight = height - footerRect.height - headerRect.height
    cardBody.style.height = `${bodyHeight}px`
    setListHeight(bodyHeight)
  }

  function onResize(event, direction, ref) {
    updateSize(ref)
  }

  if (!show) {
    return null
  }

  const gm = auth_info.id === adventure.owner_id

  return (<>
    <MovableComponent maxWidth={window.innerWidth} maxHeight={window.innerHeight} minWidth={320} minHeight={150}
                      name='chat-control' onResize={onResize}
                      cancel='input,.cc-menu-button,.dropdown-item,.cc-body,.drag-ignore' id='chat-control'
                      defaultPosition={{x:614, y:24}} defaultSize={{width: 800, height: 600}}>
      <Card ref={root} className='chat-control'>
        <Card.Header className='cc-header'>
          {gm && <Dropdown>
            <Dropdown.Toggle as='div' className='cc-menu-button'>
              <ThreeDots/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={clearChatLog}>
                <div className='cc-menu-icon'>
                  <Trash3 style={{color:'orangered'}}/>
                  Clear Chat Log
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>}
          Random Chat
          <CloseButton className='drag-ignore' onClick={onHide}/>
        </Card.Header>
        <Card.Body className='cc-body' style={{height:listHeight}}>
          {messages.filter(ea => ea.event_dt)
            .map(msg => <ChatMessage data={msg} adventure={adventure} key={msg.event_dt}/>)}
          <div ref={endRef}/>
        </Card.Body>
        <Card.Footer className='cc-footer'>
          <Tooltip title='Roll percentile dice (high open-ended)'>
            <Button style={{display:'contents'}} className='drag-ignore' onClick={handleDieRoll}>
              <img src={icosahedron} alt='roll-d100' width={22} style={{margin:'4px 12px 0 0'}}/>
            </Button>
          </Tooltip>
          <input type='text' placeholder='Say something' ref={inputRef} onKeyDown={handleKeyDown}/>
        </Card.Footer>
      </Card>
    </MovableComponent>
  </>)
}

function ChatMessage({data, adventure}) {
  const {type, success, from_id, from, content, event_dt} = data
  const auth_info = useSelector(state => state.combatReducers.auth_info)

  if (success === false) {
    console.error(data)
    return null
  }

  switch (type) {
    case 'connect':
      return <div className='cc-connect'/>
    case 'text':
      const verb = content.verb ? content.verb : 'say'
      let sender = (from_id === auth_info.id) ? `You ${verb}` : `${from} ${verb}s`
      if (verb === 'roll') {
        const terms = parseDiceRolls(content.source.content)
        let readable = []
        const images = terms
          .map((dieExpr, id) => {
            const {count, faces, explode} = dieExpr
            const n = Math.min(count, 98)
            readable.push(`${count}d${faces}` + (explode ? ' (open-ended) ' : ''))
            return [...Array(n).keys()].map(i => {
              const key = `${event_dt}-${id}-${i}`
              return <img key={key} src={imageLookup[faces] || d6} alt={`d${faces}`}/>
            })
          })
          .flat()

        const description = readable.length > 0 ? readable.join(', ') : ' nothing random'
        return <div className='cc-message'>
          <div className='cc-sender'>{sender} {description}</div>
          <div className='cc-roll-container'>
            {images}
            <div className='cc-roll'>{content.content}</div>
          </div>
          <div className='cc-timestamp'><ReactTimeAgo date={event_dt}/></div>
        </div>
      }
      return <div className='cc-message'>
        <div className='cc-sender'>{sender}</div>
        <div>{content.content}</div>
        <div className='cc-timestamp'><ReactTimeAgo date={event_dt}/></div>
      </div>
    case 'create':
      return <>
        <div className='cc-message'>
          {from} started {adventure.name}
          <div className='cc-timestamp'><ReactTimeAgo date={adventure.start_dt}/></div>
        </div>
        </>
    case 'leave':
      return <div className='cc-message'>{from} has left</div>
    case 'updateUsers':
      if (data.session?.adventure?.id === adventure.id) {
        return <div className='cc-message'>{from} has joined</div>
      }
      return null
    default:
      return null
  }
}
