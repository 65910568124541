import React, {useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/combat.css'
import {EncounterCombatant} from './EncounterCombatant'
import EncounterDetails from './EncounterDetails'
import {ListGroup, Modal} from 'react-bootstrap'
import Draggable from 'react-draggable'
import EditCombatant from './EditCombatant'
import {maxZIndex, useLocalStorage} from '../util/hooks'
import {GameSessionContext} from './GameSession'
import EncounterLog from './EncounterLog'

export default function CombatHUD({encounter, show}) {
  const roles = useSelector(state => state.combatReducers.combatants)
  const auth_info = useSelector(state => state.combatReducers.auth_info)

  const [teams, setTeams] = useState({})
  const [showRoleEdit, setShowRoleEdit] = useState(false)
  const [role, setRole] = useState(null)
  const [combatants, setCombatants] = useState([])

  const [position, setPosition] = useLocalStorage('encounter-combatants-pos', {x:18, y:64})
  const [zIndex, setZIndex] = useState(0)

  const session = useContext(GameSessionContext)
  const gameSession = session.gameSession(encounter?.game_id)
  const game = gameSession && gameSession.adventure
  const gm = game && game.owner_id === auth_info.id

  useEffect(() => {
    const combatants = roles
      .filter(role => encounter.tokens.find(ea => ea.role_id === role.id))
      .sort((a, b) => b.initiative?.total - a.initiative?.total)
    const teams = combatants.reduce((acc, val) => {
      acc[val.team] = acc[val.team] ? acc[val.team].concat(val) : [val]
      return acc
    }, {})
    setCombatants(combatants)
    setTeams(teams)
  }, [roles, encounter])

  useEffect(() => {
    if (show) {
      updateZIndex()
    }
  }, [show])

  const modal= (<>
    <Modal show={showRoleEdit} onHide={dismissModal} dialogClassName='cs-combatant-grid'>
      <Modal.Header closeButton>
        <Modal.Title>{role?.id ? `Edit ${role.name}` : `New ${role?.type}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditCombatant role={role} done={dismissModal}/>
      </Modal.Body>
    </Modal>
  </>)

  function dismissModal() {
    setShowRoleEdit(false)
  }

  function editRole(combatant) {
    setRole(combatant)
    setShowRoleEdit(true)
  }

  function updateWindowPosition(event, data) {
    const {x, y} = data
    setPosition({x, y})
  }

  function updateZIndex() {
    setZIndex(maxZIndex() + 1)
  }

  return (<>
    {modal}
    {gm && <Draggable defaultPosition={position} onMouseDown={updateZIndex}
                onStop={updateWindowPosition} cancel='input,button,.cg-initiative-button,.cg-avatar'>
      <ListGroup className='cg-encounter-combatants' style={{zIndex}}>
        {combatants
          .map((role, key) => {
            return <EncounterCombatant role={role} team={role.team} index={key}
                                       combatants={combatants} encounter={encounter}
                                       selected={key === encounter.selectedAttack}
                                       editCombatant={editRole}
                                       key={key}/>
          })}
      </ListGroup>
    </Draggable>}
    {encounter && <div className='cg-encounter-log'>
      <EncounterDetails encounter={encounter} combatants={combatants} teams={teams}/>
    </div>}
    <EncounterLog encounter={encounter} combatants={combatants} gm={gm}/>
  </>)
}
