import {Shape} from './Shape'
import {Door} from './Door'

export class Scene {
  constructor ({id, name, grid = [32,32], gridEnabled = true, items = []}) {

    this.id = id
    this.name = name
    this.grid = grid
    this.gridEnabled = gridEnabled
    this.items = items.map(item => this.createShape(item))
  }

  createShape(data) {
    switch (data.type) {
      case 'door':
        return new Door(data)
      default:
        return new Shape(data)
    }
  }

}
