import {Combatant} from '../models/Combatant'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import {Encounter} from '../models/Encounter'
import {Weapon} from '../models/Weapon'
import {Adventure} from '../models/Adventure'
import {Scene} from '../models/Scene'
import {Player} from '../models/Player'
import {AUTH_TOKEN} from '../util/string_constants'
import {Shape} from '../models/Shape'

axios.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return response
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // if (error.response && (error.response.status === 401 || error.response.status === 403)) {
  //   const url = new URL(window.location.href)
  //   if (url.pathname === '/') {
  //     window.location.href = '/login'
  //   }
  // }
  return Promise.reject(error)
})

export const authHeaders = () => {
  const token = localStorage.getItem(AUTH_TOKEN)
  const headers = {Authorization: `Bearer ${token}`}
  return token ? {headers} : {}
}

export const setCombatReducer = (key, value) => dispatch => {
  dispatch({type: 'UPDATE_COMBAT_STORE', payload: {key, value}})
}

/* If a modal dialog is being displayed, disable events for sibling elements */
export const disableBackgroundDragEvents = (show) => dispatch => {
  dispatch({type: 'DISABLE_BACKGROUND_DRAG_EVENTS', payload: show})
}

export const setUserFromToken = (token) => dispatch => {
  try {
    const user = token && jwt_decode(token)
    dispatch({type: 'UPDATE_AUTH_INFO', payload: user})
  } catch (err) {
    console.error('setUserFromToken bad token:', token, err)
    dispatch({type: 'UPDATE_AUTH_INFO', payload: null})
  }
}

export const displayAlert = (message) => dispatch => {
  dispatch({type: 'DISPLAY_ALERT', payload: message})
}

const updateCombatants = (combatants) => {
  combatants.forEach(ea => ea.update())
}

export const nameOf = (combatant) => `<span class="combat-name ${combatant.team}">${combatant.name}</span>`

export const critical_callback = async (attacker, defender, session, dispatch, state, encounter, entry, roll) => {
  const {addLogEntry, removeLogEntry, resolve_critical, attackResolved} = encounterContext(session, encounter)
  const {critical, severity, chart, roll_reduction, damage, weapon} = entry.context
  if (critical?.label.match(/large/i)) {
    addLogEntry(`${nameOf(attacker)} should make an open-ended roll`)
  }
  defender.hits -= await resolve_critical(attacker, defender, state, severity, chart, weapon, roll - roll_reduction)
  removeLogEntry(entry)
  addLogEntry(`${nameOf(attacker)} hits target for ${damage} damage`)
  dispatch(attackResolved(attacker, defender))
}

export const fumble_callback = (attacker, defender, session, dispatch, state, encounter, entry, roll) => {
  const {weapon} = entry.context
  const {removeLogEntry, resolve_fumble, attackResolved} = encounterContext(session, encounter)
  resolve_fumble(attacker, weapon, roll).then(_  => {
    removeLogEntry(entry)
    dispatch(attackResolved(attacker, defender))
  })
}

/**
 * Encounter updates are distributed to all connected players, and come from the game session through
 * the session's Web Socket connection.
 * Redux dispatch/getState is used to update the combatants in the global store and to obtain global
 * state required to resolve combat rounds.
 * The synchronization of the token state maintained in the encounter, with the combatants maintained
 * in the global store, is handled by the functions provided here.
 *
 * Dispatching to the global store and sending session messages are performance sensitive operations.
 *
 * @param session
 * @param encounter
 * @returns {{attackResolved: (function(*, *): function(*): void), beginCombat: (function(*): function(*): void), attack: (function(*, *): function(*, *): Promise<void>), endCombat: endCombat, resolve_fumble: ((function(*, *, *): Promise<void>)|*), addLogEntry: addLogEntry, deleteCombatLog: deleteCombatLog, nextAttacker: nextAttacker, removeLogEntry: removeLogEntry, nextRound: (function(*): function(*): void), resolve_critical: (function(*, *, *, *, *, *, *): Promise<number>)}}
 */
export function encounterContext(session, encounter) {
  const addLogEntry = (text) => {
    encounter.combatLog = encounter.combatLog.concat(Array.isArray(text) ? text : [text])
  }

  const removeLogEntry = (item) => {
    const index = encounter.combatLog.findIndex(element => {
      if (typeof(item) === 'string') {
        return item === element
      }
      else if ((typeof(element) === 'object') && (typeof(item) === 'object')) {
        return element.component === item.component && element.callback === item.callback
      }
      else {
        return false
      }
    })
    if (index >= 0) {
      encounter.combatLog.splice(index, 1)
    }
  }

  const deleteCombatLog = () => {
    axios.delete(`/app/encounters/log/${encounter.id}`, authHeaders())
      .then(_ => {
        encounter.combatLog = []
        session.sendMessage({type: 'encounter', encounter})
      })
  }

  const beginCombat = (combatants) => dispatch => {
    dispatch(initializeCombatants(combatants))
    dispatch(updateTokens(combatants))
    dispatch(rollInitiative(combatants))
    encounter.combatLog = [`<span class='combat-name'>Round 1</span>`]
    const updated = new Encounter({...encounter, combatRound:1, attackIndex:0})
    session.sendMessage({type: 'encounter', encounter: updated})
  }

  const endCombat = () => {
    const updated = new Encounter({...encounter, selectedAttack:-1, combatRound:0})
    session.sendMessage({type: 'encounter', encounter: updated})
  }

  const nextRound = (combatants) => dispatch => {
    const round = encounter.combatRound + 1
    updateCombatants(combatants)
    dispatch(updateTokens(combatants))
    dispatch(rollInitiative(combatants))
    const combatLog = encounter.combatLog.slice()
    combatLog.push(`<span class='combat-name'>Round ${round}</span>`)
    const updated = new Encounter({...encounter, selectedAttack:0, combatRound:round, combatLog})
    session.sendMessage({type: 'encounter', encounter: updated})
  }

  const nextAttacker = () => {
    encounter.selectedAttack++
    session.sendMessage({type: 'encounter', encounter})
  }

  const updateTokens = (combatants) => (dispatch, getState) => {
    const tokens = encounter.tokens
    const state = getState()
    const {system} = state.combatReducers
    if (tokens) {
      for (const combatant of combatants) {
        const token = tokens.find(ea => ea.role_id === combatant.id)
        if (!token) {
          console.error('resolution unable to find token for combatant', combatant)
          return
        }
        const { attack, target, hits, must_parry, no_parry, bleeding, stunned, dead } = combatant
        const max_hits = system.maximum_hits(combatant)
        token.data = {
          ...token.data,
          attack,
          target: target?.id,
          hits,
          max_hits,
          must_parry,
          no_parry,
          bleeding,
          stunned,
          dead
        }
      }
    }
  }

  const attackResolved = (attacker, defender) => dispatch => {
    dispatch(updateTokens([attacker, defender]))
    dispatch({ type: 'ATTACK_RESOLVED', payload: {defender, attacker} })
    session.sendMessage({type: 'encounter', encounter})
  }

  const initializeCombatants = (combatants) => (dispatch, getState) => {
    const state = getState()
    const {system} = state.combatReducers
    combatants.forEach(combatant => {
      if (!system.maximum_hits(combatant)) {
        combatant.max_hits = combatant.hits
      }
      // convenience to reset hit points at the beginning of a round, but only if uninitialized
      if (!combatant.dead && !combatant.hits) {
        combatant.hits = system.maximum_hits(combatant)
      }
      // same convenience for mind points
      if (!combatant.dead && !combatant.stats.magic) {
        combatant.stats.magic = system.maximum_mps(combatant)
      }
      // same convenience for power points
      if (!combatant.dead && !combatant.stats.magic) {
        combatant.stats.magic = system.maximum_pps(combatant)
      }
      combatant.db_modifiers = {}
      combatant.ob_modifiers = {}
      combatant.ob_temp = 0
      combatant.db_temp = 0
    })
  }

  const attack = (attacker, defender) => (dispatch, getState) => {

    if (!attacker || !defender) {
      throw new Error('Attacker or defender missing from request')
    } else if (attacker.dead) {
      addLogEntry(`${nameOf(attacker)} is dead.`)
    } else if (attacker.stunned > 0) {
      addLogEntry(`${nameOf(attacker)} is stunned.`)
    } else if (attacker.must_parry > 0) {
      addLogEntry(`${nameOf(attacker)} elects to parry.`)
    } else if (attacker.hits < 0) {
      addLogEntry(`${nameOf(attacker)} has fallen.`)
    } else if (defender.dead) {
      addLogEntry(`${nameOf(defender)} is already dead.`)
    } else if (defender.hits < 0) {
      defender.dead = true
      addLogEntry(`${nameOf(attacker)} slays ${nameOf(defender)} out of hand`)
    } else {
      resolve_attack(attacker, defender, getState(), dispatch).then(_ => {
        dispatch(attackResolved(attacker, defender))
      })
      return
    }
    dispatch(attackResolved(attacker, defender))
  }

  const resolve_attack = async (attacker, defender, state, dispatch) => {
    const {weapons, lastRoll, system} = state.combatReducers
    const weapon_id = attacker.attack && attacker.attack.weapon_id
    const messages = []

    if (!weapon_id) {
      messages.push(`${nameOf(attacker)} is unarmed`)
      addLogEntry(messages)
      return
    }

    let total = lastRoll
    const weapon = weapons[weapon_id]
    messages.push(`${nameOf(attacker)} attacks ${nameOf(defender)} with ${weapon.label}!`)
    let message = `${nameOf(attacker)} rolled ${total} `

    // Check for fumble
    if (total <= weapon.fumble) {
      messages.push(message + "and fumbled their weapon -- roll again on the fumble chart")
      const input = {
        component: "RandomInput",
        callback: "fumble_callback",
        context: {weapon}
      }
      messages.push(input)
      addLogEntry(messages)
      return
    }

    // compute defensive bonus
    const shield_bonuses = system.shieldBonuses(defender)
    const aqp = -system.armor_quickness_penalty(defender.at)
    const bonuses = system.statBonuses(defender)

    // Adrenal Defense bonus
    const adrenal_defense_skill_id = 193
    const adrenal_defense_skill = defender.skills.find(ea => ea.id === adrenal_defense_skill_id)
    const adrenal_defense_bonus = adrenal_defense_skill ? system.skill_bonus(defender, adrenal_defense_skill) : 0

    let shield_bonus = 0
    let adrenal_bonus = 0

    switch (weapon.type) {
      case 'Melee':
        shield_bonus = shield_bonuses.melee
        adrenal_bonus = adrenal_defense_bonus
        break
      case 'Energy':
        shield_bonus = shield_bonuses.energy
        adrenal_bonus = adrenal_defense_bonus / 2
        break
      case 'Firearm':
        shield_bonus = shield_bonuses.firearm
        adrenal_bonus = adrenal_defense_bonus / 2
        break
      case 'Thrown':
        shield_bonus = shield_bonuses.thrown
        adrenal_bonus = adrenal_defense_bonus / 2
        break
      case 'Directed':
        shield_bonus = shield_bonuses.energy
        adrenal_bonus = adrenal_defense_bonus / 2
        break
    }
    // Monsters already have QU bonus included in their DB
    const qu_bonus = (defender.type !== 'Monster') ? bonuses.qu * 3 : 0
    const db = defender.db + qu_bonus + aqp + shield_bonus + adrenal_bonus

    const defense_bonuses = []
    if (adrenal_defense_bonus) defense_bonuses.push(`Adrenal ${adrenal_bonus}`)
    if (defender.db > 0) defense_bonuses.push(`DB ${defender.db}`)
    // Monsters already have QU bonus included in their DB
    if (bonuses.qu > 0 && defender.type !== 'Monster') defense_bonuses.push(`QU ${bonuses.qu * 3}`)
    if (aqp > 0) defense_bonuses.push(`AQP ${aqp}`)
    if (shield_bonus > 0) defense_bonuses.push(`shield ${shield_bonus}`)
    const db_msg = (defense_bonuses.length > 0) ? `(${defense_bonuses.join(', ')})` : ''
    messages.push(`${nameOf(defender)} has DB of ${db} ${db_msg} vs ${weapon.type}`)

    total += attacker.ob - db
    if (defender.parry > 0) {
      if (defender.no_parry) {
        messages.push(`${nameOf(defender)} is unable to parry`)
      } else {
        total -= defender.parry
        messages.push(`${nameOf(defender)} parries with: ${defender.db + defender.parry} `)
      }
    }

    if (defender.stunned > 0) {
      messages.push(`${nameOf(defender)} is stunned: +20 added to attack`)
      total += 20
    }

    messages.push(message + ` + OB ${attacker.ob} - DB ${db} = ${total} `)
    if (attacker.ob_temp > 0) {
      messages.push(`${nameOf(attacker)} receives temporary offensive bonus: ${attacker.ob_temp}, `)
    }
    addLogEntry(messages)

    try {
      await lookup_damage(attacker, defender, total, weapon, state, dispatch)
    } catch (error) {
      console.error('lookup_damage', error)
    }
  }

  const lookup_damage = async (attacker, defender, total, weapon, state) => {
    const {criticals, system} = state.combatReducers
    const messages = []
    const effective_roll = size_restriction_adjust(weapon, attacker.attack, total)

    if (effective_roll !== total) {
      messages.push(`Attack with ${weapon.label} is limited to ${effective_roll}`)
    }

    const armorType = system.armorTypes[defender.at]
    const {adjusted_at, adjusted_roll} = combat_system_adjust(armorType, effective_roll, attacker, weapon)
    if (adjusted_at !== defender.at) {
      messages.push(`Armor adjustment: AT ${defender.at} to ${adjusted_at}, roll to ${adjusted_roll}`)
    }

    const response = await axios.get(`/app/weapons/lookup?id=${weapon.id}&at=${adjusted_at}&roll=${adjusted_roll}`, authHeaders())
    let {damage, severity} = response.data
    let chart = weapon.critical_id

    // the critical result is either a single character indicating severity,
    // a 2-character code indicating severity and the chart code, or an empty string
    if (severity && severity.length === 2) {
      const special_code = severity[1]
      severity = severity[0]
      const critical = criticals.find(ea => ea.code === special_code && ea.type === 'critical')
      chart = critical.value
      messages.push(`Special attack damage is ${damage}${severity} ${critical.label} critical`)
    } else {
      messages.push(`Weapon damage is ${damage}${severity || ''}`)
    }

    if (damage === 0) {
      messages.push(`${nameOf(attacker)} misses target`)
      addLogEntry(messages)
      return
    }

    defender.hits -= damage

    // severity beyond A, B, C, D, E will be resolved as E with additional rolls
    const additional = additionalCrits(weapon, chart, severity)
    if (additional.length > 0) {
      severity = 'E'
    }
    const multiple_crits = [{chart, severity}].concat(additional)

    // check if attack has additional criticals (may be a comma-separated list)
    if (chart && severity && attacker.attack.additional_critical) {
      attacker.attack.additional_critical.split(',')
        .forEach(critical => {
          if (critical.includes(' ')) {
            const [new_severity, label] = critical.split(' ')
            const chart = criticals.find(ea => ea.label === label && ea.type === 'critical')
            multiple_crits.push({chart:chart.value, severity:new_severity || severity})
          }
          else {
            const chart = criticals.find(ea => ea.label === critical && ea.type === 'critical')
            multiple_crits.push({chart:chart.value, severity})
          }
        })
    }

    multiple_crits.forEach((critical) => {
      if (critical.severity) {
        // check if attack has alternative criticals
        if (attacker.attack.critical) {
          const charts = state.combatReducers.criticals
          const chart = charts.find(ea => ea.label === attacker.attack.critical && ea.type === 'critical')
          critical.chart = chart.value
        }
        const [adj_chart, adj_severity, roll_reduction] = vulnerability_adjust(critical.chart, critical.severity, weapon, attacker, defender)
        const adj_critical = criticals.find(ea => ea.value === adj_chart && ea.type === 'critical')

        if (!adj_severity) {
          messages.push(`${nameOf(attacker)} has inflicted a deft blow, but target is not vulnerable to the effect`)
        } else if (adj_severity === critical.severity) {
          messages.push(`${nameOf(attacker)} has inflicted a ${adj_severity} ${adj_critical.label} critical, and can roll again`)
        }

        if (adj_severity) {
          if (adj_severity !== critical.severity || roll_reduction) {
            const reduction_msg = roll_reduction ? `-${roll_reduction} modification` : `${adj_severity} severity`
            messages.push(`${nameOf(defender)} has reduced vulnerability ${defender.crit_codes} (${reduction_msg})`)
          }
          const input = {
            component: "RandomInput",
            callback: "critical_callback",
            context: {critical:adj_critical, severity:adj_severity, chart:adj_chart, roll_reduction, damage, weapon}
          }
          messages.push(input)
        } else {
          messages.push(`${nameOf(attacker)} hits target for ${damage} damage`)
        }
      }
    })
    addLogEntry(messages)
  }

  /*
   * Large critical charts have special severity codes
   * These charts are open-ended (high) with values ranging from 1 to 250+
   *
   *   18,Large Creature -- arms law 4.4 (Normal, Magic, Adamantine, Holy Arms, Slaying)
   *   22,Super Large Creature -- arms law 4.10 (Normal, Magic, Adamantine, Holy Arms, Slaying)
   *   33,Superlarge Energy -- blaster law 7.19 (Blaster, Laser, Plasma, Burn/Scorch, Burst/Raking)
   *   34,Superlarge Ballistic -- blaster law 7.18 (Puncture, Hollowpoint, Armor Piercing, Impact, Shrapnel)
   *   44,Large Energy -- blaster law 7.10 (Blaster, Laser, Plasma, Burn/Scorch, Burst/Raking)
   *   45,Large Ballistic -- blaster law 7.9 (Puncture, Hollowpoint, Armor Piercing, Impact, Shrapnel)
   *   46,Large Android -- robotic 6.12 (Blast, Burst/Raking, Piercing, Puncture, Melee)
   *   47,Superlarge Android -- robotic 6.15 (Blast, Burst/Raking, Piercing, Puncture, Melee)
   */

  /*
   * Critical Codes Chart:
   *
   * Critical Code    Code Effect
   * -------------    -----------
   *  — (or null)     Use normal critical procedure
   *       I          Decrease critical severity by one
   *                  (‘A’ is modified by -20, ‘B’ becomes an ‘A’, ‘C’ becomes a ‘B’, etc.)
   *       II         Decrease critical severity by two
   *                  (‘A’ is modified by -50, ‘B’ is modified by -20 on the ‘A’ column,
   *                   ‘C’ becomes an ‘A’, etc.)
   *       LA         Use Large Creature Critical Strike Table
   *                  Only critical strikes of severity ‘B’, ‘C’, ‘D’, or ‘E’ affect large creatures
   *                  (i.e., ‘A’ severity criticals are ignored)
   *       SL         Use Super Large Creature Critical Strike Table
   *                  Only critical strikes of severity ‘D’ or ‘E’ affect super-large creatures
   *                  (i.e., ‘A’, ‘B’, and ‘C’ severity criticals are ignored)
   *       ALA        Use Large Android Critical Strike Table
   *       ASL        Use Super Large Android Critical Strike Table
   *       @          Stun results do not affect creature
   *       #          Stun results and bleeder do not affect creature
   */

  function vulnerability_adjust(chart, severity, weapon, attacker, defender) {
    const crit_codes = defender.crit_codes || ''
    const match = crit_codes.match(/((LA)|(SL)|(ALA)|(ASL)|(II)|(I)).*/)
    const attack = attacker.attack
    let [adj_chart, adj_severity, roll_reduction] = [chart, severity, 0]

    if (match) {
      switch (match[1]) {
        case 'I':
          [adj_severity, roll_reduction] = reduce_severity(severity, 1)
          break
        case 'II':
          [adj_severity, roll_reduction] = reduce_severity(severity, 2)
          break
        case 'LA':
          if (['A'].includes(severity)) {
            adj_severity = null
            break
          }
          if (weapon.type === 'Energy') {
            // 44,Large Energy -- blaster law 7.10 (Blaster, Laser, Plasma, Burn/Scorch, Burst/Raking)
            adj_severity = blaster_law_large_severity(weapon, attack)
            adj_chart = 44
          } else if (weapon.type === 'Ballistic') {
            // burst or continuous mode cause shrapnel criticals, otherwise criticals depend on ammunition type
            // 45,Large Ballistic -- blaster law 7.9 (Puncture, Hollowpoint, Armor Piercing, Impact, Shrapnel)
            if (weapon.critical_type === 'Shrapnel') {
              adj_severity = 'Shrapnel'
            } else {
              adj_severity = attack.mode
            }
            adj_chart = 45
          } else if (weapon.critical_type === 'Raking') {
            adj_severity = 'Burst/Raking'
            adj_chart = 44
          } else {
            // 18,Large Creature -- arms law 4.4 (Normal, Magic, Adamantine, Holy Arms, Slaying)
            adj_severity = attack.mode || 'Normal'
            adj_chart = 18
          }
          break
        case 'SL':
          if (['A', 'B', 'C'].includes(severity)) {
            adj_severity = null
            break
          }
          if (weapon.type === 'Energy') {
            // 33,Superlarge Energy -- blaster law 7.19 (Blaster, Laser, Plasma, Burn/Scorch, Burst/Raking)
            adj_severity = blaster_law_large_severity(weapon, attack)
            adj_chart = 33
          } else if (weapon.type === 'Ballistic') {
            // burst or continuous mode cause shrapnel criticals, otherwise criticals depend on ammunition type
            // 34,Superlarge Ballistic -- blaster law 7.18 (Puncture, Hollowpoint, Armor Piercing, Impact, Shrapnel)
            if (weapon.critical_type === 'Shrapnel') {
              adj_severity = 'Shrapnel'
            } else {
              adj_severity = attack.mode
            }
            adj_chart = 34
          } else if (weapon.critical_type === 'Raking') {
            adj_severity = 'Burst/Raking'
            adj_chart = 33
          } else {
            // 22,Super Large Creature -- arms law 4.10 (Normal, Magic, Adamantine, Holy Arms, Slaying)
            adj_severity = attack.mode || 'Normal'
            adj_chart = 22
          }
          break
        case 'ALA':
          if (['A'].includes(severity)) {
            adj_severity = null
            break
          }
          adj_severity = blaster_law_large_android_severity(weapon, attack)
          // 46,Large Android -- robotic 6.12 (Blast, Burst/Raking, Piercing, Puncture, Melee)
          adj_chart = 46
          break
        case 'ASL':
          if (['A', 'B', 'C'].includes(severity)) {
            adj_severity = null
            break
          }
          adj_severity = blaster_law_large_android_severity(weapon, attack)
          // 47,Super large Android -- robotic 6.15 (Blast, Burst/Raking, Piercing, Puncture, Melee)
          adj_chart = 47
          break
        default:
          break
      }
    }
    return [adj_chart, adj_severity, roll_reduction]
  }

  const blaster_law_large_android_severity = (weapon, attack) => {
    if (['Blaster', 'Laser', 'Plasma'].includes(weapon.critical_type)) {
      if ((attack.mode === 'burst') || (attack.mode === 'continuous')) {
        return 'Burst/Raking'
      } else {
        return 'Blast'
      }
    } else if (weapon.type === 'Ballistic') {
      if (weapon.critical_type === 'Shrapnel') {
        return 'Shrapnel'
      } else if (weapon.critical_type === 'Ballistic Puncture') {
        return 'Puncture'
      } else if (attack.mode === 'Ballistic Piercing') {
        return 'Piercing'
      } else if (attack.mode === 'Hollowpoint') {
        return 'Burst/Raking'
      }
    } else if (weapon.critical_type === 'Raking') {
      return 'Burst/Raking'
    } else if (weapon.type === 'Melee') {
      return 'Melee'
    }
    return null
  }

  const blaster_law_large_severity = (weapon, attack) => {
    if (['Blaster', 'Laser', 'Plasma'].includes(weapon.critical_type)) {
      if ((attack.mode === 'burst') || (attack.mode === 'continuous')) {
        return 'Burst/Raking'
      } else {
        return weapon.critical_type
      }
    }
    return null
  }

  const reduce_severity = (severity, level) => {
    const severities = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
    let roll_reduction = 0
    let reduced_severity = severity
    const index = severities.indexOf(severity)
    if (index < 0) {
      throw Error(`Unexpected critical severity ${severity}`)
    }
    // (‘A’ is modified by -20, ‘B’ becomes an ‘A’, ‘C’ becomes a ‘B’, etc.)
    console.log('reduce_severity', severity, level)
    if (level === 1) {
      if (index === 0) {
        roll_reduction = 20
      } else {
        reduced_severity = severities[index - 1]
      }
    }
    // (‘A’ is modified by -50, ‘B’ is modified by -20 on the ‘A’ column, ‘C’ becomes an ‘A’, etc.)
    else if (level === 2) {
      if (index === 0) {
        roll_reduction = 50
      } else if (index === 1) {
        roll_reduction = 20
      } else {
        reduced_severity = severities[index - 2]
      }
    }
    return [reduced_severity, roll_reduction]
  }

  const resolve_fumble = async (attacker, weapon, roll) => {
    let messages = []
    try {
      const {type, fumble_type} = weapon
      if (!fumble_type) {
        messages.push(`${nameOf(attacker)} weapon has no fumble data: ${weapon.label}`)
      }
      else {
        const response = await axios.get(`/app/weapons/fumble?chart=${type}&type=${fumble_type}&roll=${roll}`, authHeaders())
        const {description} = response.data
        messages.push(`${nameOf(attacker)} rolled ${roll}: ${description}`)
      }
    } catch (err) {
      messages.push(`An error occurred ${err}`)
    }
    addLogEntry(messages)
  }

  const resolve_critical = async (attacker, defender, state, severity, chart, weapon, roll) => {
    const extra_hits_pattern = /(\d+)H/
    const must_parry_pattern = /(\d*)π/
    const attack_bonus_pattern = /\((\+\d+)\)/
    const defend_penalty_pattern = /\((-\d+)\)/
    const stun_parry_pattern = /(\d*)∑∏/
    const stun_pattern = /(\d*)∑/
    const no_parry_pattern = /(\d*)∏/
    const bleeding_pattern = /(\d*)∫/
    const dead_later_pattern = /^(.*?),?(([Dd]ies )|(dead))(( in )|(after))(\d+)(.*?)"?$/
    const dead_now_pattern = /^(.*?),?(( [Dd]ies )|(dead[ .])|(killed))(.*?)"?$/
    const paralyzed_pattern = /^(.*?),?(paralyzed)(.*?)"?$/

    let extra_hits = 0, match, messages = [], critical, damage, location
    const {system} = state.combatReducers
    const {attack} = attacker

    try {
      const response = await axios.get(`/app/weapons/critical?severity=${severity}&chart=${chart}&roll=${roll}`, authHeaders())
      critical = response.data.critical || ''
      damage = response.data.damage || ''
      location = response.data.location

      /** Ballistic Armor adjustments **/
      const armor = system.armorTypes[defender.at]

      // "slug throwers" cause ballistic puncture criticals, shotguns cause shrapnel criticals (29)
      if (weapon.type === 'Firearm') {
        // if the target is wearing modern armor (AT I-IV) check to see if the critical affects
        // a body location covered by the armor. If it does, use ballistic impact instead of ballistic puncture
        if (['I', 'II', 'III', 'IV'].includes(defender.at)) {
          // if the attacker is using armor-piercing rounds, then apply armor piercing criticals
          if (attack.mode === 'Armor Piercing') {
            messages.push(`${nameOf(attacker)}'s armor piercing rounds puncture ${nameOf(defender)}'s kinetic armor!`)
            const response = await axios.get(`/app/weapons/critical?severity=${severity}&chart=${27}&roll=${roll}`, authHeaders())
            critical = response.data.critical || ''
            damage = response.data.damage || ''
          }
          else {
            if (armor.protects.includes(location)) {
              messages.push(`${nameOf(defender)}'s modern armor reduces the ballistic puncture to an impact critical`)
              const response = await axios.get(`/app/weapons/critical?severity=${severity}&chart=${12}&roll=${roll}`, authHeaders())
              critical = response.data.critical || ''
              damage = response.data.damage || ''
            }
          }
        }
        // if the target is wearing kinetic armor (AT V-VII), ignore all firearm criticals that hit
        // an armored location, armor piercing or not
        else if (['V', 'VI', 'VII'].includes(defender.at)) {
          if (armor.protects.includes(location)) {
            messages.push(`The energy of the impact dissipates against ${nameOf(defender)}'s kinetic armor `)
            addLogEntry(messages)
            return 0
          }
        }
        // Combat armor is affected by armor piercing rounds (resolve normally), but all other criticals are
        // ignored, unless a shrapnel critical is caused by armor piercing rounds, in which case the shrapnel
        // critical can be applied normally
        else if (['VIII', 'IX', 'X'].includes(defender.at)) {
          if (attack.mode !== 'Armor Piercing') {
            messages.push(`The energy of the impact dissipates against ${nameOf(defender)}'s combat armor `)
            addLogEntry(messages)
            return 0
          }
        }
        // if the attacker is using hollow points, ballistic hollow points are used on unarmored locations
        if ((attack.mode === 'Hollowpoint') && (!armor.protects.includes(location))) {
          messages.push(`${nameOf(attacker)}'s hollow-point rounds hit ${nameOf(defender)} in an unarmored location!`)
          const response = await axios.get(`/app/weapons/critical?severity=${severity}&chart=${28}&roll=${roll}`, authHeaders())
          critical = response.data.critical || ''
          damage = response.data.damage || ''
        }
      }

      messages.push(`${nameOf(attacker)} rolled ${roll}: ${critical}`)
    } catch (err) {
      messages.push(`An error occurred ${err}`)
    }

    match = damage.match(extra_hits_pattern)
    if (match) {
      extra_hits = +match[1]
      if (extra_hits > 0) {
        defender.hits -= extra_hits
        messages.push(`${nameOf(defender)} receives an additional ${extra_hits} hit points of damage`)
      }
    }
    match = damage.match(must_parry_pattern)
    if (match) {
      let rounds = +match[1] || 1
      defender.must_parry += rounds
      messages.push(`${nameOf(defender)} must parry for ${defender.must_parry} rounds `)
    }
    match = damage.match(stun_parry_pattern)
    if (match) {
      let rounds = +match[1] || 1
      defender.no_parry += rounds
      defender.parry = 0  // remove any parry bonus
      if (defender.isStunProof()) {
        messages.push(`${nameOf(defender)} is unfazed, but unable to parry for ${defender.no_parry} rounds`)
      } else {
        defender.stunned += rounds
        messages.push(`${nameOf(defender)} is stunned for ${defender.stunned} and unable to parry for ${defender.no_parry} rounds`)
      }
    } else {
      // don't try to match no_parry or stun unless stun_no_parry already failed (don't count twice)
      match = damage.match(no_parry_pattern)
      if (match) {
        let rounds = +match[1] || 1
        defender.no_parry += rounds
        messages.push(`${nameOf(defender)} cannot parry for ${defender.no_parry} rounds `)
      }
      match = damage.match(stun_pattern)
      if (match) {
        if (defender.isStunProof()) {
          messages.push(`${nameOf(defender)} is unfazed by your onslaught`)
        } else {
          let rounds = +match[1] || 1
          defender.stunned += rounds
          messages.push(`${nameOf(defender)} is stunned for ${defender.stunned} rounds`)
        }
      }
    }
    match = damage.match(bleeding_pattern)
    if (match) {
      if (defender.isBleedProof()) {
        messages.push(`${nameOf(defender)} does not bleed`)
      } else {
        let rounds = +match[1] || 1
        defender.bleeding += rounds
        messages.push(`${nameOf(defender)} is bleeding at ${defender.bleeding} hits per round`)
      }
    }
    match = damage.match(attack_bonus_pattern)
    if (match) {
      attacker.ob_temp = +match[1]
      messages.push(`${nameOf(attacker)} receives a bonus of (${attacker.ob_temp}) `)
    }
    match = damage.match(defend_penalty_pattern)
    if (match) {
      let defend_penalty = +match[1]
      let temp_penalty = defender.ob_temp || 0
      defender.ob_temp = temp_penalty + defend_penalty
      messages.push(`${nameOf(defender)} has a penalty of (${defender.ob_temp}) `)
    }
    match = critical.match(paralyzed_pattern)
    if (match) {
      messages.push(`${nameOf(defender)} is paralyzed`)
      defender.paralyzed = true
    }
    match = critical.match(dead_later_pattern)
    if (match) {
      messages.push(`${nameOf(defender)} will die in ${+match[8]} rounds`)
    } else {
      match = critical.match(dead_now_pattern)
      if (match) {
        messages.push(`${nameOf(defender)} is dead`)
        defender.dead = true
      }
    }
    addLogEntry(messages)
    return extra_hits
  }

  return {
    addLogEntry,
    removeLogEntry,
    deleteCombatLog,
    beginCombat,
    endCombat,
    nextRound,
    nextAttacker,
    attack,
    resolve_critical,
    resolve_fumble,
    attackResolved
  }
}

export const waitingForInput = (value) => dispatch => {
  dispatch({type: 'WAITING_FOR_INPUT', payload: value})
}

export const setLastRoll = (value) => dispatch => {
  dispatch({type: 'LAST_ROLL', payload: value})
}

function size_restriction_adjust(weapon, attack, total) {
  // special AL attacks will have a size restriction that will limit the total lookup value
  const limits = (weapon.attack_limit && Weapon.attack_limits[weapon.attack_limit]) || []
  const limit = limits.find(ea => ea.label === attack.size)
  return Math.min(total, limit ? limit.value : 150)
}

function combat_system_adjust(armorType, total, role, weapon) {
  // Convert Arms Law weapons to SM AT and add weapon adjustment
  // if at is in [I...X] and weapon is RM then use RM-SM roll adjustment and downgrade slash/puncture to krush
  // if at is in [2,6,7,9,10,13-20] and weapon is SM then use SM-RM adjustment
  const system = weapon.system
  const sm_ats = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X']
  const rmss_ats = ['2', '6', '7', '9', '10', '13', '14', '15', '16', '17', '18', '19', '20']
  const need_adjust = (system.startsWith('SM') && rmss_ats.includes('' + role.target.at)) ||
    (system.startsWith('RM') && sm_ats.includes(role.target.at))
  const adjusted_at = (need_adjust) ? armorType.alt_at : role.target.at
  const adjusted_roll = (need_adjust) ? total - armorType.alt_adj : total

  return {adjusted_at, adjusted_roll}
}

function additionalCrits(weapon, chart, severity) {
  const krush_chart = 1
  const grapple_chart = 2
  const puncture_chart = 5
  const slash_chart = 6
  const unbalance_chart = 10
  const blaster_chart = 13
  const cold_chart = 15
  // const electricity_chart = 16
  const heat_chart = 17
  const laser_chart = 19
  const plasma_chart = 20
  // const shrapnel_chart = 26
  const impact_chart = 32

  const pincher_attack = 31 // FS => ES + CK
  const bite_attack = 32  // FP => EP + CS
  const claw_attack = 34  // FP => EP + CS
  const fall_attack = 35  // FK => EK + CK
  const grapple_attack = 36 // FG => EG + CG
  const horn_attack = 37  // FU => EP + CU
  const ram_attack = 40   // FU => EU + CK
  const trample_attack = 43 // FK => EK + CK
  const grenade_attack = 55 // F => E Shrapnel + C Heat (not specified in rules)
  // const nuclear_attack = 59 // A-E => A-E Heat + A-E Rad, F-J => E Heat + E Rad + A-E Plasma
  const icebolt_attack = 73 // F => EI + A Cold, G => EI + C Cold
  const lightning_attack = 74 // F => EE + AI, G => EE + BI, H => EE + CI + AH, I = EE + DI + BH

  if (weapon.id === grenade_attack) {
    switch (severity) {
      case 'F':
        return [{severity: 'C', chart: heat_chart}]
      default:
        return []
    }
  }

  if (weapon.id === lightning_attack) {
    switch (severity) {
      case 'F':
        return [{severity: 'A', chart: impact_chart}]
      case 'G':
        return [{severity: 'B', chart: impact_chart}]
      case 'H':
        return [{severity: 'C', chart: impact_chart}, {severity: 'A', chart: heat_chart}]
      case 'I':
        return [{severity: 'D', chart: impact_chart}, {severity: 'B', chart: heat_chart}]
      default:
        return []
    }
  }

  if (weapon.id === icebolt_attack) {
    switch (severity) {
      case 'F':
        return [{severity: 'A', chart: cold_chart}]
      case 'G':
        return [{severity: 'C', chart: cold_chart}]
      default:
        return []
    }
  }

  if (weapon.category === "Special Attacks" && severity === 'F') {
    switch (weapon.id) {
      case pincher_attack:
        return [{severity: 'C', chart: krush_chart}]
      case bite_attack:
        return [{severity: 'C', chart: slash_chart}]
      case claw_attack:
        return [{severity: 'C', chart: slash_chart}]
      case fall_attack:
        return [{severity: 'C', chart: krush_chart}]
      case grapple_attack:
        return [{severity: 'C', chart: grapple_chart}]
      case horn_attack:
        return [{severity: 'C', chart: unbalance_chart}]
      case ram_attack:
        return [{severity: 'C', chart: krush_chart}]
      case trample_attack:
        return [{severity: 'C', chart: krush_chart}]
      default:
        return []
    }
  }

  if (![blaster_chart, laser_chart, plasma_chart].includes(chart)) {
    return []
  }
  const tertiary = ((chart === laser_chart) || (chart === blaster_chart)) ? puncture_chart : heat_chart
  const secondary = heat_chart

  switch (severity) {
    case 'F' :
      return [{severity: 'A', chart: secondary}]
    case 'G' :
      return [{severity: 'B', chart: secondary}]
    case 'H' :
      return [{severity: 'C', chart: secondary}]
    case 'I' :
      return [{severity: 'D', chart: secondary}]
    case 'J' :
      return [{severity: 'E', chart: secondary}]
    case 'K' :
      return [{severity: 'E', chart: secondary}, {severity: 'A', chart: tertiary}]
    case 'L' :
      return [{severity: 'E', chart: secondary}, {severity: 'B', chart: tertiary}]
    case 'M' :
      return [{severity: 'E', chart: secondary}, {severity: 'C', chart: tertiary}]
    default:
      return []
  }
}

export async function nDx(n, x) {
  return axios.get(`/app/sessions/roll?formula=${n}d${x}`, authHeaders())
}

export const fetchSystem = (system) => dispatch => {
  axios.get(`/app/system/${system}`, authHeaders())
    .then(response => dispatch({
      type: 'READ_SYSTEM',
      payload: response.data
    }))
  axios.get(`/app/weapons/`, authHeaders())
    .then(response => dispatch({
      type: 'READ_WEAPONS',
      payload: response.data.reduce((acc, ea) => {
        acc[ea.id] = new Weapon(ea)
        return acc
      }, {})
    }))
  axios.get('/app/weapons/criticals', authHeaders())
    .then(response => dispatch({
      type: 'READ_CRITICALS',
      payload: response.data
    }))
}

export const fetchRoles = (game_id) => dispatch => {
  axios.get(`/app/adventures/${game_id}/roles`, authHeaders())
    .then(response => {
      dispatch({
        type: 'READ_ROLES',
        payload: response.data.map(member => new Combatant(member))
      })
    })
}

export const fetchScenes = (game_id) => dispatch => {
  axios.get(`/app/adventures/${game_id}/scenes`, authHeaders())
    .then(response => {
      const scenes = response.data.map(data => new Scene(data))
      dispatch({type: 'FETCH_SCENES', payload: scenes})
    })
}

export const fetchScene = (game_id, scene_id) => dispatch => {
  axios.get(`/app/adventures/${game_id}/scenes/${scene_id}`, authHeaders())
    .then(response => {
      const scene = new Scene(response.data)
      dispatch({type: 'FETCH_SCENE', payload: scene})
    })
}

export const fetchRole = (id) => dispatch => {
  axios.get(`/app/roles/${id}`, authHeaders())
    .then(response => {
      const combatant = new Combatant(response.data)
      dispatch(saveCombatant(combatant))
    })
}

export const fetchEncounters = (game_id) => dispatch => {
  axios.get(`/app/adventures/${game_id}/encounters`, authHeaders())
    .then(response => dispatch({
      type: 'READ_ENCOUNTERS',
      payload: response.data.map(enc => new Encounter(enc))
    }))
}

export const createAdventure = (options) => dispatch => {
  const adventure = new Adventure({...options, system:'CoC7e'})
  const formData = new FormData()
  formData.append('adventure', JSON.stringify(adventure))
  axios.post('/app/adventures', formData, authHeaders())
    .then(_ => {
      dispatch(fetchAdventures())
    })
}

export const updateAdventure = (adventure) => dispatch => {
  const formData = new FormData()
  formData.append('adventure', JSON.stringify(adventure))
  axios.put('/app/adventures', formData, authHeaders())
    .then(_ => {
      dispatch({type: 'UPDATE_ADVENTURE', payload: adventure})
    })
    .catch(err => console.error(err))
}

export const deleteAdventure = (adventure) => dispatch => {
  axios.delete(`/app/adventures/${adventure.id}`, authHeaders())
    .then(_ => {
      dispatch({type: 'DELETE_ADVENTURE', payload: adventure.id})
    })
    .catch(err => console.error(err))
}

export const fetchAdventures = () => dispatch => {
  axios.get('/app/adventures', authHeaders())
    .then(response => dispatch({
      type: 'FETCH_ADVENTURES',
      payload: response.data.map(res => new Adventure(res))
    }))
}

export const fetchAdventure = (id) => dispatch => {
  axios.get(`/app/adventures/${id}`, authHeaders())
    .then(response => dispatch({
      type: 'UPDATE_ADVENTURE',
      payload: new Adventure(response.data)
    }))
}

export const updateEncounter = (encounter) => dispatch => {
  const formData = new FormData()
  const id = encounter.id
  const game_id = encounter.game_id
  const method = id ? axios.put : axios.post
  formData.append('encounter', JSON.stringify(encounter))
  method('/app/encounters/', formData, authHeaders())
    .then(() => dispatch(fetchEncounters(game_id)))
    .catch(console.error)
}

export const deleteEncounter = (encounter) => dispatch => {
  const id = encounter.id
  const game_id = encounter.game_id
  axios.delete(`/app/encounters/${id}`, authHeaders())
    .then(() => dispatch(fetchEncounters(game_id)))
    .catch(console.error)
}

export const fetchTrainingPackages = () => dispatch => {
  axios.get('/app/skills/tp', authHeaders())
    .then(response => {
      dispatch({
        type: 'READ_TRAINING_PACKAGES',
        payload: response.data
      })
    })
}

export const deleteCombatant = (id) => dispatch => {
  dispatch({
    type: 'DELETE_COMBATANT',
    payload: {id}
  })
}

export const saveCombatant = (combatant) => dispatch => {
  dispatch({
    type: 'SAVE_COMBATANT',
    payload: {combatant}
  })
}

export const rollInitiative = (combatants) => dispatch => {
  combatants.forEach(role => {
    nDx(2, 10).then(result => {
      const roll = +result.data.total
      dispatch(updateInitiative(role, roll))
    })
  })
}

export const updateInitiative = (role, roll) => dispatch => {
  dispatch({type: 'UPDATE_INITIATIVE', payload: {role, roll}})
}

export const updatePointers = (data) => dispatch => {
  dispatch({type: 'UPDATE_POINTERS', payload: data})
}

export const deleteShape = (shape_id) => dispatch => {
  axios.delete(`/app/shapes/${shape_id}`, authHeaders())
    .then(_ => {
      dispatch({type: 'DELETE_SHAPE', payload: shape_id})
    })
}

export const deleteScene = (scene_id) => dispatch => {
  // use own id for container id when deleting scenes (for access control)
  axios.delete(`/app/scenes/${scene_id}`, authHeaders())
    .then(_ => {
      dispatch({type: 'DELETE_SCENE', payload: scene_id})
    })
}

export const createScene = (item, root) => dispatch => {
  const game_id = root.id
  const formData = new FormData();
  formData.append('item', JSON.stringify({...item, game_id}))
  axios.post('/app/scenes/', formData, authHeaders())
    .then(res => {
      item.id = res.data.id
      console.log('createScene', item)
      dispatch({type: 'CREATE_SCENE', payload: new Scene(item)})
    })
}

export const createShape = (item, dest) => dispatch => {
  const scene_id = dest ? dest.id : null
  const formData = new FormData();
  formData.append('items', JSON.stringify([{...item, scene_id}]))
  axios.post('/app/shapes/', formData, authHeaders())
    .then(res => {
      item.id = res.data.ids[0]
      dispatch({type: 'CREATE_SHAPE', payload: new item.constructor({...item, scene_id})})
    })
}

/**
 * When cloning shapes we don't know their ids until the POST callback is invoked.
 * Objects can meanwhile be modified by the user, but the ones we update will not have
 * any of the changes, so the POST callback needs to find the new versions of those
 * shapes, set their ids, and update those.
 */
export const cloneShapes = (shapes) => dispatch => {
  const clones = shapes.map(item => {
    const options = {...item, id:'clone_'+item.id}
    return new Shape(options)
  })
  const formData = new FormData();
  formData.append('items', JSON.stringify(clones))
  axios.post('/app/shapes/', formData, authHeaders())
    .then(res => {
      dispatch({type: 'UPDATE_CLONES', payload: [clones, res.data.ids]})
    })
  dispatch({type: 'CREATE_SHAPES', payload: clones})
}

export const saveShape = (item) => dispatch => {
  const formData = new FormData();
  formData.append('item', JSON.stringify(item))
  axios.put(`/app/shapes/`, formData, authHeaders())
    .then(() => {
      dispatch({type: 'UPDATE_SHAPE', payload: new item.constructor(item)})
    })
}

export const saveScene = (item) => dispatch => {
  const formData = new FormData();
  formData.append('item', JSON.stringify(item))
  axios.put(`/app/scenes/`, formData, authHeaders())
    .then(() => {
      dispatch({type: 'UPDATE_SCENE', payload: new Scene(item)})
    })
}

export const updateScene = (scene, rest) => dispatch => {
  dispatch({type: 'UPDATE_SCENE', payload: new Scene({...scene, ...rest})})
}

export const updateShape = (shape, rest) => dispatch => {
  const payload = new shape.constructor({...shape, ...rest})
  dispatch({type: 'UPDATE_SHAPE', payload: payload})
}

// multiple selection has changed
export const updateShapes = (shapes) => dispatch => {
  dispatch({type: 'UPDATE_SHAPES', payload: shapes})
}

export const fetchShapes = (scene_id) => dispatch => {
  axios.get(`/app/scenes/${scene_id}/shapes`, authHeaders())
    .then(response => {
      const shapes = response.data.map(data => new Shape(data))
      dispatch({type: 'FETCH_SHAPES', payload: shapes})
    })
}

export const fetchPlayers = (game_id) => dispatch => {
  axios.get(`/app/adventures/${game_id}/players`, authHeaders())
    .then(response => {
      const players = response.data.map(data => new Player(data))
      dispatch({type: 'FETCH_PLAYERS', payload: players})
    })
}

export const savePlayer = (player) => dispatch => {
  const {game_id} = player
  const formData = new FormData();
  formData.append('player', JSON.stringify(player))
  const action = player.id ? axios.put : axios.post
  action(`/app/adventures/${player.game_id}/players/${player.id}`, formData, authHeaders())
    .then((_) => {
      dispatch(fetchPlayers(game_id))
    })
}
export const cancelPlayerInvite = (player) => dispatch => {
  const {game_id} = player
  axios.delete(`/app/adventures/${player.game_id}/players/${player.id}`, authHeaders())
    .then((_) => {
      dispatch(fetchPlayers(game_id))
    })
}

export const updatePlayer = (player, rest) => dispatch => {
  dispatch({type: 'UPDATE_PLAYER', payload: {player, ...rest}})
}

export const addSelection = (object) => dispatch => {
  dispatch({type: 'ADD_SELECTION', payload: object})
}

export const removeSelection = (object) => dispatch => {
  dispatch({type: 'REMOVE_SELECTION', payload: object})
}

export const setSelection = (object) => dispatch => {
  dispatch({type: 'SELECT_ITEM', payload: object})
}

/* --- Game Canvas management --- */

export const disableNavbar = () => dispatch => {
  dispatch({type: 'NAVBAR_ENABLED', payload: false})
}

export const enableNavbar = () => dispatch => {
  dispatch({type: 'NAVBAR_ENABLED', payload: true})
}
