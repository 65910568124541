import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import SkillAssignment from '../models/SkillAssignment'
import {Combatant} from '../models/Combatant'
import {saveCombatant} from '../actions/combatActions'

export default function EditSkill(props) {
  const {done, role, skill} = props
  const dispatch = useDispatch()

  const [specialty, setSpecialty] = useState(skill.specialty)
  const [common_use, setCommonUse] = useState(skill.common_use)
  const [special_bonus1, setSpecialBonus1] = useState(skill.special_bonus1)
  const [special_bonus2, setSpecialBonus2] = useState(skill.special_bonus2)

  const handleSubmit = (e) => {
    e.preventDefault()
    const index = role.skills.indexOf(skill)
    const new_role = new Combatant(role)
    new_role.skills[index] = new SkillAssignment({...skill, specialty, special_bonus1, special_bonus2, common_use})
    dispatch(saveCombatant(new_role))
    done()
  }

  function toggleFavorite(event) {
    setCommonUse(event.target.checked)
  }

  return (
      <div className='edit-skill'>
        <Form onSubmit={handleSubmit} onReset={() => done()}>
          <Row className='mb-3'>
            <Form.Group as={Col}>
              <Form.Label>Specialty:</Form.Label>
              <Form.Control value={specialty}
                            onChange={(e) => setSpecialty(e.target.value)}/>
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col}>
              <Form.Label>Special 1:</Form.Label>
              <Form.Control value={special_bonus1 || 0} type='number'
                            onChange={(e) => setSpecialBonus1(e.target.value)}/>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Special 2:</Form.Label>
              <Form.Control value={special_bonus2 || 0} type='number'
                            onChange={(e) => setSpecialBonus2(e.target.value)}/>
            </Form.Group>
          </Row>
          <Row>&nbsp;</Row>
          <Modal.Footer>
            <Form.Check label='Commonly used' checked={common_use} style={{marginRight:'auto'}} onChange={toggleFavorite}/>
            <Button variant='secondary' type='reset'>Cancel</Button>
            <Button variant='primary' type='submit'>Update</Button>
          </Modal.Footer>
        </Form>
      </div>
  )
}
