const cat_id_body_dev = 12
const cat_id_mind_dev = 24

export default class SkillAssignment {
  constructor(props) {
    const {id, label, specialty, ranks, levels, common_use, category, special_bonus1, special_bonus2} = props
    this.id = id
    this.label = label
    this.category = category
    this.specialty = specialty
    this.ranks = ranks || []
    this.levels = levels || []
    this.common_use = common_use
    this.special_bonus1 = special_bonus1
    this.special_bonus2 = special_bonus2
  }

  get cat_id() {
    return this.category.id
  }

  get isAttack() {
    return [22, 23, 42, 53, 54, 55, 56, 57, 58, 59, 60, 27, 28, 29, 30, 31, 32, 33, 34, 35]
      .includes(this.category.id)
  }

  getShortLabel() {
    const text = this.specialty || this.getLabel()
    return [22, 23].includes(this.category.id) ? this.getLabel() : text
  }

  getLabel() {
    if (this.label === this.category.label && (
        (this.category.id !== cat_id_mind_dev) && (this.category.id !== cat_id_body_dev))) {
      return this.specialty || this.category.label
    }
    if ([53, 54, 55, 56, 57, 58, 59, 60].includes(this.category.id)) {
      return this.specialty || this.category.label
    }
    let text = this.label
    if (this.specialty) {
      text = text + ` (${this.specialty})`
    }
    return text
  }

  num_ranks(role, level = null) {
    if (level !== null) {
      const index = this.levels.indexOf(level)
      return (index < 0) ? 0 : this.ranks[index]
    }
    return this.ranks.reduce((acc, val) => acc + val, 0)
  }

  setNumRanks(value, role, level) {
    const ranks = this.ranks
    const levels = this.levels
    if (levels.includes(level)) {
      const index = levels.indexOf(level)
      ranks[index] = value
    }
    else {
      ranks.push(value)
      levels.push(level)
    }
    this.ranks = ranks
    this.levels = levels
  }

  rank_bonus_description(role) {
    const progressions = this.skill_progressions(this.category.rank_bonus_type, role.race)
    return <div>
      <b>Skill Rank Bonuses</b><br/><br/>
      {this.category.rank_bonus_type} progression:<br/>
      No ranks: {progressions[0]}<br/>
      Ranks 1-10: +{progressions[1]} per rank<br/>
      Ranks 11-20: +{progressions[2]} per rank<br/>
      Ranks 21-30: +{progressions[3]} per rank<br/>
      Above Rank 30: +{progressions[4]} per rank
    </div>
  }

  max_ranks() {
    return this.category.max_ranks()
  }

  rank_bonus(role, level) {
    const progressions = this.skill_progressions(this.category.rank_bonus_type, role.race)
    const ranks = this.num_ranks(role, level)
    let sum = ranks === 0 ? progressions[0] : 0, progression = 0
    for (let rank = 1; rank <= ranks; rank++) {
      if (rank <= 10) {
        progression = progressions[1]
      }
      else if (rank <= 20) {
        progression = progressions[2]
      }
      else if (rank <= 30) {
        progression = progressions[3]
      }
      else {
        progression = progressions[4]
      }
      sum += progression
    }
    return sum
  }

  item_bonus(role) {
    const matching = role.items.find(item => item.skill_id === this.id)
    if (matching) {
      return matching.bonus
    }
    return null
  }

  rank_cost(role, level = null) {
    const cat = this.category
    const costs = [cat.cost_1, cat.cost_2, cat.cost_3]
    const max_ranks = this.max_ranks(role)
    let sum = 0
    if (level === null) {
      for (let l = -1; l <= role.level; l++) {
        sum += this.rank_cost(role, l)
      }
      return sum
    }
    const ranks = this.num_ranks(role, level)
    for (let i = 0; i < Math.min(max_ranks, ranks); i++) {
      sum += +costs[i]
    }
    return sum
  }

  useBonus(system, role, level = null) {
    const cat_bonus = () => this.category.useBonus(system, role, level).cat_bonus()
    const rank_bonus = (level) => this.rank_bonus(role, level)

    // A special item can provide a bonus to a specific skill (or category -- see Category.js)
    // The skill could be a generic category (e.g. 1H Melee), a specific skill (TODO or even multiple skills)
    // (e.g. a weapon, Stalking and Hiding, Picking Locks)
    const item_bonus = () => this.item_bonus(role)

    const getLabel = () => this.getLabel()

    return {
      cat_bonus,
      rank_bonus,
      item_bonus,

      getLabel,

      skill_bonus: () => +cat_bonus() + +rank_bonus() + +item_bonus() + +this.special_bonus1 + +this.special_bonus2
    }

  }

  skill_progressions(rank_bonus_type, race) {
    switch (rank_bonus_type) {
      case 'Standard':
        return [-15, 3, 2, 1, 0.5]
      case 'Combined':
        return [-15, 5, 3, 1.5, 0.5]
      case 'Limited':
        return [0, 1, 1, 0.5, 0]
      case 'Special':
        return this.special_progression(race)
      default:
        console.error('unknown rank bonus type', rank_bonus_type)
        return [0, 0, 0, 0, 0]
    }
  }

  // Special progressions for Body Development and Mind Point Development
  // Falanar, [0,6,5,2,1], [0,5,3,2,2]
  // Human, [0,6,5,2,1], [0,7,6,5,4]
  // Kagoth, [0,10,7,5,4], [0,5,3,2,2]
  // Oort, [0,6,2,2,1], [0,7,6,5,4]
  // Tulgar, [0,6,5,2,1], [0,5,3,2,2]
  // Valiesian, [0,6,4,2,1], [0,5,3,2,2]
  // Xatosian, [0,6,3,2,1], [0,8,7,6,5]

  // Standard: {categories: [-15, 2, 1, 0.5, 0], skills: [-15, 3, 2, 1, 0.5]},
  // Combined: {categories: [0, 0, 0, 0, 0], skills: [-15, 5, 3, 1.5, 0.5]},
  // Special: {}, // see above comment
  // Limited: {categories: [0, 0, 0, 0, 0], skills: [0, 1, 1, 0.5, 0]},

  special_progression(race) {
    // assume special progressions are only for body or mind dev
    // TODO use the body_dev categories from Race class
    const body_dev = 12
    const cat_id = this.category.id
    switch (race) {
      case 'Falanar':
        return cat_id === body_dev ? [0,6,5,2,1] : [0,5,3,2,2]
      case 'Human':
        return cat_id === body_dev ? [0,6,5,2,1] : [0,7,6,5,4]
      case 'Kagoth':
        return cat_id === body_dev ? [0,10,7,5,4] : [0,5,3,2,2]
      case 'Oort':
        return cat_id === body_dev ? [0,6,2,2,1] : [0,7,6,5,4]
      case 'Tulgar':
        return cat_id === body_dev ? [0,6,5,2,1] : [0,5,3,2,2]
      case 'Valiesian':
        return cat_id === body_dev ? [0,6,4,2,1] : [0,5,3,2,2]
      case 'Xatosian':
        return cat_id === body_dev ? [0,6,3,2,1] : [0,8,7,6,5]
      default:
        return cat_id === body_dev ? [0,6,5,2,1] : [0,7,6,5,4]
    }
  }
}
