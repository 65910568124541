import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'

export default function SignupSuccess() {

  return (<>
    <Container fluid='sm' style={{paddingTop:'80px',textAlign:'center'}}>
      <Row>
        <Col sm={{span:5, offset:3}}>
          <h3>Thank You!</h3>
          <p className='text-muted'>
            Thank you for registering your interest.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{span:5, offset:3}}>
          <Link as='button' to='/login'>Sign in</Link>
        </Col>
      </Row>
    </Container>
  </>)
}