import {Sprite, Stage} from '@pixi/react'
import {Container, Graphics, Matrix, Sprite as PIXISprite, Text, Texture, Ticker} from 'pixi.js'
import {Emitter} from '@pixi/particle-emitter'
import {useDispatch, useSelector} from 'react-redux'
import {useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {authHeaders, disableNavbar, enableNavbar, fetchRole, updateEncounter} from '../actions/combatActions'
import Viewport from './Viewport'
import {GameSessionContext} from './GameSession'
import {Encounter, Token} from '../models/Encounter'
import placeholder_image from '../images/person-circle.svg'
import dead_icon from '../images/dead.png'
import paralyzed_icon from '../images/paralyzed.png'
import stunned_icon from '../images/stunned.svg'
import blood_icon from '../images/blood-drop.svg'
import must_parry_icon from '../images/must-parry.png'
import no_parry_icon from '../images/no-parry.png'
import '../styles/encounter.css'
import axios from 'axios'
import Tippy from '@tippyjs/react'
import {Dropdown} from 'react-bootstrap'
import particleImage from '../images/laser-pointer-particle.png'
import {Magic} from 'react-bootstrap-icons'
import {Combatant} from '../models/Combatant'

export default function EncounterCanvas({ game,
                                          gm,
                                          draggingRole,
                                          showCharacterFn,
                                          editCharacterFn,
                                          removeTokenFn,
                                          editEncounterFn }) {
  const dispatch = useDispatch()
  const [sprite, setSprite] = useState()
  const [viewport, setViewport] = useState(null)
  const [selectedTokens, setSelectedTokens] = useState([])
  const [menuContext, setMenuContext] = useState(null)
  const [menuPosition, setMenuPosition] = useState({x: 0, y: 0})
  const [laserPointerEnabled, setLaserPointerEnabled] = useState(false)
  const stage = useRef(null)
  const session = useContext(GameSessionContext)
  const encounter = game.encounter

  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])
  const requestId = useRef()
  const emitter = useRef()
  const elapsed = useRef()
  const laserPointerEnabledRef = useRef()   // because state is cached in callback :(
  const [fps, setFps] = useState(0)

  const zoomHandler = useCallback(zoomed, [])
  const broadcaster = useCallback(broadcastEncounter, [encounter, session, dispatch])
  const roles = useSelector(state => state.combatReducers.combatants)
  const pointers = useSelector(state => state.combatReducers.pointers)

  useEffect(() => {
    requestId.current = requestAnimationFrame(animate)
    return () => {
      cancelAnimationFrame(requestId.current)
    }
  }, [])

  // display the laser pointer for the GM, if it is enabled
  // players do not have direct access to this flag, it is controlled by the pointers update mechanism
  useEffect(() => {
    const gm_pointer = pointers.find(ea => ea.id === game.owner_id)
    if (!gm && gm_pointer) {
      setLaserPointerEnabled(gm_pointer.enabled)
    }
  }, [pointers])


  useEffect(() => {
    if (viewport) {
      Texture.fromURL(particleImage).then(particleTexture => {
        // don't create multiple emitters
        if (emitter.current) {
          emitter.current.destroy()
          emitter.current = null
        }
        if (laserPointerEnabled) {
          emitter.current = new Emitter(viewport, {
            lifetime: { min: 0.2, max: 0.8 },
            frequency: 0.001,
            spawnChance: 1,
            particlesPerWave: 1,
            emitterLifetime: -1,
            maxParticles: 500,
            pos: { x: 0, y: 0 },
            autoUpdate: true,
            behaviors: [
              {type: 'scale', config: {scale: {list: [{value: 0.5, time: 0}, {value: 0.01, time: 1}]}}},
              {type: 'alpha', config: {alpha: {list: [{value: 1, time: 0}, {value: 0.5, time: 1}]}}},
              {type: 'color', config: {color: {list: [{value: 'ff001e', time: 0}, {value: 'f5232a', time: 1}]}}},
              {type: 'spawnShape', config: {type: 'torus', data: {x: 0, y: 0, radius: 0}}},
              {type: 'textureSingle', config: {texture: particleTexture}},
            ],
          })
          elapsed.current = Date.now()
        }
      })
    }
  }, [viewport, laserPointerEnabled])

  useEffect(() => {
    if (emitter.current && !gm) {
      const gm_pointer = pointers.find(ea => ea.id === game.owner_id)
      if (gm_pointer) {
        const {x, y} = gm_pointer.pos
        if (gm_pointer.enabled) {
          emitter.current.spawnPos = {x, y}
        }
        else {
          emitter.current.destroy()
          emitter.current = null
        }
      }
    }
  }, [pointers])

  useEffect(() => {
    if (gm && encounter && !laserPointerEnabled) {
      session.sendMessage({type: 'pointer', pos:{x:0, y:0}, game_id:encounter.game_id, enabled:false})
    }
    laserPointerEnabledRef.current = laserPointerEnabled
  }, [gm, encounter, laserPointerEnabled]);

  function animate() {
    // update particle emitter
    const now = Date.now()
    if (emitter.current) {
      emitter.current.update((now - elapsed.current) * 0.001)
    }
    elapsed.current = now

    // update FPS counter
    setFps((Ticker.shared.FPS).toFixed(0))
    requestAnimationFrame(animate)
  }

  // turn off the nav bar when the encounter canvas is running, re-enable it when the component unmounts
  useEffect(() => {
    dispatch(disableNavbar())
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = ''
      dispatch(enableNavbar())
    }
  }, [])

  // Update the viewport dependencies when the window is resized
  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', updateSize)

    updateSize()
    // remove handler when component is unmounted
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  useEffect(() => {
    if (encounter?.imageURL && sprite && viewport) {
      Texture.fromURL(encounter.imageURL)
        .then(texture => {
          const minScale = Math.max(window.innerWidth / texture.width, window.innerHeight / texture.height)
          if (viewport.worldWidth !== texture.width && viewport.worldHeight !== texture.height) {
            viewport.resize(window.innerWidth, window.innerHeight, texture.width, texture.height)
            viewport.clampZoom({minScale, maxScale: 1})
            if (viewport.worldWidth < window.innerWidth && viewport.worldHeight < window.innerHeight) {
              viewport.moveCenter(texture.width * 0.5, texture.height * 0.5)
            }
          }
          const {center, scale} = encounter
          viewport.setZoom(scale || minScale)
          viewport.moveCenter(center?.x || texture.width / 2, center?.y || texture.height / 2)

          const grid = viewport.getChildByName('grid')
          if (grid) {
            viewport.removeChild(grid)
            viewport.removeListener('zoomed', zoomHandler)
          }
          if (encounter.grid.enabled) {
            viewport.sortableChildren = true
            addGrid(viewport)
            viewport.on('zoomed', zoomHandler)
          }
          addViewportHandlers()
        })
        .catch(console.error)
    }
  }, [encounter, viewport, sprite, windowSize])

  useEffect(() => {
    if (encounter && viewport) {
      const {center, scale} = encounter
      scale && viewport.setZoom(scale)
      center?.x && center?.y && viewport.moveCenter(center?.x, center?.y)
    }
  }, [encounter, viewport]);

  useEffect(() => {
    if (viewport) {
      // remove existing sprites
      viewport.children.slice().forEach(child => {
        if (child.name === 'combatant-sprite') {
          viewport.removeChild(child)
        }
      })

      if (encounter?.active) {
        addTokens(encounter.tokens)
      }
    }
  }, [encounter, viewport])

  useEffect(() => {
    for (const container of selectedObjects(viewport)) {
      addSelectionHandles(container)
    }
  }, [selectedTokens, viewport])

  function selectedObjects(viewport) {
    if (!viewport) {
      return []
    }
    return viewport.children.filter(ea => ea.name === 'combatant-sprite' && isSelected(ea.token))
  }

  /**
   * Grid line thickness should not be scaled linearly with zoom;
   * as the lines get closer together they should be fainter and thinner
   */
  function zoomed({viewport}) {
    const {y} = viewport.transform.scale
    const scale = 1 / y
    const scaledAlpha = 1 * y

    const grid = viewport.getChildByName('grid')
    if (grid) {
      for (let data of grid.geometry.graphicsData) {
        data.lineStyle.width = scale
        data.lineStyle.alpha = scaledAlpha
      }
      grid.geometry.invalidate()
    }

    updateSelectionHandles(viewport)
    setMenuContext(null)   // don't try to track the sprites with a DOM menu
  }

  function addGrid(viewport) {
    const graphics = new Graphics()
    graphics.name = 'grid'
    const {worldWidth: width, worldHeight: height} = viewport
    const size = encounter.grid.pixels
    const color = encounter.grid.color

    const cols = width / size
    const rows = height / size

    const {y} = viewport.transform.scale
    const scaledWidth = 1 / y
    const scaledAlpha = 1 * y
    graphics.lineStyle({width: scaledWidth, alpha: scaledAlpha, color: color})
    for (let j = 0; j < cols; j++) {
      graphics.moveTo(j * size, 0)
      graphics.lineTo(j * size, height)
    }
    for (let i = 0; i < rows; i++) {
      graphics.moveTo(0, i * size)
      graphics.lineTo(width, i * size)
    }
    viewport.addChild(graphics)
    graphics.zIndex = 1
  }

  function addTokens(tokens) {
    tokens.forEach(token => {
      // miscellaneous combat and display data
      const {
        bleeding,
        stunned,
        must_parry,
        no_parry,
        paralyzed,
        dead,
        visible,
        display_health_bar,
        display_label
      } = token.data

      // don't render an icon for players if it is invisible
      if (!gm && !visible) {
        return
      }

      // top level container for all the token rendering components
      const container = new Container()
      container.name = 'combatant-sprite'
      container.token = token
      let {x, y, scale, rotation} = token
      if (x && y) {
        container.x = x
        container.y = y
      } else {
        const pos = findDefaultPosition()
        container.x = pos.x
        container.y = pos.y
      }
      container.interactive = true
      if (gm) {
        addTokenHandlers(container)
      }
      viewport.addChild(container)

      Texture.fromURL(token.imageURL || placeholder_image)
        .then(texture => {
          const sprite = new PIXISprite()

          sprite.texture = texture
          sprite.anchor.set(0.5)
          // noinspection JSUndefinedPropertyAssignment -- exported from EventEmitter but not in ts def
          sprite.interactive = true

          if (!visible) {
            sprite.alpha = 0.5
          }

          container.addChild(sprite)
          if (scale) sprite.scale.set(scale, scale)
          if (rotation) sprite.rotation = (rotation)

          let tokenLabel = {width: 0, y: sprite.height / 2}
          if (display_label) {
            tokenLabel = addTokenLabel(token, container)
          }
          if (display_health_bar) {
            addHitsContainer(token, container)
          }

          let x_offset = tokenLabel.width / 2
          let y_offset = tokenLabel.y + 16
          if (dead) {
            addStatusContainer(dead_icon, container, x_offset, y_offset)
            return
          }
          if (paralyzed) {
            addStatusContainer(paralyzed_icon, container, x_offset, y_offset)
            return
          }
          if (bleeding) {
            addBleedingContainer(bleeding, container, x_offset, y_offset)
            x_offset += 16
          }
          if (stunned) {
            addStatusContainer(stunned_icon, container, x_offset, y_offset)
            x_offset += 32
          }
          if (must_parry) {
            addStatusContainer(must_parry_icon, container, x_offset, y_offset)
            x_offset += 24
          }
          if (no_parry) {
            addStatusContainer(no_parry_icon, container, x_offset, y_offset)
          }

          if (isSelected(token)) {
            addSelectionHandles(container)
          }
        })
        .catch(console.error)
    })
  }

  function distance(a, b) {
    return Math.sqrt((a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y))
  }

  /** Try to place tokens in the center of the encounter map without overlapping with each other **/
  function findDefaultPosition() {
    const {grid, worldHeight, worldWidth} = encounter
    const gridSize = grid.pixels
    const cols = worldWidth / gridSize
    const rows = worldHeight / gridSize
    let pos = {x: cols / 2 * grid.pixels, y: rows / 2 * gridSize}

    viewport.children.forEach(child => {
      if (distance(child, pos) < gridSize) {
        if (pos.y < worldHeight - gridSize) {
          pos.y += gridSize
        } else if (pos.x < worldWidth - gridSize) {
          pos.x += gridSize
          pos.y = gridSize
        } else {
          pos.x = gridSize
          pos.y = gridSize
        }
      }
    })
    return pos
  }

  function addTokenLabel(token, container) {
    const labelContainer = new Container()
    container.addChild(labelContainer)
    const label = new Text(token.name, {fontFamily: 'Arial', fontSize: 24, align: 'center'})
    const rect = new Graphics()
    rect.beginFill(0xffffff, 0.5)
    // anchor is 0.5 so origin is at the center
    rect.drawRoundedRect(-5, 0, label.width + 10, label.height, 10)
    rect.endFill()
    labelContainer.addChild(rect)
    labelContainer.addChild(label)
    labelContainer.x = -label.width / 2
    labelContainer.y = container.height / 2
    return labelContainer
  }

  function addHitsContainer(token, container) {
    const {hits, max_hits} = token.data
    if (hits !== undefined && max_hits > 0) {
      const hitsContainer = new Container()
      container.addChild(hitsContainer)
      const percent = Math.max(hits, 0) / max_hits
      const rect = new Graphics()
      rect.beginFill(0xC03000)
      rect.drawRect(-2, 0, 96, 10)
      rect.endFill()
      rect.beginFill(0x009B00)
      rect.drawRect(-2, 0, 96 * percent, 10)
      rect.endFill()
      hitsContainer.addChild(rect)
      hitsContainer.x = -rect.width / 2
      hitsContainer.y = -container.height / 2 - rect.height
    }
  }

  function addBleedingContainer(bleeding, container, x, y) {
    const bleedingContainer = new Container()
    container.addChild(bleedingContainer)
    Texture.fromURL(blood_icon).then(texture => {
      bleedingContainer.x = x + 4
      bleedingContainer.y = y - bleeding * texture.height / 3
      for (let i = 0; i < bleeding; i++) {
        const sprite = new PIXISprite()
        sprite.texture = texture
        sprite.y = texture.height / 2 * i
        bleedingContainer.addChild(sprite)
      }
    })
  }

  function addStatusContainer(icon, container, x, y) {
    const statusContainer = new Container()
    container.addChild(statusContainer)
    Texture.fromURL(icon).then(texture => {
      statusContainer.x = x + 4
      statusContainer.y = y - texture.height
      const sprite = new PIXISprite()
      sprite.texture = texture
      sprite.y = texture.height / 2
      statusContainer.addChild(sprite)
    })
  }

  /**
   * The viewport zoomed, so adjust the scaling of the selection handles
   */
  function updateSelectionHandles(viewport) {
    if (!viewport) return

    const {y} = viewport.transform.scale
    const scale = 1 / y

    const matrix = Matrix.IDENTITY
    matrix.scale(scale, scale)

    viewport.children.forEach(child => {
      if (child.name === 'combatant-sprite') {
        const selectionHandles = child.getChildByName('selection-handles')
        if (!selectionHandles) {
          return
        }
        const labels = child.children.filter(ea => ea.name?.endsWith('label'))
        labels.forEach(label => {
          if (label.name === 'width-label') {
            label.y = -child.height / 2
          } else {
            label.x = child.width / 2
          }
          label.scale.x = scale
          label.scale.y = scale
        })
        selectionHandles.children.forEach(handle => {
          for (let data of handle.geometry.graphicsData) {
            if (handle.name === 'handle') {
              data.matrix = matrix
            }
            data.lineStyle.width = scale
          }
          handle.geometry.invalidate()
        })
      }
    })
  }

  function addSelectionHandles(container) {
    const {width, height} = container

    const resizeHandles = createResizeHandles(width, height)
    container.addChild(resizeHandles)

    updateSelectionHandles(viewport)
  }

  function createResizeHandles(width, height) {
    const resizeHandles = new Container()
    resizeHandles.name = 'selection-handles'
    const thickness = 16

    const selectionRect = new Graphics()
    selectionRect.beginFill('cyan', 0.5)
    selectionRect.lineStyle(2, 'black')
    selectionRect.drawRoundedRect(-width / 2 - thickness / 2, -height / 2 - thickness / 2, width + thickness, height + thickness, thickness)
    selectionRect.endFill()
    selectionRect.beginHole()
    selectionRect.drawRect(-width / 2, -height / 2, width, height)
    selectionRect.endHole()
    resizeHandles.addChild(selectionRect)

    return resizeHandles
  }

  function isSelected(token) {
    return token && !!selectedTokens.find(ea => ea === token.id)
  }

  function selectToken(token) {
    if (isSelected(token)) {
      return
    }
    const selections = selectedTokens.slice()
    selections.push(token.id)
    setSelectedTokens(selections)
  }

  function deselectToken(token) {
    if (!isSelected(token)) {
      return
    }
    const selections = selectedTokens.slice()
    const index = selections.indexOf(token.id)
    selections.splice(index, 1)
    setSelectedTokens(selections)
  }

  function addViewportHandlers() {

    viewport.onpointermove = (event) => {
      const {x, y} = event.data.getLocalPosition(viewport)

      // dragging will only be true if there is a token selected and mouse button is down
      const dragToken = viewport.children.find(ea => ea.dragging)
      if (dragToken) {
        dragToken.x = x - dragToken.data.x
        dragToken.y = y - dragToken.data.y
      }

      // we can't use React state objects inside these callbacks
      if (emitter.current && gm && laserPointerEnabledRef.current) {
        emitter.current.spawnPos  = {x: x + 20, y: y + 20}
        session.sendMessage({type: 'pointer', pos:{x, y}, game_id:encounter.game_id, enabled:true})
      }
    }

    viewport.onwheel = (event) => {
      if (event.shiftKey) {
        event.stopPropagation()
        const selectedSprites = viewport.children.filter(ea => ea.name === 'combatant-sprite' && isSelected(ea.token))
        selectedSprites.forEach(container => {
          const index = encounter.tokens.findIndex(ea => ea.role_id === container.token.role_id)
          const rotation = container.token.rotation + Math.sign(event.deltaX) * Math.PI / 8
          encounter.tokens[index] = {...container.token, rotation}
          broadcaster({tokens: encounter.tokens})
        })
      }
    }
  }

  function duplicateContainer(container) {
    const sprite = container.children.find(ea => ea.isSprite)
    let duplicate = new PIXISprite()
    duplicate.name = 'drag-sprite'
    duplicate.texture = sprite.texture
    duplicate.scale.set(sprite.scale)
    duplicate.rotation = sprite.rotation
    duplicate.interactive = true
    duplicate.anchor.set(0.5)
    duplicate.x = sprite.parent.x
    duplicate.y = sprite.parent.y
    duplicate.width = sprite.width
    duplicate.height = sprite.height
    duplicate.token = container.token

    viewport.addChild(duplicate)
    viewport.follow(duplicate, {speed: 2, acceleration: 0.5, radius: 500})

    return duplicate
  }

  function addTokenHandlers(container) {

    container.onpointerdown = (event) => {
      event.stopPropagation()
      const {token} = container

      // if shift key is down: add or remove token from selection
      if (event.getModifierState('Shift')) {
        if (isSelected(token)) {
          deselectToken(token)
        } else {
          selectToken(token)
        }
      }
      // if shift key is not down: change selection to token, unless it is already selected
      else if (!isSelected(token)) {
        setSelectedTokens([token.id])
      }

      let draggingSprite = container

      if (event.getModifierState('Alt')) {
        draggingSprite = duplicateContainer(container)
        draggingSprite.duplicate = true
        draggingSprite.onpointermove = container.onpointermove
        draggingSprite.onpointerup = (event) => {
          try {
            duplicateToken(draggingSprite).then(_ => {})
          }
          catch (err) {
            console.error(err)
          }
          finally {
            draggingSprite.dragging = false
            draggingSprite.data = null
            viewport.drag()
            viewport.plugins.remove('follow')
            viewport.removeChild(draggingSprite)
          }
        }
        viewport.addChild(draggingSprite)
      }

      draggingSprite.dragging = true
      draggingSprite.data = event.data.getLocalPosition(viewport)
      draggingSprite.data.x -= draggingSprite.x;
      draggingSprite.data.y -= draggingSprite.y;
      viewport.drag({pressDrag: false})
      viewport.follow(container, {speed: 2, acceleration: 0.5, radius: 500})
    }

    container.onpointerup = () => {
      updateToken(container)
      container.dragging = false
      container.data = null
      viewport.drag()
      viewport.plugins.remove('follow')
    }

    container.onpointerupoutside = () => {
      updateToken(container)
      container.dragging = false
      container.data = null
      viewport.drag()
      viewport.plugins.remove('follow')
    }

    container.onpointermove = (event) => {
      event.stopPropagation()
      if (container.dragging) {
        const {x, y} = event.data.getLocalPosition(viewport)
        container.x = x - container.data.x
        container.y = y - container.data.y
      }
    }
  }

  function dragEnter(event) {
    event.preventDefault()
    let sprite = viewport.getChildByName('drag-sprite')
    Texture.fromURL(draggingRole.tokenArtwork?.path || placeholder_image).then(texture => {
      sprite = new PIXISprite()
      sprite.name = 'drag-sprite'
      sprite.role = draggingRole
      sprite.texture = texture
      sprite.scale.set(Token.tokenScale(draggingRole, encounter))
      sprite.interactive = true
      sprite.anchor.set(0.5)

      viewport.addChild(sprite)
      viewport.follow(sprite, {speed: 2, acceleration: 0.5, radius: 500})
    })
  }

  function dragOver(event) {
    event.preventDefault()
    let sprite = viewport.getChildByName('drag-sprite')
    if (sprite) {
      const {x, y} = viewport.toWorld(event.clientX, event.clientY)
      sprite.x = x - sprite.width / 2
      sprite.y = y - sprite.height / 2
    }
  }

  function dragEnd() {
    let sprite = viewport.getChildByName('drag-sprite')
    if (sprite) {
      viewport.removeChild(sprite)
      viewport.plugins.remove('follow')
    }
  }

  function dragLeave() {
    let sprite = viewport.getChildByName('drag-sprite')
    if (sprite) {
      viewport.removeChild(sprite)
      viewport.plugins.remove('follow')
    }
  }

  /**
   * A domain object has been dragged into the canvas.
   * Create the appropriate token, obtain an id, and update the session
   * @param event
   */
  async function performDrop(event) {
    event.preventDefault()
    let sprite = viewport.getChildByName('drag-sprite')
    if (sprite) {
      viewport.removeChild(sprite)
      viewport.plugins.remove('follow')
    }
    const token = await createToken(sprite.role, encounter)
    sprite.token = token
    updateToken(sprite)
  }

  async function createToken(role, encounter, options) {
    const token = Token.createToken(role, encounter, options)
    const body = new FormData()
    body.append('token', JSON.stringify(token))
    const response = await axios.post('/app/encounters/tokens', body, authHeaders())
    token.id = response.data.id
    return token
  }

  function duplicateName(name) {
    const match = name.match(/^(.*)?([\d+])$/)
    if (match) {
      return match[1] + (+match[2] + 1)
    }
    return name + ' 1'
  }

  async function duplicateRole(role) {
    const name = duplicateName(role.name)
    let duplicate = new Combatant({...role, id:undefined, name})
    const formData = new FormData()
    formData.append('role', JSON.stringify(duplicate))
    try {
      const res = await axios.post('/app/roles/', formData, authHeaders())
      duplicate.id = res.data.id

      // duplicate artwork, if present
      if (role.tokenArtwork) {
        const imageFormData = new FormData()
        imageFormData.append('artwork', JSON.stringify(role.tokenArtwork))
        await axios.put(`/app/roles/${duplicate.id}/image?type=token`, imageFormData, authHeaders())
      }

      // duplicate skills, if present
      const skillsData = new FormData()
      skillsData.append('role', JSON.stringify(duplicate))
      await axios.put('/app/roles/skills', skillsData, authHeaders())
    }
    catch (err) {
      console.error(err)
    }
    dispatch(fetchRole(duplicate.id))
    return duplicate
  }

  async function duplicateToken(displayObject) {
    const {token} = displayObject
    const {role_id, scale, rotation} = token
    const role = roles.find(ea => ea.id === role_id)
    const new_role = await duplicateRole(role)
    const duplicate = await createToken(new_role, encounter, {name:new_role.name, rotation, scale})
    displayObject.token = duplicate
    updateToken(displayObject)
  }

  function updateToken(displayObject) {
    const {x, y, token} = displayObject
    const imageURL = token.imageURL
    const tokens = encounter.tokens.filter(ea => ea.role_id !== token.role_id)
    tokens.push(new Token({...token, enc_id: encounter.id, imageURL, x, y}))

    // const {x, y} = viewport.center
    // const {y} = viewport.transform.scale
    const center = {x: viewport.center.x, y: viewport.center.y}
    const scale = viewport.transform.scale.x
    broadcaster({tokens, center, scale})
  }

  // update database & connected sessions
  function broadcastEncounter(options) {
    const updatedEncounter = new Encounter({...encounter, ...options})
    session.sendMessage({type: 'encounter', encounter: updatedEncounter})
    dispatch(updateEncounter(updatedEncounter))
  }

  function createReferenceClientRect({x = 0, y = 0}) {
    return () => ({
      width: 0, height: 0, top: y, right: x, bottom: y, left: x
    })
  }

  function saveFile(response, event) {
    const disposition = event.currentTarget.getResponseHeader('Content-Disposition')
    // see https://stackoverflow.com/a/23054920/ for explanation of parsing filename from content disposition
    const filename = disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
      .replaceAll('"', '')
    const element = document.createElement('a')
    document.body.appendChild(element)
    element.style = "display:none"
    const url = window.URL.createObjectURL(response)
    element.href = url
    element.download = filename
    element.click()
    window.URL.revokeObjectURL(url)
  }

  function handleDownload(role) {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', `/app/roles/${role.id}`, true)
    const {headers} = authHeaders()
    xhr.setRequestHeader('Authorization', headers['Authorization'])
    xhr.responseType = 'blob'
    xhr.onload = (event) => saveFile(xhr.response, event)
    xhr.send()
  }

  function toggleHealthStatus(token) {
    const index = encounter.tokens.findIndex(ea => ea.role_id === token.role_id)
    const {display_health_bar} = token.data
    encounter.tokens[index] = {...token, data: {...token.data, display_health_bar: !display_health_bar}}
    broadcaster({tokens: encounter.tokens})
  }

  function toggleVisibility(token) {
    const index = encounter.tokens.findIndex(ea => ea.role_id === token.role_id)
    const {visible} = token.data
    encounter.tokens[index] = {...token, data: {...token.data, visible: !visible}}
    broadcaster({tokens: encounter.tokens})
  }

  function toggleDisplayLabel(token) {
    const index = encounter.tokens.findIndex(ea => ea.role_id === token.role_id)
    const {display_label} = token.data
    encounter.tokens[index] = {...token, data: {...token.data, display_label: !display_label}}
    broadcaster({tokens: encounter.tokens})
  }

  function tokenContextMenu(token) {
    const role = roles.find(ea => ea.id === token.role_id)
    const { display_label, display_health_bar, visible } = token.data
    return (<>
      <div>
        <h6>{token.name}</h6>
        <Dropdown className='token-menu'>
          <Dropdown.Item onClick={() => {
            editCharacterFn && editCharacterFn(token)
            setMenuContext(null)
          }}>Edit Stats</Dropdown.Item>
          {role.type !== 'Monster' && <Dropdown.Item onClick={() => {
            showCharacterFn && showCharacterFn(token)
            setMenuContext(null)
          }}>Character Sheet</Dropdown.Item>}
          <Dropdown.Item onClick={() => {
            handleDownload(role)
            setMenuContext(null)
          }}>Download JSON</Dropdown.Item>
          <Dropdown.Divider/>
          <Dropdown.Item onClick={() => {
            removeTokenFn && removeTokenFn(token)
            setMenuContext(null)
          }}>Remove from Encounter</Dropdown.Item>
          <Dropdown.Item onClick={() => {
            toggleDisplayLabel(token)
            setMenuContext(null)
          }}>{display_label ? 'Hide Label' : 'Show Label'}</Dropdown.Item>
          <Dropdown.Item onClick={() => {
            toggleHealthStatus(token)
            setMenuContext(null)
          }}>{display_health_bar ? 'Hide Health Bar' : 'Show Health Bar'}</Dropdown.Item>
          <Dropdown.Item onClick={() => {
            toggleVisibility(token)
            setMenuContext(null)
          }}>{visible ? 'Make Invisible' : 'Make Visible'}</Dropdown.Item>
        </Dropdown>
      </div>
    </>)
  }

  function noEncounterContextMenu() {
    return (<>
      <div>
        <Dropdown className='token-menu'>
          <Dropdown.Item onClick={() => {
            editEncounterFn && editEncounterFn(new Encounter({game_id: game.id}))
            setMenuContext(null)
          }}>New Encounter</Dropdown.Item>
        </Dropdown>
      </div>
    </>)
  }

  function encounterContextMenu(encounter) {
    if (!encounter) {
      return noEncounterContextMenu()
    }
    return (<>
      <div>
        <h6>{encounter.name}</h6>
        <Dropdown className='token-menu'>
          <Dropdown.Item onClick={() => {
            editEncounterFn && editEncounterFn(encounter)
            setMenuContext(null)
          }}>Edit Encounter</Dropdown.Item>
          <Dropdown.Item onClick={() => {
            setLaserPointerEnabled(!laserPointerEnabled)
            setMenuContext(null)
          }}><Magic/>&nbsp;{laserPointerEnabled ? 'Stop Laser Pointer' : 'Use Laser Pointer'}</Dropdown.Item>
        </Dropdown>
      </div>
    </>)
  }

  function contextMenu(options) {
    if (!options) {
      return null
    }
    const {token, encounter} = options
    return token ? tokenContextMenu(token) : encounterContextMenu(encounter)
  }

  function contextMenuHandler(event) {
    event.preventDefault()
    if (!gm) {
      return
    }
    const {clientX: x, clientY: y, pageX, pageY} = event
    const rootBoundary = stage.current.app.renderer.events.rootBoundary

    setMenuPosition({x, y})
    const target = rootBoundary.hitTest(pageX, pageY)
    if (target) {
      const token = target.parent?.token
      if (token) {
        setMenuContext({token})
        return
      }
    }
    // default to show encounter if there is no selection
    setMenuContext({encounter})
  }

  return (<>
    <Stage id='encounter-canvas' options={{backgroundColor: 0x2f4f4f}}
           ref={stage}
           onDragEnter={dragEnter}
           onDragOver={dragOver}
           onDrop={performDrop}
           onDragEnd={dragEnd}
           onDragLeave={dragLeave}
           onContextMenu={contextMenuHandler}
           width={window.innerWidth} height={window.innerHeight}>
      {encounter?.imageURL &&
        <Viewport screenWidth={window.innerWidth} screenHeight={window.innerHeight}
                  worldWidth={window.innerWidth} worldHeight={window.innerHeight} ref={setViewport}>
          <Sprite image={encounter.imageURL} ref={setSprite}/>
        </Viewport>}
    </Stage>
    <Tippy visible={menuContext}
           arrow={false}
           placement={'right-start'}
           interactive={true}
           appendTo={() => document.querySelector('#root')}
           offset={[0, 0]}
           getReferenceClientRect={createReferenceClientRect(menuPosition)}
           onClickOutside={() => setMenuContext(null)}
           content={contextMenu(menuContext)}/>
    <div className='ec-fps-counter'>
      <b>FPS</b>&nbsp;{fps}
    </div>
  </>)
}
