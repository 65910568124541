import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import '../styles/combat.css'
import CriticalDetails from './CriticalDetails'
import {Button, Card, CloseButton, Dropdown, Form, Stack} from 'react-bootstrap'
import {Book} from 'react-bootstrap-icons'
import {Tooltip} from 'react-tippy'
import icosahedron from '../images/icosahedron.svg'
import {authHeaders, nDx} from '../actions/combatActions'
import chart_data from '../charts/charts.json'
import MovableComponent from './MovableComponent'
import Select from 'react-select'
import axios from 'axios'

export default function CriticalLookup({game, show, onHide}) {
  const [roll, setRoll] = useState(0)
  const [chart, setChart] = useState({})
  const [chartOptions, setChartOptions] = useState([])
  const [category, setCategory] = useState('')
  const [categories, setCategories] = useState([])
  const [table, setTable] = useState('Attack')
  const [damageLookup, setDamageLookup] = useState('')
  const [imageHeight, setImageHeight] = useState(0)

  const charts = useSelector(state => state.combatReducers.criticals)
  const armor_types = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', 'X', 'IX', 'VIII', 'VII', 'VI', 'V', 'IV', 'III', 'II', 'I']
  const tables = ["Attack", "Critical", "Fumble"]

  useEffect(() => {
    const options = chart_data
      .filter(ea => ea.table === table.toLowerCase())
      .map(ea => { return { label: ea.name, value: ea }})
    setChartOptions(options)
    setChart(options[0])
  }, [chart_data, table])

  useEffect(() => {
    if (chart) {
      if (chart.table === 'attack') {
        setCategories(armor_types)
      }
      else {
        const lookup = charts.find(ea => ea.value === chart.id && ea.type === chart.table)
        if (lookup) {
          setCategories(lookup.categories)
        }
      }
    }
  }, [chart, charts])

  useEffect(() => {
    if (!category || !categories.find(ea => ea.category === category)) {
      setCategory(categories[0])
    }
  }, [categories])

  useEffect( () => {
    if (chart.table === 'attack' && category && roll) {
      axios.get(`/app/weapons/lookup?id=${chart.id}&at=${category}&roll=${roll}`, authHeaders())
        .then(lookup => {
          setDamageLookup(lookup.data)
        })
    }
  }, [category, roll, chart])

  function handleDieRoll() {
    nDx(1, 100).then(result => setRoll(result.data.total))
  }

  function chartIdentifier(chart) {
    if (chart.table === 'attack') {
      return chart
    }
    else {
      return charts.find(ea => ea.value === chart.id && ea.type === chart.table)
    }
  }

  const dieRollInput = (<>
    <Tooltip title='Roll percentile dice'>
      <Button onClick={handleDieRoll} style={{display:'contents'}} className='drag-ignore'>
        <img src={icosahedron} alt='roll-d100' width={22}/>
      </Button>
    </Tooltip>
    <Form.Control className="col" placeholder="Roll" type='number' value={roll}
                  style={{width:60}}
                  onChange={(e) => setRoll(+e.target.value)}/>
  </>)

  function imagePath(path) {
    return `/images/charts/${path}`
  }

  function onResize(event, direction, ref) {
    const chartContainer = ref.querySelector('#chart-container')
    if (chartContainer) {
      const chartTop = chartContainer.getBoundingClientRect().top
      const windowHeight = ref.getBoundingClientRect().height
      setImageHeight(windowHeight - chartTop)
    }
  }

  if (!show) {
    return null
  }

  return (<>
    <MovableComponent maxWidth={600} maxHeight={900} minWidth={540} minHeight={150} name='critical-lookup'
                      cancel='input,select,.dropdown,.cc-menu-button,.dropdown-item,.cc-body,.drag-ignore'
                      onResize={onResize}
                      defaultPosition={{x: 134, y: 24}} defaultSize={{width: 800, height: 600}}>
      <Card style={{height:'100%'}}>
        <Card.Header className='cc-header'>
          <h6>Charts</h6>
          <CloseButton onClick={onHide} className='align-self-center drag-ignore'/>
        </Card.Header>
        <Card.Body>
          <Stack className='table-lookup' gap={2}>
            <Stack direction='horizontal' gap={6} style={{gap:'inherit'}}>
              <Dropdown>
                <Dropdown.Toggle as='div' className='cc-menu-button'>
                  <Book/>{` ${table} `}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {tables.map((each, i) => {
                    return (
                      <Dropdown.Item eventKey={each} key={i} onClick={() => setTable(each)}>
                        {each}
                      </Dropdown.Item>)})}
                </Dropdown.Menu>
              </Dropdown>
              <Select style={{paddingLeft: 20, width:200}}
                // styles={{ valueContainer: (base) => { return {...base, width: '100%'} }}}
                value={{label: chart.name, value: chart}}
                onChange={(option) => setChart(option.value)}
                options={chartOptions}/>
              {dieRollInput}
              <div>Type</div>
              <Form.Select className="col" value={category} onChange={(event) => setCategory(event.target.value)}>
                {categories.map((ea, i) => <option key={i}>{ea}</option>)}
              </Form.Select>
            </Stack>
            {table === 'Attack' ? <>
                {damageLookup.damage >= 0 && <div>Damage: {damageLookup.damage}</div>}
                {damageLookup.severity && <div>Critical: {damageLookup.severity}</div>}
              </>:
              <CriticalDetails game={game} roll={roll} chart={chartIdentifier(chart)} category={category}/>}

            {chart?.path && <div id='chart-container' className='drag-ignore' style={{height: imageHeight, overflowY: 'auto'}}>
              <Card.Img src={imagePath(chart.path)} alt={chart.name}
                        style={{objectFit: 'cover', objectPosition: 'top'}} />
            </div>}
          </Stack>
        </Card.Body>
      </Card>
    </MovableComponent>
  </>)
}
