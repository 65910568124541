import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers/rootReducer'

export default function configureStore(initialState={}) {
  console.warn('creating store')
  return createStore(
      rootReducer,
      initialState,
      applyMiddleware(thunk)
  )
}