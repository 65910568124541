import React, {Fragment} from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import '../../styles/character.css'
import {useSelector} from 'react-redux'

export default function CharacterStats(props) {
  const {state, setState} = props
  const system = useSelector(state => state.combatReducers.system)

  function handleChange(event) {
    const {name, value} = event.target
    setState({ type: name, value })
  }

  const num_rows = 5
  const num_cols = 2

  if (!system) {
    return null
  }

  return (
    <>
      <Row style={{textAlign:'center'}}>
        <Col xs={{offset: 2}}>
          <Form.Label>Temp</Form.Label>
        </Col>
        <Col xs>
          <Form.Label>Pot</Form.Label>
        </Col>
        <Col xs={{offset: 2}}>
          <Form.Label>Temp</Form.Label>
        </Col>
        <Col xs>
          <Form.Label>Pot</Form.Label>
        </Col>
      </Row>

      {[...Array(num_rows).keys()].map(row => {
        return <Row key={row} style={{alignItems:'baseline',paddingBottom:4}}>
          {[...Array(num_cols).keys()].map(col => {
            const index = col * num_rows + row
            const stat = system.stats[index]
            const value = state[stat.abbr]
            const pot_value = state[stat.pot_abbr]
            return (
              stat && <Fragment key={index}>
                <Col xs={2}>
                  <Form.Label>{stat.name}</Form.Label>
                </Col>
                <Col>
                  <Form.Control type='number' value={value || ''} name={stat.abbr}
                                style={{width: 72, textAlign: 'right'}}
                                onChange={(handleChange)}/>
                </Col>
                <Col>
                  <Form.Control type='number' value={pot_value || ''} name={stat.pot_abbr}
                                style={{width: 72, textAlign: 'right'}}
                                onChange={handleChange}/>
                </Col>
              </Fragment>)
          })}
        </Row>
      })}

      <Row>
        &nbsp;
      </Row>
    </>)
}
