import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import '../styles/campaign.css'
import '../styles/character.css'
import CharacterSheet from './CharacterSheet'
import {Breadcrumb, Button, Container, Modal, Pagination, Row} from 'react-bootstrap'
import EditCategories from './EditCategories'
import {Combatant} from '../models/Combatant'
import {authHeaders, saveCombatant} from '../actions/combatActions'
import axios from 'axios'
import EditCombatant from './EditCombatant'
import stats_icon from '../images/stats.svg'
import weapon_costs_icon from '../images/weapon_costs.svg'
import psychic_costs_icon from '../images/psychic_costs.svg'
import edit_icon from '../images/edit.svg'
import print_icon from '../images/print.svg'
import {Tooltip} from 'react-tippy'
import {useNavigate} from 'react-router'

const mode = {
  edit_role: {title: 'Vitals', className: 'cs-combatant-grid'},
  weapon_costs: {title: 'Weapon Costs', className: 'cs-cost-grid'},
  psychic_costs: {title: 'Psychic Costs', className: 'cs-cost-grid'},
  edit_skills: {title: 'Edit Skills', className: 'cs-add-grid'},
}

export default function CharacterView({role}) {
  const dispatch = useDispatch()
  const [modal, setModal] = useState(null)
  const [editSkills, setEditSkills] = useState(false)
  const [active, setActive] = useState(1)
  const auth_info = useSelector(state => state.combatReducers.auth_info)

  /**
   * All the skill and category assignments have been updated in the global store & can only be
   * rolled back by requesting a refresh from the db
   */
  const handleCancel = () => {
    dispatch(() => {
      axios.get(`/app/roles/${role.id}`, authHeaders())
      .then(response => {
        const new_role = new Combatant(response.data)
        dispatch(saveCombatant(new_role))
        setEditSkills(false)
      })
      .catch(err => console.error(err))
    })
  }

  /**
   * To save, we need to send the skill assignments in the role's skills and skill_categories
   * collections and save them in a sensible format
   */
  const handleSave = () => {
    const formData = new FormData()
    formData.append('role', JSON.stringify(role))
    axios.put('/app/roles/skills', formData, authHeaders())
      .then(() => setEditSkills(false))
  }

  const printCharacterSheet = () => {
    window.open(`/print/${role.id}`, role.name,
        'width=768,height=1008,location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes,directories=no,status=no')
  }

  return (<>
    <Container>
      <Row>&nbsp;</Row>
      <NavigationTrail role={role}/>
      <div className="campaign-view">
        {role &&
        <div className='cs-sheet-controls'>
          <div className='cs-pagination'>
            <Pagination>
              <Pagination.Item key={1} active={1 === active} onClick={() => setActive(1)}>
                Stats
              </Pagination.Item>
              <Pagination.Item key={2} active={2 === active} onClick={() => setActive(2)}>
                Skill Categories
              </Pagination.Item>
              <Pagination.Item key={3} active={3 === active} onClick={() => setActive(3)}>
                Skills
              </Pagination.Item>
              <Pagination.Item key={4} active={4 === active} onClick={() => setActive(4)}>
                Inventory
              </Pagination.Item>
            </Pagination>
            <div className='cs-edit-icons'>
              {auth_info && auth_info.roles.includes('gm') && <>
                <Tooltip title='Edit Skills'>
                  <img src={edit_icon} alt='Edit Skills' onClick={() => setModal(() => setEditSkills(true))}/>
                </Tooltip>
                <Tooltip title='Vitals'>
                  <img src={stats_icon} alt='Vitals' onClick={() => setModal(mode.edit_role)}/>
                </Tooltip>
                <Tooltip title='Weapon Costs'>
                  <img src={weapon_costs_icon} alt='Weapon Costs' onClick={() => setModal(mode.weapon_costs)}/>
                </Tooltip>
                <Tooltip title='Psychic Costs'>
                  <img src={psychic_costs_icon} alt='Psychic Costs' onClick={() => setModal(mode.psychic_costs)}/>
                </Tooltip>
              </>}
              <Tooltip title='Print Sheet'>
                <img src={print_icon} alt='Print Sheet' onClick={printCharacterSheet}/>
              </Tooltip>
            </div>
            {editSkills &&
            <div className='cs-edit-buttons'>
              <Button variant='secondary' type='reset' onClick={handleCancel}>Cancel</Button>
              <Button variant='primary' type='submit' onClick={handleSave}>Save</Button>
            </div>
            }
          </div>

          <CharacterSheet role={role}
                          isEditing={editSkills}
                          page={active}
                          done={() => setEditSkills(false)}/>
        </div>}

        {modal &&
        <Modal dialogClassName={modal.className} show={!!modal} onHide={() => setModal(null)}>
          <Modal.Header closeButton>
            <Modal.Title>{modal.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {renderModal(modal, role, () => setModal(null))}
          </Modal.Body>
        </Modal>}
      </div>
    </Container>
  </>)
}

function renderModal(modal, role, done) {
  switch (modal) {
    case mode.edit_role:
      return <EditCombatant role={role || new Combatant({active:true})} done={done}/>
    case mode.psychic_costs:
      return <EditCategories role={role} category='psychic_costs' done={done}/>
    case mode.weapon_costs:
      return <EditCategories role={role} category='weapon_costs' done={done}/>
    default:
      return null
  }
}

function NavigationTrail({role}) {
  const navigate = useNavigate()

  return (<>
    <Breadcrumb>
      <Breadcrumb.Item onClick={() => navigate('/games')}>My Games</Breadcrumb.Item>
      <Breadcrumb.Item onClick={() => navigate(`/games/${role.campaign_id}`)}>{role.campaign}</Breadcrumb.Item>
      <Breadcrumb.Item active>{role.name}</Breadcrumb.Item>
    </Breadcrumb>
  </>)
}

