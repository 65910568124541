import React, {useEffect, useState} from 'react'
import {Button, Col, Container, Form, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import axios from 'axios'

export default function Recover() {
  const [email, setEmail] = useState('')
  const [email_message, setEmailMessage] = useState('')

  const handleLogin = (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('email', email)
    axios.post('/app/recover', formData)
    .then(() => {
      window.location.href = '/recover/success'
    })
    .catch(err => {
      const message = err.response.data && err.response.data.message
      setEmailMessage(message)
    })
  }

  useEffect(() => {
    if (email) {
      setEmailMessage(null)
    }
  }, [email])

  return (<>
    <Container className='app-signin'>
      <Row>
        <Col sm={{span:8, offset:2}}>
          <h3>Password Recovery</h3>
          <p className='text-muted'>
            Enter the email you used to sign up for Spymaster
          </p>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col sm={{span:6, offset:3}} className='app-signin-form'>
          <Form onSubmit={handleLogin}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" value={email}
                            onChange={(e) => setEmail(e.target.value)}/>
              <Form.Text>
                <span style={{color:'red'}}>{email_message}</span>
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
              Continue
            </Button>
          </Form>
        </Col>
        <Col sm={{span:8, offset:2}}>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col sm={{span:8, offset:2}}>
          <Link to='/login'>Back to Sign in</Link>
        </Col>
      </Row>
    </Container>
  </>)
}