import {critical_callback, fumble_callback} from '../actions/combatActions'
import Draggable from 'react-draggable'
import RandomInput from './RandomInput'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {maxZIndex, useLocalStorage} from '../util/hooks'
import {useDispatch, useStore} from 'react-redux'
import {GameSessionContext} from './GameSession'

export default function EncounterLog({gm, encounter, combatants}) {
  const log = encounter.combatLog

  const [position, setPosition] = useLocalStorage('combat-log-pos', {x: 80, y: 127})
  const [zIndex, setZIndex] = useState(0)
  const store = useStore()
  const dispatch = useDispatch()
  const state = store.getState()
  const session = useContext(GameSessionContext)
  const endRef = useRef()

  const combatIndex = encounter?.selectedAttack
  const attacker_id = combatants[combatIndex] && combatants[combatIndex].id
  const attacker = combatants.find(ea => ea.id === attacker_id)
  const target_id = attacker && attacker.target && attacker.target.id
  const defender = combatants.find(ea => ea.id === target_id)

  const function_lookup = {
    'critical_callback': critical_callback,
    'fumble_callback': fumble_callback,
  }

  useEffect(() => {
    endRef.current?.scrollIntoView({behavior: 'smooth'})
  }, [encounter])

  function updateWindowPosition(event, data) {
    const {x, y} = data
    setPosition({x, y})
  }

  function updateZIndex() {
    setZIndex(maxZIndex() + 1)
  }

  if (log.length === 0) {
    return null
  }

  function title() {
    const message = (encounter.combatRound > 0) ?
      <>Combat has begun</> : <>Combat has ended</>

    return (<>
      <div style={{fontWeight:600,marginBottom:'1rem'}}>{message}</div>
    </>)
  }

  return (<>
    <Draggable defaultPosition={position} onMouseDown={updateZIndex}
               onStop={updateWindowPosition} cancel='input,.drag-ignore,.dropdown-toggle,.dropdown-item'>
      <div className='cg-combat-log' style={{zIndex}}>
        {title()}
        <div className='cg-combat-contents drag-ignore'>
          {log.map((entry, _i) => {
            switch (typeof(entry)) {
              case 'string':
                return <div key={_i} dangerouslySetInnerHTML={{__html: entry}}/>
              case 'object':
                if (!gm) {
                  return null
                }
                if (entry.component === 'RandomInput') {
                  const callback = function_lookup[entry.callback]
                  return (
                    <div key={_i}>
                      <RandomInput
                        callback={callback.bind(this, attacker, defender, session, dispatch, state, encounter, entry)}/>
                    </div>)
                }
                else {
                  return null
                }
              default:
                console.error('unexpected entry', entry)
                return null
            }
          })}
          <div ref={endRef}/>
        </div>
      </div>
    </Draggable>
  </>)
}
