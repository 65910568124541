import React, {useEffect, useState} from 'react'
import {Button, Col, Container, Form, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {setUserFromToken} from '../actions/combatActions'
import {useDispatch} from 'react-redux'
import {AUTH_TOKEN} from '../util/string_constants'

export default function SignIn(props) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [token, setToken] = useState('')
  const [username_message, setUsernameMessage] = useState('')
  const [password_message, setPasswordMessage] = useState('')
  const [is_verified, setIsVerified] = useState(true)
  const dispatch = useDispatch()

  if (props.logout) {
    localStorage.removeItem(AUTH_TOKEN)
    window.location.href = '/login'
  }

  const handleLogin = (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('username', username)
    formData.append('password', password)
    axios.post('/app/login', formData)
    .then((response) => {
      localStorage.setItem(AUTH_TOKEN, response.data)
      dispatch(setUserFromToken(response.data))
      window.location.href = '/'
    })
    .catch(err => {
      const status = err.response && err.response.status
      const message = err.response && err.response.data && err.response.data.message
      if (status === 401) {
        setUsernameMessage(message)
      }
      else if (status === 403 || status === 409) {
        setPasswordMessage(message)
        setEmail(err.response.data && err.response.data.email)
        setToken(err.response.data && err.response.data.token)
        setIsVerified(status !== 409)
      }
      else {
        console.error(err)
      }
    })
  }

  useEffect(() => {
    if (username || password) {
      setUsernameMessage(null)
      setPasswordMessage(null)
    }
  }, [username, password])

  const resendVerify = (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('username', username)
    formData.append('token', token)
    formData.append('email', email)
    axios.post('/app/resend_verification', formData)
    .then(() => {
      window.location.href = '/signup/success'
    })
    .catch(err => {
      if (err.response) {
        setPasswordMessage(err.response.data && err.response.data.message)
      }
      else {
        console.error(err)
      }
    })
  }

  return (<>
    <Container className='app-signin'>
      <Row>
        <Col sm={{span:8, offset:2}}>
          <h3>Spymaster Sign in</h3>
          <p>Manage characters, items and skills for your adventures</p>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col sm={{span:6, offset:3}} className='app-signin-form'>
          <Form onSubmit={handleLogin}>

            <Form.Group controlId="formBasicUsername">
              <Form.Label>Name</Form.Label>
              <Form.Control placeholder="Enter user name" value={username}
                            onChange={(e) => setUsername(e.target.value)}/>
              <Form.Text id="login-user" style={{color:'red'}}>
                <span style={{color:'red'}}>{username_message}</span><br/>
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" value={password}
                            onChange={(e) => setPassword(e.target.value)}/>
              <Form.Text>
                <span style={{color:'red'}}>{password_message}</span><br/>
                {is_verified ?
                  <Link to='/recover' className='text-muted'>Forgot your password?</Link> :
                  <Link to='#' onClick={resendVerify} className='text-muted'>
                    Re-send verification email
                  </Link>
                }
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
              Sign in
            </Button>
          </Form>
        </Col>
        <Col sm={{span:8, offset:2}}>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col sm={{span:8, offset:2}}>
          <p>Need an account? <Link to='/signup'>Click here to sign up</Link></p>
        </Col>
      </Row>
    </Container>
  </>)
}