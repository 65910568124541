export class Weapon {
  constructor(options) {
    const {id, code, label, system, fumble, strength, breakage, critical_type,
      range_unit, pb, sr, mr, lr, xlr, pb_mod, sr_mod, mr_mod, lr_mod, xlr_mod,
      category, attack_limit, critical_id, type, fumble_type } = options
    this.id = id
    this.code = code
    this.label = label
    this.system = system
    this.fumble = fumble
    this.strength = strength
    this.breakage = breakage
    this.critical_type = critical_type
    this.range_unit = range_unit
    this.pb = pb
    this.sr = sr
    this.mr = mr
    this.lr = lr
    this.xlr = xlr
    this.pb_mod = pb_mod
    this.sr_mod = sr_mod
    this.mr_mod = mr_mod
    this.lr_mod = lr_mod
    this.xlr_mod = xlr_mod
    this.category = category
    this.attack_limit = attack_limit
    this.critical_id = critical_id
    this.type = type
    this.fumble_type = fumble_type
  }

  // weapon types for Large/SuperLarge creatures (Arms Law)
  static large_critical_types = [
    'Normal', 'Magic', 'Adamantine', 'Holy Arms', 'Slaying'
  ]

  static large_energy_types = [
    'Blaster', 'Laser', 'Plasma', 'Burn/Scorch', 'Burst/Raking'
  ]

  static large_robotic_types = [
    'Blast', 'Burst/Raking', 'Piercing', 'Puncture', 'Melee'
  ]

  // special attack modes for energy weapons (Blaster Law)
  static energy_attack_modes = [
    {label: 'Single shot', value: 'single-shot'},
    {label: 'Double tap', value: 'double-tap'},
    {label: 'Burst', value: 'burst'},
    {label: 'Continuous', value: 'continuous'}
  ]

  // special ammo types for ballistic weapons (Blaster Law)
  static ballistic_ammo_types = [
    {label: 'Puncture', value: 'Puncture'},
    {label: 'Hollowpoint', value: 'Hollowpoint'},
    {label: 'Armor Piercing', value: 'Armor Piercing'},
    {label: 'Impact', value: 'Impact'},
    {label: 'Shrapnel', value: 'Shrapnel'},
  ]

  // special attacks for arms law
  static attack_limits = {
    'Size': [
      {label: 'Tiny', value: 81},   // tooth and claw only
      {label: 'Small', value: 105},
      {label: 'Medium', value: 120},
      {label: 'Large', value: 135},
      {label: 'Huge', value: 150},
    ],
    // SM psychic bolts
    'Mag': [
      {label: '1-5', value: 102},
      {label: '6-10', value: 114},
      {label: '11-15', value: 126},
      {label: '16-20', value: 138},
      {label: '21-100', value: 150},
    ],
    // SM nuclear grenade attacks
    'Rad':  [
      {label: '11', value: 21},
      {label: '10', value: 27},
      {label: '9', value: 33},
      {label: '8', value: 39},
      {label: '7', value: 45},
      {label: '6', value: 102},
      {label: '5', value: 114},
      {label: '4', value: 126},
      {label: '3', value: 138},
      {label: '2', value: 150},
    ],
    // SM vehicle attacks
    'Mark': [
      {label: '1', value: 21},
      {label: '2', value: 27},
      {label: '3', value: 33},
      {label: '4', value: 39},
      {label: '5', value: 45},
      {label: '6', value: 54},
      {label: '7', value: 66},
      {label: '8', value: 78},
      {label: '9', value: 90},
      {label: '10', value: 102},
      {label: '15', value: 108},
      {label: '20', value: 114},
      {label: '25', value: 120},
      {label: '30', value: 126},
      {label: '40', value: 138},
      {label: '50', value: 150},
    ],
  }
}
