import React from 'react'
import {Button, CloseButton, Modal} from 'react-bootstrap'
import EditCombatant from './EditCombatant'
import {useSelector} from 'react-redux'

export default function EditCombatantModal({role, show, dismissModal}) {
  const alertMessage = useSelector(state => state.combatReducers.alertMessage)

  if (!role) {
    return null
  }

  return (<>
    <Modal show={show} onHide={dismissModal} dialogClassName='cs-combatant-grid'>
      <Modal.Header closeButton>
        <Modal.Title>{role?.name || `New ${role?.type}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alertMessage && <div className='alert alert-danger alert-dismissible'>
          {alertMessage}
          <Button style={{cursor:'pointer', display:'contents'}} onClick={dismissModal}>
            <CloseButton data-dismiss="alert" aria-label="Close"/>
          </Button>
        </div>}
        <EditCombatant role={role} done={dismissModal}/>
      </Modal.Body>
    </Modal>
  </>)
}
