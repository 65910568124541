const all_skills = new Map()

export default class Skill {
  constructor(options) {
    const {id, cat_id, label, is_multiple, data} = options
    this.id = id
    this.cat_id = cat_id
    this.label = label
    this.is_multiple = is_multiple
    this.default_bonus = data?.default_bonus
    all_skills.set(id, this)
  }

  skillAssignmentFor(role) {
    return role.skills.find(ea => ea.id === this.id)
  }

  num_ranks(role, level) {
    const assignment = this.skillAssignmentFor(role)
    return assignment ? assignment.num_ranks(role, level) : 0
  }

  useBonus(system, role, level = null) {

    const skillAssignment = this.skillAssignmentFor(role)
    const category = role.skill_categories.find(ea => ea.id === this.cat_id)

    const cat_bonus = () => {
      return category?.useBonus(system, role, level).cat_bonus()
    }

    const rank_bonus = (level) => skillAssignment ? skillAssignment.rank_bonus(role, level) : 0

    // A special item can provide a bonus to a specific skill (or category -- see Category.js)
    // The skill could be a generic category (e.g. 1H Melee), a specific skill (TODO or even multiple skills)
    // (e.g. a weapon, Stalking and Hiding, Picking Locks)
    const item_bonus = () => {
      const item = role.items.find(ea => ea.skill_id === this.id)
      return item?.bonus || 0
    }

    const special_bonus1 = () => skillAssignment?.special_bonus1
    const special_bonus2 = () => skillAssignment?.special_bonus2

    const getLabel = () => {
      return skillAssignment ? skillAssignment.getLabel() : this.label
    }

    return {
      cat_bonus,
      rank_bonus,
      item_bonus,
      special_bonus1,
      special_bonus2,

      getLabel,

      skill_bonus: () => +cat_bonus() + +rank_bonus() + +item_bonus() + +special_bonus1() + +special_bonus2()
    }
  }
}
