import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router'
import React, {useState} from 'react'
import {deleteAdventure, updateAdventure} from '../actions/combatActions'
import {Button, Card, Col, FloatingLabel, Form, Modal, Row, Stack} from 'react-bootstrap'
import Select from 'react-select'

export default function AdventureSettings({adventure}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [adventureName, setAdventureName] = useState(adventure.name)
  const [setting, setSetting] = useState(adventure.setting || '')
  const [year, setYear] = useState(adventure.year || '')
  const [system, setSystem] = useState(adventure.system)
  const [isEditing, setIsEditing] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  function saveSettings() {
    const data = {...adventure.data, setting, year}
    dispatch(updateAdventure({...adventure, name:adventureName, system, data}))
    setIsEditing(false)
  }

  function discardEdits() {
    setAdventureName(adventure.name)
    setIsEditing(false)
  }

  function handleKeyboard(event) {
    if (event.key === 'Escape') {
      setAdventureName(adventure.name)
      setIsEditing(false)
    }
    else if (event.key === 'Enter') {
      saveSettings()
    }
  }

  function handleDelete() {
    dispatch(deleteAdventure(adventure))
    navigate('/games')
  }

  const systemChoices = [
    {label: 'Rolemaster', value: 'Rolemaster'},
    {label: 'Privateers', value: 'Privateers'},
    {label: 'Dungeons & Dragons', value: 'D&D'},
    {label: 'Call of Cthulhu', value: 'CoC'},
  ]

  function selectSystem(choice) {
    setSystem(choice.value)
  }

  function gmTitle() {
    switch (adventure.system) {
      case 'Rolemaster': return 'GM'
      case 'CoC': return 'Keeper'
      case 'D&D': return 'DM'
      default: return 'Referee'
    }
  }

  function systemLabel() {
    const choice = systemChoices.find(ea => ea.value === system)
    return choice ? choice.label : system
  }

  return (<>
    <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Really delete this adventure? <br/><br/>
        All characters, scenes and history will be permanently deleted,<br/>
        and there is no way to undo this action.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete It
        </Button>
      </Modal.Footer>
    </Modal>
    {isEditing ? <>
      <Form style={{height:'calc(100vh - 270px)'}}>
        <Button size='sm' variant='outline-primary' className='ad-edit-button'
                onClick={saveSettings}>Done</Button>
        <span className='ad-edit-button' style={{width: '0.5rem'}}>&nbsp;</span>
        <Button size='sm' variant='outline-secondary' className='ad-edit-button'
                onClick={discardEdits}>Cancel</Button>
        <div className='settings-name-edit'>
          <FloatingLabel controlId='floatingInput' label='Adventure' className='mb-3' >
            <Form.Control
              placeholder='Adventure'
              aria-label='Name of Adventure'
              className='h2-edit'
              onChange={(ev) => setAdventureName(ev.target.value)}
              onKeyDown={handleKeyboard}
              autoFocus={true}
              value={adventureName} />
          </FloatingLabel>
        </div>
        <Stack>
          <Col xs={4}>
            <Form.Label>Game System</Form.Label>
            <Select
              value={systemChoices.find(ea => ea.value === system)}
              onChange={selectSystem} options={systemChoices}/>
            <Row>&nbsp;</Row>
          </Col>
          <FloatingLabel controlId='floatingInput' label='Setting' className='mb-3' >
            <Form.Control
              placeholder='Location'
              aria-label='Place'
              className='h2-edit'
              onChange={(ev) => setSetting(ev.target.value)}
              onKeyDown={handleKeyboard}
              autoFocus={true}
              value={setting} />
          </FloatingLabel>
          <FloatingLabel controlId='floatingInput' label='Year' className='mb-3' >
            <Form.Control
              placeholder='Year'
              aria-label='Time'
              className='h2-edit'
              onChange={(ev) => setYear(ev.target.value)}
              onKeyDown={handleKeyboard}
              autoFocus={true}
              value={year} />
          </FloatingLabel>
        </Stack>
        <Button variant='danger'
                onClick={() => setConfirmDelete(true)}>Delete this Adventure</Button>
      </Form>
    </> : <>
      <Button size='sm' className='ad-edit-button'
              onClick={() => setIsEditing(true)}>Edit</Button>
      <Card.Title>{adventure.name}</Card.Title>
      <Card.Subtitle className='mb-2 text-muted'>Game System: {systemLabel()}</Card.Subtitle>
      <Card.Subtitle className='mb-2 text-muted'>{setting && `Setting: ${setting}`}{year && `, ${year}`}</Card.Subtitle>
      <Card.Subtitle className='mb-2 text-muted'>{gmTitle()}: {adventure.gm}</Card.Subtitle>
    </>
    }
  </>)
}
