import React, {Fragment, useEffect} from 'react'
import {Col, Form, ListGroup, Row, Stack} from 'react-bootstrap'
import '../../styles/character.css'
import Tippy from '@tippyjs/react'
import {useSelector} from 'react-redux'

export default function CharacterStats(props) {
  const {state, setState} = props
  const system = useSelector(state => state.combatReducers.system)
  const {stats} = system

  function handleChange(event) {
    const {name, value} = event.target
    setState({ type: name, value })
  }

  const editable = ['Reg', 'Current', 'Starting']

  function slotValueForSection(stat, index) {
    if (stat.labels[index] === 'Half') {
      const slot = stat.slots[0]
      return Math.floor(state[slot] / 2)
    }
    else if (stat.labels[index] === 'Fifth') {
      const slot = stat.slots[0]
      return Math.floor(state[slot] / 5)
    }
    else {
      const slot = stat.slots[index]
      return state[slot]
    }
  }

  useEffect(() => {
    const derivedValue = Math.floor((state['CON'] + state['SIZ']) / 10)
    setState({type: 'max_hits', value: derivedValue})
    setState({type: 'max_magic', value: derivedValue})
  }, [state['CON'], state['SIZ']])

  useEffect(() => {
    setState({type: 'start_sanity', value: state['POW']})
  }, [state['POW']])

  useEffect(() => {
    setState({type: 'sanity', value: state['start_sanity']})
  }, [state['start_sanity']])

  useEffect(() => {
    setState({type: 'magic', value: state['max_magic']})
  }, [state['max_magic']])

  useEffect(() => {
    setState({type: 'hits', value: state['max_hits']})
  }, [state['max_hits']])

  useEffect(() => {
    setState({type: 'luck', value: state['start_luck']})
  }, [state['start_luck']])

  const num_rows = 4
  const num_cols = 3
  const stat_keys = Object.keys(stats)

  return (<>
    {[...Array(num_rows).keys()].map(row => {
      return <Row key={row} style={{alignItems:'baseline',paddingBottom:4}}>
        {[...Array(num_cols).keys()].map(col => {
          const index = col * num_rows + row
          const key = stat_keys[index]
          const stat = stats[key]
          const slot = stat.slots[0]
          const description = state[slot + '_description']
          return (<Fragment key={index}>
            <Col style={{textAlign:'end',alignSelf:'flex-end',paddingRight:0}}>
              <div style={{display:'flex',flexDirection:'column'}}>
                <Tippy content={<span>{stat.formula}
                  {description ? <><br/><span style={{fontSize:9}}> = {description}</span></> : ''}
                </span>}>
                  <Form.Label>{key}</Form.Label>
                </Tippy>
              </div>
            </Col>
            <Col>
              <Stack direction='horizontal'>
                {stat.labels.map((section, i) => {
                  return (<div key={i} style={{color:'darkred',fontSize:12,textAlign:'center',width:64}}>
                    {stat.labels[i]}
                  </div>)
                })}
              </Stack>
              <Stack direction='horizontal'>
                <ListGroup horizontal>
                  {stat.labels.map((section, i) => {
                    const isEditable = editable.find(ea => ea === section)
                    const background = isEditable ? 'aliceblue' : 'white'
                    const name = stat.slots[i] ?? slot
                    let value = slotValueForSection(stat, i)
                    return (
                      <ListGroup.Item style={{backgroundColor:background,textAlign:'right',width:64}} key={index + '-' + i}>
                        {isEditable ?
                          <input type='number' value={value} name={name}
                                 tabIndex={index + 1}
                                 style={{width:44, margin:0, padding:0, border:'none', textAlign:'right', backgroundColor:'transparent'}}
                                 onChange={handleChange}/>
                          : value}
                      </ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </Stack>
            </Col>
          </Fragment>)
        })}
      </Row>
    })}
    <Row>&nbsp;</Row>
  </>)
}
