import React, {useRef} from 'react'
import axios from 'axios'
import {authHeaders, displayAlert, setCombatReducer} from '../actions/combatActions'
import '../styles/adventure.css'
import {Pencil} from 'react-bootstrap-icons'
import {useDispatch, useSelector} from 'react-redux'
import {Spinner} from 'react-bootstrap'

export default function FileUpload({game_id, onCompletion}) {
  const progress = useSelector(state => state.combatReducers.uploadProgress)
  const inputRef = useRef() // accessing input element
  const dispatch = useDispatch()

  const handleChange = (e) => {
    dispatch(setCombatReducer('uploadProgress', 0))
    const file = e.target.files[0]
    uploadFile(file)
  }

  const uploadFile = (file) => {
    if (!file) {
      return
    }
    const formData = new FormData()
    formData.append('file', file)
    formData.append('game_id', game_id)
    const abortController = new AbortController()
    dispatch(setCombatReducer('uploadController', abortController))
    axios.post('/app/images/upload', formData, {
      ...authHeaders(),
      signal: abortController.signal,
      onUploadProgress: (progressEvent) => {
        let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        dispatch(setCombatReducer('uploadProgress', progress))
      }
    }).then(res => {
      dispatch(setCombatReducer('uploadProgress', 0))
      dispatch(setCombatReducer('uploadController', null))
      onCompletion && onCompletion(res)
    }).catch(err => {
      dispatch(setCombatReducer('uploadProgress', 0))
      dispatch(setCombatReducer('uploadController', null))
      console.error(err)
      if (err.code !== "ERR_CANCELED") {
        const message = err?.message || 'An error has occurred'
        dispatch(displayAlert(message))
      }
    })}

  return (
      <>
        <div className="file-upload">
          <input type="file" ref={inputRef} onChange={handleChange} />
          {progress > 0 && progress <= 100 ?
            <div>
              <Spinner style={{color:'lightblue'}}/>
              <div className='file-upload-progress'>{progress}%</div>
            </div> :
            <div className="fu-button" onClick={() => inputRef.current.click()}>
              <Pencil/>
            </div>
          }
        </div>
      </>
  )
}
