import Category from './Category'
import SkillAssignment from './SkillAssignment'
import {Item} from './Item'
import {convert_to_kgm} from '../util/rpg_utils'

export class Combatant {
  constructor(options) {
    const {
      id, name, player, user_id, profession, race, height, height_units, weight, weight_units, level = -1,
      hits, max_hits, ob, db, at, shield, ob_temp, xp, bleeding, stunned, loading, surprised, actions = [],
      must_parry, no_parry, parry, dead, paralyzed, attack, attacks, ob_modifiers, db_modifiers,
      skills, skill_categories = [], team, weapon_costs, psychic_costs, initiative = {}, target, campaign,
      background, items, tp_assignments, type, active, image_data, crit_codes, movement_speed,
      attack_quickness, campaign_id, system, culture, gender, stats = {}, width, length, notes = '',
    } = options

    this.catLookup = new Map()
    this.skill_categories = skill_categories.map(data => new Category(data)) // [Category]
    this.initializeSkills(skills)
    this.id = id

    this._ob = ob || 0
    this.actions = actions
    this.active = active
    this.at = at || '1'
    this.attack = attack || {}      // {label, weapon_id, bonus}
    this.attack_quickness = attack_quickness
    this.attacks = attacks || []    // [{label, weapon_id, bonus}]
    this.background = background || ''
    this.bleeding = bleeding || 0   // hits per round
    this.campaign = campaign
    this.campaign_id = campaign_id
    this.crit_codes = crit_codes
    this.culture = culture
    this._db = db || 0
    this.db_modifiers = db_modifiers || {}
    this.dead = dead
    this.gender = gender
    this.height = height || 0
    this.height_units = height_units || 'cm'
    this.hits = hits || 0
    this.image_data = (image_data || []).map(art => new Artwork(art))
    this.items = (items || []).map(item => new Item(item))
    this.length = length || 0
    this.level = level
    this.loading = loading          // loading/preparing weapon for this many rounds (cannot parry)
    this.max_hits = max_hits || 0
    this.movement_speed = movement_speed
    this.must_parry = must_parry || 0 // must parry for this many rounds
    this.name = name || ''
    this.notes = notes
    this.no_parry = no_parry || 0   // is unable to parry for this many rounds
    this.ob_modifiers = ob_modifiers || {}
    this.ob_temp = ob_temp          // critical bonus - positive ob lasts for one round only, negative ob will linger
    this._parry = parry || 0        // portion of ob devoted to parry
    this.paralyzed = paralyzed || 0 // paralysis due to combat
    this.player = player
    this.profession = profession || ''
    this.race = race
    this.shield = shield || ''      // shield label
    this.stats = stats
    this.stunned = stunned || 0    // stunned for this many rounds
    this.surprised = surprised
    this.system = system
    this.target = target            // opponent for melee simulations
    this.team = team
    this.type = type
    this.user_id = user_id
    this.weight = weight || 0
    this.weight_units = weight_units || 'kg'
    this.width = width || 0
    this.xp = xp || 0               // experience points

    // Rolemaster & Privateers
    this.weapon_costs = weapon_costs || []
    this.psychic_costs = psychic_costs || []
    this.initiative = initiative
    this.tp_assignments = tp_assignments || []
  }

  // Rolemaster & Privateers
  initializeSkills(skills) {
    this.skill_categories.forEach(cat => this.catLookup.set(cat.id, cat))
    this.skills = (skills || []).map(skill => {
      const category = this.catLookup.get(skill.cat_id)
      return new SkillAssignment({...skill, category})
    })
  }

  get tokenArtwork() {
    return this.image_data.find(ea => ea.image_type === 'token')
  }

  // Rolemaster & Privateers
  // Rolemaster & Privateers
  dev_points() {
    const {stats} = this
    return Math.round((+stats.ag + +stats.co + +stats.me + +stats.re + +stats.sd) / 5)
  }

  get mass() {
    return convert_to_kgm(this.weight, this.weight_units)
  }

  get height_m() {
    return convert_to_kgm(this.height, this.height_units)
  }

  get body_development() {
    return this.skills.find(ea => ea.category.id === 12)
  }

  get mind_point_development() {
    return this.skills.find(ea => ea.category.id === 24)
  }

  get power_point_development() {
    return this.skills.find(ea => ea.category.id === 78)
  }

  get ob() {
    const weapon_ob = this.attack && this.attack.bonus
    let value = weapon_ob || this._ob || 0
    Object.keys(this.ob_modifiers).forEach(key => {
      value += +this.ob_modifiers[key]
    })
    // add ob_temp value -- this is a one-round bonus that will be reset when update() is called
    value += this.ob_temp || 0
    return value
  }

  get parry() {
    return this._parry
  }

  set parry(value) {
    let db_modifiers = this.db_modifiers || {}
    let ob_modifiers = this.ob_modifiers || {}
    let max_parry = Math.min(this.ob, value)
    this._parry = Math.max(0, max_parry)
    db_modifiers.parry = this._parry
    ob_modifiers.parry = -this._parry
    this.db_modifiers = db_modifiers
    this.ob_modifiers = ob_modifiers
  }

  set ob(value) {
    // offset modifiers so we don't get wonky updates
    Object.keys(this.ob_modifiers).forEach(key => {
      value -= this.ob_modifiers[key]
    })
    value += this.ob_temp || 0
    this._ob = Math.max(value, 0)
  }

  get db() {
    let value = this._db || 0
    Object.keys(this.db_modifiers).forEach(key => {
      if (isNaN(this.db_modifiers[key])) {
        console.error('invalid db_modifiers', this)
      }
      value += this.db_modifiers[key]
    })
    return value
  }

  set db(value) {
    // offset modifiers so we don't get wonky updates
    Object.keys(this.db_modifiers).forEach(key => {
      value -= this.db_modifiers[key]
    })
    this._db = value
  }

  isStunProof() {
    return this.crit_codes && (this.crit_codes.match(/.*(@)/) || this.crit_codes.match(/.*(#)/))
  }

  isBleedProof() {
    return this.crit_codes && this.crit_codes.match(/.*(#)/)
  }

  // call every round to update the status
  update() {
    if (this.bleeding > 0) {
      this.hits -= this.bleeding
    }
    if (this.ob_temp > 0) {
      this.ob_temp = 0
    }
    if (this.stunned > 0) {
      this.stunned -= 1
    }
    if (this.no_parry > 0) {
      this.no_parry -= 1
    }
    if (this.must_parry > 0) {
      this.must_parry -= 1
    }
  }
}

export class Artwork {
  constructor(options) {
    const {image_type, path, width, height} = options
    this.image_type = image_type
    this.width = width
    this.height = height
    this.path = path
  }
}
