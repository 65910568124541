export class TrainingPackage {
  constructor({id, code, name, system, type, stat_gain_1, stat_gain_2, content}) {
    this.id = id
    this.code = code
    this.name = name
    this.system = system
    this.type = type
    this.stat_gain_1 = +stat_gain_1
    this.stat_gain_2 = +stat_gain_2
    this.content = content
  }
}
