import {PixiComponent, useApp} from '@pixi/react'
import {Viewport as PixiViewport} from 'pixi-viewport'
import {forwardRef} from 'react'
import {EventSystem} from 'pixi.js'

const Viewport = forwardRef(function Viewport(props, ref) {
  const app = useApp()
  return <PixiComponentViewport ref={ref} app={app} {...props} />
})

const PixiComponentViewport = PixiComponent("Viewport", {
  create: (props) => {

    // Install EventSystem, if not already
    // (PixiJS 6 doesn't add it by default)
    if (!("events" in props.app.renderer))
      props.app.renderer.addSystem(EventSystem, "events")

    const { screenWidth, screenHeight, worldWidth, worldHeight, app, onDragStart, onDragEnd} = props

    const viewport = new PixiViewport({
      screenWidth: screenWidth, screenHeight: screenHeight,
      worldWidth: worldWidth, worldHeight: worldHeight,
      ticker: app.ticker,
      events: app.renderer.events,
      stopPropagation: true,
      disableOnContextMenu: true,
    })

    // accessibility is adding divs that interfere with react modals
    // see https://github.com/pixijs/pixijs/issues/5111#issuecomment-420047824
    props.app.renderer.plugins.accessibility?.destroy()
    delete props.app.renderer.plugins.accessibility

    viewport
      .drag()
      .pinch()
      .wheel()
      .clamp({ direction: "all" })
      .decelerate({friction:0.75})

    if (onDragStart) {
      viewport.on('drag-start', onDragStart)
    }

    if (onDragEnd) {
      viewport.on('drag-end', onDragEnd)
    }

    return viewport
  },
  // didMount: (instance, parent) => {
  //   console.log(instance)
  // },
  // willUnmount: (instance, parent) => {
  //   // clean up before removal
  //   console.log('willUnmount', instance)
  // },
  // applyProps: (instance, oldProps, newProps) => {
  //   // props changed
  //   // apply logic to the instance
  // },
  config: {
    // destroy instance on unmount?
    // default true
    // XXX destroy will fail on unmount when this is true because the listeners are removed twice
    destroy: false,

    /// destroy its children on unmount?
    // default true
    destroyChildren: true,
  },})

export default Viewport
