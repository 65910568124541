export class Player {
  constructor(options) {
    const {id, game_id, name, role, status, email} = options
    this.id = id
    this.game_id = game_id
    this.name = name
    this.role = role
    this.status = status
    this.email = email
  }
}