import {useParams} from 'react-router-dom'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {GameSessionContext} from './GameSession'
import {Button, Container, Dropdown, Row, Spinner} from 'react-bootstrap'
import {useNavigate} from 'react-router'
import GameChat from './GameChat'
import '../styles/container.css'
import CriticalLookup from './CriticalLookup'
import {useDispatch, useSelector} from 'react-redux'
import {
  displayAlert,
  fetchEncounters,
  fetchPlayers,
  fetchRoles,
  fetchSystem,
  setCombatReducer,
  updateEncounter
} from '../actions/combatActions'
import EncounterCanvas from './EncounterCanvas'
import logo from '../images/logo.svg'
import {Bullseye, Chat, House, PersonCircle, StopCircle, Table} from 'react-bootstrap-icons'
import EncounterList from './EncounterList'
import CharacterList from './CharacterList'
import CharacterSheetWrapper from './CharacterSheetWrapper'
import {useLocalStorage} from '../util/hooks'
import CombatWrapper from './CombatWrapper'
import swords from '../images/crossed-swords.png'
import ModalEncounter from './ModalEncounter'
import EditCombatantModal from './EditCombatantModal'

export default function AdventurePlayer({user}) {
  const {game_id} = useParams()
  const session = useContext(GameSessionContext)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const roles = useSelector(state => state.combatReducers.combatants)
  const encounters = useSelector(state => state.combatReducers.encounters)
  const [showEncounter, setShowEncounter] = useState(false)
  const uploadController = useSelector(state => state.combatReducers.uploadController)

  const gameSession = session.gameSession(+game_id)
  const game = gameSession && gameSession.adventure
  const gm = game && game.owner_id === user.id

  const [showGameChat, setShowGameChat] = useLocalStorage('window-game-chat', true)
  const [showCharts, setShowCharts] = useLocalStorage('window-charts', false)
  const [showEncounters, setShowEncounters] = useLocalStorage('window-encounters', true)
  const [showCharacters, setShowCharacters] = useLocalStorage('window-characters', false)
  const [showCharacterSheet, setShowCharacterSheet] = useLocalStorage('window-sheet', false)
  const [showCombat, setShowCombat] = useLocalStorage('window-combat', false)
  const [draggingRole, setDraggingRole] = useState(null)
  const [editCharacter, setEditCharacter] = useState(false)

  const reconnectInterval = useRef(100)
  const reconnect = useRef(null)

  const [character, setCharacter] = useState(null)
  const [encounter, setEncounter] = useState(null)

  useEffect(() => {
    dispatch(fetchEncounters(+game_id))
    dispatch(fetchRoles(+game_id))
    dispatch(fetchPlayers(+game_id))
  }, [])

  useEffect(() => {
    if (game) {
      dispatch(fetchSystem(game.system))
    }
  }, [game])

  useEffect(() => {
    if (!gameSession) {
      tryReconnect()
    } else {
      const timeout = reconnect.current
      if (timeout) {
        reconnect.current = null
        clearTimeout(timeout)
        reconnectInterval.current = 100
      }
    }
  }, [gameSession])

  // if the current user is the GM, then token selection sets current state
  // otherwise, players can see only their own character
  useEffect(() => {
    if (user) {
      if (gm) {
        // in this case we need to refresh the local state if there is an update
        if (character) {
          setCharacter(roles.find(ea => ea.id === character.id))
        }
      }
      else {
        setCharacter(roles.find(ea => ea.user_id === user.id))
      }
    }
  }, [user, roles])

  useEffect(() => {
    setEncounter(game?.encounter)
  }, [game])

  function tryReconnect() {
    console.log(`attempting reconnect in ${reconnectInterval.current} ms`)
    session.reconnect()
    // async reconnect may fail, so schedule next attempt
    reconnectInterval.current = Math.min(reconnectInterval.current * 2, 15000)
    reconnect.current = setTimeout(tryReconnect, reconnectInterval.current)
  }

  if (!game || !gameSession.id) {
    return (<>
      <Container>
        <Row>&nbsp;</Row>
        <Row>
          <h2>Session does not exist</h2>

          <p>The game session is unavailable. This might be temporary, trying to reconnect...</p>
          <Spinner variant='warning'/>
        </Row>

      </Container>
    </>)
  }

  if (!gm && game.finish_dt) {
    return (<>
      <Container>
        <Row>&nbsp;</Row>
        <Row>
          <h2>Session has ended</h2>

          <p>The game session has ended.</p>
          <Button variant='warning' onClick={() => navigate('/games')}>
            Back to My Games
          </Button>
        </Row>

      </Container>
    </>)
  }

  function toggleGameChat() {
    setShowGameChat(!showGameChat)
  }

  function toggleCharts() {
    setShowCharts(!showCharts)
  }

  function toggleEncounters() {
    setShowEncounters(!showEncounters)
  }

  function toggleCharacters() {
    setShowCharacters(!showCharacters)
  }

  function toggleShowCharacterSheet() {
    setShowCharacterSheet(!showCharacterSheet)
  }

  function toggleShowCombat() {
    setShowCombat(!showCombat)
  }

  function endSession() {
    session.sendMessage({type: 'shutdown', game_id})
    navigate('/games')
  }

  function gmMenu() {
    return (<>
      <Dropdown.Item onClick={toggleCharts}>
        <Table/> {showCharts ? 'Hide' : 'Show'} Charts
      </Dropdown.Item>
      <Dropdown.Item onClick={toggleEncounters}>
        <Bullseye/> {showEncounters ? 'Hide' : 'Show'} Encounters
      </Dropdown.Item>
      <Dropdown.Item onClick={toggleShowCombat}>
        <img src={swords} alt='Crossed Swords' width={16} height={16}/> {showCombat ? 'Hide' : 'Show'} Combat
      </Dropdown.Item>
      <Dropdown.Item onClick={toggleCharacters}>
        <PersonCircle/> {showCharacters ? 'Hide' : 'Show'} Characters
      </Dropdown.Item>
      <hr style={{margin: 'revert'}}/>
      <Dropdown.Item onClick={endSession}>
        <div className='cc-menu-icon'>
          <StopCircle style={{color: 'red'}}/>
          End Game
        </div>
      </Dropdown.Item>
    </>)
  }

  function playerMenu() {
    return (<>
      <Dropdown.Item onClick={toggleShowCharacterSheet}>
        <PersonCircle/> {showCharacterSheet ? 'Hide' : 'Show'} Character Sheet
      </Dropdown.Item>
    </>)
  }

  function showCharacterSheetForToken(token) {
    const role = roles.find(ea => ea.id === token.role_id)
    if (!role) {
      console.error('showCharacterSheetForToken', roles, token)
      return
    }
    setCharacter(role)
    setShowCharacterSheet(true)
  }

  function editCharacterForToken(token) {
    const role = roles.find(ea => ea.id === token.role_id)
    if (!role) {
      console.error('showCharacterSheetForToken', roles, token)
      return
    }
    setCharacter(role)
    setEditCharacter(true)
  }

  function removeTokenFromEncounter(token) {
    const index = game.encounter.tokens.findIndex(ea => ea.role_id === token.role_id)
    if (index < 0) {
      console.error('removeTokenFromEncounter', token)
      return
    }
    game.encounter.tokens.splice(index, 1)
    dispatch(updateEncounter(game.encounter))
    session.sendMessage({type:'encounter', encounter: game.encounter})
  }

  function editEncounter(encounter) {
    setEncounter(encounter)
    setShowEncounter(true)
  }

  function dismissModal() {
    setShowEncounter(false)
    setEditCharacter(false)
    dispatch(displayAlert(null))
    // cancel any file uploads in progress
    if (uploadController) {
      uploadController.abort()
      dispatch(setCombatReducer('uploadProgress', 0))
      dispatch(setCombatReducer('uploadController', null))
    }
  }

  return (<>
    <EditCombatantModal show={editCharacter} role={character} dismissModal={dismissModal}/>
    <ModalEncounter show={showEncounter} encounter={encounter} dismissModal={dismissModal}/>

    <Dropdown>
      <Dropdown.Toggle as='div' className='ap-combat-menu'>
        <img src={logo} width='32px' height='32px' alt='Spymaster logo'/>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => navigate('/games')}>
          <House/> My Games
        </Dropdown.Item>
        <hr style={{margin: 'revert'}}/>
        <Dropdown.Item onClick={toggleGameChat}>
          <Chat/> {showGameChat ? 'Hide' : 'Show'} Game Chat
        </Dropdown.Item>
        {gm && gmMenu()}
        {!gm && playerMenu()}
      </Dropdown.Menu>
    </Dropdown>

    {gm && <CriticalLookup show={showCharts}
                           onHide={toggleCharts}
                           game={game}/>}

    {gm && <EncounterList show={showEncounters}
                          onHide={toggleEncounters}
                          encounters={encounters}
                          combatants={roles}
                          game={game}/>}

    {gm && <CharacterList show={showCharacters}
                          onHide={toggleCharacters}
                          roles={roles}
                          user={user}
                          game={game}
                          onDragCombatant={setDraggingRole}/>}

    {game.encounter && <CombatWrapper
      show={(gm && showCombat) || (!gm && game.encounter?.combatRound > 0)}
      encounter={game.encounter}
      onHide={toggleShowCombat}/>}

    <CharacterSheetWrapper
      show={showCharacterSheet}
      onHide={toggleShowCharacterSheet}
      page={1}
      role={character}/>

    <GameChat show={showGameChat}
              onHide={toggleGameChat}
              adventure={game}/>

    <EncounterCanvas game={game} gm={gm} draggingRole={draggingRole}
                     showCharacterFn={showCharacterSheetForToken}
                     editCharacterFn={editCharacterForToken}
                     removeTokenFn={removeTokenFromEncounter}
                     editEncounterFn={editEncounter}/>
  </>)
}
