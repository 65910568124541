import {Container, Row} from 'react-bootstrap'
import React from 'react'
import {Link} from 'react-router-dom'

export default function NotFound() {
  return (<>
    <Container>
      <Row>&nbsp;</Row>
      <Row>
        <h2>Page not found</h2>

        <p>Sorry, you've been misdirected.</p>
        <p>Maybe you can find you're looking for here:&nbsp;
        <Link to='/games'>My Games</Link>
        </p>
      </Row>

    </Container>
  </>)
}

