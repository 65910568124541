import React, { Component } from 'react'
import {waitingForInput} from '../actions/combatActions'
import {connect} from 'react-redux'

class RandomInput extends Component {

  constructor(props) {
    super(props)
    this.state = { value: '' }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.waitingForInput(true)
  }

  handleChange(event) {
    this.setState({value: event.target.value})
  }

  handleSubmit(event) {
    event.preventDefault()
    this.props.callback(+this.state.value)
    this.props.waitingForInput(false)
  }

  render() {
    return (
        <div>
          <div dangerouslySetInnerHTML={{__html: this.props.prefix}}/>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <input type="number" onChange={this.handleChange} value={this.state.value}/>
            <button type="submit">OK</button>
          </form>
        </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => ({
  waitingForInput: (value) => dispatch(waitingForInput(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RandomInput)