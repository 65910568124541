import {Breadcrumb, CloseButton, Container, Modal, Row} from 'react-bootstrap'
import React, {useEffect, useState} from 'react'
import {Adventure} from '../models/Adventure'
import {displayAlert, fetchPlayers, fetchRoles, fetchScenes, fetchSystem} from '../actions/combatActions'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router'
import EditCombatant from './EditCombatant'
import AdventureSettings from './AdventureSettings'
import AdventureCard from './AdventureCard'

export default function AdventureDetail({adventures, user}) {
  const {game_id} = useParams()
  const adventure = adventures.find(ea => ea.id === +game_id)
  const alertMessage = useSelector(state => state.combatReducers.alertMessage)
  const [showCharacter, setShowCharacter] = useState(false)
  const [role, setRole] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (adventure) {
      dispatch(fetchPlayers(game_id))
      dispatch(fetchRoles(game_id))
      dispatch(fetchScenes(game_id))
      dispatch(fetchSystem(adventure.system))
    }
  }, [game_id, dispatch, adventure])

  function dismissModal() {
    setShowCharacter(false)
    dispatch(displayAlert(null))
  }

  function editCharacter(role) {
    setRole(role)
    setShowCharacter(true)
  }

  return (<>
    <Modal show={showCharacter} onHide={dismissModal} dialogClassName='cs-combatant-grid'>
      <Modal.Header closeButton>
        <Modal.Title>{(role && role.name) || 'New Character'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alertMessage && <div className='alert alert-danger alert-dismissible'>
          {alertMessage}
          <CloseButton data-dismiss="alert" aria-label="Close" onClick={() => dispatch(displayAlert(null))}/>
        </div>}
        <EditCombatant role={role} done={() => setShowCharacter(false)}/>
      </Modal.Body>
    </Modal>

    <Container>
      <Row>&nbsp;</Row>
      <Row>
        <NavigationTrail adventure={adventure}/>
      </Row>
      <AdventureCard user={user} adventure={adventure} editCharacter={editCharacter}/>
    </Container>
  </>)
}

function NavigationTrail({adventure}) {
  const navigate = useNavigate()

  return (<>
    <Breadcrumb>
      <Breadcrumb.Item onClick={() => navigate('/games')}>My Games</Breadcrumb.Item>
      <Breadcrumb.Item active>{adventure?.name}</Breadcrumb.Item>
    </Breadcrumb>
  </>)
}

AdventureSettings.propTypes = {
  adventure: PropTypes.instanceOf(Adventure)
}

AdventureDetail.propTypes = {
  adventure: PropTypes.instanceOf(Adventure)
}
