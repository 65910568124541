import Statistic from './Statistic'
import Skill from './Skill'
import Race from './Race'
import Profession from './Profession'
import {totalMass} from '../util/rpg_utils'
import SkillAssignment from './SkillAssignment'
import Monster from './Monster'
import axios from 'axios'
import {authHeaders} from '../actions/combatActions'
import {Combatant} from './Combatant'

export class RPGSystem {
  constructor({label, stats = [], skills = [], professions = [], monsters = [], races = []}) {
    this.label = label
    this.stats = stats.map(ea => new Statistic(ea))
    this.skills = skills.map(ea => new Skill(ea))
    this.professions = professions.map(ea => new Profession(ea))
    this.monsters = monsters.map(ea => new Monster(ea))
    this.races = races.map(ea => new Race(ea))
  }

  static fromData(data) {
    const {label} = data
    switch (label) {
      case 'Rolemaster':
        return new RolemasterSystem(data)
      case 'Privateers':
        return new RolemasterSystem(data)
      case 'D&D':
        return new OpenGameSystem(data)
      case 'CoC':
        return new BasicRoleplaying(data)
      default:
        throw Error('Unknown RPG system: ' + label)
    }
  }

  async generateStats(character) {
    throw new Error('No statistic generation method is defined')
  }

  getStat(key) {
    return this.stats.find(ea => ea.abbr === key)
  }

}

class RolemasterSystem extends RPGSystem {

  constructor(options) {
    super(options)
    this.stats = [
      new Statistic('Agility', 'ag', 'ag_pot'),
      new Statistic('Constitution', 'co', 'co_pot'),
      new Statistic('Memory', 'me', 'me_pot'),
      new Statistic('Reasoning', 're', 're_pot'),
      new Statistic('Self Discipline', 'sd', 'sd_pot'),
      new Statistic('Empathy', 'em', 'em_pot'),
      new Statistic('Intuition', 'in', 'in_pot'),
      new Statistic('Presence', 'pr', 'pr_pot'),
      new Statistic('Quickness', 'qu', 'qu_pot'),
      new Statistic('Strength', 'st', 'st_pot'),
    ]

    this.shields = [
      {label:'None', melee: 0, thrown: 0, firearm: 0, energy: 0, psychic: 0},
      {label:'Main Gauche', melee: 15, thrown: 0, firearm: 0, energy: 0, psychic: 0},
      {label:'Buckler', melee: 20, thrown: 10, firearm: 5, energy: 5, psychic: 5},
      {label:'Normal', melee: 20, thrown: 20, firearm: 15, energy: 15, psychic: 15},
      {label:'Full', melee: 25, thrown: 25, firearm: 20, energy: 20, psychic: 20},
      {label:'Wall', melee: 30, thrown: 40, firearm: 30, energy: 30, psychic: 30},
      {label:'Absorption', melee: 30, thrown: 30, firearm: 30, energy: 30, psychic: 30},
      {label:'Barrier', melee: 60, thrown: 70, firearm: 70, energy: 90, psychic: 90},
      {label:'Deflector', melee: 5, thrown: 15, firearm: 40, energy: 60, psychic: 60},
      {label:'Velocity', melee: 30, thrown: 45, firearm: 60, energy: 0, psychic: 0},
    ]

    /**
     * Critical location codes (used for ballistic puncture/impact)
     *
     * 1. Hand
     * 2. Forearm
     * 3. Bicep
     * 4. Tricep
     * 5. Shoulder / Clavicle
     * 6. Thigh
     * 7. Calf / Lower leg
     * 8. Foot / Ankle
     * 9. Hip / Backside / Groin
     * 10. Head / Skull / Eyes / Ear
     * 11. Knee
     * 12. Abdomen / Kidneys / Spleen
     * 13. Side / Ribs
     * 14. Chest / Heart
     */

    this.armorTypes = {
      // 12	11	8	5	4	3	1	X	IX	VIII	VII	VI	V	IV	III	II	I
      // See Spacemaster Privateers Tech Law Equipment manual, p. 98 for conversion rules
      1: {label: 'Skin', alt_at: '1', alt_adj: 0, protects:[]},
      2: {label: 'Robes', alt_at: '1', alt_adj: -10, protects:[]}, // == 1(-10)
      3: {label: 'Light Hide', alt_at: '3', alt_adj: 0, protects:[]},
      4: {label: 'Heavy Hide', alt_at: '4', alt_adj: 0, protects:[]},
      5: {label: 'Leather Jerkin', alt_at: '5', alt_adj: 0, protects:[]},
      6: {label: 'Leather Coat', alt_at: '4', alt_adj: 0, protects:[]}, // == 4
      7: {label: 'Reinforced Leather', alt_at: '4', alt_adj: 0, protects:[]}, // == 4
      8: {label: 'Reinforced Coat', alt_at: '8', alt_adj: 0, protects:[]},
      9: {label: 'Leather Breastplate', alt_at: 'I', alt_adj: 0, protects:[5, 12, 13, 14]}, // == I
      10: {label: 'Leather Breastplate & Greaves', alt_at: 'II', alt_adj: 0, protects:[5, 7, 12, 13, 14]}, // == II
      11: {label: 'Half-Hide Plate', alt_at: '11', alt_adj: 0, protects:[]},
      12: {label: 'Full-Hide Plate', alt_at: '12', alt_adj: 0, protects:[]},
      13: {label: 'Chain Shirt', alt_at: 'III', alt_adj: -10, protects:[5, 12, 13, 14]}, // == III(-10)
      14: {label: 'Chain Shirt & Greaves', alt_at: 'IV', alt_adj: -10, protects:[5, 7, 12, 13, 14]}, // == IV(-10)
      15: {label: 'Full Chain', alt_at: 'VI', alt_adj: -10, protects:[]}, // == VI(-10)
      16: {label: 'Chain Hauberk', alt_at: 'VII', alt_adj: -15, protects:[]}, // == VII(-15)
      17: {label: 'Metal Breastplate', alt_at: 'V', alt_adj: -10, protects:[5, 12, 13, 14]}, // == V(-10)
      18: {label: 'Metal Breastplate & Greaves', alt_at: 'VI', alt_adj: -10, protects:[5, 7, 12, 13, 14]}, // == VI(-10)
      19: {label: 'Half Plate', alt_at: 'VII', alt_adj: -10, protects:[]}, // == VII(-10)
      20: {label: 'Full Plate', alt_at: 'VII', alt_adj: -10, protects:[]}, // == VII(-10)
      I : {label: 'Flak Vest', alt_at: '1', alt_adj: 0, protects:[12, 13, 14]}, // == 9(+10) -- slash resolved as krush
      II: {label: 'Extended Flak Vest', alt_at: '9', alt_adj: +10, protects:[5, 12, 13, 14]}, // == 10(+10) -- slash resolved as krush
      III: {label: 'Reinforced Flak Vest', alt_at: '11', alt_adj: +10, protects:[5, 6, 12, 13, 14]}, // == 11(+10) -- slash resolved as krush
      IV: {label: 'Reinforced Flak Armor', alt_at: '12', alt_adj: +10, protects:[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]}, // == 12(+10) -- slash resolved as krush
      V: {label: 'Kinetic Vest', alt_at: '17', alt_adj: +10, protects:[5, 12, 13, 14]}, // == 17(+10) -- slash, puncture resolved as krush
      VI: {label: 'Kinetic Jacket', alt_at: '18', alt_adj: +10, protects:[5, 6, 9, 12, 13, 14]}, // == 18(+10) -- slash, puncture resolved as krush
      VII: {label: 'Kinetic Suit', alt_at: '19', alt_adj: +10, protects:[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]}, // == 19(+10) -- slash, puncture resolved as krush
      VIII: {label: 'Combat Breastplate', alt_at: '17', alt_adj: +50, protects:[5, 12, 13, 14]}, // == 17(+50) -- slash, puncture resolved as krush
      IX: {label: 'Combat Breastplate & Greaves', alt_at: '18', alt_adj: +50, protects:[2, 3, 4, 5, 6, 7, 9, 10, 12, 13, 14]}, // == 18(+50) -- slash, puncture resolved as krush
      X: {label: 'Full Combat Armor', alt_at: '20', alt_adj: +50, protects:[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]}, // == 20(+50) -- slash, puncture resolved as krush

      // SM Vehicles Construction Armor Types (CAT)
      XI: {label: 'Steel'},
      XII: {label: 'Titanium'},
      XIII: {label: 'Crysteel'},
      XIV: {label: 'Crystanium'},
      XV: {label: 'Reinforced Crystanium'},
      XVI: {label: 'Fullerene'},
      XVII: {label: 'Reinforced Fullerene'},
      XVIII: {label: 'Crystanium Double Hull'},
      XIX: {label: 'Fullerene Double Hull'},
      XX: {label: 'Colossium'},
      // SM Structural materials (Table VM-8.2)
      // --- need to figure out combat resolution for wooden ships and buildings ---
      // Straw: {label: 'Straw or Glass'},
      // Plaster: {label: 'Lathe and Plaster'},
      // Plywood: {label: 'Plywood, Sheetrock, Safety Glass'},
      // Aluminum: {label: 'Aluminum or Sheet Metal'},
      // Concrete: {label: 'Concrete'},
      // Rebar: {label: 'Reinforced Concrete'},
      // Brass: {label: 'Bronze, Brass, or Light Steel'},
    }

    this.maneuverPenalties = {
      I : {min: 0, max: 0, ranged: 0, aqp: 0, skill_id: 3, cat_id: 2},
      II : {min: -5, max: -40, ranged: 0, aqp: 0, skill_id: 3, cat_id: 2},
      III : {min: -10, max: -60, ranged: 5, aqp: 5, skill_id: 3, cat_id: 2},
      IV : {min: -15, max: -80, ranged: 10, aqp: 10, skill_id: 3, cat_id: 2},
      V : {min: 0, max: 0, ranged: 0, aqp: 0, skill_id: 4, cat_id: 3},
      VI : {min: -5, max: -40, ranged: 0, aqp: 0, skill_id: 4, cat_id: 3},
      VII : {min: -10, max: -60, ranged: 5, aqp: 5, skill_id: 4, cat_id: 3},
      VIII : {min: -10, max: -70, ranged: 0, aqp: 5, skill_id: 1, cat_id: 1},
      IX : {min: -20, max: -100, ranged: 10, aqp: 10, skill_id: 1, cat_id: 1},
      X : {min: -30, max: -130, ranged: 20, aqp: 15, skill_id: 1, cat_id: 1},
      powered : {min: -25, max: -160, ranged: 20, aqp: 15, skill_id: 2, cat_id: 1},
    }

  }

  potentialFormula(temp) {
    if (temp <= 24) return '20 + 8d10'  // 20-24 20 + 8d10
    if (temp <= 34) return '30 + 7d10'  // 25-34 30 + 7d10
    if (temp <= 44) return '40 + 6d10'  // 35-44 40 + 6d10
    if (temp <= 54) return '50 + 5d10'  // 45-54 50 + 5d10
    if (temp <= 64) return '60 + 4d10'  // 55-64 60 + 4d10
    if (temp <= 74) return '70 + 3d10'  // 65-74 70 + 3d10 * could be less than temp
    if (temp <= 84) return '80 + 2d10'  // 75-84 80 + 2d10 * could be less than temp
    if (temp <= 91) return '90 + 1d10'  // 85-91 90 + 1d10
    if (temp <= 92) return '91 + 1d9'   // 92 91 + 1d9
    if (temp <= 93) return '92 + 1d8'   // 93 92 + 1d8
    if (temp <= 94) return '93 + 1d7'   // 94 93 + 1d7
    if (temp <= 95) return '94 + 1d6'   // 95 94 + 1d6
    if (temp <= 96) return '95 + 1d5'   // 96 95 + 1d5
    if (temp <= 97) return '96 + 1d4'   // 97 96 + 1d4
    if (temp <= 98) return '97 + 1d3'   // 98 97 + 1d3
    if (temp <= 99) return '98 + 1d2'   // 99 98 + 1d2

    return '99 + 1d2'                   // 100 99 + 1d2
  }

  async generateStats(character) {
    const prof = this.professions.find(ea => ea.label === character.profession)

    // prime stats must all be over 90
    const prime_stats = prof?.prime_stats.split('/') || []
    for (const abbr of prime_stats) {
      const slot = this.stats[abbr.toLowerCase()]
      const response = await axios.get(`/app/sessions/roll?formula=d10+90`, authHeaders())
      const {total} = response.data
      character.stats[slot] = total
    }

    // all other stats must be over 20
    const other_stats = this.stats.filter(ea => !prime_stats.includes(ea.abbr))
    for (const stat of other_stats) {
      const response = await axios.get(`/app/sessions/roll?formula=d80+20`, authHeaders())
      const {total} = response.data
      character.stats[stat.abbr] = total
    }

    await this.computePotentials(character)
  }

  async computePotentials(character) {
    for (const stat of this.stats) {
      const value = character.stats[stat.abbr]
      const formula = this.potentialFormula(value)
      const response = await axios.get(`/app/sessions/roll?formula=${formula}`, authHeaders())
      const {total} = response.data
      character.stats[stat.pot_abbr] = Math.max(total, value)
    }
  }

  useRole(role) {
    return {
      profession: () => this.getProfession(role),
      race: () => this.getRace(role),
      bonuses: () => this.statBonuses(role),
      role: role,
      assignSkill: (spec) => this.addSkillAssignment(role, spec),
      setCategoryRanks: (spec) => this.setCategoryRanks(role, spec),

      basic_bonus: (stat) => this.basic_bonus(role, stat),
      race_bonus: (stat) => this.race_bonus(role, stat),
      special_bonus: (stat) => this.special_bonus(role, stat),
      stat_bonus: (stat) => this.total_stat_bonus(role, stat),

      maximum_hits: () => this.maximum_hits(role),
      maximum_mps: () => this.maximum_mps(role),
      maximum_pps: () => this.maximum_pps(role),

      encumbrance_penalty: () => this.encumbrance_penalty(role),
      base_movement_rate: () => this.base_movement_rate(role),
      moving_maneuver_penalty: () => this.moving_maneuver_penalty(role),
      weight_penalty: () => this.weight_penalty(role),
    }
  }

  getProfession(role) {
    return this.professions.find(ea => ea.label === role.profession)
  }

  getRace(role) {
    return this.races.find(ea => ea.label === role.race)
  }

  getSkill(label) {
    return this.skills.find(ea => ea.label === label)
  }

  /**
   * Add the skill assignments for adolescent skill development.
   *
   * These will be specified as follows:
   * 1. {category: <string>, id: <number>, ranks: <number>}
   *    -- n category ranks, lookup by id
   * 2. {categories: <string>, ids:[<number>], ranks: <number>}
   *    -- n category ranks, lookup by ids (player/gm choice)
   * 3. {skill: <string>, id: <number>, ranks: <number>}
   *    -- n skill ranks, lookup by skill id
   * 4. {skills: <string>, id: [<number>], ranks: <number>}
   *    -- n skill ranks, look up by category id (player/gm choice)
   */
  addAdolescentSkills(role, race) {
    const role_system = this.useRole(new Combatant(role))

    race?.adolescent_ranks.forEach(spec => {
      if (spec.skill) {
        this.addSkillAssignment(role, {...spec, level: -1})
      } else if (spec.category) {
        this.setCategoryRanks(role, {...spec, level: -1})
      } else if (spec.skills) {
        for (let id of spec.ids) {
          this.skills.filter(ea => ea.cat_id === id)
            .forEach(skill => {
              role_system.assignSkill({id: skill.id, level: -1, ranks: 0})
            })
        }
      } else if (spec.categories) {
        for (let id of spec.ids) {
          role_system.setCategoryRanks({id: id, level: -1, ranks: 0})
        }
      }
    })
  }

  addSkillAssignment(role, spec) {
    const existing = role.skills.find(ea => ea.id === spec.id)
    if (existing) {
      existing.setNumRanks(spec.ranks, role, spec.level)
    } else {
      const skill = this.skills.find(ea => ea.id === spec.id)
      if (!skill) {
        console.error('Bad skill spec', spec)
        return
      }
      const category = role.catLookup.get(skill.cat_id)
      role.skills.push(new SkillAssignment({...skill, ranks: [spec.ranks], levels: [spec.level], category}))
    }
  }

  setCategoryRanks(role, spec) {
    const category = role.catLookup.get(spec.id)
    category.setNumRanks(spec.ranks, role, spec.level)
  }

  statBonuses(role) {
    return this.stats.reduce((map, val) => {
      if (val.abbr === 'qu') {
        map[val.abbr] = Math.max(this.total_stat_bonus(role, val) - this.armor_quickness_penalty(role.at), 0)
      } else {
        map[val.abbr] = this.total_stat_bonus(role, val)
      }
      return map
    }, {})
  }

  maximum_hits(role) {
    const skill = role.body_development
    return (skill && skill.useBonus(this, role).skill_bonus()) || role.max_hits
  }

  maximum_mps(role) {
    const skill = role.mind_point_development
    return (skill && skill.useBonus(this, role).skill_bonus()) || role.stats.max_magic
  }

  maximum_pps(role) {
    const skill = role.power_point_development
    return (skill && skill.useBonus(this, role).skill_bonus()) || role.stats.max_magic
  }

  basic_bonus(role, stat) {
    const stat_value = role.stats[stat.abbr]
    if (stat_value >= 102) return 14
    else if (stat_value === 101) return 12
    else if (stat_value === 100) return 10
    else if (stat_value >= 99) return 9
    else if (stat_value >= 96) return 8
    else if (stat_value >= 94) return 7
    else if (stat_value >= 92) return 6
    else if (stat_value >= 90) return 5
    else if (stat_value >= 85) return 4
    else if (stat_value >= 80) return 3
    else if (stat_value >= 75) return 2
    else if (stat_value >= 70) return 1
    else if (stat_value >= 31) return 0
    else if (stat_value >= 26) return -1
    else if (stat_value >= 21) return -2
    else if (stat_value >= 16) return -3
    else if (stat_value >= 11) return -4
    else if (stat_value === 10) return -5
    else if (stat_value >= 8) return -6
    else if (stat_value >= 6) return -7
    else if (stat_value >= 4) return -8
    else if (stat_value >= 2) return -9
    else return -10
  }

  race_bonus(role, stat) {
    const race = this.getRace(role)
    return (race && race[stat.abbr]) || 0
  }

  special_bonus(role, stat) {
    return stat.abbr === 'qu' ? -this.armor_quickness_penalty(role.at) : 0
  }

  total_stat_bonus(role, stat) {
    if (role.type === 'Monster') {
      switch (role.attack_quickness) {
        case ('IN'): return -16;  // Inching
        case ('CR'): return -12;  // Creeping
        case ('VS'): return -8;   // Very Slow
        case ('SL'): return -4;   // Slow
        case ('MD'): return 0;    // Medium
        case ('MF'): return 4;    // Moderately Fast
        case ('FA'): return 8;    // Fast
        case ('VF'): return 12;   // Very Fast
        case ('BF'): return 16;   // Blindingly Fast
        default:
          return this.special_bonus(role, stat)
      }
    }
    // armor quickness penalty will not reduce total bonus below zero
    if (stat.abbr === 'qu') {
      const bonus = this.basic_bonus(role, stat) + this.race_bonus(role, stat)
      return Math.max(bonus + this.special_bonus(role, stat), 0)
    }
    return this.basic_bonus(role, stat) + this.race_bonus(role, stat) + this.special_bonus(role, stat)
  }

  shieldBonuses(role) {
    return this.shields.find(ea => ea.label === role.shield) || this.shields[0]
  }

  skill_bonus(role, skill) {
    return skill.useBonus(this, role).skill_bonus()
  }

  stride_modification(height) {
    // return (height - 1.9) * 10;
    if (height > 2.6) return 7;
    if (height > 2.5) return 6;
    if (height > 2.4) return 5;
    if (height > 2.3) return 4;
    if (height > 2.2) return 3;
    if (height > 2.1) return 2;
    if (height > 2.0) return 1;
    if (height > 1.9) return 0;
    if (height > 1.8) return -1;
    if (height > 1.7) return -2;
    if (height > 1.6) return -3;
    if (height > 1.5) return -4;
    if (height > 1.4) return -5;
    if (height > 1.3) return -6;
    if (height > 1.2) return -7;
    if (height > 1.1) return -8;
    if (height > 1.0) return -9;
    if (height > 0.9) return -10;
    if (height > 0.8) return -11;
    return -12;
  }

  armor_maneuver_skill(at) {
    const mod = this.maneuverPenalties[at]
    return mod && mod.skill_id
  }

  armor_maneuver_category(at) {
    const mod = this.maneuverPenalties[at]
    return mod && mod.cat_id
  }

  min_maneuver_mod(at) {
    const mod = this.maneuverPenalties[at]
    return (mod && mod.min) || 0
  }

  max_maneuver_mod(at) {
    const mod = this.maneuverPenalties[at]
    return (mod && mod.max) || 0
  }

  ranged_attack_penalty(at) {
    const mod = this.maneuverPenalties[at]
    return (mod && mod.ranged) || 0
  }

  armor_quickness_penalty(at) {
    const mod = this.maneuverPenalties[at]
    return (mod && mod.aqp) || 0
  }

  encumbrance_penalty(role) {
    return role.mass ? -8 * Math.trunc(10 * totalMass(role.items) / role.mass) : 0
  }

  weight_penalty(role) {
    return Math.min(0, (this.encumbrance_penalty(role) - this.armor_quickness_penalty(role.at))
      + 3 * this.total_stat_bonus(role, this.getStat('st')))
  }

  moving_maneuver_penalty(role) {
    let mmp = 0

    const armor_skill = role.skills.find(ea => ea.id === this.armor_maneuver_skill(role.at))
    if (armor_skill) {
      const bonus = Math.max(armor_skill.useBonus(this, role).skill_bonus(role), 0)
      const min = Math.min(bonus, this.min_maneuver_mod(role.at))
      return Math.min(this.max_maneuver_mod(role.at) + bonus, min)
    }
    const armor_category = role.skill_categories.find(ea => ea.id === this.armor_maneuver_category(role.at))
    if (armor_category) {
      const bonus = Math.max(armor_category.useBonus(this, role).cat_bonus(), 0)
      const min = Math.min(bonus, this.min_maneuver_mod(role.at))
      return Math.min(this.max_maneuver_mod(role.at) + bonus, min)
    }

    return mmp
  }

  base_movement_rate(role) {
    return 15 + this.statBonuses(role).qu + this.stride_modification(role.height_m)
  }

}

class OpenGameSystem extends RPGSystem {

}

class BasicRoleplaying extends RPGSystem {
  constructor(args) {
    super(args)
    this.stats = {
      STR: { formula: '3d6 * 5', labels: ['Reg', 'Half', 'Fifth'], slots: ['STR'] },
      CON: { formula: '3d6 * 5', labels: ['Reg', 'Half', 'Fifth'], slots: ['CON'] },
      DEX: { formula: '3d6 * 5', labels: ['Reg', 'Half', 'Fifth'], slots: ['DEX'] },
      INT: { formula: '(2d6 + 6) * 5', labels: ['Reg', 'Half', 'Fifth'], slots: ['INT'] },
      SIZ: { formula: '(2d6 + 6) * 5', labels: ['Reg', 'Half', 'Fifth'], slots: ['SIZ'] },
      POW: { formula: '3d6 * 5', labels: ['Reg', 'Half', 'Fifth'], slots: ['POW'] },
      APP: { formula: '3d6 * 5', labels: ['Reg', 'Half', 'Fifth'], slots: ['APP'] },
      EDU: { formula: '(2d6 + 6) * 5', labels: ['Reg', 'Half', 'Fifth'], slots: ['EDU'] },
      Hits: { formula: '(CON + SIZ) / 10', labels: ['Max', 'Current'], slots: ['max_hits', 'hits'], derived: true },
      Magic: { formula: '(CON + SIZ) / 10', labels: ['Max', 'Current'], slots: ['max_magic', 'magic'], derived: true },
      Luck: { formula: '(2d6 + 6) * 5', labels: ['Starting', 'Current'], slots: ['start_luck', 'luck'] },
      Sanity: { formula: 'POW', labels: ['Max', 'Current'], slots: ['start_sanity', 'sanity'], derived: true },
    }
  }

  maximum_hits(role) {
    return role.stats['max_hits']
  }

  async generateStats(character) {
    for (const key of Object.keys(this.stats)) {
      const stat = this.stats[key]
      if (!stat.derived) {
        const response = await axios.get(`/app/sessions/roll?formula=${stat.formula}`, authHeaders())
        const {total, result} = response.data
        const slot = stat.slots[0]
        // May only work for CoC characters
        character.stats[slot] = total
        character.stats[slot + '_description'] = (+result === total) ? null : result
      }
    }
  }

}
