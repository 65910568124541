import {convert_to_kgm} from '../util/rpg_utils'

export class Encounter {
  constructor(options) {
    const {
      id,
      name,
      tokens = [],
      game_id,
      imageURL,
      smallImageURL,
      grid = {},
      active,
      worldWidth = 800,
      worldHeight = 600,
      center = {},
      scale = {},
      selectedAttack = -1,
      combatRound = -1,
      combatLog = []
    } = options
    this.id = id
    this.name = name
    this.tokens = tokens.map(token_data => new Token(token_data))
    this.game_id = game_id
    this.imageURL = imageURL
    this.smallImageURL = smallImageURL
    this.grid = new Grid(grid)
    this.active = active
    this.worldWidth = worldWidth
    this.worldHeight = worldHeight
    this.center = center
    this.scale = scale
    this.selectedAttack = selectedAttack
    this.combatRound = combatRound
    this.combatLog = combatLog || []
  }
}

export class Token {

  static tokenScale(role, encounter) {
    // default token scaling is the longest horizontal dimension (not height)
    const {length: role_length, width: role_width, height_units} = role
    const units = height_units || 'm'
    let combatant_size = Math.max(role_length, role_width) || 1
    if (combatant_size !== 1) {
      combatant_size = convert_to_kgm(combatant_size, units)
    }
    const encounter_size = convert_to_kgm(encounter.grid.distance, encounter.grid.units)
    const {width: token_width, height: token_height} = role.tokenArtwork || {width: 16, height: 16}
    const token_size = Math.max(token_width, token_height) || 0
    if (token_size > 0) {
      return encounter.grid.pixels * combatant_size / encounter_size / token_size
    }
    return undefined
  }

  static createToken(combatant, encounter, options = {}) {
    const {width, height, path} = combatant.tokenArtwork || {}
    const token_options = {
      role_id: combatant.id,
      imageURL: path,
      width: width,
      height: height,
      scale: this.tokenScale(combatant, encounter),
      type: 'role',
      enc_id: encounter.id,
      name: combatant.name,
      data: {display_label: combatant.type !== 'Monster', display_health_bar: false, visible: true},
      ...options
    }
    return new Token(token_options)
  }

  constructor(options) {
    const {
      id,
      enc_id,
      type,
      role_id,
      imageURL,
      x,
      y,
      scale,
      rotation,
      name,
      data = {}
    } = options
    this.id = id
    this.role_id = role_id
    this.type = type
    // noinspection JSUnusedGlobalSymbols -- enc_id is saved to the server and used as a key
    this.enc_id = enc_id
    this.imageURL = imageURL
    this.x = x
    this.y = y
    this.scale = scale
    this.rotation = rotation
    this.data = data
    this.name = name
  }

}

export class Grid {
  constructor(options) {
    const {enabled = true, pixels = 140, units = 'ft', distance = 5, color = '#999999'} = options
    this.enabled = enabled
    this.pixels = pixels
    this.units = units
    this.distance = distance
    this.color = color
  }
}
