import '../styles/adventure.css'

export default function WelcomePage() {
  return (<>
      <div className='wp-welcome'>
        <h2>Welcome to Spymaster</h2>

        <p>Spymaster is an action oriented online role playing system that puts you in charge of the narrative.</p>

        <p>As the Game Master, you can create stories in an open ended world, invite your friends to join, and referee
          complex combat scenarios of many types.</p>

        <p>The weapon system is extensive, with everything from one on one combat using medieval weapons, realistic
          fire fights with historical and modern firearms, to space opera settings with a rich choice of energy
          weapons.</p>

        <p>All that is needed to create your first adventure is to give it a name, add some scenarios and
          adversaries, with as much or as little descriptive text and imagery as you feel appropriate.</p>

        <p>Once you’re ready, you can send invitations to your friends. If they haven’t yet signed up, you can
          invite them to join with a personalized e-mail message.</p>

        <p>
          You can read our <a href="/privacy">privacy policy here</a>.
        </p>

      </div>
    </>
  )
}