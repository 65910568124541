import React from 'react'
import {CloseButton, Modal} from 'react-bootstrap'
import {displayAlert} from '../actions/combatActions'
import EditEncounter from './EditEncounter'
import {useDispatch, useSelector} from 'react-redux'

export default function ModalEncounter({show, dismissModal, encounter}) {

  const dispatch = useDispatch()
  const alertMessage = useSelector(state => state.combatReducers.alertMessage)

  if (!encounter) {
    return null
  }

  return (<>
    <Modal show={show} onHide={dismissModal}>
      <Modal.Header>
        <Modal.Title>{encounter.name || 'New Encounter'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alertMessage && <div className='alert alert-danger alert-dismissible'>
          {alertMessage}
          <CloseButton data-dismiss="alert" aria-label="Close" onClick={() => dispatch(displayAlert(null))}/>
        </div>}
        <EditEncounter encounter={encounter} done={dismissModal}/>
      </Modal.Body>
    </Modal>
  </>)


}
