import {useEffect, useState} from 'react'

/**
 * Provide a getter and setter pair for a key/value pair in browser local storage
 * @param key
 * @param defaultValue
 * @returns {unknown[]}
 */
export function useLocalStorage(key, defaultValue = null) {
  const [value, setValue] = useState(() => {
    try {
      const saved = localStorage.getItem(key) || defaultValue
      if (saved !== null) {
        return JSON.parse(saved)
      }
    } catch {
      return defaultValue
    }
  })
  useEffect(() => {
    const rawValue = JSON.stringify(value)
    localStorage.setItem(key, rawValue)
  }, [key, value])

  return [value, setValue]
}

export function maxZIndex() {
  const draggables = [...document.getElementsByClassName('react-draggable')]
  const indexes = [...draggables.map(ea => +ea.style.zIndex)]
  return Math.max(...indexes)
}
