import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Breadcrumb, Button, Card, Container, FloatingLabel, Form, Modal, Nav, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router'
import {deleteScene, fetchAdventure, fetchScene, saveScene} from '../actions/combatActions'
import {useParams} from 'react-router-dom'
import PropTypes from 'prop-types'
import {Scene} from '../models/Scene'

export default function SceneDetails() {
  const {game_id, scene_id} = useParams()
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState('settings')
  const scene = useSelector(state => state.combatReducers.scene)
  const adventure = useSelector(state => state.combatReducers.adventure)

  useEffect(() => {
    dispatch(fetchAdventure(game_id))
    dispatch(fetchScene(game_id, scene_id))
  }, [game_id, scene_id, dispatch])

  function selectedComponent() {
    if (!scene) {
      return null
    }
    switch (selectedTab) {
      case 'settings':
        return <SceneSettings scene={scene} adventure={adventure}/>
      default:
        return null
    }
  }

  return (<>
    <Container>
      <Row>&nbsp;</Row>
      <Row>
        <NavigationTrail scene={scene} adventure={adventure}/>
      </Row>
      <Card>
        <Card.Header>
          <Nav variant="pills" defaultActiveKey="settings">
            <Nav.Item className='ad-settings-tab'>
              <Nav.Link eventKey='settings' onClick={() => setSelectedTab('settings')}>Settings</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body>
          {selectedComponent(selectedTab)}
        </Card.Body>
      </Card>
    </Container>
  </>)
}

function NavigationTrail({adventure, scene}) {
  const navigate = useNavigate()

  if (!adventure || !scene) {
    return null
  }

  return (<>
    <Breadcrumb>
      <Breadcrumb.Item onClick={() => navigate('/games')}>My Games</Breadcrumb.Item>
      <Breadcrumb.Item onClick={() => navigate(`/games/${adventure.id}`)}>{adventure.name}</Breadcrumb.Item>
      <Breadcrumb.Item active>{scene.name}</Breadcrumb.Item>
    </Breadcrumb>
  </>)
}

function SceneSettings({scene, adventure}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [name, setName] = useState()
  const [isEditing, setIsEditing] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  useEffect(() => {
    discardEdits(scene)
  }, [scene])

  function saveName() {
    dispatch(saveScene({...scene, name: name}))
    setIsEditing(false)
  }

  function discardEdits(scene) {
    if (!scene) {
      return
    }
    setName(scene.name)
    setIsEditing(false)
  }

  function handleKeyboard(event) {
    if (event.key === 'Escape') {
      setName(scene.name)
      setIsEditing(false)
    } else if (event.key === 'Enter') {
      saveName()
    }
  }

  function handleDelete() {
    dispatch(deleteScene(scene.id))
    navigate(`/games/${adventure.id}`)
  }

  return (<>
    <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Really delete this scene? <br/><br/>
        All rooms and treasure items will be permanently deleted,<br/>
        and there is no way to undo this action.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete It
        </Button>
      </Modal.Footer>
    </Modal>
    {isEditing ? <>
      <Form>
        <Button size='sm' variant='outline-primary' className='ad-edit-button'
                onClick={saveName}>Done</Button>
        <span className='ad-edit-button' style={{width: '0.5rem'}}>&nbsp;</span>
        <Button size='sm' variant='outline-secondary' className='ad-edit-button'
                onClick={discardEdits}>Cancel</Button>
        <div className='settings-name-edit'>
          <FloatingLabel controlId='floatingInput' label='Scene' className='mb-3'>
            <Form.Control
              placeholder='Scene'
              aria-label='Scene name'
              className='h2-edit'
              onChange={(ev) => setName(ev.target.value)}
              onKeyDown={handleKeyboard}
              autoFocus={true}
              value={name}/>
          </FloatingLabel>
        </div>
        <Button variant='danger' onClick={() => setConfirmDelete(true)}>Delete this Scene</Button>
      </Form>
    </> : <>
      <Button size='sm' className='ad-edit-button'
              onClick={() => setIsEditing(true)}>Edit</Button>
      <Card.Title><b>Scene:</b> {scene.name}</Card.Title>
    </>
    }
  </>)
}

SceneSettings.propTypes = {
  scene: PropTypes.instanceOf(Scene)
}