import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from 'react'
import {cancelPlayerInvite, fetchPlayers, fetchRoles, savePlayer} from '../actions/combatActions'
import {Button, Container, Form, Row, Stack} from 'react-bootstrap'
import PropTypes from 'prop-types'
import {Adventure} from '../models/Adventure'
import {Player} from '../models/Player'
import Tippy from '@tippyjs/react'
import {XCircle} from 'react-bootstrap-icons'

export default function PlayerList({adventure}) {
  const dispatch = useDispatch()
  const players = useSelector(state => state.combatReducers.players)
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (adventure) {
      dispatch(fetchPlayers(adventure.id))
      dispatch(fetchRoles(adventure.id))
    }
  }, [adventure])


  function handleSubmit(event) {
    event.preventDefault()
    // backend will validate the email address, savePlayer() should set alert message
    const player = new Player({game_id:adventure.id, email})
    dispatch(savePlayer(player))
    // clear input field
    setEmail('')
  }

  function cancelInvitation(player) {
    dispatch(cancelPlayerInvite(player))
  }

  function pendingRequests() {
    const filtered = players.filter(ea => ea.status === 'pending')
    return (<>
      <h3>Pending Invitations</h3>
      {filtered.map(ea => {
        return <div key={ea.id}>
          <span>{ea.name} ({ea.email})</span>
          <span>&nbsp;</span>
          <Tippy arrow={true} content='Cancel Invitation'>
            <Button style={{display:'contents'}} onClick={() => cancelInvitation(ea)}>
              <XCircle style={{color:'darkmagenta',height:16}}/>
            </Button>
          </Tippy>
        </div>
      })}
    </>)
  }

  function validatedPlayers() {
    const filtered = players.filter(ea => ea.status === 'verified')
    return (<>
      <h3>Verified Players</h3>
      {filtered.map(ea => {
        return <div key={ea.id}>{ea.name} ({ea.email})</div>
      })}
    </>)
  }

  return (<>
    <Container>
      <Row>&nbsp;</Row>
      <Row>
        <h2>Invite Players</h2>

        <p>Enter the email address of the player you would like to invite to your game.
          If they don't already have an account, they will be given an opportunity to create one.</p>

        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Stack direction='horizontal' gap={3}>
              <Form.Label>Email&nbsp;address</Form.Label>
              <Form.Control type='email' placeholder='Enter email' style={{width: 250}} value={email}
                            onChange={(event) => setEmail(event.target.value)}/>
              <Button variant='primary' type='submit' disabled={!email}>Submit</Button>
            </Stack>
          </Form.Group>
        </Form>
      </Row>

      <Row>
        <Row>&nbsp;</Row>
        {pendingRequests()}
      </Row>

      <Row>
        <Row>&nbsp;</Row>
        {validatedPlayers()}
      </Row>

    </Container>
  </>)
}

PlayerList.propTypes = {
  adventure: PropTypes.instanceOf(Adventure)
}
