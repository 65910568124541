import {useContext, useEffect, useState} from 'react'
import {GameSessionContext} from './GameSession'
import {Tooltip} from 'react-tippy'

export default function ConnectionStatus() {
  const session = useContext(GameSessionContext)
  const [indicator, setIndicator] = useState('disconnected')

  useEffect(() => {
    setIndicator(session.ready ? 'connected' : 'disconnected')
  }, [session])

  function reconnect() {
    if (!session.ready) {
      session.reconnect()
    }
  }

  return (<>
    <Tooltip title={session.ready ? 'Connected' : 'Click to reconnect'}>
      <div className={`ad-connect-status ${indicator}`} onClick={reconnect}/>
    </Tooltip>
  </>)
}