import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import {Combatant} from '../models/Combatant'
import {fetchSystem, fetchTrainingPackages, saveCombatant} from '../actions/combatActions'
import Select from 'react-select'
import axios from 'axios'

export default function AddTrainingPackage(props) {
  const {done, role} = props
  const dispatch = useDispatch()

  const [selectedTp, setSelectedTp] = useState([])
  const [tpAssignment, setTpAssignment] = useState([])
  const [selectedTpCost, setSelectedTpCost] = useState(0)
  const [tpChoices, setTpChoices] = useState()
  const [tp_cost, setTpCost] = useState({})
  const tps = useSelector(state => state.combatReducers.tps)
  const [skills, setSkills] = useState([])

  const system = useSelector(state => state.combatReducers.system)

  useEffect(() => {
    if (system) {
      setSkills(system.skills)
    }
  }, [system]);

  useEffect(() => {
    if (tps && tps.length === 0) {
      dispatch(fetchTrainingPackages())
    }
  }, [dispatch, tps])

  useEffect(() => {
    if (role) {
      dispatch(fetchSystem(role.system))
    }
  }, [role]);

  useEffect(() => {
    setTpChoices(tps.map(tp => {
      return {label:tp.name, value:tp}
    }))
  }, [tps])

  useEffect(() => {
    if (tpChoices) {
      setSelectedTp(tpChoices[0])
    }
  }, [tpChoices])

  useEffect(() => {
    if (selectedTp && selectedTp.value) {
      const tp = selectedTp.value
      setSelectedTpCost(tp_cost[tp.code])
      setTpAssignment(tp.content.map(row => {
        if (row.cat_id) {
          // fixed selection
          return {cat_id:row.cat_id, ranks:row.ranks}
        }
        else if (row.skill_id) {
          // fixed selection (but specialty might be a choice)
          return {skill_id:row.skill_id, ranks:row.ranks, specialty:row.specialty}
        }
        else if (row.cat_ids) {
          // user must select category (potentially more than one, but let's worry about that later)
          return {cat_id:null, ranks:row.ranks}
        }
        else {
          // otherwise it's a skill selection
          return {skill_id:null, ranks:row.ranks, specialty:null}
        }
      }))
    }
  }, [selectedTp, tp_cost])

  useEffect(() => {
    if (role && role.profession) {
      axios.get(`/app/skills/tp_cost/${role.profession}`)
          .then(response => {
            setTpCost(response.data)
          })
    }
  }, [role])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (selectedTp) {
      const new_role = new Combatant(role)
      const tp = selectedTp.value
      const selections = tp.content
      new_role.tp_assignments.push({...tp, selections})
      dispatch(saveCombatant(new_role))
    }
    done()
  }

  const updateTpAssignment = (selection, index) => {
    const new_assignment = tpAssignment.slice()
    new_assignment[index] =
    setTpAssignment(new_assignment)
  }

  function renderRows(tp, role) {
    if (!tp) {
      return null
    }

    return tp.content.map((row, index) => {
      const skill = row.skill_id && skills.find(ea => ea.id === row.skill_id)
      const cat = row.cat_id && role.skill_categories.find(ea => ea.id === row.cat_id)

      if (skill) {
        return <Row key={index}>
          <Col xs={1}>&nbsp;</Col>
          <Col xs={7}>{skill.label}</Col>
          <Col xs={2}>&nbsp;</Col>
          <Col xs={2}>{row.ranks}</Col>
        </Row>
      }
      else if (cat) {
        return <Form.Group key={index}>
          <Form.Label>{cat.category_label()} (ranks: {row.ranks > 0 ? row.ranks : 'n/a'})</Form.Label>
        </Form.Group>
      }
      else if (row.skill_or_cat === 'Choice' || row.skill_or_cat === 'Same') {
        // if the most recent category in the tp content is fixed, then we can just use that category
        // but if it was selected from a list of cat_ids, then we need to base the skill choices on that
        // either way, the category id should be embedded in the tpAssignment list
        let last_cat
        for (let i = index; i >= 0; --i) {
          if (tpAssignment[i] && tpAssignment[i].cat_id) {
            last_cat = role.skill_categories.find(ea => ea.id === tpAssignment[i].cat_id)
            break
          }
        }
        const choices = last_cat ? skills
          .filter(ea => ea.cat_id === last_cat.id)
          .map(skill => {
            return {label:skill.label, value:skill}
          }) : []
        return <Row key={index}>
          <Col xs={1}>&nbsp;</Col>
          <Col xs={7}>
            <Form.Select value={tpAssignment[index]}
                    placeholder='Select Skill...'
                    onChange={(selection) => updateTpAssignment(selection, index)}>
              {choices.map(option => <option key={option.id}>{option.label}</option>)}
            </Form.Select>
          </Col>
          <Col xs={2}>&nbsp;</Col>
          <Col xs={2}>{row.ranks}</Col>
        </Row>
      }
      else {
        const choices = row.cat_ids.map(cat_id => {
          const cat = role.skill_categories.find(ea => ea.id === cat_id)
          return {label: cat.label, value: cat}
        })
        return <Row key={index}>
          <Col xs={7}>
            <Form.Select value={tpAssignment[index]}
                    placeholder='Select Skill Category...'
                    onChange={(selection) => updateTpAssignment(selection, index)}>
              {choices.map(option => <option key={option.id}>{option.label}</option>)}
            </Form.Select>
          </Col>
          <Col xs={3}>&nbsp;</Col>
          <Col xs={2}>{row.ranks}</Col>
        </Row>
      }
    })
  }

  return (
      <div className='edit-skill'>
        <Form onSubmit={handleSubmit} onReset={() => done()}>
          <Row>
            <Col>
              <Form.Label>Package:</Form.Label>
            </Col>
            <Col xs='auto'>
              <Select value={[selectedTp]}
                      onChange={(selection) => setSelectedTp(selection)}
                      options={tpChoices}/>
            </Col>
            <Col>
              Cost: {selectedTpCost}
            </Col>
          </Row>
          <Row>
            <Col>Category or Skill</Col>
            <Col>&nbsp;</Col>
            <Col className='justify-content-end text-center'>Ranks</Col>
          </Row>
          <Row>&nbsp;</Row>

          {selectedTp && renderRows(selectedTp.value, role)}

          <Row>&nbsp;</Row>
          <Modal.Footer>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <Button variant='secondary' type='reset'>Cancel</Button>
            <Button variant='primary' type='submit'>Update</Button>
          </Modal.Footer>
        </Form>
      </div>
  )
}
