import React from 'react'
import CombatHUD from './CombatHUD'

export default function CombatWrapper(props) {
  const {show} = props

  if (!show) {
    return null
  }

  return (<>
    <CombatHUD {...props}/>
  </>)
}

