import React, {useEffect, useState} from 'react'
import {Button, Stack} from 'react-bootstrap'
import Select from 'react-select'
import {CheckCircle} from 'react-bootstrap-icons'
import remove_icon from '../images/minus-button-red.svg'

// "Falar (S7/W7); Species Standard (S10/W10); Human (S10/W10); Oort (S8/W8); Tulgaran (S7/W7)"
function parseLanguageChoice(choice) {
  const start = choice.indexOf('(')
  const end = choice.indexOf(')')
  const lang = start > 0 && choice.substring(0, start - 1).trim()
  const [sn, wn] = choice.substring(start + 1, end).split('/')
  const spoken = parseInt(sn.substring(1))
  const written = parseInt(wn.substring(1))
  return { choice, lang, spoken, written }
}

function parseLanguageChoices(race) {
  const languages = race.allowed_adolescent_languages
  if (!languages) {
    return []
  }
  return languages.split(';').map(parseLanguageChoice)
}

export default function AdolescentSkillAssignment({role, rank, system, race, category_selection}) {
  const {category, skill, skills, categories, ranks} = rank

  const [selected, setSelected] = useState({})
  const [skillChoices, setSkillChoices] = useState({})

  /**
   * Add the skill assignments for adolescent skill development.
   *
   * These will be specified as follows:
   * 1. {category: <string>, id: <number>, ranks: <number>}
   *    -- n category ranks, lookup by id
   * 2. {categories: <string>, ids:[<number>], ranks: <number>}
   *    -- n category ranks, lookup by ids (player/gm choice)
   * 3. {skill: <string>, id: <number>, ranks: <number>}
   *    -- n skill ranks, lookup by skill id
   * 4. {skills: <string>, id: [<number>], ranks: <number>}
   *    -- n skill ranks, look up by category id (player/gm choice)
   */
  useEffect(() => {
    const choices = filterSkills().map(ea => { return {...ea, value:ea.id} })
    setSkillChoices(choices)
    setSelected(choices[0])
  }, [role, rank]);

  function filterSkills() {
    if (skills === 'Weapon') {
      return system.skills.filter((item) => item.cat_id === category_selection.id)
    }
    else if (rank.ids) {
      if (categories) {
        return role.skill_categories.filter((item) => rank.ids.includes(item.id))
      }
      if (skills) {
        if (category_selection) {
          return system.skills.filter((item) => item.cat_id === category_selection.id)
        }
      }
    }
    return []
  }

  function removeAllocation(skill) {
    console.log('removeAllocation', skill)
  }

  function addAllocation() {
    console.log('addAllocation')
  }


  function skillSelection() {
    if (!category_selection || skillChoices.length === 0) {
      return null
    }

    const allocations = role.skills.filter(ea => ea.cat_id === category_selection.id)

    return (<>
      {/*<div>*/}
      {/*  <label style={{fontWeight: 600}}>Skill Selection:*/}
      {/*    <img src={add_icon}*/}
      {/*         alt='select skill'*/}
      {/*         onClick={selectSkill}/>*/}
      {/*  </label>*/}
      {/*</div>*/}
      <Stack direction='horizontal' className='justify-content-between'>
        <Select className='enc-select' value={selected} onChange={setSelected} options={skillChoices}/>
        <Button variant='success' className='cc-add-button' size='sm'
                onClick={addAllocation}>Add</Button>
      </Stack>
      {allocations.map((allocation, i) => {
        const skill = role.skills.find(ea => ea.id === allocation.id)
        return <div key={i}>
          <div className="enc-list-item">
            <img src={remove_icon}
                 alt='remove attack'
                 onClick={() => removeAllocation(skill)}/>
            <div>{skill.label}</div>
          </div>
        </div>
      })}
    </>)
  }

  const languageChoices = parseLanguageChoices(race)

  const spokenChoices = languageChoices
    .filter(ea => ea.spoken > 0)
    .map(ea => {return {label: ea.lang, value: ea.lang}})

  const writtenChoices = languageChoices
    .filter(ea => ea.written > 0)
    .map(ea => {return {label: ea.lang, value: ea.lang}})

  let label
  let allocated = 0
  let rankCount = ranks === 1 ? 'rank' : 'ranks'

  if (category) {
    label = `${category} skill category`
    allocated = ranks
  }
  if (categories) {
    label = `${categories} group`
    allocated = 0
  }
  else if (skill) {
    label = <div><div style={{width:'2em'}}></div>{skill} skill</div>
    allocated = ranks
  }
  else if (skills) {
    label = skills
    if (skills.match(/(Spoken|Written)/)) {
      label = <Stack gap={1}>
        <Stack direction='horizontal' gap={3}>
          Spoken:&nbsp;<Select options={spokenChoices}/>
          {/*<input type="number" onChange={spokenChanged} value={spoken} max={choice.spoken} min={0}/>*/}
        </Stack>
        <Stack direction='horizontal' gap={3}>
          Written:&nbsp;<Select options={writtenChoices}/>
          {/*<input type="number" onChange={writtenChanged} value={written} max={choice.written} min={0}/>*/}
        </Stack>
      </Stack>
    }
    allocated = 0
  }

  return (<>
    <Stack direction='horizontal' style={{justifyContent:'space-between'}}>
      <div>{label}</div>
      <div className='cc-menu-button'>
        <div style={{color: 'green'}}>
          {allocated} of {ranks} {rankCount} allocated &nbsp;
          {(allocated === ranks) ?  <CheckCircle/> : null}
        </div>
      </div>
    </Stack>
    {skillSelection()}
  </>)
}
