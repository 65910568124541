import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {fetchAdventure, setUserFromToken} from '../actions/combatActions'
import {useDispatch, useSelector} from 'react-redux'
import PlayerList from './PlayerList'
import {AUTH_TOKEN} from '../util/string_constants'

export default function PlayerInvite() {
  const {game_id} = useParams()
  const dispatch = useDispatch()

  const adventure = useSelector(state => state.combatReducers.adventure)

  useEffect(() => {
    dispatch(fetchAdventure(+game_id))
    const token = localStorage.getItem(AUTH_TOKEN)
    dispatch(setUserFromToken(token))
  }, [])

  return (<>
    <PlayerList adventure={adventure}/>
  </>)
}
