import React, {useState} from 'react'
import PlayerList from './PlayerList'
import AdventureSettings from './AdventureSettings'
import {Card, Nav} from 'react-bootstrap'

export default function AdventureCard({adventure, user}) {
  const [selectedTab, setSelectedTab] = useState('settings')

  function selectedComponent() {
    if (!adventure) {
      return null
    }
    switch (selectedTab) {
      case 'settings':
        return <AdventureSettings adventure={adventure} user={user}/>
      case 'players':
        return <PlayerList adventure={adventure}/>
      default:
        return null
    }
  }

  return (<>
    <Card>
      <Card.Header>
        <Nav variant="pills" defaultActiveKey="settings">
          <Nav.Item>
            <Nav.Link eventKey='settings' onClick={() => setSelectedTab('settings')}>Settings</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='players' onClick={() => setSelectedTab('players')}>Players</Nav.Link>
          </Nav.Item>
        </Nav>
      </Card.Header>
      <Card.Body>
        {selectedComponent(selectedTab)}
      </Card.Body>
    </Card>
  </>)
}
