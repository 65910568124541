import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'

export default function Privacy() {
  return (<Container>
    <Row>&nbsp;</Row>
    <Row>
      <h2>Privacy Policy</h2>
    </Row>
    <Row>
      <Col sm={{span: 9, offset: 1}}>
        <p>
          Spymaster is currently in a closed beta program. This website is a work in progress and
          our policies will be updated to respond to the changing needs of our users. We do not
          verify user identities, but neither do we guarantee anonymity.
        </p>

        <p>
          Please do not share sensitive information on this site. Any inappropriate content, including
          sexually explicit images, copyrighted materials, or any other image, text,
          audio or video files deemed to be offensive or illegal, may be deleted at our sole discretion.
          We may impose a permanent ban of offending users, with no warning or recourse.
        </p>

        <p>
          When using the Spymaster site, we may use the following information about you:
        </p>

        <ul>
          <li>Nicknames used for inter-player chat or user login</li>
          <li>E-mail address</li>
          <li>Name and version of the software you use to connect to this site</li>
          <li>Information about the character roles you create</li>
          <li>Data regarding our interactions with you</li>
          <li>We may also process any data you send when you use the network. This includes the content
            of any messages you send. We may log your network requests for testing purposes, and we will
            save any messages you send to us when you inquire about technical problems or data access.
          </li>
        </ul>

        <p>This data may be used for the following purposes:</p>

        <ul>
          <li>To improve and debug our applications.</li>
          <li>To manage your relationship with games you develop or participate in.</li>
          <li>Account management.</li>
          <li>Protection of our staff, users, and our services from abuse.</li>
          <li>To enable you to reset your password.</li>
          <li>To contact you regarding project registration, sponsorship, and game management.</li>
          <li>To respond to support inquiries by email or our support system.</li>
          <li>Compliance with valid legal processes and legislation.</li>
          <li>Where data is necessary to run our service, the Lawful Basis we use will generally be
            “Legitimate Interest” &mdash; the genuine need to process data on you while operating a
            service which you benefit from, and where you have chosen to use the service, in order to
            make it available to you and others. In rare instances where required by law to hold or
            process data, our lawful basis may be “Legal Obligation” or “Public Task”.
          </li>
        </ul>

        <p>We will generally not process data on you which is “Special Category”.</p>

        <p>The personal information you share may be logged by third parties, which is beyond our control.</p>

        <p>
          We will not share your data with third parties for marketing purposes or site analytics.
          In particular, we do not use third-party tracking cookies for analytics providers. We do not
          allow advertising of commercial services on this site.
        </p>

        <p>
          We store your data for the purpose of debugging and restoration after you last connect to the
          network. If you delete your account, all games, character data, image data, and activity associated
          with your account will be deleted permanently. You will be given an opportunity to export your
          data. Backups may be retained for up to 60 days.
        </p>

        <p>
          Data collected for the purposes of recording and mitigating abuse may be kept for an indefinite
          period, as required by law. The following information is covered by this policy:
        </p>

        <ul>
          <li>network bans (target, setter, reason, duration, timestamp, known aliases)</li>
          <li>connections affected by network bans (nickname, username, hostname, IP address, timestamp of effect)
          </li>
          <li>account registrations for the purposes of network policy violation (email address, nickname, username,
            hostname, IP address, timestamp, known aliases)
          </li>
          <li>Data collected by email or our support system in relation to project registration, sponsorship and
            game management will be stored at least until the game is deleted or the sponsorship
            is terminated.
          </li>
        </ul>

        <p>
          When using this site your data may be transferred to and/or processed outside of
          the country where you access the site. The server is located in the United States and
          can be accessed from anywhere in the world.
        </p>

        <p>
          We reserve the right to change this policy at any time, without notice. The currently active
          version can be found on this website.
        </p>
      </Col>
    </Row>
  </Container>)
}

