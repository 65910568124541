import React, {Fragment, useContext, useEffect, useReducer, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Select from 'react-select'
import axios from 'axios'
import {
  authHeaders,
  deleteCombatant,
  displayAlert,
  fetchRole,
  fetchSystem,
  setCombatReducer
} from '../actions/combatActions'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/combatant.css'
import remove_icon from '../images/minus-button-red.svg'
import add_icon from '../images/plus-button-green.svg'
import {Weapon} from '../models/Weapon'
import {Button, Card, Col, Dropdown, Form, Image, Row, Stack, Tab, Tabs} from 'react-bootstrap'
import {default as RMStats} from '../Rolemaster/components/CharacterStats'
import {default as CoCStats} from '../CoC/components/CharacterStats'
import Tippy from '@tippyjs/react'
import placeholder_person from '../images/placeholder_person.png'
import placeholder_monster from '../images/placeholder_monster.png'
import FileUpload from './FileUpload'
import {formatInt, parseAttack, speedModifiers} from '../util/rpg_utils'
import {InfoCircle} from 'react-bootstrap-icons'
import {GameSessionContext} from './GameSession'
import AdolescentSkillsPanel from './AdolescentSkillsPanel'

export default function EditCombatant({done, role}) {
  const weapons = useSelector(state => state.combatReducers.weapons)
  const system = useSelector(state => state.combatReducers.system)
  const teams = useSelector(state => state.combatReducers.teams)
  const players = useSelector(state => state.combatReducers.players)
  const auth_info = useSelector(state => state.combatReducers.auth_info)
  const uploadController = useSelector(state => state.combatReducers.uploadController)

  const [name, setName] = useState(role.name || '')
  const [type, setType] = useState(role.type || '')
  const [active, setActive] = useState(role.active)
  const [level, setLevel] = useState(role.level)
  const [at, setAT] = useState(role.at)
  const [profession, setProfession] = useState(role.profession)
  const [hits, setHits] = useState(role.hits)
  const [max_hits, setMaxHits] = useState(0)
  const [db, setDB] = useState(role.db)
  const [shield, setShield] = useState(role.shield)
  const [race, setRace] = useState(role.race || '')
  const [culture, setCulture] = useState(role.culture || '')
  const [gender, setGender] = useState(role.gender || '')
  const [team, setTeam] = useState(role.team)
  const [notes, setNotes] = useState(role.notes || '')
  const [attacks, setAttacks] = useState(role.attacks)
  const [user_id, setUserId] = useState(role.user_id)
  const [artwork, setArtwork] = useState(role.tokenArtwork)
  const [loadingImage, setLoadingImage] = useState(false)

  const [xp, setXP] = useState(role.xp)
  const [height, setHeight] = useState(role.height)
  const [width, setWidth] = useState(role.width)
  const [length, setLength] = useState(role.length)
  const [height_units, setHeightUnits] = useState(role.height_units)
  const [weight, setWeight] = useState(role.weight)
  const [weight_units, setWeightUnits] = useState(role.weight_units)
  const [bleeding, setBleeding] = useState(role.bleeding)
  const [stunned, setStunned] = useState(role.stunned)
  const [must_parry, setMustParry] = useState(role.must_parry)
  const [no_parry, setNoParry] = useState(role.no_parry)
  const [parry, setParry] = useState(role.parry)
  const [ob_temp, setObTemp] = useState(role.ob_temp || 0)
  const [dead, setDead] = useState(role.dead)
  const [paralyzed, setParalyzed] = useState(role.paralyzed)
  const [ob_modifiers, setObModifiers] = useState(role.ob_modifiers)
  const [db_modifiers, setDbModifiers] = useState(role.db_modifiers)
  const [crit_table, setCritTable] = useState('')
  const [bleed_proof, setBleedProof] = useState(false)
  const [stun_proof, setStunProof] = useState(false)
  const [crit_codes, setCritCodes] = useState(role.crit_codes || '')
  const [movement_speed, setMovementSpeed] = useState(role.movement_speed)
  const [attack_quickness, setAttackQuickness] = useState(role.attack_quickness)
  const [cultures, setCultures] = useState([])

  const session = useContext(GameSessionContext)

  const professions = system.professions || []

  const shieldChoices = (system?.shields || [])
    .map(shield => {
      return {label: shield.label, value: shield}
    })

  const weaponChoices = Object.keys(weapons)
    .map(id => {
      return {label: weapons[id].label, value: id}
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const criticalTableChoices = [
    {label: 'Normal', value: '-'},
    {label: 'Invulnerable I', value: 'I'},
    {label: 'Invulnerable II', value: 'II'},
    {label: 'Large', value: 'LA'},
    {label: 'Super Large', value: 'SL'},
    {label: 'Large Android', value: 'ALA'},
    {label: 'S/L Android', value: 'ASL'},
  ]

  function criticalTableInfo(code) {
    const ct_match = code?.match(/((LA)|(SL)|(ALA)|(ASL)|(II)|(I)).*/)
    if (ct_match) {
      switch (ct_match[1]) {
        case 'I':
          return `Decrease critical severity by one:
                    'A’ is modified by -20, ‘B’ becomes an ‘A’, ‘C’ becomes a ‘B’, etc.)`
        case 'II':
          return `Decrease critical severity by two:
                    'A’ is modified by -50, ‘B’ is modified by -20 on the ‘A’ column,
                    ‘C’ becomes an ‘A’, etc.)`
        case 'LA':
          return `Use Large Creature Critical Strike Table:
                    Only critical strikes of severity ‘B’, ‘C’, ‘D’, or ‘E’ affect large creatures
                    (Ignore ‘A’ severity criticals)`
        case 'SL':
          return `Use Super Large Creature Critical Strike Table:
                    Only ‘D’ or ‘E’ criticals affect super-large creatures (combat encounters
                    will ignore ‘A’, ‘B’, and ‘C’)`
        case 'ALA':
          return `Use Large Android Critical Strike Table:
            Only critical strikes of severity ‘B’, ‘C’, ‘D’, or ‘E’ affect large robots
          (Ignore ‘A’ severity criticals)`
        case 'ASL':
          return `Use Super Large Android Critical Strike Table'
                    Only ‘D’ or ‘E’ criticals affect super-large robots (combat encounters
                    will ignore ‘A’, ‘B’, and ‘C’)`
        case '@':
          return 'Stun results do not affect creature'
        case '#':
          return 'Stun results and bleeder do not affect creature'
        default:
          return 'Use normal critical procedure'
      }
    }
    return 'Special critical procedure: ' + code
  }

  /**
   *                          DB Modifications
   *                          (MS)    (MS)    (AQ)    (AQ)
   *                          Base    Flee    Charge  Initiative
   *                          DB      Evade   Lunge   Mod
   * IN Inching               -25       0       0     -16
   * CR Creeping              -20       0       0     -12
   * VS Very Slow             -10       0       0     -8
   * SL Slow                    0       0       0     -4
   * MD Medium                 10       5      -5     +0
   * MF Moderately Fast        20      10     -10     +4
   * FA Fast                   30      15     -15     +8
   * VF Very Fast              40      20     -20     +12
   * BF Blindingly Fast        50      25     -20     +16
   */

  function speedTableInfo(attribute, value) {
    const mods = speedModifiers.find(ea => ea.code === value)
    if (!mods) {
      return null
    }
    if (attribute === 'MS') {
      return (<>
        <h6>Bonuses/penalties</h6>
        <ul>
          <li>Base DB modification: {mods.db}</li>
          <li>Flee/Evade: {mods.evade}</li>
        </ul>
      </>)
    } else if (attribute === 'AQ') {
      return (<>
        <h6>Bonuses/penalties</h6>
        <ul>
          <li>Initiative modification: {mods.initiative}</li>
          <li>Charge/Lunge: {mods.charge}</li>
        </ul>
      </>)
    } else return null
  }

  const speedChoices = speedModifiers
    .map(mod => {
      return {value: mod.code, label: mod.rate}
    })

  const vehicleTypeChoices = [
    {label: 'Ground', value: 'Ground'},
    {label: 'Marine', value: 'Marine'},
    {label: 'Aircraft', value: 'Aircraft'},
    {label: 'Spacecraft', value: 'Spacecraft'},
  ]

  const genderChoices = [
    {label: 'Male', value: 'male'},
    {label: 'Female', value: 'female'},
    {label: 'Neutral', value: 'ungendered'},
  ]

  const allCultures = system.races.map(ea => ea.cultures).flat()
  const raceChoices = system.races.map(ea => ea.label)
    .filter(race => !allCultures.includes(race))
    .map(ea => {
      return {label: ea, value: ea}
    })

  const professionChoices = system.professions
    .map(ea => {
      return {label: ea.label, value: ea.label}
    })

  const attackItemChoices = (role) => {
    return role.items.filter(item => item.type === 'weapon')
      .map(item => {
        return {label: item.description, value: item.id}
      })
  }

  const characterATs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X']

  const constructionATs = ['XI', 'XII', 'XIII', 'XIV', 'XV', 'XVI', 'XVII', 'XVIII', 'XIX', 'XX']

  function reducer(state, action) {
    if (action.type === 'reset') {
      return role
    }
    const result = {...state}
    result[action.type] = action.value
    return result
  }

  const [state, setState] = useReducer(reducer, role.stats)

  function handleChange(event) {
    const {name, value} = event.target
    setState({type: name, value})
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSystem(role.system))
    dispatch(setCombatReducer('uploadProgress', 0))
  }, [])

  useEffect(() => {
    setMaxHits(system.maximum_hits(role))
  }, [system, role])

  useEffect(() => {
    if (race && system.races.length > 0) {
      setCultures(race['cultures'] || [])
      setCulture(null)
    }
  }, [race])

  useEffect(() => {
    if (!state.magic && (role.maximum_mps || role.maximum_pps)) {
      setState({type: 'magic', value: role.maximum_mps || role.maximum_pps})
    }
  }, [role, state])

  // parse critical codes
  // LA, SLA, ALA, ASL -- use appropriate critical charts for this defender
  // @ -- stun results do not affect this creature
  // # -- stun and bleed results to not affect this creature
  useEffect(() => {
    if (!crit_codes || crit_codes === '—') {
      setStunProof(false)
      setBleedProof(false)
      setCritTable('–')
      return
    }
    const ct_match = crit_codes.match(/((LA)|(SL)|(ALA)|(ASL)|(II)|(I)).*/)
    if (ct_match) {
      setCritTable(ct_match[1])
    }
    const st_match = crit_codes.match(/.*(@)/)
    if (st_match) {
      setStunProof(true)
    }
    const bl_match = crit_codes.match(/.*(#)/)
    if (bl_match) {
      setBleedProof(true)
      setStunProof(true)
    }
  }, [crit_codes])

  // update critical codes
  useEffect(() => {
    if (crit_table) {
      const bleed_stun = bleed_proof ? '#' : (stun_proof ? '@' : '')
      const codes = (crit_table || '') + bleed_stun
      setCritCodes(codes)
    }
  }, [crit_table, bleed_proof, stun_proof])

  const critTableLabel = (code) => {
    const lookup = criticalTableChoices.find(ea => ea.value === code)
    return (lookup && lookup.label) || 'Normal'
  }

  const handleReset = () => done()

  const handleSubmit = async (event) => {
    event.preventDefault()
    const role_id = await doSave(role)
    done(role_id)
  }

  async function doSave(role) {
    const formData = new FormData()
    const {id, campaign_id} = role
    const gameSession = session.gameSession(campaign_id)
    const encounter = gameSession?.adventure?.encounter
    const tokens = encounter?.tokens || []
    const index = tokens.findIndex(ea => ea.role_id === id)
    if (index >= 0) {
      const data = {...tokens[index].data, bleeding, hits, max_hits, db, stunned, must_parry, no_parry, paralyzed, dead}
      const token = {...tokens[index], name, data, imageURL: artwork?.path}
      tokens[index] = token
      session.sendMessage({type: 'encounter', encounter: {...encounter, tokens}})
    }
    const stats = {...state}
    const method = id ? axios.put : axios.post
    formData.append('role',
      JSON.stringify({
        id, name, level, user_id, at, profession, hits, max_hits, db, shield, race, attacks, team, type, active,
        xp, height, height_units, weight, weight_units, bleeding, stunned, must_parry, no_parry, parry,
        dead, paralyzed, ob_modifiers, db_modifiers, crit_codes, movement_speed, attack_quickness, stats,
        culture, system: system.label, gender, width, length, notes
      }))

    try {
      const res = await method('/app/roles/', formData, authHeaders())
      const role_id = id || res.data.id
      dispatch(fetchRole(role_id))

      if (artwork) {
        const imageFormData = new FormData()
        imageFormData.append('artwork', JSON.stringify(artwork))
        await axios.put(`/app/roles/${role_id}/image?type=token`, imageFormData, authHeaders())
      }
      return role_id
    } catch (err) {
      console.error(err)
      return null
    }
  }

  const handleDelete = (id) => {
    axios.delete(`/app/roles/${id}`, authHeaders())
      .then(_ => {
        done()
        dispatch(deleteCombatant(id))
      })
      .catch(console.error)
  }

  const addAttack = () => {
    const slice = attacks.concat({weapon_id: 0, label: 'Unarmed', bonus: 0})
    setAttacks(slice)
  }

  const removeAttack = (i) => {
    const slice = attacks.slice()
    slice.splice(i, 1)
    setAttacks(slice)
  }

  const setAttackBonus = (value, attack) => {
    const slice = attacks.slice()
    attack.bonus = value
    setAttacks(slice)
  }

  const setAttackWeapon = (attack, weapon_id) => {
    attack.weapon_id = weapon_id
    const weapon = weapons[weapon_id]
    attack.label = weapon.label
    const skill = role.skills.find(skill => skill.category.label === weapon.category)
    if (skill) {
      attack.bonus = system.skill_bonus(role, skill)
      attack.skill_id = skill.category.id
    }
    delete attack.type
    setAttacks(attacks.slice())
  }

  const setAttackSize = (attack, size) => {
    attack.size = size
    setAttacks(attacks.slice())
  }

  const setAttackItem = (attack, item_id) => {
    attack.item_id = item_id
    setAttacks(attacks.slice())
  }

  const setAttackMode = (attack, mode) => {
    attack.mode = mode
    setAttacks(attacks.slice())
  }

  const characterDetails = () => {
    return <>
      <Form.Group>
        <Form.Label column>Occupation: </Form.Label>
        <Select className='add-template-select'
                value={{label: profession, value: profession}}
                onChange={(event) => setProfession(event.label)}
                options={professions.map(ea => {
                  return {label: ea.label, value: ea.label}
                })}/>
      </Form.Group>
      <Form.Group>
        <Form.Label column>Race: </Form.Label>
        <Select className='add-template-select'
                value={{label: race, value: race}}
                onChange={(event) => setRace(event.label)}
                options={raceChoices}/>
      </Form.Group>
    </>
  }

  function parseMonster(eventKey) {
    const monsterType = system.monsters.find(ea => ea.label === eventKey)
    const {imageURL, label, hits, at_db, speed_aq, size_crit, attack} = monsterType
    console.log(monsterType)
    const attack_data = parseAttack(attack)
    console.log(attack_data)

    const sizeChoices = {
      'T': 'Tiny',
      'S': 'Small',
      'M': 'Medium',
      'L': 'Large',
      'H': 'Huge',
    }

    const elementChoices = {
      'F': 'Fire',
      'I': 'Ice',
      'L': 'Lightning',
      'S': 'Shock',
      'W': 'Water',
      'G': 'Gas',
      'C': 'Cold',
      'P': 'Plasma',
      'D': 'Lightning' // Dark Bolt = Lightning w/ Cold Criticals
    }

    const attacks = attack_data.attacks.map(attack => {
      const size = sizeChoices[attack.size]
      try {
        const {non_weapon, element, shape, weapon} = attack
        if (non_weapon) {
          const chart = Object.values(weapons).find(ea => ea.code === non_weapon)
          return {...attack, weapon_id: chart.id, label: chart.label, size}
        } else if (element) {
          const elementLabel = elementChoices[element]
          const shapeLabel = (shape === 'Br' && ['C', 'G'].includes(element)) ? 'Ball' : 'Bolt'
          const weaponLabel = (element === 'P') ? 'Assault Plasma' : `${elementLabel} ${shapeLabel}`
          const chart = Object.values(weapons).find(ea => ea.label === weaponLabel)
          return {weapon_id: chart.id, label: chart.label, size, ...attack}
        } else if (weapon) {
          const chart = Object.values(weapons).find(ea => ea.code === weapon)
          if (weapon === 'We') {
            return {...attack, weapon_id: null, label: 'Weapon'}
          }
          if (weapon === 'ro') {
            return {...attack, weapon_id: null, label: 'Large Rock', size}
          }
          return {...attack, weapon_id: chart.id, label: chart.label, size}
        }
      } catch (err) {
        console.warn(`Unable to parse ${JSON.stringify(attack)}`, err)
      }
      return {weapon_id: 0, label: attack.type, size, ...attack}
    })

    setAttacks(attacks)

    const [ms, aq] = speed_aq.split('/')
    setMovementSpeed(ms)
    setAttackQuickness(aq)

    const at_db_match = at_db.match(/(\d+)\s*\((\d+)(\*?)\)/)
    if (at_db_match) {
      setAT(+at_db_match[1])
      setDB(+at_db_match[2])
    }
    const hits_match = hits.match(/(\d+)([A-Z]?)/)
    // character following hits is used to generate a random range
    if (hits_match) {
      setHits(+hits_match[1])
      setMaxHits(+hits_match[1])
    }
    const [size, crit_codes] = size_crit.split('/')
    setCritCodes(crit_codes)

    setName(label)
    // TODO we need monster size, and image width and height to scale to encounter canvas
    setArtwork({path: imageURL})
  }

  function updateTokenImage(response) {
    const {path, width, height, name} = response.data
    console.log('updateTokenImage', width, height, name)
    setArtwork({width, height, path})
    const formData = new FormData()
    formData.append('artwork', JSON.stringify(artwork))
    axios.put(`/app/roles/${role.id}/image?type=token`, formData, authHeaders())
      .catch(console.error)
  }

  const monsterDetails = () => {
    return <>
      <div className="modal-input">
        <label>Name:</label>
        <input type="text"
               style={{width: 180, textAlign: 'left'}}
               onChange={(event) => setName(event.target.value)}
               autoFocus={!name}
               value={name}/>
        <Stack direction='horizontal' style={{gridColumnStart: 4}} gap={2}>
          <Dropdown onSelect={parseMonster}>
            <Dropdown.Toggle id='monster-type-menu' size='sm' variant='secondary'/>
            <Dropdown.Menu as={FilterMenu} className='ec-monster-menu'>
              {system.monsters.map(monsterType => {
                const {label} = monsterType
                return <Dropdown.Item key={label} eventKey={label}>{label}</Dropdown.Item>
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
      </div>
      <div className="modal-input">
        <div style={{width: 170, gridColumnStart: 1, paddingLeft: 56}}>
          <Image rounded style={{marginTop: 10}}
                 src={artwork?.path || placeholder_monster} alt={name} height={170}/>
          <Card.ImgOverlay className='fu-overlay'>
            <FileUpload game_id={role.campaign_id} onCompletion={updateTokenImage}/>
          </Card.ImgOverlay>
        </div>
        <label style={{gridColumnStart: 5}}>Movement: </label>
        <div>
          <Select className='add-template-select'
                  value={speedChoices.find(ea => ea.value === movement_speed)}
                  onChange={(event) => setMovementSpeed(event.value)}
                  options={speedChoices}/>
        </div>
        <Tippy content={speedTableInfo('MS', movement_speed)}>
          <InfoCircle className='cc-info-button'/>
        </Tippy>
      </div>
      <div className="modal-input">
        <label style={{gridColumnStart: 5}}>Attack: </label>
        <Select className='add-template-select'
                value={speedChoices.find(ea => ea.value === attack_quickness)}
                onChange={(event) => setAttackQuickness(event.value)}
                options={speedChoices}/>
        <Tippy content={speedTableInfo('AQ', attack_quickness)}>
          <InfoCircle className='cc-info-button'/>
        </Tippy>
      </div>
      <div className='modal-input'>
        <label style={{gridColumnStart: 5}}>Criticals: </label>
        <Select className='add-template-select'
                style={{textAlign: 'center', gridColumnStart: 6}}
                value={{label: critTableLabel(crit_table), value: crit_table}}
                onChange={(event) => setCritTable(event.value)}
                options={criticalTableChoices.map(ea => {
                  return {label: ea.label, value: ea.value}
                })}/>
        <Tippy content={criticalTableInfo(crit_codes)}>
          <InfoCircle className='cc-info-button'/>
        </Tippy>
      </div>
      <div>&nbsp;</div>
      <div className="modal-input">
        <div style={{gridColumnStart: 5, gridColumnEnd: 7}}>
          <Form.Check
            checked={stun_proof}
            disabled={bleed_proof}
            type='checkbox'
            id='id_stun_proof'
            label='Stun Proof'
            onChange={() => setStunProof(!stun_proof)}
          />
          <Form.Check
            checked={bleed_proof}
            type='checkbox'
            id='id_bleed_proof'
            label='Bleed and Stun Proof'
            onChange={() => setBleedProof(!bleed_proof)}
          />
        </div>
      </div>
    </>
  }

  const vehicleDetails = () => {
    return <>
      <div className="modal-input">
        <label>DB: </label>
        <input type="number"
               onChange={(event) => setDB(+event.target.value)}
               value={db}/>
        <label>Mode: </label>
        <Select className='add-template-select'
                value={{label: profession, value: profession}}
                onChange={(event) => setProfession(event.label)}
                options={vehicleTypeChoices}/>
        <label>Unit: </label>
        <Select className='add-template-select'
                value={{label: team, value: team}}
                onChange={(event) => setTeam(event.label)}
                options={teams.map(ea => {
                  return {label: ea.label, value: ea.label}
                })}/>
      </div>
      <div className="modal-input">
        <label>CAT: </label>
        <Select className='add-template-select'
                style={{textAlign: 'center'}}
                value={{label: at, value: at}}
                onChange={(event) => setAT(event.label)}
                options={constructionATs.map(ea => {
                  return {label: ea, value: ea}
                })
                }/>
        <input type="text" readOnly className='stat-at-label' value={system.armorTypes[at].label}/>
      </div>
    </>
  }

  const roleDetails = () => {
    switch (type) {
      case 'NPC':
      case 'PC':
        return characterDetails()
      case 'Monster':
        return monsterDetails()
      case 'Vehicle':
        return vehicleDetails()
      default:
        return <></>
    }
  }

  const ammoChoices = (attack) => {
    const weapon = weapons[attack.weapon_id]

    // energy weapons
    if (weapon.type === 'Energy') {
      const modeChoices = Weapon.energy_attack_modes
      const mode = modeChoices.find(ea => ea.value === attack.mode) || modeChoices[0]
      return <>
        <label>Mode: </label>
        <Select className='add-template-select'
                value={{label: mode.label, value: attack}}
                onChange={(event) => setAttackMode(attack, event.value)}
                options={modeChoices}/>
      </>
    }
    else if (weapon.type === 'Firearm') {
      const modeChoices = Weapon.ballistic_ammo_types
      const mode = modeChoices.find(ea => ea.value === attack.mode) || modeChoices[0]
      return <>
        <label>Ammo: </label>
        <Select className='add-template-select'
                value={{label: mode.label, value: attack}}
                onChange={(event) => setAttackMode(attack, event.value)}
                options={modeChoices}/>
      </>
    }
  }

  const attackSizeRestrictions = (attack) => {
    const weapon = weapons[attack.weapon_id]
    const limit = weapon && weapon.attack_limit
    const sizeChoices = Weapon.attack_limits[limit]

    // special AL attacks have max_s, max_m, max_l & max_h specified
    return (limit && <>
      <label>{limit}: </label>
      <Select className='add-template-select'
              value={{label: attack.size, value: attack}}
              onChange={(event) => setAttackSize(attack, event.label)}
              options={sizeChoices}/>
    </>)
  }

  const itemSelection = (attack) => {
    if (attack.non_weapon) {
      return null
    }

    const item = role.items.find(item => item.id === attack.item_id)
    return (<>
      <label>Item: </label>
      <Select className='add-template-select'
              value={item && {label: item.description, value: item.id}}
              onChange={(event) => setAttackItem(attack, event.value)}
              options={attackItemChoices(role)}/>
    </>)
  }

  function extraAttackInfo(attack) {
    const {
      damage_multiply,
      per_round,
      critical,
      additional_critical,
      next_round,
      same_round,
      special,
      group_size,
      probability
    } = attack

    let info = []
    if (damage_multiply) info.push(`Damage multiply x ${damage_multiply}`)
    if (per_round) info.push(`Attacks ${per_round} times per round`)
    if (critical) info.push(`Use ${critical} critical chart`)
    if (additional_critical) info.push(`Additional ${additional_critical} critical`)
    if (next_round) info.push(`Will use this attack next round if a non-Tiny critical is obtained by the previous attack`)
    if (same_round) info.push(`Will use this attack in the same round if a non-Tiny critical is obtained by the previous attack`)
    if (special) info.push(`Special: refer to Creatures & Treasures book or use your imagination`)
    if (group_size) info.push(`Use this attack if ${group_size} or more creatures are present`)
    if (probability) info.push(`Probability of this attack is ${probability}%`)

    if (info.length === 0) {
      return null
    }

    const content = <>
      <h6>Additional Information</h6>
      <ul>{info.map((str, i) => <li key={i}>{str}</li>)}</ul>
    </>

    return (<>
      <Tippy content={content}>
        <InfoCircle className='cc-info-button'/>
      </Tippy>
    </>)
  }

  const modeChoices = (attack) => {
    const weapon = attack.weapon_id && weapons[attack.weapon_id]
    const limit = weapon && weapon.attack_limit
    if (limit) {
      return attackSizeRestrictions(attack)
    }
    else if (weapon.type === 'Firearm' || weapon.type === 'Energy') {
      return ammoChoices(attack)
    }
    else {
      return itemSelection(attack)
    }
  }

  const attackDetails = () => {
    return (<>
      <Form.Group>
        <Form.Label column style={{fontWeight: 600}}>Attacks:
          <img src={add_icon} alt='add attack' onClick={addAttack}/>
        </Form.Label>
      </Form.Group>
      {attacks.map((attack, i) => {

        if (attack.both) {
          const probability = attack.probability || 100
          return <>
            <Row key={i}>
                <span style={{gridColumnStart: 2, gridColumnEnd: 7, color: 'darkred'}}>
                Both of the above attacks can be made in the same round ({probability}%)
                </span>
            </Row>
          </>
        }

        return <Fragment key={i}>
          <Row className="modal-input">
            <label>
              <img src={remove_icon}
                   alt='remove attack'
                   onClick={() => removeAttack(i)}
              /> OB:
            </label>
            <input type="number"
                   onChange={(event) => {
                     setAttackBonus(+event.target.value, attacks[i])
                   }}
                   value={attack.bonus}/>
            <label>Weapon: </label>
            <Select className='add-template-select'
                    value={{label: attack.label, value: attack}}
                    onChange={(event) => setAttackWeapon(attacks[i], +event.value)}
                    options={weaponChoices}/>
            {modeChoices(attack)}
            {extraAttackInfo(attack)}
          </Row>
        </Fragment>
      })}
    </>)
  }

  function playerChanged(selection) {
    setType(selection.value === auth_info.id ? 'NPC' : 'PC')
    setUserId(selection.value)
  }

  const profilePage = () => {
    const player = players.find(ea => ea.id === user_id)
    const playerChoices = [{label: 'NPC', value: -1}]
      .concat(players.map(ea => { return {label: ea.name, value: ea.id} }))

    const playerValue = player && {label: player.name, value: player.id}
    return (<>
      <Stack direction='horizontal' gap={3}>
        <Stack>
          <Image rounded style={{marginTop: 10, objectFit: 'contain'}}
                 src={artwork?.path || placeholder_person} alt={name} height={170}/>
          <FileUpload game_id={role.campaign_id} onCompletion={updateTokenImage}/>
          <Form.Group>
            <Form.Label column>Notes: </Form.Label>
            <Form.Control as="textarea" rows={3} value={notes}
                          onChange={(event) => setNotes(event.target.value)}/>
          </Form.Group>
        </Stack>
        <Stack>
          <Form.Group>
            <Form.Label column>Name: </Form.Label>
            <Form.Control type="text" autoFocus={!name} value={name}
                          onChange={(event) => setName(event.target.value)}/>
          </Form.Group>
          <Form.Group>
            <Form.Label column>Player: </Form.Label>
            <Select className='add-template-select'
                    value={playerValue}
                    onChange={playerChanged}
                    options={playerChoices}/>
          </Form.Group>
          {roleDetails()}
        </Stack>
      </Stack>
      {attackDetails()}
    </>)
  }

  const weightUnitChoices = ['kg', 'g', 'lbs'].map(ea => {
    return {label: ea, value: ea}
  })

  const heightUnitChoices = ['cm', 'm', 'in', 'ft'].map(ea => {
    return {label: ea, value: ea}
  })

  const descriptiveTextStyle = {
    fontSize: 12,
    color: 'darkslategray',
    marginTop: '6pt'
  }

  const sizePage = () => {
    return (<>
      <div className='modal-input' style={{grid: '40px / repeat(4, 70pt) 140pt', gap: 4}}>
        <label>Size Units</label>
        <Select className='add-template-select'
                value={[{label: height_units, value: height_units}]}
                onChange={(sel) => setHeightUnits(sel.value)}
                options={heightUnitChoices}/>
        <label style={{gridColumnStart: 3}}>Height</label>
        <input type="number"
               style={{width: '70pt'}}
               onChange={(event) => setHeight(+event.target.value)}
               value={height}/>
        <span style={descriptiveTextStyle}>Height of humanoid PC or NPC</span>
        <label style={{gridColumnStart: 3}}>Width</label>
        <input type="number"
               style={{width: '70pt'}}
               onChange={(event) => setWidth(+event.target.value)}
               value={width}/>
        <span style={descriptiveTextStyle}>Breadth at shoulders</span>
        <label style={{gridColumnStart: 3}}>Length</label>
        <input type="number"
               style={{width: '70pt'}}
               onChange={(event) => setLength(+event.target.value)}
               value={length}/>
        <span style={descriptiveTextStyle}>Length of beast, including tail</span>
        <hr style={{gridColumnStart: 1, gridColumnEnd: 6}}/>
        <label style={{gridColumnStart: 1}}>Weight: </label>
        <input type="number"
               style={{width: '70pt'}}
               onChange={(event) => setWeight(+event.target.value)}
               value={weight}/>
        <Select className='add-template-select'
                value={[{label: weight_units, value: weight_units}]}
                onChange={(sel) => setWeightUnits(sel.value)}
                options={weightUnitChoices}/>
      </div>
    </>)
  }

  const combatPage = () => {
    // Display Mind points / Power points only if skills are present
    const isPsychic = role.maximum_mps > 0
    const isMagic = role.maximum_pps > 0

    return (<>
      <div className='modal-input'>
        <label>Hits: </label>
        <input type="number"
               onChange={(event) => setHits(event.target.value)}
               value={hits}/>
        <label>Max Hits: </label>
        {role.body_development ?
          <Tippy content='Body Development skill bonus'>
            <input type="number" readOnly={true} value={system.maximum_hits(role)}/>
          </Tippy> :
          <input type="number" name='max_hits'
                 onChange={(event) => setMaxHits(event.target.value)}
                 value={max_hits}/>
        }
        <label>Armor: </label>
        <Select className='add-template-select'
                style={{textAlign: 'center'}}
                value={{label: at, value: at}}
                onChange={(event) => setAT(event.label)}
                options={characterATs.map(ea => {
                  return {label: ea, value: ea}
                })}/>
      </div>
      <div className='modal-input'>
        <label>DB: </label>
        <input type="number"
               onChange={(event) => setDB(+event.target.value)}
               value={db}/>
        <label>Parry: </label>
        <input type="number"
               onChange={(event) => setParry(+event.target.value)}
               value={parry}/>
        <label>Shield: </label>
        <Select className='add-template-select'
                value={{label: shield, value: shield}}
                onChange={(event) => setShield(event.label)}
                options={shieldChoices}/>
      </div>
      {isPsychic && <div className="modal-input">
        <label>MPs: </label>
        <input type="number" name='magic'
               onChange={handleChange}
               value={state.magic}/>
        <label>Max MPs: </label>
        <Tippy content='Mind Point Development skill bonus'>
          <input type="number" readOnly={true}
                 value={system.maximum_mps(role)}/>
        </Tippy>
      </div>}
      {isMagic && <div className="modal-input">
        <label>PPs: </label>
        <input type="number" name='magic'
               onChange={handleChange}
               value={state.magic}/>
        <label>Max PPs: </label>
        <Tippy content='Power Point Development skill bonus'>
          <input type="number" readOnly={true}
                 value={system.maximum_pps(role)}/>
        </Tippy>
      </div>}
      <div className="modal-input">
        <label>Bleeding: </label>
        <input type="number"
               onChange={(event) => setBleeding(event.target.value)}
               value={bleeding}/>
        <label>Temp OB: </label>
        <input type="number"
               onChange={(event) => setObTemp(+event.target.value)}
               value={ob_temp}/>
        <label style={{gridColumnStart: 5}}>Unit: </label>
        <Select className='add-template-select'
                value={{label: team, value: team}}
                onChange={(event) => setTeam(event.label)}
                options={teams.map(ea => {
                  return {label: ea.label, value: ea.label}
                })}/>
      </div>
      <div className="modal-input">
        <label>Stunned: </label>
        <input type="number"
               onChange={(event) => setStunned(event.target.value)}
               value={stunned}/>
      </div>
      <div className="modal-input input-combo">
        <label>No Parry: </label>
        <input type="number"
               onChange={(event) => setNoParry(event.target.value)}
               value={no_parry}/>
      </div>
      <div className="modal-input input-combo">
        <label>Must&nbsp;Parry: </label>
        <input type="number"
               onChange={(event) => setMustParry(event.target.value)}
               value={must_parry}/>
      </div>
      <div className="modal-input">
        <label>Paralyzed: </label>
        <input type="number"
               onChange={(event) => setParalyzed(event.target.value)}
               value={paralyzed}/>
      </div>
      <div className="modal-input">
        <label>Dead: </label>
        <input type="checkbox"
               onChange={(event) => setDead(event.target.checked)}
               checked={dead}/>
        <label>Active: </label>
        <input type="checkbox"
               onChange={(event) => setActive(event.target.checked)}
               checked={active}/>
      </div>
    </>)
  }

  function statsPage() {
    return (<>
      {(role.system === 'Rolemaster' || role.system === 'Privateers') &&
        <RMStats role={role} profession={profession} state={state} setState={setState}/>}
      {role.system === 'CoC' && <CoCStats role={role} state={state} setState={setState}/>}
    </>)
  }

  function editCharacter() {
    return (
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <Tabs defaultActiveKey='profilePage' className="mb-3">
          <Tab eventKey="profilePage" title="Profile">
            {profilePage()}
          </Tab>
          <Tab eventKey="combatPage" title="Combat">
            {combatPage()}
          </Tab>
          <Tab eventKey="statsPage" title="Stats">
            {statsPage()}
          </Tab>
          <Tab eventKey="sizePage" title="Size">
            {sizePage()}
          </Tab>
        </Tabs>
        <div className="stat-modal-footer">
          {(role.id && <Button onClick={() => handleDelete(role.id)} variant='danger'>Delete</Button>) ||
            <div>&nbsp;</div>}
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <Button variant="secondary" type='reset'>Cancel</Button>
          <Button variant="primary" type='submit' disabled={!name || uploadController}>Save</Button>
          {/*<Button variant="secondary" onClick={() => handleDuplicate(role)}>Save As...</Button>*/}
        </div>
      </Form>)
  }

  function newMonster() {
    return (
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <Tabs defaultActiveKey='profilePage' className="mb-3">
          <Tab eventKey="profilePage" title="Profile">
            {monsterDetails()}
            {attackDetails()}
          </Tab>
          <Tab eventKey="combatPage" title="Combat">
            {combatPage()}
          </Tab>
          <Tab eventKey="sizePage" title="Size">
            {sizePage()}
          </Tab>
        </Tabs>
        <div className="stat-modal-footer">
          {(role.id && <Button onClick={() => handleDelete(role.id)} variant='danger'>Delete</Button>) ||
            <div>&nbsp;</div>}
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <Button variant="secondary" type='reset'>Cancel</Button>
          <Button variant="primary" type='submit' disabled={!name}>Save</Button>
          {/*<Button variant="secondary" onClick={() => handleDuplicate(role)}>Save As...</Button>*/}
        </div>
      </Form>)
  }

  function raceBonuses() {
    const r = system?.races.find(ea => ea.label === race)
    return (<>
      <Col md={6}>
        <h6>Stat Bonuses</h6>
        <Row>
          <Col md={1}/>
          <Col>
            <Row>
              Ag: {formatInt(r.ag)} Co: {formatInt(r.co)} Me: {formatInt(r.me)} Re: {formatInt(r.re)} SD: {formatInt(r.sd)}
            </Row>
            <Row>
              Em: {formatInt(r.ag)} In: {formatInt(r.co)} Pr: {formatInt(r.me)} Qu: {formatInt(r.re)} St: {formatInt(r.sd)}
            </Row>
          </Col>
        </Row>

        <Row>&nbsp;</Row>
        <h6>Resistance Roll Modifiers</h6>
        <Row>
          <Col md={1}/>
          <Col>
            <Row>
              poison: {formatInt(r.poison)} disease: {formatInt(r.disease)} fear: {formatInt(r.fear)} psychic: {formatInt(r.psychic)}
            </Row>
          </Col>
        </Row>

        <Row>&nbsp;</Row>
        <h6>Body Dev Progression</h6>
        <Row>
          <Col md={1}/>
          <Col>
            <Row>
              {r.bd_prog.join('•')}
            </Row>
          </Col>
        </Row>

        <Row>&nbsp;</Row>
        <h6>Mind Point Progression</h6>
        <Row>
          <Col md={1}/>
          <Col>
            <Row>
              {r.mp_prog.join('•')}
            </Row>
          </Col>
        </Row>
      </Col>
    </>)
  }

  function stage() {
    return state['stage'] || 0
  }

  function setStage(value) {
    setState({type: 'stage', value})
  }

  const newCharacterStageCount = 3

  function newCharacterStage() {
    switch (stage()) {
      case 0:
        return profilePage()
      case 1:
        return statsPage()
      case 2:
        return <AdolescentSkillsPanel role={role} system={system} race={race}/>
      default: {
        console.error('invalid stage', stage())
        setStage(1)
        return profilePage()
      }
    }
  }

  async function handleNextStage() {
    setStage(stage() + 1)
  }

  async function handlePreviousStage() {
    setStage(stage() - 1)
  }

  function newCharacter() {
    let stage = state['stage'] || 0
    return (<>
      {newCharacterStage()}
      <Row>
        <div className="stat-modal-footer">
          {(role.id && <Button onClick={() => handleDelete(role.id)} variant='danger'>Delete</Button>) ||
            <div>&nbsp;</div>}
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          {
            (stage > 0) ?
              <Button onClick={handlePreviousStage}>{'<< Back'}</Button> :
              <Button variant="secondary" onClick={done}>Cancel</Button>
          }
          {
            (stage < newCharacterStageCount - 1) ?
              <Button disabled={!enable_next} onClick={handleNextStage}>Next >> </Button> :
              <Button disabled={!enable_next} onClick={handleSubmit}>Done</Button>
          }
        </div>
      </Row>
    </>)
  }

  function generateName() {
    let {r, g, c, p} = {r: race, g: gender, c: culture, p: profession}
    axios.get(`/app/generate/name?culture=${c || r}&gender=${g}&occupation=${p}`, authHeaders())
      .then(response => {
        let n = response.data.choices[0]?.text.trim()
        setName(n)
      })
  }

  function generateImage(name, race, culture, profession) {
    if (loadingImage) {
      return
    }
    setLoadingImage(true)
    const era = system.label === 'Privateers' ? 'space-age' : 'middle-earth'
    const raceLookup = system.races.find(ea => ea.label === race)
    const description = raceLookup?.description || ''
    const cult = (culture || race || '').replaceAll('Man', 'Human')
    axios.get(`/app/generate/image?description=${description}&culture=${cult}&era=${era}&gender=${gender}&occupation=${profession}`, authHeaders())
      .then(response => {
        const {data} = response.data
        setArtwork(data[0].url)
        setLoadingImage(false)
      })
      .catch(error => {
        dispatch(displayAlert(error.message))
        setLoadingImage(false)
      })
  }

  useEffect(() => {
    const raceLookup = system.races.find(ea => ea.label === race)
    setCultures(raceLookup?.cultures || [])
  }, [race])

  const enable_next = name && race && (culture || cultures.length === 0)

  if (role.type === 'Monster') {
    return newMonster()
  } else if (role.level < 0) {
    return newCharacter()
  } else {
    return editCharacter()
  }
}

function LabeledSelection(props) {
  const {label, choices, value = '', setter} = props
  return (<>
    <Row>
      <Col><label>{label}: </label></Col>
      <Col>
        <Selection
          style={{borderRadius: '0.375rem', marginLeft: 8, width: 180}}
          name={label} value={{label: value, value: value}}
          onChange={(choice) => setter(choice.label)}
          options={choices}/>
      </Col>
    </Row>
  </>)
}

function Selection(props) {
  const {style, children} = props
  return (<>
    <Select {...props}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles, ...style
              })
            }}>
      {children}
    </Select>
  </>)
}

const FilterMenu = React.forwardRef(
  ({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
    const [value, setValue] = useState('');

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
    // "escape" special regex characters in user input
    const re = new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul style={{overflowY: 'auto', maxHeight: 'calc(100vh - 340px)'}}>
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.match(re),
          )}
        </ul>
      </div>
    )
  }
)
