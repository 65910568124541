import React from 'react'
import {Col, Container, Navbar, Row} from 'react-bootstrap'
import logo from '../images/logo.svg'
import {Link} from 'react-router-dom'

export default function ResetSuccess() {

  return (<>
    <Navbar bg='dark' variant='dark'>
      <Navbar.Brand href='/'>
        <img src={logo} width={30} height={30} className='d-inline-block align-top'
             alt='Spymaster logo'/>
      </Navbar.Brand>
    </Navbar>
    <Container fluid='sm' style={{paddingTop:'80px',textAlign:'center'}}>
      <Row>
        <Col sm={{span:5, offset:3}}>
          <h3>Thank You!</h3>
          <p className='text-muted'>
            Your password has been reset.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{span:5, offset:3}}>
          <Link as='button' to='/login'>Sign in</Link>
        </Col>
      </Row>
    </Container>
  </>)
}