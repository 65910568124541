import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import {Combatant} from '../models/Combatant'
import {saveCombatant} from '../actions/combatActions'
import Category from '../models/Category'

export default function EditCategory(props) {
  const {done, role, category} = props
  const dispatch = useDispatch()

  const [special_bonus1, setSpecialBonus1] = useState(category.special_bonus1)
  const [special_bonus2, setSpecialBonus2] = useState(category.special_bonus2)

  const handleSubmit = (e) => {
    e.preventDefault()
    const categories = role.skill_categories.slice()
    const index = categories.findIndex(cat => cat.id === category.id)
    const new_role = new Combatant(role)
    new_role.skill_categories[index] = new Category({ ...category, special_bonus1, special_bonus2 })
    dispatch(saveCombatant(new_role))
    done()
  }

  return (
    <>
      <Form onSubmit={handleSubmit} onReset={() => done()}>
        <Row className='mb-3'>
          <Form.Group as={Col}>
            <Form.Label>Rank Bonus Type:</Form.Label>
            <Form.Control value={category.rank_bonus_type} readOnly/>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Applicable Stats:</Form.Label>
            <Form.Control value={category.applicable_stats} readOnly/>
          </Form.Group>
        </Row>
        <Row className='mb-3'>
          <Form.Group as={Col}>
            <Form.Label>Special 1:</Form.Label>
            <Form.Control value={special_bonus1 || 0} type='number'
                          onChange={(e) => setSpecialBonus1(e.target.value)}/>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Special 2:</Form.Label>
            <Form.Control value={special_bonus2 || 0} type='number'
                          onChange={(e) => setSpecialBonus2(e.target.value)}/>
          </Form.Group>
        </Row>
        <Row>&nbsp;</Row>
        <Modal.Footer>
          <Button variant='secondary' type='reset'>Cancel</Button>
          <Button variant='primary' type='submit'>Update</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}
