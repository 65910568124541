export class Shape {
  constructor ({id, name, scene_id, origin, extent, x, y, w, h, type, items = []}) {
    this.id = id
    this.name = name
    this.scene_id = scene_id
    this.origin = origin || [x, y]
    this.extent = extent || [w, h]
    this.type = type
    this.items = items.map(item => new Shape(item))
  }

  normalizeGeometry() {
    if (this.extent && this.extent[0] < 0) {
      this.origin[0] += this.extent[0]
      this.extent[0] = -this.extent[0]
    }
    if (this.extent && this.extent[1] < 0) {
      this.origin[1] += this.extent[1]
      this.extent[1] = -this.extent[1]
    }
  }

  // Draw dungeon room
  draw(context, extent, [w, h] = [32, 32]) {
    context.save()
    context.fillStyle = 'white'
    context.strokeStyle = 'black'
    context.lineWidth = 2
    context.beginPath()
    context.roundRect(0, 0, extent[0], extent[1], 2)
    context.fill()
    context.stroke()
    context.fillStyle = 'black'
    const cols = extent[0] / w
    const rows = extent[1] / h
    context.setLineDash([2, 5])
    context.beginPath()
    for (let j = 0; j < cols; j++) {
      context.moveTo(j * w, 0)
      context.lineTo(j * w, extent[1])
    }
    for (let i = 0; i < rows; i++) {
      context.moveTo(0, i * h)
      context.lineTo(extent[0], i * h)
    }
    context.stroke()
    context.restore()
  }

}
