import {createContext, useEffect, useRef, useState} from 'react'
import {AUTH_TOKEN} from '../util/string_constants'
import {useDispatch} from 'react-redux'
import {fetchEncounters, updatePointers} from '../actions/combatActions'

class Session {
  constructor(ready = false, send = () => {}, messages = [], queue = [], connections = []) {
    if (typeof send !== 'function') {
      console.error('Illegal argument', send)
    }
    this.ready = ready
    this.send = send
    this.messages = messages
    this.queue = queue
    this.connections = connections
    this.reconnect = () => {}
  }

  // convenience method
  sendMessage(message) {
    const token = localStorage.getItem(AUTH_TOKEN)
    const payload = {...message, token}
    try {
      this.send(JSON.stringify(payload))
    }
    catch(err) {
      console.error(err)
    }
  }

  gameSession(game_id) {
    return this.connections.find(session => session.adventure.id === game_id)
  }

}

export const GameSessionContext = createContext(new Session())

export default function GameSession({children}) {
  const [session, setSession] = useState(new Session())
  const connections = useRef([])
  const dispatch = useDispatch()

  function connectUser(user) {
    connections.current.forEach(session => {
      if (session.adventure.players.find(ea => ea.player_id === user.user_id)) {
        if (!session.users.find(ea => ea.user_id === user.user_id)) {
          session.users.push(user)
        }
      }
    })
  }

  function disconnectUser(user) {
    connections.current.forEach(session => {
      if (session.adventure.players.find(ea => ea.player_id === user.user_id)) {
        let user_index = session.users.findIndex(ea => ea.user_id === user.user_id)
        if (user_index >= 0) {
          session.users.splice(user_index, 1)
        }
      }
    })
  }

  // force a refresh of GameChat message log that depends on the session's adventure
  function updateAdventure(data) {
    const session = connections.current.find(ea => ea.adventure.owner_id === data.from_id)
    if (session) {
      session.adventure = Object.assign({}, session.adventure)
      updateSession(session)
    }
  }

  // update the existing session with the data from the server
  function updateSession(session) {
    const session_index = connections.current.findIndex(existing => existing.adventure.id === session.adventure.id)
    if (session_index >= 0) {
      connections.current[session_index] = session
    }
  }

  function connect() {
    const ws_url = (process.env.NODE_ENV === 'production') ? 'wss://spymaster.me/chat/' : 'ws://localhost:3001'
    console.warn('creating new WS connection', ws_url)
    let ws = new WebSocket(ws_url)

    function updateConnections(data) {
      const {type} = data
      switch (type) {
        case 'register':
          connections.current = data.sessions
          return
        case 'leave':
          disconnectUser(data.user)
          return
        case 'close':
          disconnectUser(data.user)
          return
        case 'create':
          updateSession(data.session)
          return
        case 'shutdown':
          updateSession(data.session)
          return
        case 'encounter':
          updateSession(data.session)
          const game_id = data.game_id
          dispatch(fetchEncounters(game_id))
          return
        case 'pointer':
          dispatch(updatePointers(data))
          return
        case 'updateUsers':
          connectUser(data.user)
          return
        case 'clear':
          updateAdventure(data)
          return
        default:
          return
      }
    }

    function checkThenSend(data) {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(data)
      }
      else {
        session.queue.push(data)
        console.error('check failed, queuing data', data)
        ws = connect()
      }
    }

    ws.onopen = (_ => {
      while (session.queue && session.queue.length) {
        const message = session.queue.shift()
        ws.send(message)
      }
      // register an interest in any ongoing or upcoming games
      const token = localStorage.getItem(AUTH_TOKEN)
      if (!token) {
        return
      }
      ws.send(JSON.stringify({token, type:'register'}))
      setSession(new Session(true, checkThenSend))
    })

    ws.onmessage = ((message) => {
      const data = JSON.parse(message.data)
      if (data.success === false) {
        console.error(data)
        return
      }
      updateConnections(data)
      const new_session = new Session(true, checkThenSend, [data], [], connections.current)
      setSession(new_session)
    })

    ws.onclose = (_ => {
      const session = new Session(false)
      session.reconnect = connect
      setSession(session)
    })

    ws.onerror = (err) => {
      console.error(err)
      setSession(null)
    }

    return ws
  }

  useEffect(() => {
    const ws = connect()
    return () => {
      ws.close()
    }
  }, [])

  return (
    <GameSessionContext.Provider value={session}>
      {children}
    </GameSessionContext.Provider>
  )
}
