export default class Race {
  constructor(options) {
    const {label, system, data} = options
    this.label = label
    this.system = system
    for (const [key, value] of Object.entries(data)) {
      this[key] = value
    }
  }

  stat_bonus(stat) {
    return this[stat]
  }

}
