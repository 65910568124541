import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import SkillAssignment from '../models/SkillAssignment'
import {Combatant} from '../models/Combatant'
import {fetchSystem, saveCombatant} from '../actions/combatActions'
import Select from 'react-select'

export default function AddSkill(props) {
  const {done, role} = props
  const dispatch = useDispatch()
  const categories = role.skill_categories.map(cat => {return {label:cat.label, value:cat}})
  categories.sort((a, b) => a.value.id - b.value.id)

  const [specialty, setSpecialty] = useState('')
  const [commonUse, setCommonUse] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(categories[0])
  const [selectedSkill, setSelectedSkill] = useState()
  const [skills, setSkills] = useState([])

  const system = useSelector(state => state.combatReducers.system)

  const skillsForCategory = (cat) => {
    return skills
      .filter(ea => ea.cat_id === cat.id)
      .map(skill => {
        return { label:skill.label, value:skill }
      })
  }

  useEffect(() => {
    if (system) {
      setSkills(system.skills)
    }
  }, [system]);

  useEffect(() => {
    if (role) {
      dispatch(fetchSystem(role.system))
    }
  }, [role]);

  useEffect(() => {
    const skillSelections = skillsForCategory(selectedCategory.value)
    setOptions(skillSelections)
    setSelectedSkill(skillSelections[0])
  }, [selectedCategory, skills])

  useEffect(() => {
    setSpecialty('')
  }, [selectedSkill])

  const handleSubmit = (e) => {
    e.preventDefault()
    const theSkill = selectedSkill.value
    const category = role.skill_categories.find(cat => cat.id === theSkill.cat_id)
    const new_skill = new SkillAssignment({...theSkill, category, specialty, common_use: commonUse})
    const new_role = new Combatant(role)
    new_role.skills = role.skills.concat([new_skill])
    dispatch(saveCombatant(new_role))
  }

  return (
      <div className='edit-skill'>
        <Form onSubmit={handleSubmit} onReset={() => done()}>
          <Row>
            <Col xs={4}>
              <Form.Label>Category:</Form.Label>
            </Col>
            <Col xs={7}>
              <Select value={[selectedCategory]}
                      onChange={(selection) => setSelectedCategory(selection)}
                      options={categories}/>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Form.Label>Skill:</Form.Label>
            </Col>
            <Col xs={7}>
              <Select value={[selectedSkill]}
                      onChange={(selection) => setSelectedSkill(selection)}
                      options={options}/>
            </Col>
          </Row>
          {selectedSkill && selectedSkill.value.is_multiple &&
            <Row>
              <Col xs={4}>
                <Form.Label>Specialty:</Form.Label>
              </Col>
              <Col xs={7}>
                <Form.Control value={specialty}
                              onChange={(e) => setSpecialty(e.target.value)}/>
              </Col>
            </Row>}
          <Row>&nbsp;</Row>
          <Modal.Footer>
            <Form.Check label='Commonly used' checked={commonUse}
                        onChange={(e) => setCommonUse(e.target.value)}/>
            <Button variant='secondary' type='reset'>Done</Button>
            <Button variant='primary' type='submit'>Add</Button>
          </Modal.Footer>
        </Form>
      </div>
  )
}
