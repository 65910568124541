import React, {useEffect, useState} from 'react'
import {Card, CloseButton, Pagination} from 'react-bootstrap'
import CharacterSheet from './CharacterSheet'
import MovableComponent from './MovableComponent'

export default function CharacterSheetWrapper(props) {
  const {role, show, onHide} = props
  const [editSkills, setEditSkills] = useState(false)
  const [active, setActive] = useState(1)

  const wrapperStyle = {
    overflowY: 'visible',
    overflowX: 'hidden',
    padding: 0,
  }
  const contentStyle = {
    boxSizing: 'border-box',
    display: 'inline-block',
    transformOrigin: '0 0',
  }

  useEffect(() => {
    // TODO change root to a ref to the component
    const root = document.getElementById('character-sheet-wrapper')
    if (root && show) {
      updateSize(root)
    }
  }, [show, role]);

  function updateSize(ref) {
    const content = ref.querySelector('.cs-content')
    const wrapper = ref.querySelector('.cs-wrapper')
    const cardBody = ref.querySelector('.card-body')
    const cardHeader = ref.querySelector('.card-header')

    const {width, height} = ref.getBoundingClientRect()

    if (!cardBody) {
      return
    }

    // determine the size of the content area of the component (excluding header)
    const headerRect = cardHeader.getBoundingClientRect()
    const bodyHeight = height - headerRect.height
    cardBody.style.height = `${bodyHeight}px`

    if (content) {
      content.style.transform = `scale(${width / 850})`
      wrapper.style.height = `${bodyHeight}px`
    }
  }

  function onResize(event, direction, ref) {
    updateSize(ref)
  }

  if (!show) {
    return null
  }

  // movable component minimum dimensions
  // -- must be at least 186 pixels high to support list components
  // -- must be at least 555 pixels wide to keep skill list headers from wrapping
  return (<>
    <MovableComponent maxWidth={window.innerWidth} maxHeight={window.innerHeight} minWidth={555} minHeight={186}
                      name='character-sheet'
                      cancel='input,.drag-ignore' onResize={onResize} id='character-sheet-wrapper'
                      defaultPosition={{x: 80, y: 24}} defaultSize={{width: 800, height: 600}}>
      <Card>
        <Card.Header className='cc-header' style={{height:'3rem'}}>
          <Pagination size='sm'>
            <Pagination.Item key={1} active={1 === active} onClick={() => setActive(1)}>
              Stats
            </Pagination.Item>
            <Pagination.Item key={2} active={2 === active} onClick={() => setActive(2)}>
              Skill Categories
            </Pagination.Item>
            <Pagination.Item key={3} active={3 === active} onClick={() => setActive(3)}>
              Skills
            </Pagination.Item>
            <Pagination.Item key={4} active={4 === active} onClick={() => setActive(4)}>
              Inventory
            </Pagination.Item>
          </Pagination>
          <CloseButton className='drag-ignore' onClick={onHide} style={{paddingTop:10}}/>
        </Card.Header>
        <Card.Body style={wrapperStyle} className='cs-wrapper drag-ignore'>
          <div className='cs-content' style={contentStyle}>
            <CharacterSheet role={role}
                            isEditing={editSkills}
                            page={active}
                            done={() => setEditSkills(false)}/>
          </div>
        </Card.Body>
      </Card>
    </MovableComponent>
  </>)
}

