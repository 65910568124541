import React, {useEffect} from 'react'
import CharacterSheet from './CharacterSheet'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {fetchRoles, fetchSystem} from '../actions/combatActions'

export default function PrintCharacterSheet() {
  const {id} = useParams()
  const dispatch = useDispatch()
  const roles = useSelector(state => state.combatReducers.combatants)

  const role = roles.find(ea => ea.id === +id)

  useEffect(() => {
    if (role) {
      dispatch(fetchSystem(role.system))
    }
  }, [role])

  useEffect(() => {
    dispatch(fetchRoles())
  }, [])

  useEffect(() => {
    if (role) {
      document.fonts.ready.then(() => {
        window.print()
      })
    }
  }, [role])

  if (!role) {
    return null
  }

  return (<>
    <CharacterSheet isPrinting={true} role={role} page={1}/>
    <div className='page-break'>&nbsp;</div>
    <CharacterSheet isPrinting={true} role={role} page={2}/>
    <div className='page-break'>&nbsp;</div>
    <CharacterSheet isPrinting={true} role={role} page={3}/>
    <div className='page-break'>&nbsp;</div>
    <CharacterSheet isPrinting={true} role={role} page={4}/>
  </>)
}
